import { Box, Typography } from "@mui/material";

export const PageHeader = ({ title = "", description = "", marginTop = 5 }) => {
  return (
    <Box sx={{ marginTop }}>
      <Typography variant={"h2"} gutterBottom>
        {title}
      </Typography>
      <Typography
        variant={"subtitle1"}
        component={"div"}
        sx={{ marginBottom: 5 }}
      >
        {description}
      </Typography>
    </Box>
  );
};
