import { Alert, Container, Stack, ToggleButton } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../../components/DataTable/DataTable";
import { DialogModal } from "../../components/Dialog/Dialog";
import { LoaderPage } from "../../components/LoaderPage/LoaderPage";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { SelectInput } from "../../components/Select/SelectInput";
import { useUserAccessFilterContext } from "../../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { divisionIdToLabel } from "../../components/UserAccessFilter/UserAccessFilter";
import { useSkipContentId } from "../../hooks/useSkipContentId";
import { getPermsForUser } from "./hooks/getPermsForUser";
import { postPermChanges } from "./hooks/postPermChanges";
import { postPermCreations } from "./hooks/postPermCreations";
import { useGetPermissions } from "./hooks/useGetPermissions";
import { useGetSelectOptions } from "./hooks/useGetSelectOptions";
import { useGetUserList } from "./hooks/useGetUserList";

export const EDIT_MODE = { VIEW: 0, EDIT: 1 };

// TODO: I noticed that when deactivating AB/Vax, I can no longer select AB/Thera or AB/Proph in the top selection!

export const AccessProvision = () => {
  const { t } = useTranslation("accessProvisioning");
  const skipContentId = useSkipContentId();

  const [userDataLoading, setUserDataLoading] = useState(true);
  const [userPermDataLoading, setUserPermDataLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [selectedUserPerms, setSelectedUserPerms] = useState(undefined);
  const [editMode, setEditMode] = useState(EDIT_MODE.VIEW);
  const [isResultDialogOpen, setIsResultDialogOpen] = useState(false);
  const [resultObject, setResultObject] = useState({
    creations: undefined,
    changes: undefined,
  });

  const {
    selectableFpts, // based on the FPTs the current user is an admin of
    permissionsWithActiveAdmin, // all FPT/division combos the current user is an admin of
    permissionToken,
  } = useGetPermissions();
  const { isAdmin } = useUserAccessFilterContext();

  const users = // the full list of users we can manage permissions for
    useGetUserList(permissionToken, setUserDataLoading).sort(
      (a, b) =>
        a.userLastName.localeCompare(b.userLastName) ||
        a.userFirstName.localeCompare(b.userFirstName)
    );
  /**
   * Returns a translated string for the provided role ID.
   * @param roleId {number} - currently 1 (general) and 2 (admin)
   * @param original {string} - the original string for the role as returned by the database, this is used if a translation is not provided.
   * @returns {string}
   */
  const roleIdToLabel = (roleId, original) => {
    switch (roleId) {
      case 1:
        return t("portalGeneralUser");
      case 2:
        return t("portalSiteAdministrator");
      default:
        console.error("no translation found for role ", roleId, original);
        return original;
    }
  };

  const roles = // the roles available for selection. currently 1/general and 2/admin.
    useGetSelectOptions("roles", permissionToken)?.map((role) => ({
      value: role.roleId,
      label: roleIdToLabel(role.roleId, role.roleDescription),
    }));

  /**
   * Function used to determine which permissions have already been created for the currently selected user.
   * Used to disable permissions that exist already, so they can not be added a second time.
   * @param {number} fptId
   * @param {number} divisionId
   * @returns {boolean} - true if the permission exists already
   */
  const getPermissionExistsAlready = (fptId, divisionId) =>
    !!selectedUserPerms.find(
      (perm) =>
        perm.Fpt.fptId === fptId && perm.Division.divisionId === divisionId
    );

  /**
   * Builds a list of divisions (in label+value format for SelectInput) that can be selected for the provided fptId.
   * @param {number} fptId
   * @param {number} currentDivisionId - the divisionId that is currently selected in the dropdown, preventing it from being marked disabled
   * @returns {{value: number, label: string, disabled: boolean}[]} - returns a list of divisions that the user is an admin of for the provided FPT
   */
  const getSelectableDivisionsForFpt = (fptId, currentDivisionId) =>
    permissionsWithActiveAdmin
      .filter((perm) => perm.Fpt.fptId === fptId)
      .map((perm) => ({
        value: perm.Division.divisionId,
        label: divisionIdToLabel(perm.Division.divisionId, t),
        disabled:
          getPermissionExistsAlready(fptId, perm.Division.divisionId) &&
          perm.Division.divisionId !== currentDivisionId,
      }));

  /**
   * @param {number} fptId
   * @param {number} divisionId
   * @returns {boolean} - returns true if the user is an admin for the provided FPT/division
   */
  const getIsAdminForFptDivision = (fptId, divisionId) => {
    const foundPerm = permissionsWithActiveAdmin.find(
      (perm) =>
        perm.Fpt.fptId === fptId && perm.Division.divisionId === divisionId
    );
    return !!foundPerm;
  };

  /**
   * When the change dialog is submitted, send these changes to SAP, and save the results once finished.
   */
  const handleSubmitChanges = () => {
    setResultObject({ creations: undefined, changes: undefined });
    const changesNew = selectedUserPerms.filter((perm) => perm.isNew);
    postPermCreations(changesNew, permissionToken).then((response) => {
      console.log("creations: ", response);
      resultObject.creations = response;
      setResultObject({ ...resultObject });
    });
    const changesUpdate = selectedUserPerms.filter(
      (perm) => perm.isUpdated && !perm.isNew
    );
    postPermChanges(changesUpdate, permissionToken).then((response) => {
      console.log("changes: ", response);
      resultObject.changes = response;
      setResultObject({ ...resultObject });
    });
    setIsResultDialogOpen(true);
  };

  /**
   * Resets the dialog data, and closes it, readying it for the next use.
   */
  const closeAndResetDialog = () => {
    setIsDialogOpen(false);
    setUserPermDataLoading(false);
    // we use a timeout here so that when the dialog is closing, there is no re-rendering during the close animation
    setTimeout(() => {
      setSelectedUser(undefined);
      setSelectedUserPerms(undefined);
      setEditMode(EDIT_MODE.VIEW);
    }, 50);
  };

  /**
   * Resets the result dialog data, and closes it, readying it for the next use.
   */
  const closeAndResetResultDialog = () => {
    setIsResultDialogOpen(false);
    // we use a timeout here so that when the dialog is closing, there is no re-rendering during the close animation
    setTimeout(() => {
      setResultObject({
        creations: undefined,
        changes: undefined,
      });
    }, 50);
  };

  // handles fetching permission data when a user is selected for viewing
  useEffect(() => {
    const fetchPermsForSelectedUser = async function () {
      if (!selectedUser) return closeAndResetDialog();
      setUserPermDataLoading(true);
      const userPermissions = await getPermsForUser(
        [selectedUser?.userEmail], // this was built to support multiple users, but after refactoring we just need one at a time
        permissionToken
      );
      const processed = userPermissions
        .map((perm) => ({
          ...perm,
          // not exactly sure of the purpose of these "original" properties, but I'm opting not to refactor them out
          org_access_fpt_id: perm.access_fpt_id,
          org_access_division_id: perm.access_division_id,
        }))
        .sort(
          (a, b) =>
            a.Fpt.fptName.localeCompare(b.Fpt.fptName) ||
            a.Division.divisionDescription.localeCompare(
              b.Division.divisionDescription
            )
        );
      setUserPermDataLoading(false);
      setSelectedUserPerms(processed);
    };
    fetchPermsForSelectedUser().then();
  }, [permissionToken, selectedUser]);

  const columns = [
    {
      name: "userLastName",
      text: t("lastName"),
      inView: true,
      inCSV: true,
      hasFilter: true,
    },
    {
      name: "userFirstName",
      text: t("firstName"),
      inView: true,
      inCSV: true,
      hasFilter: true,
    },
    {
      name: "userEmail",
      text: t("email"),
      inView: true,
      inCSV: true,
      hasFilter: true,
    },
    {
      name: "action",
      text: t("actions"),
      inView: true,
      customViewRenderer: ({ onClick, data }) => (
        <Button
          onClick={onClick}
          variant="outlined"
          id={`view-${data.userEmail}`}
        >
          {t("view")}
        </Button>
      ),
      inCSV: false,
      hasFilter: false,
    },
  ];

  const hasPendingChanges = !!selectedUserPerms?.find((perm) => perm.isUpdated);
  const hasPendingCreations = !!selectedUserPerms?.find((perm) => perm.isNew);
  const pendingCreationsAreInvalid =
    hasPendingCreations &&
    !!selectedUserPerms?.filter(
      (perm) =>
        perm.isNew && (!perm.Fpt.fptName || !perm.Division.divisionDescription)
    ).length;
  const userEmail = permissionsWithActiveAdmin[0]?.User.userEmail;
  const selectedUserEmail = selectedUser?.userEmail;
  const viewingOwnUser = userEmail === selectedUserEmail;

  /**
   * Build an object with various data on the result of the request posted to SAP.
   * @returns {{
   * loading: boolean,
   * success: boolean,
   * hadCreations: boolean,
   * hadChanges: boolean,
   * creationsHadError: boolean,
   * changesHadError: boolean,
   * countSuccessfulCreations: number,
   * countSuccessfulChanges: number
   * }}
   */
  const getResultData = () => {
    const isDoneSubmitting = resultObject.creations && resultObject.changes;
    const hadCreations =
      !!resultObject.creations && !!resultObject.creations.length;
    const hadChanges = !!resultObject.changes && !!resultObject.changes.length;
    const countSuccessfulCreations =
      resultObject.creations?.filter((result) => !result.error).length || -1;
    const countSuccessfulChanges =
      resultObject.changes?.filter((result) => !result.error).length || -1;
    const creationsHadError =
      hadCreations &&
      !!resultObject.creations?.find((result) => !!result.error);
    const changesHadError =
      hadChanges && !!resultObject.changes?.find((result) => !!result.error);
    const anyHadError = !!(creationsHadError || changesHadError);

    return {
      loading: !isDoneSubmitting,
      success: !anyHadError,
      hadCreations,
      hadChanges,
      creationsHadError,
      changesHadError,
      countSuccessfulCreations,
      countSuccessfulChanges,
    };
  };
  const resultData = getResultData();

  return !isAdmin ? (
    <Container maxWidth={"lg"} id={skipContentId}>
      <PageHeader title={t("title")} description={t("noAccess")} />
    </Container>
  ) : (
    <Container maxWidth={"lg"} id={skipContentId}>
      <PageHeader title={t("title")} description={t("subTitle")} />
      <DataTable
        setSelectedItem={(data) => {
          setSelectedUser(data);
          setEditMode(EDIT_MODE.VIEW);
          setIsDialogOpen(true);
        }}
        isDataLoading={userDataLoading}
        data={users}
        columns={columns}
      />
      <DialogModal
        title={`${t("user")}: ${selectedUser?.userLastName}, ${
          selectedUser?.userFirstName
        } (${selectedUser?.userEmail})`}
        open={isDialogOpen}
        maxWidth={"md"}
        bodyComponent={
          <Stack spacing={2}>
            {userPermDataLoading && <LoaderPage />}
            {!userPermDataLoading && (
              <>
                {editMode === EDIT_MODE.VIEW && (
                  <Alert severity="info">
                    {t("userStats", {
                      activeCount: selectedUserPerms?.filter(
                        (perm) => perm.activeInactiveFlag
                      ).length,
                      inactiveCount: selectedUserPerms?.filter(
                        (perm) => !perm.activeInactiveFlag
                      ).length,
                    })}
                  </Alert>
                )}
                {editMode === EDIT_MODE.EDIT && (
                  <Alert severity="info">{t("editHelpText")}</Alert>
                )}
                <TableContainer component={Paper}>
                  <Table size={"small"}>
                    <TableHead>
                      <TableRow>
                        <TableCell align={"center"}>{t("fpt")}</TableCell>
                        <TableCell align={"center"}>{t("division")}</TableCell>
                        <TableCell align={"center"}>{t("role")}</TableCell>
                        <TableCell align={"center"}>{t("actions")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedUserPerms?.map((perm, idx) => {
                        const selectableDivisionsForFpt =
                          getSelectableDivisionsForFpt(
                            perm.Fpt.fptId,
                            perm.Division.divisionId
                          );

                        const canChangeStatus = perm.isNew
                          ? false // forces items to be active if they are new
                          : getIsAdminForFptDivision(
                              perm.Fpt.fptId,
                              perm.Division.divisionId
                            );

                        const canChangeRole = getIsAdminForFptDivision(
                          perm.Fpt.fptId,
                          perm.Division.divisionId
                        );

                        const statusText = perm.activeInactiveFlag
                          ? t("active")
                          : t("inactive");

                        const finishUpdate = () => {
                          perm.isUpdated = true;
                          setSelectedUserPerms([...selectedUserPerms]);
                        };

                        const onChangeFpt = (newFptId) => {
                          const foundFpt = selectableFpts.find(
                            (fpt) => fpt.value === newFptId
                          );
                          perm.access_fpt_id = foundFpt.value;
                          perm.Fpt.fptId = foundFpt.value;
                          perm.Fpt.fptName = foundFpt.label;
                          // clear division selection
                          perm.Division = { divisionDescription: undefined };
                          finishUpdate();
                        };

                        const onChangeDivision = (newDivisionId) => {
                          const foundDivision = selectableDivisionsForFpt.find(
                            (division) => division.value === newDivisionId
                          );
                          perm.access_division_id = foundDivision.value;
                          perm.Division.divisionId = foundDivision.value;
                          perm.Division.divisionDescription =
                            foundDivision.label;
                          finishUpdate();
                        };

                        const onChangeStatus = (newStatus) => {
                          perm.activeInactiveFlag = newStatus;
                          finishUpdate();
                        };
                        const onHandleDelete = (index) => {
                          selectedUserPerms.splice([index], 1);
                          setSelectedUserPerms([...selectedUserPerms]);
                        };

                        const onChangeRole = (newRoleId) => {
                          const foundRole = roles.find(
                            (role) => role.value === newRoleId
                          );
                          perm.access_role_id = foundRole.value;
                          perm.Role.roleId = foundRole.value;
                          perm.Role.roleDescription = foundRole.label;
                          finishUpdate();
                        };

                        return (
                          <TableRow key={perm.accessId}>
                            {/* FPT */}
                            <TableCell align={"center"}>
                              {editMode === EDIT_MODE.EDIT && perm.isNew ? (
                                <SelectInput
                                  id={"fpt"}
                                  options={selectableFpts}
                                  value={perm.Fpt.fptId}
                                  onChangeValue={(newValue) => {
                                    onChangeFpt(newValue);
                                  }}
                                  size={"small"}
                                />
                              ) : (
                                perm.Fpt.fptName
                              )}
                            </TableCell>
                            {/* Division */}
                            <TableCell align={"center"}>
                              {editMode === EDIT_MODE.EDIT && perm.isNew ? (
                                <SelectInput
                                  id={"division"}
                                  options={selectableDivisionsForFpt}
                                  value={perm.Division.divisionId}
                                  onChangeValue={(newValue) => {
                                    onChangeDivision(newValue);
                                  }}
                                  disabled={!selectableDivisionsForFpt.length}
                                  size={"small"}
                                />
                              ) : (
                                divisionIdToLabel(perm.Division.divisionId, t)
                              )}
                            </TableCell>
                            {/* Role */}
                            <TableCell align={"center"}>
                              {editMode === EDIT_MODE.EDIT ? (
                                <SelectInput
                                  id={"role"}
                                  options={roles}
                                  disabled={!canChangeRole}
                                  value={perm.Role.roleId}
                                  onChangeValue={(newValue) => {
                                    onChangeRole(newValue);
                                  }}
                                  size={"small"}
                                />
                              ) : (
                                roleIdToLabel(
                                  perm.Role.roleId,
                                  perm.Role.roleDescription
                                )
                              )}
                            </TableCell>
                            {/* Actions */}
                            <TableCell align={"center"}>
                              {/* Status toggle button when modifying existing permisions */}
                              {editMode === EDIT_MODE.EDIT && !perm.isNew ? (
                                <ToggleButton
                                  value="status"
                                  onChange={() => {
                                    onChangeStatus(!perm.activeInactiveFlag);
                                  }}
                                  selected={canChangeStatus}
                                  disabled={!canChangeStatus}
                                  size={"small"}
                                  color={
                                    perm.activeInactiveFlag
                                      ? "primary"
                                      : "error"
                                  }
                                >
                                  {statusText}
                                </ToggleButton>
                              ) : EDIT_MODE.EDIT && perm.isNew ? (
                                // Delete button to remove newly created permissions
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={() => {
                                    onHandleDelete(idx);
                                  }}
                                >
                                  {t("delete")}
                                </Button>
                              ) : (
                                statusText
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {editMode === EDIT_MODE.EDIT && hasPendingCreations && (
                  <Alert severity="info">{t("creationHelpText")}</Alert>
                )}
              </>
            )}
          </Stack>
        }
        handleClose={() => {
          closeAndResetDialog();
        }}
        primaryButtonText={
          editMode === EDIT_MODE.VIEW ? t("edit") : t("submit")
        }
        handlePrimaryClick={() => {
          if (editMode === EDIT_MODE.VIEW) {
            setEditMode(EDIT_MODE.EDIT);
          } else {
            handleSubmitChanges();
            closeAndResetDialog();
          }
        }}
        primaryButtonDisabled={
          editMode === EDIT_MODE.VIEW
            ? viewingOwnUser || userPermDataLoading // in VIEW mode, disable when viewing yourself, or when still loading
            : !hasPendingChanges || pendingCreationsAreInvalid // in EDIT mode, disable when no changes to submit, or when changes are not valid
        }
        secondaryButtonText={
          editMode === EDIT_MODE.VIEW ? t("close") : t("add")
        }
        handleSecondaryClick={() => {
          if (editMode === EDIT_MODE.VIEW) {
            closeAndResetDialog();
          } else {
            selectedUserPerms.push({
              User: { userEmail: selectedUser?.userEmail },
              Fpt: { fptName: undefined },
              Division: { divisionDescription: undefined },
              Role: { roleDescription: "portal general user", roleId: 1 },
              access_role_id: 1,
              activeInactiveFlag: true,
              isNew: true,
            });
            setSelectedUserPerms([...selectedUserPerms]);
          }
        }}
        secondaryButtonVariant={
          editMode === EDIT_MODE.VIEW ? "text" : "outlined"
        }
        tertiaryButtonText={
          editMode === EDIT_MODE.VIEW ? undefined : t("cancel")
        }
        handleTertiaryClick={() => {
          closeAndResetDialog();
        }}
        tertiaryButtonVariant={"text"}
      />
      <DialogModal
        title={resultData.loading ? t("loading") : t("results")}
        open={isResultDialogOpen}
        maxWidth={"sm"}
        bodyComponent={
          resultData.loading ? (
            <LoaderPage />
          ) : (
            <Stack spacing={2}>
              <Alert
                severity={resultData.success ? "success" : "error"}
                sx={{ whiteSpace: "pre-line" }} // this allows line-breaks to show
              >
                {resultData.success ? t("successMessage") : t("errorMessage")}
                {resultData?.hadCreations &&
                  `\n\n${t("successfulCreations")}: ${
                    resultData.countSuccessfulCreations
                  }`}
                {resultData?.hadChanges &&
                  `\n\n${t("successfulChanges")}: ${
                    resultData.countSuccessfulChanges
                  }`}
                {resultData?.creationsHadError &&
                  `\n\n${t("creationErrors")}:\n${resultObject.creations.map(
                    (result) => ` - ${result.error.message}`
                  )}`}
                {resultData?.changesHadError &&
                  `\n\n${t("changeErrors")}:\n${resultObject.changes.map(
                    (result) => ` - ${result.error.message}`
                  )}`}
              </Alert>
            </Stack>
          )
        }
        handleClose={() => {
          closeAndResetResultDialog();
        }}
        primaryButtonText={t("close")}
        handlePrimaryClick={() => {
          closeAndResetResultDialog();
        }}
      />
    </Container>
  );
};

AccessProvision.defaultProps = {};
