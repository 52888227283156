import dayjs from "dayjs";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Context } from "../../../../context/Context";
import { useUserAccessFilterContext } from "../../../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { postCall } from "../../../utils/requests/apiCalls";
import apiUrls from "../../../utils/requests/apiUrls";

export const useDeleteInventory = () => {
  const currentLocale = useTranslation().i18n.language;
  const { userAttributes } = useContext(Context);
  const { selectedFptText, selectedDivision } = useUserAccessFilterContext();

  const deleteInventoryRequestBody = (
    reportingDate,
    fileName,
    testSwitch = false
  ) => `
  {
    "d" : {
      "Division"    : "0${selectedDivision}",
      "Fpt"         : "${selectedFptText}",
      "InvReptDate" : "${
        reportingDate ? dayjs(reportingDate).format("YYYYMMDD") : ""
      }",
  
      "Filename"    : "${fileName}",
      "SubmitUser"  : "${userAttributes.username}",
      "TestSwitch"  : "${testSwitch ? "X" : ""}",
      "Lang"        : "${currentLocale === "fr-CA" ? "F" : "E"}",
      "Message_FPT_INVSet" : {"results":[]},
      "Detail_FPT_INVSet" : {
        "results" : [ ]
      }
    }
  }`;

  const deleteInventory = async (reportingDate, fileName, testSwitch) => {
    const result = await postCall(
      `${apiUrls.inventory}`,
      deleteInventoryRequestBody(reportingDate, fileName, testSwitch)
    );
    const res = await result.json();
    console.log(
      `[Inventory] ${testSwitch ? "Soft" : ""} DELETE SAP Response`,
      res
    );
    const data = {
      StatusFlag: res.StatusFlag,
      InvReptDate: dayjs(res.InvReptDate).format("ll"),
      ErrorMessages:
        res.Message_FPT_INVSet.results.length > 1
          ? res.Message_FPT_INVSet.results.map((item) => item.MsgText)
          : [],
    };
    return data;
  };

  return { deleteInventory };
};
