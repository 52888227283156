import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import * as _ from "lodash";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const filterOptions = (options, { inputValue }) => {
  return _.uniqBy(options, "address");
};

function onSearchThrottle(onSearch) {
  return _.throttle((e, value) => {
    if (value && value.length > 3) {
      onSearch(value);
    }
  }, 200);
}

export const DropdownAsync = ({
  onSearch = () => {},
  onClear = () => {},
  onSelect,
  selected = {},
  listItems = [],
  searchTerm = "",
  optionLabelKey = "",
  label = "",
  disabled = true,
  disableClearable = undefined,
  required,
  error,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const loading = open && !listItems.length;

  useEffect(() => {}, [searchTerm]);

  return (
    <Autocomplete
      disabled={disabled}
      value={selected}
      filterOptions={filterOptions}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={onSearchThrottle(onSearch)}
      onChange={(e, value) => (value ? onSelect(value) : onClear())}
      getOptionLabel={(option) => option[optionLabelKey] || ""}
      options={listItems}
      loadingText={searchTerm ? t("loading") : t("typeThreeForSearch")}
      disableClearable={disableClearable}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading && searchTerm ? (
                  <CircularProgress
                    color="inherit"
                    sx={{ marginRight: "25px" }}
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};
