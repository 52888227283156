import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getBuildText } from "../../2.0/utils/getBuildText";
import Logo from "../../assets/images/canada_logo.svg";
import strings from "../../utils/strings.js";
import "./footer.scss";

const { REACT_APP_ENVIRONMENT } = process.env;
const displayBuildInfo = REACT_APP_ENVIRONMENT.includes("dev");

const Footer = ({ ariaHidden }) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const lang = currentLocale === "fr-CA" ? "fr" : "en";

  const {
    footer: { copyright, about, terms, privacy, logoAlt, links },
  } = strings;

  return (
    <footer className="footer" aria-hidden={ariaHidden}>
      <div className="footer__container">
        <div className="footer__container__text">
          <p className="footer__container__text__copyright">
            {copyright[lang]}
          </p>
          {
            <div className="footer__container__text__linksContainer">
              <a
                className="footer__container__text__linksContainer__link"
                target="_blank"
                href={links.about[lang]}
                rel="noreferrer"
              >
                {about[lang]}
              </a>
              <div>•</div>
              <a
                className="footer__container__text__linksContainer__link"
                target="_blank"
                href={links.terms[lang]}
                rel="noreferrer"
              >
                {terms[lang]}
              </a>
              <div>•</div>
              <a
                className="footer__container__text__linksContainer__link"
                target="_blank"
                href={links.privacy[lang]}
                rel="noreferrer"
              >
                {privacy[lang]}
              </a>
            </div>
          }
          {displayBuildInfo && (
            <>
              <p>Build Info: {getBuildText()}</p>
            </>
          )}
        </div>
        <div className="footer__container__image">
          <img src={Logo} alt={logoAlt[lang]} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;

Footer.propTypes = {
  overrideLang: PropTypes.string,
};

Footer.defaultProps = {
  overrideLang: undefined,
};
