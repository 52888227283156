import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import strings from "../../../utils/strings.js";
import "./hero.scss";

const Hero = ({ ariaHidden }) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const lang = currentLocale === "fr-CA" ? "fr" : "en";
  const {
    headerTitle,
    home: {
      hero: { welcome },
    },
  } = strings;
  return (
    <div className="homeHero" aria-hidden={ariaHidden}>
      <div className="homeHero__container">
        <Typography noWrap variant={"h2"} component={"h1"} color={"white"}>
          {headerTitle[lang]}
        </Typography>
        <Typography variant={"h5"} component={"div"} color={"white"}>
          {welcome[lang]}
        </Typography>
      </div>
    </div>
  );
};

export default Hero;
