/**
 * Takes the REACT_APP env, and translates it for use in the subdomains.
 * Specifically, translates staging/prod to qa/main.
 * @return {'main2'|'trn2'|'qa2'|'dev2'}
 */
export function getApiEnv() {
  const { REACT_APP_ENVIRONMENT } = process.env;
  switch (REACT_APP_ENVIRONMENT) {
    case "prod2":
      return "main2";
    case "staging2":
      return "qa2";
    default:
      return REACT_APP_ENVIRONMENT;
  }
}

const env = getApiEnv();
console.info("environment: ", env);

const getApiUrls = () => {
  const subdomainPartial = env === "main2" ? "p.g" : "n.g";
  return {
    arcGISAuthUrlString: () =>
      `https://be.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/arcgis/token`,
    arcGISGeolocateAddress: ({
      street,
      city,
      province,
      country,
      postalCode,
    }) => {
      const url = window.location.href;
      const forStorage = url.includes("qa") || url.includes("prod");
      return `https://be.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/arcgis/token?forStorage=${forStorage}&address=${encodeURIComponent(
        street
      )}&city=${encodeURIComponent(city)}&province=${encodeURIComponent(
        province
      )}&countryCode=${encodeURIComponent(
        country
      )}&postalCode=${encodeURIComponent(postalCode)}&outFields=*&f=pjson`;
    },
    modifyAllocationSet: `https://ss.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/modifyAllocationSet`,
    allocationSet: `https://ss.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/allocationSet`,
    skuSet: `https://ss.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/skuSet`,
    periodSet: `https://ss.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/periodSet`,
    previousOrder: `https://sh.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/salesHeadertoItemNav`,
    previousOrderCount: `https://sh.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/salesHeadertoItemNav/count`,
    previousOrderDetails: `https://sh.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/salesHeadertoItemNav/details`,
    shipToAddress: `https://sa.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/shipToAddress`,
    activateDeactivateVDS: `https://sa.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/shipToAddress/activate`,
    editVDS: `https://sa.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/shipToAddress/edit`,
    createOrder: `https://co.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/createOrder`,
    billOfMaterials: `https://co.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/createOrder/billOfMaterials`,
    billOfMaterialsByMaterial: (materialId) =>
      `https://co.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/createOrder/billOfMaterials/${materialId}`,
    userManagement: `https://um.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/users`,
    inventory: `https://wi.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/inventory`,
    wastage: `https://wi.api.${env}.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/waste`,
  };
};

const getLocalAPIUrls = () => {
  const subdomainPartial = env === "main" ? "p.g" : "n.g";
  return {
    arcGISAuthUrlString: () =>
      `https://be.api.dev.portfolio1.n.g.phac.cloud-nuage.canada.ca/arcgis/token`,
    arcGISGeolocateAddress: ({
      street,
      city,
      province,
      country,
      postalCode,
    }) => {
      const url = window.location.href;
      const forStorage = url.includes("qa") || url.includes("prod");
      return `https://be.api.dev.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/arcgis/token?forStorage=${forStorage}&address=${encodeURIComponent(
        street
      )}&city=${encodeURIComponent(city)}&province=${encodeURIComponent(
        province
      )}&countryCode=${encodeURIComponent(
        country
      )}&postalCode=${encodeURIComponent(postalCode)}&outFields=*&f=pjson`;
    },
    modifyAllocationSet: `http://localhost:3030/modifyAllocationSet`,
    allocationSet: `http://localhost:3030/allocationSet`,
    skuSet: `http://localhost:3030/skuSet`,
    periodSet: `http://localhost:3030/periodSet`,
    previousOrderDetails: `http://localhost:3020/salesHeadertoItemNav/details`,
    previousOrder: `http://localhost:3020/salesHeadertoItemNav`,
    previousOrderCount: `http://localhost:3020/salesHeadertoItemNav/count`,
    shipToAddress: "http://localhost:3025/shipToAddress",
    activateDeactivateVDS: `http://localhost:3025/shipToAddress/activate`,
    editVDS: `http://localhost:3025/shipToAddress/edit`,
    createOrder: `http://localhost:3005/createOrder`,
    billOfMaterials: `https://co.api.dev.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/createOrder/billOfMaterials`,
    billOfMaterialsByMaterial: (materialId) =>
      `https://co.api.dev.portfolio1.${subdomainPartial}.phac.cloud-nuage.canada.ca/createOrder/billOfMaterials/${materialId}`,
    inventory: `http://localhost:3040/inventory`,
    wastage: `http://localhost:3040/wastage`,
  };
};

const apiUrls = env === "local" ? getLocalAPIUrls() : getApiUrls();

export default apiUrls;
