import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";

// dayjs initialization and configuration

// load necessary dayjs locales
require("dayjs/locale/en-ca");
require("dayjs/locale/fr-ca");

// load dayjs plugins
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(updateLocale);
dayjs.extend(localeData);
dayjs.extend(duration);
dayjs.extend(relativeTime);

/*
Customize dayjs locales.

Certain formats typically provided are not defined here - this is on purpose to limit the variety of formats we are using.
For some reason we can't pass "z" here, so if using LT/LTS, ensure to include the "z".

DAYJS LOCALE FORMAT REFERENCE
https://day.js.org/docs/en/display/format
https://github.com/iamkun/dayjs/blob/dev/src/locale/en-ca.js
https://github.com/iamkun/dayjs/blob/dev/src/locale/fr-ca.js
*/
const dayjsEn = {
  formats: {
    // times
    LT: "h:mm A",
    LTS: "h:mm:ss A",
    // long dates
    L: "YYYY-MM-DD", // 2023-04-24
    LL: "MMMM D, YYYY", // October 31, 2022
    LLL: "dddd, MMMM D, YYYY", // Monday, October 31, 2022
    // short dates
    ll: "MMM D, YYYY", // Oct 31, 2022
    lll: "ddd, MMM D, YYYY", // Mon, Oct 31, 2022
  },
};
dayjs.updateLocale("en", dayjsEn);
dayjs.updateLocale("en-ca", dayjsEn);
const dayjsFr = {
  formats: {
    // times
    LT: "HH [h] mm",
    LTS: "HH:mm:ss",
    // long dates
    L: "YYYY-MM-DD", // 2023-04-24
    LL: "D MMMM YYYY", // 31 octobre 2022
    LLL: "dddd D MMMM YYYY", // lundi 31 octobre 2022
    // short dates
    ll: "D MMM YYYY", // 31 oct. 2022
    lll: "ddd D MMM YYYY", // lun. 31 oct. 2022
  },
};
dayjs.updateLocale("fr", dayjsFr);
dayjs.updateLocale("fr-ca", dayjsFr);
