import dayjs from "dayjs";
/**
 *
 * Note: This method properly works if the user always provides the date as an input to SAP API. With this the input and output will always match
 * However, if the server creates a date/time then we'll need to use another method to convert the date/time to user's locale (see parseEpochDateTime below)
 * @param {*} date
 * @param {*} lang
 * @returns
 */
const parseDate = (date, lang) => {
  try {
    return date
      ? dayjs(
          new Date(Number(stripNonDigits(date))).toISOString().split("T")[0]
        ).format("L")
      : lang === "fr-CA"
      ? "ND"
      : "N/A";
  } catch (e) {
    return lang === "fr-CA" ? "ND" : "N/A";
  }
};

/**
 * When SAP API sends DEC (15) input date i.e. `/Date(1678113393000+0000)/` then we're going to convert this to Date format dayjs.format("L").
 * Note: SAP team told us to use timezone offset of +10 to match the SAP server time.
 *
 * @param {*} date  - input date object to be converted
 * @param {*} timeZone - select the timezone for initial modification of the date time. i.e if the source date timezone is in another location we can override this value
 * @returns
 */
const parseEpochDateTime = (date, lang, timeZone = "Etc/GMT+10") => {
  if (!date) {
    return lang === "fr-CA" ? "ND" : "N/A";
  }

  const timeStamp = stripNonDigits(date).slice(0, -4);
  // process date
  const processedDate = dayjs(Number(timeStamp)).tz(timeZone, true);

  // Convert to user's local time zone if we know it - otherwise keep what SAP sends us
  return (
    dayjs.tz.guess() ? processedDate.tz(dayjs.tz.guess()) : processedDate
  ).format("L");
};

const stripNonDigits = (input) => {
  return input.replace(/\D/g, "");
};

export { parseDate, parseEpochDateTime };
