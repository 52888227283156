import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../../../components/DataTable/DataTable";
import { LoaderPage } from "../../../components/LoaderPage/LoaderPage";
import {
  XLSX_INVENTORY_INGESTION_COLUMNS,
  XLSX_WASTAGE_INGESTION_COLUMNS,
} from "../../../constants";
import { useDeleteInventory } from "../hooks/useDeleteInventory";
import { useDeleteWastage } from "../hooks/useDeleteWastage";
import { useFetchInventoryListByReportDate } from "../hooks/useFetchInventoryListByReportDate";
import { useFetchWastageListByReportDate } from "../hooks/useFetchWastageListByReportDate";
import { useUpsertInventory } from "../hooks/useUpsertInventory";
import { useUpsertWastage } from "../hooks/useUpsertWastage";

require("dayjs/locale/en");
require("dayjs/locale/fr");

const WasteInventoryPreviewSection = ({
  fileData,
  fileName,
  tableData,
  setTableData,
  flow,
  action,
  setDisableSubmit,
  fileErrorMessage,
  setDeleteRecordNumber,
  dataLoading,
  setDataLoading,
  previewErrorMessage,
  setPreviewErrorMessage,
  categoryData,
  explanationData,
}) => {
  const { fetchInventoryList } = useFetchInventoryListByReportDate();
  const { fetchWastageList } = useFetchWastageListByReportDate();
  const { t, i18n } = useTranslation("wiManagement");
  const currentLocale = i18n.language;
  const [errorNumber, setErrorNumber] = useState(0);
  const { upsertInventory } = useUpsertInventory();
  const { upsertWastage } = useUpsertWastage();
  const { deleteInventory } = useDeleteInventory();
  const { deleteWastage } = useDeleteWastage();

  const convertToSAPDate = (date) => dayjs(date).format("YYYY-MM-DD");

  const getReportingDate = () => {
    if (!fileData || fileData.length === 0) return "";
    if (flow === "WASTAGE") return dayjs(fileData[0].WasReptDate).format("ll");
    if (flow === "INVENTORY")
      return dayjs(fileData[0].InvReptDate).format("ll");
  };

  useEffect(() => {
    const doUpsert = () => {
      if (tableData !== null) return;
      if (flow === "INVENTORY") {
        upsertInventory(fileData, fileName, true).then((data) => {
          setTableData(data.Items);
          setDataLoading(false);
          if (data.StatusFlag === "FAIL" && data.ErrorMessages.length > 0) {
            setPreviewErrorMessage(data.ErrorMessages);
          }
          setErrorNumber(
            data.Items?.filter((item) => item.StatusFlag !== "OK").length
          );
          setDisableSubmit(
            data.StatusFlag === "FAIL" ||
              data.Items?.filter((item) => item.StatusFlag !== "OK").length > 0
          );
        });
      }

      if (flow === "WASTAGE") {
        upsertWastage(fileData, fileName, true, categoryData).then((data) => {
          const tableDataTranslated = data.Items.map((item) => {
            return {
              ...item,
              Explanation: explanationData.filter(
                (explanation) =>
                  explanation.Explanation === item.ExpCode &&
                  explanation.Language ===
                    (currentLocale === "fr-CA" ? "FR" : "EN")
              )[0]?.ExplanationDesc,
              Category: categoryData.filter(
                (category) =>
                  category.Category === item.CatCode &&
                  category.Language ===
                    (currentLocale === "fr-CA" ? "FR" : "EN")
              )[0]?.Description,
            };
          });
          setTableData(tableDataTranslated);
          setDataLoading(false);
          if (data.StatusFlag === "FAIL" && data.ErrorMessages.length > 0) {
            setPreviewErrorMessage(data.ErrorMessages);
          }
          setErrorNumber(
            data.Items.filter((item) => item.StatusFlag !== "OK").length
          );
          setDisableSubmit(
            data.StatusFlag === "FAIL" ||
              data.Items.filter((item) => item.StatusFlag !== "OK").length > 0
          );
        });
      }
    };
    const doDelete = () => {
      if (tableData !== null) return;
      if (flow === "INVENTORY") {
        deleteInventory(fileData[0].InvReptDate, fileName, true)
          .then((data) => {
            setErrorNumber(data.StatusFlag === "FAIL" ? 1 : 0);
            setDisableSubmit(data.StatusFlag === "FAIL");
            setPreviewErrorMessage(data.ErrorMessages);
            // if the status flag is OK...
            fetchInventoryList({
              reportingDateFilter: convertToSAPDate(fileData[0].InvReptDate),
            })
              .then((data) => {
                setTableData(data);
                setDataLoading(false);
                setDeleteRecordNumber(data.length);
              })
              .catch((e) => {
                console.log(
                  "[WasteInventoryPreviewSection] - something went wrong when fetching inventory data by Reporting Date!",
                  e
                );
              });
          })
          .catch((e) => {
            console.log(
              "[WasteInventoryPreviewSection] - something went wrong when soft deleting for Inventory!",
              e
            );
          });
      }

      if (flow === "WASTAGE") {
        deleteWastage(fileData[0].WasReptDate, fileName, true)
          .then((data) => {
            setErrorNumber(data.StatusFlag === "FAIL" ? 1 : 0);
            setDisableSubmit(data.StatusFlag === "FAIL");
            setPreviewErrorMessage(data.ErrorMessages);
            // if the status flag is OK...
            fetchWastageList({
              reportingDateFilter: convertToSAPDate(fileData[0].WasReptDate),
            })
              .then((data) => {
                setTableData(data);
                setDataLoading(false);
                setDeleteRecordNumber(data.length);
              })
              .catch((e) => {
                console.log(
                  "[WasteInventoryPreviewSection] - something went wrong when fetching Wastage data by Reporting Date!"
                );
              });
          })
          .catch((e) => {
            console.log(
              "[WasteInventoryPreviewSection] - something went wrong when soft deleting for Wastage!"
            );
          });
      }
    };
    if (action === "UPSERT") doUpsert();
    if (action === "DELETE") doDelete();
  }, [
    flow,
    upsertInventory,
    upsertWastage,
    fileData,
    fileName,
    setTableData,
    tableData,
    action,
    fetchInventoryList,
    fetchWastageList,
    categoryData,
    explanationData,
  ]);

  let columns;
  if (flow === "INVENTORY") columns = XLSX_INVENTORY_INGESTION_COLUMNS();
  if (flow === "WASTAGE") columns = XLSX_WASTAGE_INGESTION_COLUMNS();

  return (
    <Stack spacing={1}>
      {action === "UPSERT" && (
        <>
          <Typography variant={"title"} gutterBottom>
            {t(
              `previewDialogUpsertReview${
                flow[0].toUpperCase() + flow.slice(1).toLowerCase()
              }`
            )}
          </Typography>
          <Typography variant={"title"} gutterBottom>
            {t("previewDialogUpsertUpload", { fileName })}
          </Typography>
          {errorNumber > 0 && (
            <Typography variant={"title"} sx={{ color: "red" }}>
              {t("previewDialogError", {
                errorCount: errorNumber,
              })}
            </Typography>
          )}
          {fileErrorMessage && (
            <Typography variant={"title"} sx={{ color: "red" }}>
              {fileErrorMessage}
            </Typography>
          )}
          {previewErrorMessage && (
            <Typography variant={"title"} sx={{ color: "red" }}>
              {previewErrorMessage.join(". ")}
            </Typography>
          )}
        </>
      )}

      {action === "DELETE" && (
        <>
          <Typography variant={"title"} gutterBottom>
            {t(
              `previewDialogDeleteReview${
                flow[0].toUpperCase() + flow.slice(1).toLowerCase()
              }`
            )}
          </Typography>
          <Typography variant={"title"} gutterBottom>
            {t("previewDialogDeleteRecords", {
              reportingDate: getReportingDate(),
            })}
          </Typography>
          {errorNumber > 0 && (
            <Typography variant={"title"} sx={{ color: "red" }}>
              {t("previewDialogError", {
                errorCount: "",
              })}
            </Typography>
          )}
          {previewErrorMessage && (
            <Typography variant={"title"} sx={{ color: "red" }}>
              {previewErrorMessage.join(". ")}
            </Typography>
          )}
        </>
      )}

      {dataLoading ? (
        <LoaderPage />
      ) : (
        <DataTable columns={columns} data={tableData} defaultRowsPerPage={10} />
      )}
    </Stack>
  );
};
export default WasteInventoryPreviewSection;
