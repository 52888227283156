import { getCall } from "./apiCalls";
import apiUrls from "./apiUrls";

/**
 * @param {{country: string, province: string, city: string, street, postalCode: string}} address
 * @returns {Promise<{candidates: Array<{attributes: {StAddr: string, AddNum: string, City: string, Postal: string, PostalExt: string, X: number, Y: number}, location: object}>}>}
 */
export const requestArcGISGeolocationAddress = async (address) => {
  try {
    const url = apiUrls.arcGISGeolocateAddress(address);
    const response = await getCall(url)
      .then(async (res) => {
        return res.json();
      })
      .catch((e) => {
        throw e;
      });
    return Promise.resolve(response.data);
  } catch (e) {
    console.error(e);
    return Promise.reject({
      data: {},
      error: { message: "unable to query for geolocation ", err: new Error(e) },
    });
  }
};

// https://be.api.dev.portfolio1.n.g.phac.cloud-nuage.canada.ca/arcgis/token
// https://be.api.dev.portfolio1.n.g.phac.cloud-nuage.canada.ca/arcgis/findAddressCandidates?forStorage=false&address=pint&city=Richmond&province=ON&countryCode=CA&postalCode=v7e4p5&token=fplIBZMCIjy25udJbMBEHdQdT5eNVjyASzdpgwBikwlBduBHiVabv5x6SBoJ2jHUus1t1RCJupvxUguwcVw34S8KaQkHsnMuyTn21im2mmGSizQOQHrozttWfVpdwCsX01lqwzxyfN8LRmrRzXVcOg..&outFields=*&f=pjson
