import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCall } from "../../../utils/requests/apiCalls";
import apiUrls from "../../../utils/requests/apiUrls";

/**
 * Fetches the wastage category data from SAP, and does some minor processing to the response.
 */
const fetchExplanationList = async () => {
  console.log("[fetchExplanationList] fetching");
  const result = await getCall(`${apiUrls.wastage}/explanation`);
  if (!result || result.status !== 200) {
    console.error(`[fetchExplanationList] failed to fetch: `, result);
    return [];
  }

  let resultJson = null;
  try {
    resultJson = await result.json();
  } catch (e) {
    console.error("[fetchExplanationList] failed to parse result: ", e);
    return [];
  }

  if (!resultJson || !resultJson.results || !resultJson.results.length) {
    console.error("[fetchCategoryList] result contains no data: ", resultJson);
    return [];
  }

  const resultProcessed = resultJson.results.map((explanation) => {
    return {
      Category: explanation.Category,
      CategoryDesc: explanation.CategoryDesc,
      Explanation: explanation.Explanation,
      ExplanationDesc: explanation.ExplanationDesc,
      Language: explanation.Language,
    };
  });

  return resultProcessed;
};

export const useFetchExplanationList = () => {
  const currentLocale = useTranslation().i18n.language;
  const [explanationData, setExplanationData] = useState([]);
  const [explanationDataLoading, setExplanationDataLoading] = useState(true);

  useEffect(() => {
    fetchExplanationList().then((result) => {
      setExplanationData(result);
    });
  }, [currentLocale]);

  return {
    explanationData,
    setExplanationData,
    explanationDataLoading,
    setExplanationDataLoading,
  };
};
