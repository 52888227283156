import * as _ from "lodash";
import { useEffect, useState } from "react";
import {
  canadaPostalCodeRegex,
  emailRegex,
  phoneIntlRegex,
  phoneRegex,
  usPostalCodeRegex,
} from "../../../constants";
import { stateFunctions } from "./DsHandlers";

/**
 * builds form validations
 * @param {object} formData - form data object
 * @param {boolean} createMode - doc is being created
 * @returns {{country: {type: string, required: boolean}, preferredLanguage: {type: string, required: boolean}, city: {type: string, required: boolean}, postalCode: {type: string, required: boolean}, active: {type: string, required: boolean}, del: {type: string, required: (boolean|string|*)}, delFlag: {type: string, required: boolean}, usePfizer: {type: string, required: boolean}, dsName1: {type: string, required: boolean, maxLength: number}, dsName2: {type: string, required: boolean, maxLength: number}, province: {type: string, required: boolean}, street: {type: string, required: boolean}, shippingNote: {type: string, required: boolean, maxLength: number}, contacts: {"0": {phone: {type: string, required: boolean}, name: {type: string, required: boolean}, email: {type: string, required: boolean}}, "1": {phone: {type: string, required: boolean}, name: {type: string, required: boolean}, email: {type: string, required: boolean}}, "2": {phone: {type: string, required: boolean}, name: {type: string, required: boolean}, email: {type: string, required: boolean}}}}}
 */
export const initialVDSValidationBuilder = ({
  formData,
  createMode = false,
}) => {
  return {
    dsName1: {
      type: "string",
      required: true,
      maxLength: 35,
    },
    dsName2: {
      type: "string",
      required: false,
      maxLength: 35,
    },
    active: {
      type: "boolean",
      required: true,
    },
    delFlag: {
      type: "boolean",
      required: true,
    },
    del: {
      type: "string",
      required: formData?.delFlag,
    },
    preferredLanguage: {
      type: "string",
      required: true,
    },
    usePfizer: {
      type: "boolean",
      required: true,
    },
    contacts: {
      0: {
        name: {
          type: "string",
          required: true,
        },
        phone: {
          type: "string",
          required: true,
          regEx: showProvinceField(formData.country)
            ? phoneRegex
            : phoneIntlRegex,
        },
        email: {
          type: "string",
          required: true,
          regEx: emailRegex,
        },
      },
      1: {
        name: {
          type: "string",
          required: false,
        },
        phone: {
          type: "string",
          required: false,
          regEx: showProvinceField(formData.country)
            ? phoneRegex
            : phoneIntlRegex,
        },
        email: {
          type: "string",
          required: false,
          regEx: emailRegex,
        },
      },
      2: {
        name: {
          type: "string",
          required: false,
        },
        phone: {
          type: "string",
          required: false,
          regEx: showProvinceField(formData.country)
            ? phoneRegex
            : phoneIntlRegex,
        },
        email: {
          type: "string",
          required: false,
          regEx: emailRegex,
        },
      },
    },
    city: {
      type: "string",
      required: createMode,
    },
    country: {
      type: "string",
      required: true,
    },
    postalCode: {
      type: "string",
      required: createMode && showProvinceField(formData.country),
      regEx:
        createMode && formData.country === "CA"
          ? canadaPostalCodeRegex
          : createMode && formData.country === "US"
          ? usPostalCodeRegex
          : false,
      maxLength:
        formData.country === "CA" || formData.country === "US" ? 10 : 10000,
    },
    province: {
      type: "string",
      required: createMode && showProvinceField(formData.country),
    },
    street: {
      type: "string",
      required: createMode,
    },
    shippingNote: {
      type: "string",
      required: false,
      maxLength: 1000,
    },
  };
};

/**
 * calculates if country show have a province field shown
 * @param {string} country - the country identifier
 * @returns {boolean}
 */
export const showProvinceField = (country) => {
  const showFieldCountries = ["CA", "US"];
  return showFieldCountries.includes(country);
};

/**
 * compares a field value based on validator rules
 * @param {object} formValidation - form field validator definition
 * @param {any} fieldValue - field value
 * @returns {boolean}
 */
const checkFieldValid = ({ formValidation, fieldValue }) => {
  let valid = true;
  if (formValidation.required) {
    switch (formValidation.type) {
      case "string":
        if (!fieldValue) {
          valid = false;
        }
        break;
      case "boolean":
        if (fieldValue !== true && fieldValue !== false) {
          valid = false;
        }
        break;
      default:
    }
  }

  if (valid && fieldValue && formValidation.regEx) {
    if (!formValidation.regEx.test(fieldValue)) {
      valid = false;
    }
  }

  return valid;
};

/**
 * checks form validations
 * @param {boolean} formEditable - is form editable
 * @param {object} formData - for data object
 * @param {boolean} createMode - data is a creation
 * @returns {{formInvalidState: {}, formInvalid: boolean}}
 */
export const checkIfVDSFormValid = ({ formEditable, createMode, formData }) => {
  if (!formEditable || !formData) {
    return {
      formInvalid: false,
      formInvalidState: {},
    };
  }

  let formInvalid = false;
  const formValidations = initialVDSValidationBuilder({ formData, createMode });
  const formInvalidState = {};

  Object.keys(formValidations).forEach((formValidationKey) => {
    if (formValidationKey === "contacts") {
      formData.contacts.forEach((contact, index) => {
        const formValidation = formValidations["contacts"][index];
        if (formValidation) {
          Object.keys(formValidation).forEach((formValidatorKey) => {
            if (
              formData.active &&
              !checkFieldValid({
                formValidation: formValidation[formValidatorKey],
                fieldValue: contact[formValidatorKey],
              })
            ) {
              formInvalid = true;
              if (!formInvalidState.contacts) formInvalidState.contacts = {};
              if (!formInvalidState.contacts[index])
                formInvalidState.contacts[index] = {};
              formInvalidState.contacts[index][formValidatorKey] = true;
            }
          });
        }
      });
    } else {
      if (
        !checkFieldValid({
          formValidation: formValidations[formValidationKey],
          fieldValue: formData[formValidationKey],
        })
      ) {
        formInvalid = true;
        formInvalidState[formValidationKey] = true;
      }
    }
  });

  return {
    formInvalid,
    formInvalidState,
  };
};

/**
 * sets validation state
 * @param {boolean} formEditable - is form editable
 * @param {object} formData - form data object
 * @param {boolean} createMode - data is a creation
 * @param {object} cleanState - original form data state
 * @param {boolean} prevIsDirty - if the current/previous state is "dirty"
 * @returns {{formInvalidState: {}, formInvalid: boolean}}
 */
export const useVDSFormValidationEffect = ({
  formEditable,
  createMode,
  formData,
  cleanState,
  prevIsDirty,
}) => {
  const [invalid, setInvalid] = useState(
    checkIfVDSFormValid({ formEditable, formData, createMode })
  );

  useEffect(() => {
    const nowIsDirty = !_.isEqual(cleanState, formData);
    if (nowIsDirty !== prevIsDirty) stateFunctions.setDirty(nowIsDirty);

    const validityResults = checkIfVDSFormValid({
      formEditable,
      createMode,
      formData,
    });
    if (!_.isEqual(invalid, validityResults)) {
      setInvalid(validityResults);
    }
  }, [cleanState, createMode, formData, formEditable, invalid, prevIsDirty]);

  return invalid;
};
