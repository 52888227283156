import { useTranslation } from "react-i18next";

export function useTranslateUnits() {
  const { t } = useTranslation(["common"]);

  /**
   * Takes a quantity and unit of measure from SAP, and returns a translated and pluralized string for display in the UI.
   * @param {number} quantity - the quantity of units
   * @param {'DS'|'VI'|'PAK'|'PL'|string} unitOfMeasure - the key for the Unit of Measure from SAP
   * @return {string} - returns something like "vial" or "doses" - text does not include the count, units are translated and pluralized
   */
  function getTranslatedUnits(quantity, unitOfMeasure) {
    const productTranslationKey =
      unitOfMeasure === "PAK"
        ? "packsCourses"
        : unitOfMeasure === "DS"
        ? "doses"
        : unitOfMeasure === "VI"
        ? "vials"
        : unitOfMeasure === "PL"
        ? "payloads"
        : "units";
    return t(productTranslationKey, { count: quantity }).toLowerCase();
  }

  /**
   * Takes a quantity and unit of measure from SAP, and returns a translated and pluralized string for display in the UI.
   * @param {number} quantity - the quantity of units
   * @param {'DS'|'VI'|'PAK'|'PL'|string} unitOfMeasure - the key for the Unit of Measure from SAP
   * @return {string} - returns something like "1 Vial" or "2 Doses" - text includes the count, and the units are translated and pluralized
   */
  function getTranslatedUnitsWithValue(quantity, unitOfMeasure) {
    return `${t("intlNumber", { val: quantity })} ${getTranslatedUnits(
      quantity,
      unitOfMeasure
    )}`;
  }

  /**
   * Takes a Unit of Measure from SAP, and returns a translated string for display in the UI.
   * @param {'DS'|'VI'|'PAK'|string} unitOfMeasure - the key for the Unit of Measure from SAP
   * @return {string} - returns something like "Doses per payload" or "Packs per box" - translated
   */
  function getTranslatedUnitsPerPayload(unitOfMeasure) {
    return unitOfMeasure === "PAK"
      ? t("packsPerBox")
      : unitOfMeasure === "DS"
      ? t("dosesPerPayload")
      : unitOfMeasure === "VI"
      ? t("vialsPerPayload")
      : t("unitsPerPayload");
  }

  return {
    getTranslatedUnits,
    getTranslatedUnitsWithValue,
    getTranslatedUnitsPerPayload,
  };
}
