export const productRequest = {
  tab: "Demande de produit",
  placeOrderTitle: "Soumettre une demande de produit",
  placeOrderSubtitle:
    "Veuillez remplir les champs ci-dessous pour créer une nouvelle demande de produit.",
  productInformation: "Informations sur le produit",
  selectedFPTRegion: "Fédéral/Province/Territoire",
  FPTReferenceNumber: "Numéro de référence FPT",
  groupNotes: "Notes de groupe",
  groupNotesHint:
    "Vous pouvez ajouter des notes applicables à toutes les demandes de produits ci-dessous.",
  productRequest: "Demande de produit",
  addDiluent: "Ajouter un diluant ",
  allocationRemaining: "Attribution restante ",
  selectedLocationsLabel: "Sites de livraison sélectionnés",
  selectedLocationsPlaceholder: "Sélectionner les sites de livraison…",
  totalUnitsRequested: "Nombre total d’unités demandées",
  requestedDate: "Date demandée",
  deliverySiteInformation: "Informations sur le site de livraison",
  carryForwardTooltip:
    "{{count}} de ces {{units}} sont reportées d'une semaine précédente.",
  issueWithOrder: "Il y a un problème avec cette demande. Veuillez résoudre.",
  overDsLimitError:
    "Vous avez sélectionné plus de 50 sites de livraison. Veuillez réduire le nombre de sites de livraison sélectionnés avant de faire votre demande.",
  quantitiesExceed:
    "Le nombre total d’unités de {{productType}} demandées ({{ordersTotal}}) dépasse la quantité hebdomadaire allouée. Veuillez réduire votre nombre d’unités.",
  editRequest: "Modifier la commande",
  importantNotice: "Avis important",
  currentWeekClosed:
    "Les demandes pour la semaine en cours ({{currentWeek}}) ont clôturé le {{closedOn}} à {{closedAt}}. Veuillez contacter l'Unité de soutien stratégique (USS) pour plus d’informations. Courriel : vaccine-connect-support-connexion-vaccin@phac-aspc.gc.ca",
  followingWeeksNoAllocation:
    "Les semaines suivantes n’apparaissent pas ci-dessous car il n’y a pas d'attribution disponible pour ces semaines :",
  markOrderForDeletion: "Supprimer cette demande",
  unmarkOrderForDeletion: "Annuler la suppression de cette demande",
  copyFromPrevious: "Copier la demande de la semaine précédente",
  newAllocationButton: "Demander de Nouvelles Attributions",
  newAllocationTitle: "Soumettre une demande de nouvelles attributions",
  newAllocationSubmitButton: "Valider la demande",
  newAllocationDescription:
    "Veuillez utiliser le formulaire ci-dessous pour demander des attributions supplémentaires pour l’article sélectionné. Jusqu’à 4 demandes peuvent être incluses.",
  newAllocationReserveAndAllocationTitle:
    "Réserve disponible et attributions actuelles",
  newAllocationReserveText: "Réserve actuelle : {{reserve}}",
  newAllocationTotalCurrentAllocationText:
    "Attribution totale actuellement disponible : {{totalAllocation}}",
  newAllocationAllocationError:
    "Il y a une erreur avec le(s) champ(s) surligné(s) en rouge. Veuillez mettre à jour et soumettre votre demande à nouveau.",
  newAllocationAllocationColumn: "Attibutions",
  newAllocationDeliveryDateColumn: "Date de livraison",
  newAllocationAllocationRequest: "Entrez la demande d'attribution",
  newAllocationRequestorEmail: "Courriel du demandeur",
  newAllocationJustification: "Justification",
  newAllocationJustificationHelper:
    "Veuillez fournir une justification pour les nouvelles attributions en utilisant l’espace ci-dessus. Limite de {{current}} / 254 caractères.",
  newAllocationSubmittedTitle: "Demande soumise",
  newAllocationSubmittedDescription:
    "Votre demande d’attributions supplémentaires a été soumise. Après examen, le CNO confirmera si votre demande a été approuvée ou non.",
  newAllocationReviewDescription:
    "Veuillez réviser les informations saisies dans le formulaire ci-dessous avant de soumettre votre demande d’attributions supplémentaires.",
  newAllocationRequestTitleError: "Erreur de soumission de la demande",
  newAllocationRequestError:
    "Il y a eu une erreur inattendue lors de la soumission de votre demande. Pour obtenir de l'aide, veuillez contacter l'Unité de soutien stratégique (USS) à l'adresse vaccine-connect-support-connexion-vaccin@phac-aspc.gc.ca. Si possible, inclure une capture d'écran de ce message d'erreur.",
};
