import { Container, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserAccessFilterContext } from "../../../2.0/components/UserAccessFilter/contexts/UserAccessFilterContext";
import { MOBILE_BREAKPOINT } from "../../../2.0/constants";
import { useSkipContentId } from "../../../2.0/hooks/useSkipContentId";
import { getCanUseAllocationPage } from "../../../2.0/pages/allocation-management/utils/getCanUseAllocationPage";
import SyringeImage from "../../../assets/images/landing-page-syringe.png";
import strings from "../../../utils/strings.js";
import { SupportInfo } from "../../Common/SupportInfo/SupportInfo";
import OrderLineItemSummary from "../OrderStatusDashboard/OrderLineItemSummary";
import OrderReserveSummary from "../OrderStatusDashboard/OrderReserveSummary";
import "./landingPage.scss";
import { getJobAidUrl } from "./utils/getJobAidUrl";

const {
  home: { tabs },
} = strings;

/*
export const NAV_STATES = Object.freeze({
  CURRENT: 1,
  PREVIOUS: 2,
  HOME: 3,
  ORDER: 4,
  ALLOCATION: 5,
  VDS: 6,
  ACCESS_PROVISION: 7,
});
*/
// Has to match the names under strings.js, under tabs

const LandingPage = ({ ariaHidden, setAriaHidden, nav, setNav }) => {
  const { t, i18n } = useTranslation(["home"]);
  const currentLocale = i18n.language;
  const lang = currentLocale === "fr-CA" ? "fr" : "en"; // keeping this until we can finally remove the old strings translation solution
  const skipContentId = useSkipContentId();

  const { isAdmin, selectedFptText } = useUserAccessFilterContext();
  const canUseAllocationPage = getCanUseAllocationPage(selectedFptText);

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_BREAKPOINT
  );

  const reportWindowSize = ({ currentTarget: { innerWidth } }) => {
    setIsMobile(innerWidth < MOBILE_BREAKPOINT);
  };

  useEffect(() => {
    window.addEventListener("resize", reportWindowSize);

    return () => {
      window.removeEventListener("resize", reportWindowSize);
    };
  }, []);

  return (
    <div className="landingPage" id={skipContentId}>
      <div className="landingPage__background">
        <div className="landingPage__background__image" />
        <div className="landingPage__background__overlay" />
      </div>

      {!isMobile && (
        <div className="landingPage__header">
          <div className="landingPage__header__text">
            <Typography
              variant={"h3"}
              component={"h2"}
              color={"white"}
              sx={{ textShadow: "#000 1px 1px 4px" }}
            >
              {t("headerTitle")}
            </Typography>
            <Typography
              variant={"h5"}
              component={"div"}
              color={"white"}
              sx={{ textShadow: "#000 1px 1px 4px" }}
            >
              {t("headerSubtitle")}
            </Typography>
          </div>
          <div className="landingPage__header__imageContainer">
            <img alt="" src={SyringeImage} aria-hidden />
          </div>
        </div>
      )}
      <Container maxWidth={"xl"}>
        <Stack padding={isMobile ? 0 : 8} className="landingPage__body">
          <Typography
            variant={"h4"}
            component={"h3"}
            mt={isMobile ? 4 : 0}
            mb={2}
          >
            {t("welcomeHeader")}
          </Typography>
          <Typography mb={2}>{t("welcomeBody1")}</Typography>
          <Typography mb={2}>{t("welcomeBody2")}</Typography>
          <Button
            href={getJobAidUrl(currentLocale)}
            download
            role={"button"}
            variant={"contained"}
            size={"large"}
            style={{ maxWidth: "fit-content" }}
            target={"_blank"} // opens in a new tab
            rel="noreferrer" // recommended by eslint after adding target='_blank'
          >
            {t("jobAidButton")}
          </Button>
          <OrderLineItemSummary isMobile={isMobile} />
          <OrderReserveSummary isMobile={isMobile} />
          <div className="landingPage__body__container">
            <Typography variant={"h4"} mt={4} mb={2}>
              {t("overviewHeader")}
            </Typography>
            <Typography>{t("overviewBody")}</Typography>
            <Stack component={"dl"} spacing={2}>
              {Object.keys(tabs)
                .filter((tab) => {
                  if (tab === "accessProvision") return isAdmin;
                  else if (tab === "allocations") return canUseAllocationPage;
                  else return true;
                })
                .map((tab) => (
                  <Fragment key={tab}>
                    <Typography variant={"h5"} component={"dt"}>
                      {tabs[tab].tab[lang]}
                    </Typography>
                    <Typography component={"dd"}>
                      {tabs[tab].description[lang]}
                    </Typography>
                  </Fragment>
                ))}
            </Stack>
          </div>

          <Typography variant={"h4"} mt={4} mb={2}>
            {t("purposeHeader")}
          </Typography>
          <SupportInfo />
        </Stack>
      </Container>
    </div>
  );
};

export default LandingPage;
