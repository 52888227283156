/**
 * Accepts a value and ensures it is a positive integer - no negatives or decimals.
 * @param {string|number} value
 * @return {number|string}
 */
export function sanitizeInteger(value) {
  // if blank or invalid, return blank
  if (isNaN(value) || value === undefined) return "";
  // convert to number type, removes leading zeros
  value = Number(value);
  // reset negative numbers to zero
  if (value < 0) return 0;
  // otherwise, all good
  return value;
}
