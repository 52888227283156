import { enUS, frFR } from "@mui/material/locale";
import { alpha, createTheme, darken } from "@mui/material/styles";

// for more info please see: https://mui.com/material-ui/customization/theme-components/#theme-default-props
const getTheme = (locale) =>
  createTheme(
    {
      // this fix is for VC-3376 - to ensure the VaccineConnect h1 logo (variant h2) does not exceed more than the allocated screen.
      typography: {
        h2: {
          fontSize: "2.5rem",
          "@media (min-width:600px)": {
            fontSize: "4rem",
          },
        },
      },
      colors: {
        // Theme Colors
        colorWhite: "#ffffff",
        colorBlack: "#000",
        colorGrey: "#cbc9c9",
        colorGreyBorder: "#b6b4b4",
        colorGreyAllocationBorder: "#ccc8c8",
        colorTextRegular: "#333333",
        colorDisabled: "#f2f1f1",
        colorDisabledText: "#929292",
        colorGreyLight: "#fafafa",
        colorGreyDark: "#4f4f4f",
        colorDarkBlue: "#293749",
        colorRed: "#b62525",
        colorBlue: "#3a73d8",
        colorLinkBlue: "#284162",
        colorLinkVisited: "#7834bc",
        colorPlaceholder: "#636161",
        colorNiceGreen: "#ecf1e8",
        colorAlertError: "#f1e7e6",
        colorAlertErrorHighlight: "#d1080c",
        colorAlertWarning: "#f9f4d8",
        colorAlertWarningHighlight: "#d0b100",
        colorAlertSuccess: "#ecf1e8",
        colorAlertSuccessHighlight: "#54981e",
        colorAlertInfo: "#effbff",
        colorAlertInfoHighlight: "#1d9bc2",
        colorAlertDanger: "#fff5ed",
        colorAlertDangerHighlight: "#e08645",
        colorAlertWarningText: "#903534",
        colorDivider: "#c4c4c4",
        colorTableHeaderOdd: "#3a73d8",
        colorTableHeaderEven: "#6599f6",
        colorTableBodyOdd: "#f2f2f2",
        colorTableBodyEven: "#fafafa",
        colorLandingPageBackground: "rgba(58, 115, 216, 0.9)",
      },
      components: {
        MuiChip: {
          styleOverrides: {
            // modify delete Icon to be more accessible
            deleteIcon: ({ ownerState, theme }) => ({
              color: theme.palette.grey[600],
              "&:hover": { color: theme.palette.grey[700] },
              ...(ownerState.disabled && {
                opacity: 0.38,
              }),
            }),
            root: ({ ownerState, theme }) => ({
              ...(ownerState.variant === "filled" &&
                ownerState.color === "default" &&
                ownerState.disabled && {
                  opacity: "0.7 !important",
                  color: theme.palette.common.black, // use black for accessibility
                }),
              ...(ownerState.variant === "default" &&
                ownerState.color === "primary" && {
                  "&:focus": {
                    backgroundColor: darken(theme.palette.primary.dark, 0.45), // darken color for accessibility
                  },
                }),
            }),
          },
        },
        MuiButton: {
          styleOverrides: {
            root: ({ ownerState, theme }) => ({
              // MUI Button style for primary/secondary outlined variant
              ...(ownerState.variant === "outlined" &&
                (ownerState.color === "primary" ||
                  ownerState.color === "secondary") && {
                  borderColor: alpha(theme.palette.primary.main, 0.75), // darken color for accessibility
                  "&:focus": {
                    color: darken(theme.palette.primary.dark, 0.3), // darken color for accessibility
                  },
                }),
              // MUI Button style for outlined error variant
              ...(ownerState.variant === "outlined" &&
                ownerState.color === "error" && {
                  "&:focus": {
                    color: darken(theme.palette.primary.dark, 0.3), // darken color for accessibility
                  },
                }),
              // MUI Button style for primary/secondary contained variant
              ...(ownerState.variant === "contained" &&
                (ownerState.color === "primary" ||
                  ownerState.color === "secondary") && {
                  "&:focus": {
                    backgroundColor: darken(theme.palette.primary.main, 0.6), // darken color for accessibility
                  },
                }),
              // MUI Button style for primary/secondary text variant
              ...(ownerState.variant === "text" &&
                (ownerState.color === "primary" ||
                  ownerState.color === "secondary") && {
                  "&:focus": {
                    color: darken(theme.palette.primary.dark, 0.3), // darken color for accessibility
                  },
                }),
            }),
          },
        },
        MuiTableSortLabel: {
          styleOverrides: {
            // keyboard focus on individual column headers
            root: ({ theme }) => ({
              "&.Mui-focusVisible": {
                border: `2px solid ${theme.palette.primary.dark}`,
                margin: "-2px",
                marginLeft: "-3px",
                paddingLeft: "1px",
              },
              // when a sort option is selected
              "&.Mui-active": {
                opacity: 1,
                color: `${theme.palette.common.black}`,
                fontWeight: "bold",
              },
            }),
            // arrow icon to let the users know the column is sortable
            icon: ({ theme }) => ({
              opacity: 0.65, // always visible value for accessibility
              color: theme.palette.grey[900], // darken color for accessibility
              "&:hover": {
                opacity: 0.7, // hover value for accessibility
              },
            }),
          },
        },
        MuiPickersDay: {
          styleOverrides: {
            root: ({ theme }) => ({
              "&.Mui-selected": {
                "&:focus": {
                  backgroundColor: darken(theme.palette.primary.dark, 0.45), // darken color for accessibility
                  color: theme.palette.common.white, // to complement the backgroundColor for accessibility
                },
              },
            }),
          },
        },
        // Toggle button Style override
        MuiToggleButton: {
          styleOverrides: {
            root: ({ theme }) => ({
              "&:focus": {
                color: theme.palette.grey[800], // darken color for accessibility
              },
            }),
          },
        },
      },
    },
    locale === "fr-CA" ? frFR : enUS
  );

export default getTheme;
