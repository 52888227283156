import { tableCellClasses, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useTranslation } from "react-i18next";
import { useFetchProductList } from "../../../2.0/hooks/useFetchProductList";
import { filterProductsByActionableDeliverWeeks } from "../../../2.0/pages/allocation-management/utils/allocationUtils";

const OrderReserveSummary = (isMobile) => {
  const { t } = useTranslation(["home"]);
  const StyledTableHeaderCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: "bold",
      textAlign: "center",
    },
  }));

  //  if current reserves is zero for this product as well as the total current allocation then consider this empty
  const isEmptyAllocations = (product) => {
    return !(
      product?.reserve?.netWithPlacedApproved !== 0 ||
      product?.reserve?.rawAllocationData?.CarryForwardQty !== 0
    );
  };
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      textAlign: "center",
      padding: "10px 0px",
    },
  }));
  const isEmpty = (products) => {
    if (!products || products.length === 0) return true;

    return products.every((product) => isEmptyAllocations(product));
  };
  const { productListOptions, productDataLoading } = useFetchProductList();

  const productsFiltered =
    filterProductsByActionableDeliverWeeks(productListOptions);

  return (
    <>
      <Typography variant={"h4"} mt={4} mb={2}>
        {t("availableReserve")}
      </Typography>
      {!productDataLoading && isEmpty(productsFiltered) ? (
        <Typography>{t("availableReserveTableEmpty")}</Typography>
      ) : (
        <>
          {t("availableReserveSummaryBody")}
          <TableContainer>
            <Table size={isMobile ? "small" : "medium"}>
              <TableHead>
                <TableRow>
                  <StyledTableHeaderCell>
                    {t("availableReserveTableProductType")}
                  </StyledTableHeaderCell>
                  <StyledTableHeaderCell>
                    {t("availableReserveTableCurrentlyAvailable")}
                  </StyledTableHeaderCell>
                  <StyledTableHeaderCell>
                    {t("availableReserveTableTotalCurrentAllocation")}
                  </StyledTableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productDataLoading ? (
                  <>
                    <TableRow>
                      <StyledTableCell>
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{ color: "#595959" }}
                        >
                          <Skeleton height={36.7} width={"90%"} />
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{ color: "#339934" }}
                        >
                          <Skeleton height={36.7} width={"90%"} />
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{ color: "#339934" }}
                        >
                          <Skeleton height={36.7} width={"90%"} />
                        </Typography>
                      </StyledTableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {productsFiltered &&
                      productsFiltered.map((productList) => (
                        <React.Fragment key={productList?.label + "-root"}>
                          {!isEmptyAllocations(productList) && (
                            <TableRow key={productList.label + "-row"}>
                              <StyledTableCell
                                key={productList.label + "-cell-1"}
                              >
                                <Typography>{productList.label}</Typography>
                              </StyledTableCell>
                              <StyledTableCell
                                key={productList.label + "-cell-2"}
                              >
                                <Typography>
                                  {t("intlNumber", {
                                    val: productList?.reserve
                                      ?.netWithPlacedApproved,
                                  })}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell
                                key={productList.label + "-cell-3"}
                              >
                                <Typography>
                                  {t("intlNumber", {
                                    val: productList?.reserve?.rawAllocationData
                                      ?.CarryForwardQty,
                                  })}
                                </Typography>
                              </StyledTableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default OrderReserveSummary;
