import { Container, Stack, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LOGIN_STATES } from "../../constants";
import { useSkipContentId } from "../../hooks/useSkipContentId";
import Hero from "./components/Hero";
import { LoginLanding } from "./components/Landing";
import { MfaPage } from "./components/Mfa";
import { NewPassword } from "./components/PasswordNew";
import { ResetPassword } from "./components/PasswordReset";
import { FinishResetPassword } from "./components/PasswordResetFinish";

const LoginPage = (props) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [signInError, setSignInError] = useState(false);
  const [signInErrorNoUsername, setSignInErrorNoUsername] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [user, setUser] = useState();
  const [mfaError, setMfaError] = useState(false);
  const [newPasswordPageError, setNewPasswordPageError] = useState(false);
  const [resetPasswordPageError, setResetPasswordPageError] = useState(false);
  const [resetPasswordPage2Error, setResetPasswordPage2Error] = useState("");
  const [loginState, setLoginState] = useState(LOGIN_STATES.LANDING);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const skipContentId = useSkipContentId();
  const { t } = useTranslation(["login"]);

  useEffect(() => {
    if (loginState === LOGIN_STATES.LANDING) {
      setUserName("");
      setPassword("");
      setVerificationCode("");
      setNewPassword("");
      setConfirmPassword("");
      setResetPasswordPageError(false);
      setResetPasswordPage2Error("");
      setNewPasswordPageError(false);
      setMfaError(false);
      setSignInErrorNoUsername(false);
      setSignInError(false);
    }
  }, [loginState]);

  const signIn = async () => {
    setIsSubmitting(true);

    let userResp = {};
    try {
      userResp = await Auth.signIn(userName, password);
      setUser(userResp);
      setSignInError(false);
      setSignInErrorNoUsername(false);

      if (userResp.challengeName === "SMS_MFA") {
        setLoginState(LOGIN_STATES.MFA);
      } else if (userResp.challengeName === "NEW_PASSWORD_REQUIRED") {
        setLoginState(LOGIN_STATES.NEW_PASSWORD);
      }
    } catch (e) {
      if (e.code === "UserNotFoundException") {
        setSignInError(false);
        setSignInErrorNoUsername(true);
      } else {
        setSignInErrorNoUsername(false);
        setSignInError(true);
      }
    }

    setIsSubmitting(false);
  };

  const confirmMfa = async () => {
    setIsSubmitting(true);
    try {
      await Auth.confirmSignIn(user, verificationCode);
      props.checkAuth();
    } catch (e) {
      setMfaError(true);
    }
    setIsSubmitting(false);
  };

  const updatePassword = async () => {
    setIsSubmitting(true);
    try {
      await Auth.completeNewPassword(user, newPassword);
      setNewPasswordPageError(false);
      setLoginState(LOGIN_STATES.MFA);
    } catch (e) {
      setNewPasswordPageError(true);
    }
    setIsSubmitting(false);
  };

  const forgotPassword = async () => {
    setIsSubmitting(true);
    try {
      await Auth.forgotPassword(userName);
      setLoginState(LOGIN_STATES.FINISH_RESET_PASSWORD);
      setResetPasswordPageError(false);
    } catch (e) {
      setLoginState(LOGIN_STATES.FINISH_RESET_PASSWORD);
      // setResetPasswordPageError(true);
    }
    setIsSubmitting(false);
  };

  const forgotPasswordComplete = async () => {
    setIsSubmitting(true);
    try {
      await Auth.forgotPasswordSubmit(userName, verificationCode, newPassword);
      setLoginState(LOGIN_STATES.LANDING);
      setResetPasswordPage2Error(true);
    } catch (e) {
      console.log("forgotPasswordComplete error", e);
      setResetPasswordPage2Error(e.code);
    }
    setIsSubmitting(false);
  };

  return (
    <div>
      <Hero />
      {/* Important: for Accessibility: noValidate (along with component="form" stops browser tooltips to appear if required */}
      <Container maxWidth={"sm"} id={skipContentId} component="form" noValidate>
        <Stack mt={4} spacing={2} mb={5}>
          <Typography variant={"h4"} mb={2}>
            {loginState === LOGIN_STATES.LANDING && t("title")}
            {loginState === LOGIN_STATES.MFA && t("confirmMfa")}
            {loginState === LOGIN_STATES.NEW_PASSWORD && t("changePass")}
            {(loginState === LOGIN_STATES.RESET_PASSWORD ||
              loginState === LOGIN_STATES.FINISH_RESET_PASSWORD) &&
              t("reset")}
          </Typography>
          {loginState === LOGIN_STATES.LANDING && (
            <LoginLanding
              userName={userName}
              setUserName={setUserName}
              password={password}
              setPassword={setPassword}
              signIn={signIn}
              signInError={signInError}
              signInErrorNoUsername={signInErrorNoUsername}
              setLoginState={setLoginState}
              isSubmitting={isSubmitting}
            />
          )}
          {loginState === LOGIN_STATES.MFA && (
            <MfaPage
              userName={userName}
              confirmMfa={confirmMfa}
              verificationCode={verificationCode}
              setVerificationCode={setVerificationCode}
              mfaError={mfaError}
              setLoginState={setLoginState}
              isSubmitting={isSubmitting}
            />
          )}
          {loginState === LOGIN_STATES.NEW_PASSWORD && (
            <NewPassword
              setLoginState={setLoginState}
              newPassword={newPassword}
              confirmPassword={confirmPassword}
              updatePassword={updatePassword}
              newPasswordPageError={newPasswordPageError}
              setNewPassword={setNewPassword}
              setConfirmPassword={setConfirmPassword}
              isSubmitting={isSubmitting}
            />
          )}
          {loginState === LOGIN_STATES.RESET_PASSWORD && (
            <ResetPassword
              setLoginState={setLoginState}
              userName={userName}
              setUserName={setUserName}
              forgotPassword={forgotPassword}
              setResetPasswordPageError={setResetPasswordPageError}
              resetPasswordPageError={resetPasswordPageError}
              isSubmitting={isSubmitting}
            />
          )}
          {loginState === LOGIN_STATES.FINISH_RESET_PASSWORD && (
            <FinishResetPassword
              setLoginState={setLoginState}
              forgotPasswordComplete={forgotPasswordComplete}
              verificationCode={verificationCode}
              setVerificationCode={setVerificationCode}
              newPassword={newPassword}
              confirmPassword={confirmPassword}
              setNewPassword={setNewPassword}
              setConfirmPassword={setConfirmPassword}
              resetPasswordPage2Error={resetPasswordPage2Error}
              isSubmitting={isSubmitting}
            />
          )}
        </Stack>
      </Container>
    </div>
  );
};

export default LoginPage;
