import { Grid, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import {
  useUserAccessFilterContext,
  useUserAccessFilterUpdateContext,
} from "./contexts/UserAccessFilterContext";

export const UserAccessFilter = () => {
  const {
    fpts = [],
    divisions = [],
    selectedFpt = 0,
    selectedDivision = 0,
    permissionDataLoading = true,
  } = useUserAccessFilterContext();

  const { t } = useTranslation();
  const onChangeHandler = useUserAccessFilterUpdateContext();

  return (
    <>
      <Grid item md={"auto"}>
        <TextField
          select
          name="fpt"
          label={t("fpt")}
          size={"small"}
          id="fpt"
          value={selectedFpt !== 0 ? selectedFpt : ""}
          onChange={(e) => {
            onChangeHandler("selectedFpt", e.target);
          }}
          disabled={!fpts.length}
          SelectProps={{ displayEmpty: true }}
          InputLabelProps={{ shrink: true }}
        >
          {!fpts.length && (
            <MenuItem value={""}>
              {permissionDataLoading ? t("loading") : t("noAccess")}
            </MenuItem>
          )}
          {fpts.map((fpt) => {
            return (
              <MenuItem
                key={fpt.value}
                value={fpt.value}
                disabled={!fpt.isActive}
              >
                {fpt.label}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid item md={"auto"}>
        <TextField
          select
          name="division"
          label={t("division")}
          size={"small"}
          id="division"
          value={selectedDivision !== 0 ? selectedDivision : ""}
          onChange={(e) => onChangeHandler("selectedDivision", e.target)}
          disabled={!divisions.length}
          SelectProps={{ displayEmpty: true }}
          InputLabelProps={{ shrink: true }}
        >
          {!divisions.length && (
            <MenuItem value={""}>
              {permissionDataLoading ? t("loading") : t("noAccess")}
            </MenuItem>
          )}
          {divisions.map((div) => {
            return (
              <MenuItem
                key={div.value}
                value={div.value}
                disabled={!div.isActive}
              >
                {divisionIdToLabel(div.value, t)}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
    </>
  );
};

/**
 * Given a division ID, returns a translated string.
 * @param {number} divId
 * @param {TFunction} t
 * @returns {string}
 */
export function divisionIdToLabel(divId, t) {
  const divisionText =
    divId === 2
      ? t("therapeutics")
      : divId === 3
      ? t("prophylactics")
      : `${t("vaccine")}/${t("diluent")}`;
  return `${t("phac")} - ${divisionText}`;
}
