import { TextField, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Context } from "../../../../context/Context";
import { DialogModal } from "../../../components/Dialog/Dialog";
import { SelectInput } from "../../../components/Select/SelectInput";
import { TextInput } from "../../../components/TextInput/TextInput";
import { useTranslateUnits } from "../../../hooks/useTranslateUnits";
import { limitNonNumericCharInput } from "../../../utils/limitNonNumericCharInput";
import { useRequestNewAllocations } from "../hooks/useRequestNewAllocations";

export const NewAllocationRequest = ({
  requestDialogOpen,
  setRequestDialogOpen,
  selectedProduct,
  productOptions,
  setProductDataLoading,
}) => {
  const initialAllocations = Array(4).fill({
    Allocations: "",
    DeliveryDate: null,
  });

  const centerGridItem = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const { userAttributes } = useContext(Context);
  const { t } = useTranslation("productRequest");
  const { requestNewAllocations } = useRequestNewAllocations();
  const { getTranslatedUnitsPerPayload } = useTranslateUnits();

  const [productList, setProductList] = useState([]);
  const [product, setProduct] = useState(selectedProduct);
  const [allocations, setAllocations] = useState(initialAllocations);
  const [reserveQuantity, setReserveQuantity] = useState(
    t("intlNumber", { val: product?.reserve.netWithPlacedApproved })
  );
  const [totalCarryForwardQuantity, setTotalCarryForwardQuantity] = useState(
    t("intlNumber", {
      val: product?.reserve?.rawAllocationData?.CarryForwardQty || 0,
    })
  );
  const [showRow, setShowRow] = useState([true, false, false, false]);
  const [justification, setJustification] = useState("");
  const [allocationErrors, setAllocationErrors] = useState(
    Array(4).fill(false)
  );
  const [dateErrors, setDateErrors] = useState(Array(4).fill(false));

  useEffect(() => {
    console.log("[newAllocationRequest] selected product:", product);
  }, [product]);

  // first the user fills the form, then clicks 'validate request' which sets isValidating to true
  const [isValidating, setIsValidating] = useState(false);
  // then the user clicks 'confirm' which sends the request to SAP and sets isSubmitting to true
  const [isSubmitting, setIsSubmitting] = useState(false);
  // once SAP responds, the request is complete which sets isComplete to true
  const [isComplete, setIsComplete] = useState(false);

  const [selected, setSelected] = useState(Array(10).fill(false));
  const [postError, setPostError] = useState(false);

  const handleBlur = (index) => {
    setSelected(selected.map((select, idx) => (idx === index ? true : select)));
  };
  // function for handling number input
  const numberValidation = (event, index) => {
    const val = event.target.value;
    // prevent negative numbers and symbols
    if (val.match(/[^0-9]/)) {
      return event.preventDefault();
    }
    // code to prevent too big of a number, could cause problem in the frontend.
    if (val.length >= 16) {
      return event.preventDefault();
    }
    // set allocations
    setAllocations(
      allocations.map((allocation, idx) =>
        idx === index
          ? {
              ...allocation,
              Allocations:
                val && showRow[idx] ? Number.parseInt(val).toString() : "", // trims leading 0s from 012 to 12
            }
          : allocation
      )
    );
  };

  // function for handling date input
  const dateValidation = (input, index) => {
    setAllocations(
      allocations.map((allocation, idx) =>
        idx === index ? { ...allocation, DeliveryDate: input } : allocation
      )
    );
  };

  const submitDateValidation = () => {
    const deliveryDates = allocations
      .map((allocation) =>
        allocation.DeliveryDate
          ? dayjs(allocation.DeliveryDate).format("L")
          : ""
      )
      .filter((deliveryDate) => deliveryDate);
    const uniqueDates = deliveryDates.filter(
      (delDate, index) => deliveryDates.indexOf(delDate) === index
    );
    if (deliveryDates.length === uniqueDates.length) {
      return true;
    } else {
      const duplicateDates = deliveryDates.filter(
        (delDate, index) => deliveryDates.indexOf(delDate) !== index
      );
      setDateErrors(
        allocations.map(
          (allocation) =>
            !!(
              allocation.DeliveryDate &&
              duplicateDates.indexOf(allocation.DeliveryDate.format("L")) !== -1
            )
        )
      );
    }
    return false;
  };

  // function for enabling or disabling button
  const allowSubmit = () => {
    // no product selected
    if (!product) return false;
    if (isSubmitting) return false;
    // one of the fields is empty and the other is not for every row
    if (
      allocations
        .map((allocation) => {
          return !!(
            (!allocation.Allocations && allocation.DeliveryDate) ||
            (allocation.Allocations && !allocation.DeliveryDate)
          );
        })
        .some((error) => error)
    )
      return false;
    // first row is empty
    if (!allocations[0].Allocations || !allocations[0].DeliveryDate)
      return false;
    // allocation quantity has errors
    if (!allocationErrors.every((error) => !error)) return false;
    // date has errors
    if (!dateErrors.every((error) => !error)) return false;
    // justification is empty
    if (!justification) return false;
    // justification over 254 characters
    return !(justification && justification?.length > 254);
  };

  // filter products by those that are not past end date (not marked for deletion)
  useEffect(() => {
    setProductList(productOptions.filter((product) => !product.isPastEndDate));
  }, [productOptions]);

  // reset state whenever product changes
  useEffect(() => {
    setAllocations(initialAllocations);
    setReserveQuantity(
      t("intlNumber", { val: product?.reserve.netWithPlacedApproved })
    );
    setTotalCarryForwardQuantity(
      t("intlNumber", {
        val: product?.reserve?.rawAllocationData?.CarryForwardQty || 0,
      })
    );
    setShowRow([true, false, false, false]);
    setJustification("");
    setAllocationErrors(Array(4).fill(false));
    setDateErrors(Array(4).fill(false));
    setIsValidating(false);
    setIsSubmitting(false);
    setIsComplete(false);
    setSelected(Array(10).fill(false));
    setPostError(false);
  }, [product, requestDialogOpen]);

  useEffect(() => {
    const newShowRow = showRow.map((row, index) => {
      for (let i = 0; i !== index; i++) {
        if (
          !allocations[i].Allocations ||
          isNaN(allocations[i].Allocations) ||
          !allocations[i].DeliveryDate
        ) {
          return false;
        }
      }
      return true;
    });

    setShowRow(newShowRow);

    setAllocationErrors(
      allocationErrors.map((error, index) => {
        return !!(allocations[index].Allocations === "0" && newShowRow[index]);
      })
    );

    setDateErrors(
      dateErrors.map((error, index) => {
        const currentDeliveryDate = allocations[index].DeliveryDate;
        return !!(
          ((currentDeliveryDate && !dayjs(currentDeliveryDate).isValid()) ||
            dayjs(currentDeliveryDate).isBefore(dayjs()) ||
            dayjs(currentDeliveryDate).isAfter(dayjs().add(4, "w"))) &&
          newShowRow[index]
        );
      })
    );
  }, [allocations]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {!isComplete && !postError && (
        <DialogModal
          open={requestDialogOpen}
          title={t("newAllocationTitle")}
          primaryButtonText={
            isValidating ? t("confirm") : t("newAllocationSubmitButton")
          }
          handlePrimaryClick={async () => {
            if (submitDateValidation()) {
              if (!isValidating) {
                setIsValidating(true);
              } else {
                setIsSubmitting(true);
                await requestNewAllocations(
                  product,
                  userAttributes.email,
                  allocations,
                  justification
                ).then((response) => {
                  if (!response) {
                    setPostError(true);
                    return;
                  }
                  setIsComplete(true);
                });
              }
            }
          }}
          primaryButtonDisabled={!allowSubmit()}
          secondaryButtonText={isValidating ? t("back") : t("cancel")}
          handleSecondaryClick={() =>
            isValidating ? setIsValidating(false) : setRequestDialogOpen(false)
          }
          handleClose={() => setRequestDialogOpen(false)}
          bodyComponent={
            // Important: for Accessibility: noValidate (along with component="form" stops browser tooltips to appear if required
            <Grid
              container
              spacing={2}
              justify="space-between"
              component="form"
              noValidate
            >
              <Grid item xs={12}>
                <Typography>{t("newAllocationDescription")}</Typography>
              </Grid>
              <Grid item xs={10}>
                <SelectInput
                  autoFocus={true}
                  label={t("chooseProductType")}
                  value={product?.value}
                  options={productList}
                  required={true}
                  onChangeValue={(value) =>
                    setProduct(
                      productList.find((product) => product.value === value)
                    )
                  }
                  onBlur={() => handleBlur(0)}
                  error={!product && selected[0]}
                  disabled={isValidating}
                />
              </Grid>
              {product && (
                <Grid item xs={12}>
                  <Typography>
                    <b>{t("newAllocationReserveAndAllocationTitle")}</b>
                  </Typography>
                  <Typography>
                    {t("newAllocationReserveText", {
                      reserve: reserveQuantity,
                    })}
                  </Typography>
                  <Typography>
                    {t("newAllocationTotalCurrentAllocationText", {
                      totalAllocation: totalCarryForwardQuantity,
                    })}
                  </Typography>
                </Grid>
              )}
              {product && (
                <Grid item xs={12}>
                  <Typography>
                    {`${getTranslatedUnitsPerPayload(product?.BaseUoM)}: ${
                      product.unitsPerPayload
                    }`}
                  </Typography>
                </Grid>
              )}
              {(allocationErrors.some((bool) => bool) ||
                dateErrors.some((bool) => bool)) && (
                <Grid item xs={12}>
                  <Alert severity="error">
                    {t("newAllocationAllocationError")}
                  </Alert>
                </Grid>
              )}
              {isValidating && (
                <Grid item xs={12}>
                  <Alert severity="warning">
                    {t("newAllocationReviewDescription")}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={5} sx={centerGridItem}>
                <Typography fontWeight={700}>
                  {t("newAllocationAllocationColumn")}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={centerGridItem}>
                <Typography fontWeight={700}>{t("units_one")}</Typography>
              </Grid>
              <Grid item xs={5} sx={centerGridItem}>
                <Typography fontWeight={700}>
                  {t("newAllocationDeliveryDateColumn")}
                </Typography>
              </Grid>
              {showRow.map((flag, index) => {
                if (flag) {
                  return (
                    <Fragment key={`newAllocation_row_${index}`}>
                      <Grid
                        item
                        xs={5}
                        sx={centerGridItem}
                        key={`newAllocation_qty_${index}`}
                      >
                        <TextField
                          key={`newAllocation_qty_${index}`}
                          label={t("newAllocationAllocationRequest")}
                          required={true}
                          rows={2}
                          fullWidth={false}
                          error={
                            allocationErrors[index] ||
                            (!allocations[index].Allocations &&
                              selected[2 * index + 1])
                          }
                          value={allocations[index].Allocations}
                          onChange={(input) => numberValidation(input, index)}
                          disabled={isValidating}
                          type={"number"}
                          onKeyDown={(e) => limitNonNumericCharInput(e)}
                          InputLabelProps={{
                            shrink: !!allocations[index].Allocations,
                          }}
                          onBlur={() => handleBlur(2 * index + 1)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={centerGridItem}
                        key={`newAllocation_unit_${index}`}
                      >
                        <Typography key={`newAllocation_unit_${index}`}>
                          {product
                            ? `${product.rawData.product.BaseUoMDesc}s`
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        sx={centerGridItem}
                        key={`newAllocation_date_${index}`}
                      >
                        <DatePicker
                          label={`${t("newAllocationDeliveryDateColumn")} *`}
                          key={`newAllocation_date_${index}`}
                          format={"LL"}
                          minDate={dayjs().add(1, "d")}
                          maxDate={dayjs().add(4, "weeks")}
                          value={allocations[index].DeliveryDate}
                          onChange={(input) => dateValidation(input, index)}
                          disabled={isValidating}
                          slotProps={{
                            textField: {
                              error:
                                dateErrors[index] ||
                                (!allocations[index].DeliveryDate &&
                                  selected[2 * index + 2]),
                              onBlur: () => handleBlur(2 * index + 2),
                            },
                            openPickerButton: {
                              onBlur: () => handleBlur(2 * index + 2),
                            },
                          }}
                        ></DatePicker>
                      </Grid>
                    </Fragment>
                  );
                }
                // if flag == false return empty fragment
                return <></>;
              })}
              <Grid item xs={12}>
                <TextInput
                  label={t("newAllocationRequestorEmail")}
                  required={true}
                  value={userAttributes.email}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={t("newAllocationJustification")}
                  required={true}
                  error={
                    justification?.length > 254 ||
                    (!justification && selected[9])
                  }
                  multiline={true}
                  helperText={t("newAllocationJustificationHelper", {
                    current: 254 - (justification ? justification.length : 0),
                  })}
                  value={justification}
                  onChange={(input) => setJustification(input)}
                  disabled={isValidating}
                  onBlur={() => handleBlur(9)}
                />
              </Grid>
            </Grid>
          }
        />
      )}
      {isComplete && (
        <DialogModal
          open={isComplete}
          title={t("newAllocationSubmittedTitle")}
          primaryButtonText={t("close")}
          handlePrimaryClick={() => {
            setRequestDialogOpen(false);
            setProductDataLoading(true);
          }}
          handleClose={() => setRequestDialogOpen(false)}
          bodyComponent={
            <Typography>{t("newAllocationSubmittedDescription")}</Typography>
          }
        />
      )}
      {!isComplete && postError && (
        <DialogModal
          open={postError}
          title={t("newAllocationRequestTitleError")}
          primaryButtonText={t("close")}
          handlePrimaryClick={() => {
            setRequestDialogOpen(false);
            setPostError(false);
            setProductDataLoading(true);
          }}
          handleClose={() => {
            setRequestDialogOpen(false);
          }}
          bodyComponent={
            <Typography>{t("newAllocationRequestError")}</Typography>
          }
        />
      )}
    </LocalizationProvider>
  );
};
