export const dsManagement = {
  tab: "Gestion SL",
  title: "Gestion des sites de livraison",
  subTitle: "Trouver ou ajouter un nouveau site de livraison (SL)",
  vds: "Site de livraison",
  city: "Ville",
  usePfizer: "Utilisation pour Pfizer",
  statusMessage:
    "L'état ne peut être changé à « Inactif » qu'une fois le site de livraison créé.",
  division: "Division",
  divisionMessage:
    "La division a été prédéfinie en fonction de votre compte utilisateur et ne peut pas être modifiée.",
  createVDS: "Ajouter un nouveau SL",
  enterVDSInfo: "Entrer les renseignements du SL",
  extension: "Extension",
  formSubTitle: "Afficher/Modifier les renseignements du SL",
  formEditHint: "Pour modifier, utilisez le bouton en bas de la page.",
  heading: "Renseignements du SL",
  dsName: "Nom du SL",
  vdsNumber: "Numéro du SL",
  primaryContactName: "Nom de la 1re personne-ressource",
  primaryContactPhone: "No de tél. de la 1re personne-ressource",
  phoneErrorShort: "Le nombre saisi est trop court.",
  phoneErrorLong: "Le nombre saisi est trop long.",
  primaryContactEmail: "Adresse courriel de la 1re personne-ressource",
  secondaryContactName: "Nom de la 2e personne-ressource",
  secondaryContactPhone: "No de tél. de la 2e personne-ressource",
  secondaryContactEmail: "Adresse courriel de la 2e personne-ressource",
  tertiaryContactName: "Nom de la 3e personne-ressource",
  tertiaryContactPhone: "No de tél. de la 3e personne-ressource",
  tertiaryContactEmail: "Adresse courriel de la 3e personne-ressource",

  // primaryContactPhoneExt: "Fr: Primary contact phone extension",
  // secondaryContactPhoneExt: "Fr: Secondary contact phone extension",
  // tertiaryContactPhoneExt: "Fr: Tertiary contact phone extension",

  primaryContactPhoneExt: "Extension",
  secondaryContactPhoneExt: "Extension",
  tertiaryContactPhoneExt: "Extension",

  country: "Pays",
  province: "Province",
  address: "Adresse",
  address2: "Adresse 2 (étage, numéro d'unité, numéro d'appartement, etc.)",
  address2Short: "Adresse 2",
  postalCode: "Code postal",
  deliveryNotes: "Notes sur le SL",
  vdsUpdateFailure:
    "Le système n'a pas pu mettre à jour le SL. Veuillez réessayer plus tard ou communiquer avec le Centre d'exploitation du réseau (CER) pour obtenir une aide immédiate.",
  vdsUpdateLockFailure:
    "La mise à jour de votre SL a échoué, le tableau étant verrouillé par un autre utilisateur. Veuillez réessayer plus tard ou communiquer avec le Centre d'exploitation du réseau (CER) pour obtenir une aide immédiate.",
  preferredLanguage: "Langue de communication",
  delFlag: "Licence d'établissement de produits pharmaceutiques (LEPP)",
  del: "LEPP Numéro de Licence",
  possibleDuplicateDetected: "Possibles doublons détectés",
  possibleDuplicateDetectedMessage:
    'Le site de livraison que vous essayez de créer correspond à un ou plusieurs sites existants selon la combinaison des champs répertoriés en rouge ci-dessous. Si vous êtes sûr de vouloir créer ce site de livraison, vous pouvez cliquer sur "Confirmer" ci-dessous.',
  exactDuplicateDetected: "Doublon détecté",
  exactDuplicateDetectedMessage:
    "Le site de livraison que vous essayez de créer correspond à un ou plusieurs sites existants selon la combinaison des champs répertoriés en rouge ci-dessous. Comme ces champs sont uniques, veuillez mettre à jour votre entrée et réessayer.",
  viewExistingVDS: "Voir le SL existant",
  vdsExportPrefix: "site_de_livraison_exportation",
  sectionDs: "Détails du site de livraison",
  sectionContact: "Informations de contact",
  sectionLocation: "Détails de l'emplacement",
  sectionNotes: "Remarques",
};
