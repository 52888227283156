import { Auth } from "aws-amplify";

/**
 *
 * @param {function} setLoggedIn
 * @param {function} setAuthChecked
 * @param {function} setUserAttributes
 * @returns {Promise<void>}
 */
export const checkAuth = async (
  setLoggedIn,
  setAuthChecked,
  setUserAttributes
) => {
  let info;
  try {
    await Auth.currentSession();
    info = await Auth.currentUserInfo();
    setLoggedIn(true);
    setAuthChecked(true);
  } catch (e) {
    console.error("checkAuth: error", e);
    setLoggedIn(false);
    setAuthChecked(true);
    return;
  }

  if (info && info.attributes) {
    const newAttributes = {
      username: info.username,
      division: 1, // TODO: dynamic from user token
      fpt: "ON", // TODO: dynamic from user token
      IntlFPT: false, // not sure what this is for
      email: info.attributes.email,
    };
    setUserAttributes(newAttributes);
  }
};
