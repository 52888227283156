import { createContext } from "react";

export const initialState = {
  userAttributes: {},
  setUserAttributes: () => {},
  checkAuth: () => {},
  ariaHidden: false,
  setAriaHidden: () => {},
  menuClicked: false,
  setMenuClicked: () => {},
};

export const Context = createContext(initialState);
