import { useMemo, useState } from "react";
import { SORTING_DIRECTIONS } from "../../../constants";

const SortTableData = (items, config) => {
  const [sortConfig, setSortConfig] = useState(config);

  const getSortingFunction = (field, isAscending) => {
    let fn = (a, b) => a;
    switch (field) {
      default:
        fn = getTextSortingFunction(field, isAscending);
    }

    return fn;
  };

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort(
        getSortingFunction(
          sortConfig.field,
          sortConfig.direction === SORTING_DIRECTIONS.ASC
        )
      );
    }
    return sortableItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, sortConfig]);

  const requestSortDropdown = (text, field, direction) => {
    setSortConfig({ text, field, direction });
  };

  const requestSortToggle = (text, field) => {
    let direction =
      sortConfig.field === field &&
      sortConfig.direction === SORTING_DIRECTIONS.ASC
        ? SORTING_DIRECTIONS.DESC
        : SORTING_DIRECTIONS.ASC;
    setSortConfig({ text, field, direction });
  };

  return {
    items: sortedItems,
    requestSortToggle,
    requestSortDropdown,
    sortConfig,
  };
};

export default SortTableData;

// The baseline sorting function
const getTextSortingFunction = (field, isAscending) => {
  return (a, b) => {
    a = a[field];
    b = b[field];
    if (typeof a === "string" && typeof b === "string") {
      a = a.toLowerCase();
      b = b.toLowerCase();
    }
    if (a < b) return isAscending ? -1 : 1;
    else if (a > b) return isAscending ? 1 : -1;
    else return 0;
  };
};
