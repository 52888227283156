export const accessProvisioning = {
  tab: "Access Provisioning",
  title: "Access Provisioning",
  subTitle: "Manage application access and permissions",
  noAccess:
    "You do not have access to this page with the selected FPT and Division combination.",
  portalGeneralUser: "portal general user",
  portalSiteAdministrator: "portal site administrator",
  user: "User",
  firstName: "First name",
  lastName: "Last name",
  role: "Role",
  successMessage: `Your changes have been processed.`,
  errorMessage: "There were errors while processing your request.",
  successfulCreations: "Successful creations",
  successfulChanges: "Successful changes",
  creationErrors: "Creation errors",
  changeErrors: "Change errors",
  userStats:
    "This user has {{activeCount}} active role(s), and {{inactiveCount}} inactive one(s).",
  editHelpText: "Click on a status to toggle its state.",
  creationHelpText:
    "If a division is greyed out in the dropdown, please update the existing permission instead.",
};
