export const wiManagement = {
  tab: "Gestion des inventaires et du gaspillage",
  inventoryTitle: "Inventaire",
  wastageTitle: "Gaspillage",
  inventoryButton: "Inventaire",
  wastageButton: "Gaspillage",
  uploadButton: "Téléverser le fichier",
  csvExportInv: "Gestion_Inventaire_Exporter ",
  csvExportWas: "Gaspillage_Gestion_Extrait",
  notReleasedText:
    "À l’heure actuelle, la page Inventaire et Gaspillage n’est accessible qu’à la division « Vaccins/diluant ». Si vous souhaitez plus d’informations sur ces fonctionnalités ou si vous souhaitez y avoir accès, veuillez contacter",
  previewDialogUpsertReviewInventory:
    "Veuillez réviser et confirmer les informations affichées ci-dessous avant de soumettre vos données d’inventaire",
  previewDialogUpsertReviewWastage:
    "Veuillez réviser et confirmer les informations affichées ci-dessous avant de soumettre vos informations de gaspillage",
  previewDialogUpsertTitle: "Prévisualiser le fichier",
  previewDialogUpsertUpload: "Téléverser le fichier : {{fileName}}",
  previewDialogDeleteReviewWastage:
    "Veuillez réviser et confirmer que les informations affichées ci-dessous doivent être  supprimées de vos données de gaspillage.",
  previewDialogDeleteReviewInventory:
    "Veuillez réviser et confirmer que les informations affichées ci-dessous doivent être supprimées de vos données d’inventaire.",
  previewDialogDeleteTitle: "Visualiser les données à supprimer",
  previewDialogDeleteRecords:
    "Suppression des données de la date du rapport : {{reportingDate}}",
  previewDialogError:
    "La feuille de données contient {{errorCount}} erreur(s). Veuillez corriger et téléverser à nouveau.",
  previewDownloadButton: "Télécharger Aperçu",
  inventoryDescription: "Rapporter les informations d’inventaire",
  wastageDescription:
    "Soumettre et afficher des informations sur le gaspillage",
  uploadFile: "Téléverser le fichier",
  uploadFileSubHeadingInventory:
    "Veuillez choisir un fichier Excel pour soumettre les informations d’inventaire",
  uploadFileSubHeadingWastage:
    "Veuillez choisir un fichier Excel pour soumettre les informations de gaspillage",
  uploadFileExtensionError:
    "Mauvais format de fichier fourni. Veuillez téléverser à nouveau un fichier avec le format de fichier xlsx, xls, ou xlsm",
  uploadFileInvalidSheet:
    "Impossible de trouver la feuille Excel nommée {{sheetNameEN}} ou {{sheetNameFR}}. Veuillez téléverser à nouveau le fichier Excel en incluant cette feuille",
  uploadGenericError:
    "Une erreur est survenue lors de l’analyse du fichier téléversé. {{uploadErrorMsg}}",
  uploadNoDataError: "Aucune donnée n’a été trouvée.",
  uploadMaxDataCount:
    "Le fichier téléchargé dépasse le nombre maximal de rangées de {{maxRowCount, number}} rangées.",
  uploadBadAvailableStockError: "Stock disponible non valide.",
  uploadReportingDateError: "Plus d’une date de rapport.",
  uploadReportingHeadersMissingError:
    "La ou les colonnes suivantes n’ont pas été trouvées ou sont mal orthographiées : {{headers}}",
  uploadBadReportingDateError: "Date du rapport non valide.",
  uploadBadWastageDateError: "Date du gaspillage non valide.",
  uploadBadWastageQuantityError: "Quantité de gaspillage non valide.",
  uploadUpsertSuccessTitle: "Fichier téléversé",
  uploadUpsertSuccessBodyInventory:
    "Vos données d'inventaires ont été soumises. Merci.",
  uploadUpsertSuccessBodyWastage:
    "Vos données de gaspillage ont été soumises. Merci.",
  uploadDeleteConfirmTitle: "Veuillez confirmer",
  uploadDeleteConfirmBody:
    "Êtes-vous sûr de vouloir supprimer {{recordNumber}} ligne(s) de données ?",
  uploadDeleteSuccessTitle: "Données supprimées",
  uploadDeleteLocked:
    "La tableau de données est verrouillé et ne peut être modifié. Veuillez réessayer plus tard. Si ce problème persiste, veuillez communiquer avec l’Unité de soutien stratégique (USS) à l’adresse suivante : vaccine-connect-support-connexion-vaccin@phac-aspc.gc.ca. ",
  uploadDeleteSuccessBodyWastage:
    "Vos données de gaspillage ont été supprimées pour la date de rapport sélectionnée.",
  uploadDeleteSuccessBodyInventory:
    "Vos données d'inventaires ont été supprimées pour la date de rapport sélectionnée.",
  inventoryTableErrors: "Erreurs",
  inventoryTableFileRow: "Rangée du fichier",
  inventoryTableReportingDate: "Date du rapport",
  inventoryTableProductName: "Nom du produit",
  inventoryTableProductGroup: "Groupe du produit",
  inventoryTableDEL: "LEPP",
  inventoryTableLotNumber: "Numéro de lot",
  inventoryTableExpiryDate: "Date d’expiration",
  inventoryTableAvailableStock: "Stock disponible",
  inventoryTableUofM: "Unité de l'article",
  inventoryTableMaterialNumber: "Numéro de l'article",
  inventoryTableFileName: "Nom de fichier",
  inventoryTableSubmittedBy: "Soumis par",
  inventoryTableSubmissionDate: "Date de soumission",
  wastageTableErrors: "Erreurs",
  wastageTableFileRow: "Rangée du fichier",
  wastageTableReportingDate: "Date du rapport",
  wastageTableWastageDate: "Date du gaspillage",
  wastageTableProductDescription: "Nom du produit",
  wastageTableProductGroup: "Groupe du produit",
  wastageTableLotNumber: "Numéro de lot",
  wastageTableExpiryDate: "Date d’expiration",
  wastageTableWastageCategory: "Catégorie de gaspillage",
  wastageTableMaterialNumber: "Numéro de l'article",
  wastageTableExplanation: "Explication",
  wastageTableQuantityAffected: "Quantité affectée",
  wastageTableUofM: "Unité de l'article",
  wastageTableFileName: "Nom de fichier",
  wastageTableSubmittedBy: "Soumis par",
  wastageTableSubmissionDate: "Date de soumission",
  next: "Suivant",
};
