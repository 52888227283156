import { Alert, Link, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../components/TextInput/TextInput";
import { LOGIN_STATES } from "../../../constants";

export const NewPassword = ({
  setLoginState,
  newPassword,
  confirmPassword,
  updatePassword,
  newPasswordPageError,
  setNewPassword,
  setConfirmPassword,
  isSubmitting,
}) => {
  const { t } = useTranslation(["login"]);

  return (
    <Stack spacing={2}>
      <TextInput
        id="new-password"
        label={`${t("changePass")} (${t("required")})`}
        type="password"
        required
        value={newPassword}
        onChange={(value) => setNewPassword(value)}
        autoCompleteValue="new-password"
      />
      <TextInput
        id="new-password-verify"
        label={`${t("confirmPass")} (${t("required")})`}
        type="password"
        required
        value={confirmPassword}
        onChange={(value) => setConfirmPassword(value)}
        autoCompleteValue="new-password"
        endAdornmentErrorOnError={true}
        error={
          newPassword && confirmPassword && confirmPassword !== newPassword
        }
      />
      <Button
        children={<>{t("changePass")}</>}
        variant={"contained"}
        id="login-button"
        size={"large"}
        onClick={updatePassword}
        disabled={
          newPassword === "" ||
          confirmPassword === "" ||
          newPassword !== confirmPassword ||
          isSubmitting
        }
      />
      {newPassword && confirmPassword && newPassword !== confirmPassword && (
        <Alert severity="error">{t("invalidPasswordNotSame")}</Alert>
      )}

      {newPasswordPageError && (
        <Alert severity="error">{t("invalidPasswordComplexity")}</Alert>
      )}
      <Typography>
        {t("back")}{" "}
        <Link
          id="back-button"
          tabIndex="0"
          href="#"
          onClick={() => {
            setLoginState(LOGIN_STATES.LANDING);
          }}
        >
          {t("button")}
        </Link>
      </Typography>
    </Stack>
  );
};

NewPassword.propTypes = {
  setLoginState: PropTypes.func.isRequired,
  newPassword: PropTypes.string.isRequired,
  updatePassword: PropTypes.func.isRequired,
  newPasswordPageError: PropTypes.bool.isRequired,
};
