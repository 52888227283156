import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import strings from "../utils/strings";

const statusFlagRender = (data) => {
  return data.StatusFlag && data.StatusFlag !== "OK" ? (
    <Stack direction="row" spacing={1} alignItems="center">
      <ErrorOutlineOutlinedIcon style={{ color: "red" }} />
      <Typography sx={{ color: "red", fontWeight: 700 }}>
        {data.StatusFlag}
      </Typography>
    </Stack>
  ) : (
    data.StatusFlag
  );
};

const highlightErrorRender = (data, key) => {
  return data.StatusFlag &&
    data.StatusFlag !== "OK" &&
    data.ErrorFields?.includes(key) ? (
    <Typography sx={{ color: "red", fontWeight: 700 }}>{data[key]}</Typography>
  ) : (
    data[key]
  );
};

export const DEFAULT_DATE_FILTER_WEEKS = 4;

export const MAIN_CONTENT_ID = "mainContent";

export const MOBILE_BREAKPOINT = Object.freeze(902);

export const XLSX_UPLOAD_SHEET_NAME = "VC_Upload";

export const XLSX_UPLOAD_SHEET_NAME_FR = "CV_T\u00E9l\u00E9verser";

export const XLSX_UPLOAD_MAX_ROWS = "5000";

export const XLSX_INVENTORY_HEADERS = {
  EN: {
    dataRow: "Row Ref",
    invReptDate: "Reporting Date (yyyy-mm-dd)",
    del: "DEL",
    batch: "Lot #",
    availableStock: "Available Stock",
  },
  FR: {
    dataRow: "Ligne de r\u00E9f",
    invReptDate: "Date du rapport (aaaa-mm-jj)",
    del: "LEPP",
    batch: "# de lot",
    availableStock: "Quantit\u00E9 disponible",
  },
};

export const XLSX_WASTAGE_HEADERS = {
  EN: {
    dataRow: "Row Ref",
    wasReptDate: "Reporting Date (yyyy-mm-dd)",
    batch: "Lot #",
    category: "Wastage Category",
    explanation: "Wastage Explanation",
    wastageDate: "Wastage Date (yyyy-mm-dd)",
    wasQty: "Wastage Quantity",
  },
  FR: {
    dataRow: "Ligne de r\u00E9f",
    wasReptDate: "Date du rapport (aaaa-mm-jj)",
    batch: "# de lot",
    category: "Catégorie de gaspillage",
    explanation: "Explication du gaspillage",
    wastageDate: "Date du gaspillage (aaaa-mm-jj)",
    wasQty: "Quantité gaspill\u00E9e",
  },
};

export const XLSX_WASTAGE_INGESTION_COLUMNS = () => {
  const { t } = useTranslation("wiManagement");

  return [
    {
      name: "StatusFlag",
      text: t("wastageTableErrors"),
      inView: true,
      inCSV: true,
      customViewRenderer: ({ data }) => statusFlagRender(data),
    },
    {
      name: "DataRow",
      text: t("wastageTableFileRow"),
      inView: true,
      inCSV: true,
    },
    {
      name: "WasReptDate",
      text: t("wastageTableReportingDate"),
      inView: true,
      inCSV: true,
    },
    {
      name: "WastageDate",
      text: t("wastageTableWastageDate"),
      inView: true,
      inCSV: true,
    },
    {
      name: "MaterialName",
      text: t("wastageTableProductDescription"),
      inView: true,
      inCSV: true,
    },
    {
      name: "MaterialGroup",
      text: t("wastageTableProductGroup"),
      inView: true,
      inCSV: true,
    },
    {
      name: "Batch",
      text: t("wastageTableLotNumber"),
      inView: true,
      inCSV: true,
      customViewRenderer: ({ data }) => highlightErrorRender(data, "Batch"),
    },
    {
      name: "ExpiryDate",
      text: t("wastageTableExpiryDate"),
      inView: true,
      inCSV: true,
    },
    {
      name: "Category",
      text: t("wastageTableWastageCategory"),
      inView: true,
      inCSV: true,
      customViewRenderer: ({ data }) => highlightErrorRender(data, "Category"),
    },
    {
      name: "MaterialNumber",
      text: t("wastageTableMaterialNumber"),
      inView: true,
      inCSV: true,
    },
    {
      name: "Explanation",
      text: t("wastageTableExplanation"),
      inView: true,
      inCSV: true,
      customViewRenderer: ({ data }) =>
        highlightErrorRender(data, "Explanation"),
    },
    {
      name: "WasQty",
      text: t("wastageTableQuantityAffected"),
      inView: true,
      inCSV: true,
      customViewRenderer: ({ data }) => highlightErrorRender(data, "WasQty"),
    },
    {
      name: "Uom",
      text: t("wastageTableUofM"),
      inView: true,
      inCSV: true,
    },
  ];
};

export const XLSX_INVENTORY_INGESTION_COLUMNS = () => {
  const { t } = useTranslation("wiManagement");
  return [
    {
      name: "StatusFlag",
      text: t("inventoryTableErrors"),
      inView: true,
      inCSV: true,
      customViewRenderer: ({ data }) => statusFlagRender(data),
    },
    {
      name: "DataRow",
      text: t("inventoryTableFileRow"),
      inView: true,
      inCSV: true,
    },
    {
      name: "InvReptDate",
      text: t("inventoryTableReportingDate"),
      inView: true,
      inCSV: true,
    },
    {
      name: "MaterialName",
      text: t("inventoryTableProductName"),
      inView: true,
      inCSV: true,
    },
    {
      name: "MaterialGroup",
      text: t("inventoryTableProductGroup"),
      inView: true,
      inCSV: true,
    },
    {
      name: "Del",
      text: t("inventoryTableDEL"),
      inView: true,
      inCSV: true,
      customViewRenderer: ({ data }) => highlightErrorRender(data, "Del"),
    },
    {
      name: "Batch",
      text: t("inventoryTableLotNumber"),
      inView: true,
      inCSV: true,
      customViewRenderer: ({ data }) => highlightErrorRender(data, "Batch"),
    },
    {
      name: "ExpiryDate",
      text: t("inventoryTableExpiryDate"),
      inView: true,
      inCSV: true,
    },
    {
      name: "AvailableStock",
      text: t("inventoryTableAvailableStock"),
      inView: true,
      inCSV: true,
      customViewRenderer: ({ data }) =>
        highlightErrorRender(data, "AvailableStock"),
    },
    {
      name: "Uom",
      text: t("inventoryTableUofM"),
      inView: true,
      inCSV: true,
    },
  ];
};

export const NAV_STATES = Object.freeze({
  CURRENT: 1,
  PREVIOUS: 2,
  HOME: 3,
  ORDER: 4,
  ALLOCATION: 5,
  VDS: 6,
  WASTAGE_INVENTORY: 7,
  ACCESS_PROVISION: 8,
});

export const NAV_PATHS = Object.freeze({
  ORDER: strings.home.tabs.order.path,
  CURRENT: strings.home.tabs.pending.path,
  PREVIOUS: strings.home.tabs.history.path,
  HOME: strings.home.tabs.home.path,
  ALLOCATION: strings.home.tabs.allocations.path,
  VDS: strings.home.tabs.vds.path,
  WASTAGE_INVENTORY: strings.home.tabs.wastageInventory.path,
  ACCESS_PROVISION: strings.home.tabs.accessProvision.path,
});

export const SORTING_DIRECTIONS = Object.freeze({
  ASC: "asc",
  DESC: "desc",
  NONE: "none",
});

export const LOGIN_STATES = Object.freeze({
  LANDING: 1,
  MFA: 2,
  NEW_PASSWORD: 3,
  RESET_PASSWORD: 4,
  FINISH_RESET_PASSWORD: 5,
});

export const PHAC_EMAIL = Object.freeze(
  "vaccine-connect-support-connexion-vaccin@phac-aspc.gc.ca"
);

export const PHAC_PHONE = Object.freeze(["1-833-695-0924"]);

// The time in milliseconds before the user is logged out if idle
// format is (MINUTES * 60000) = desired milliseconds
export const AUTH_TIMEOUT_PROD = 15 * 60000;
export const AUTH_TIMEOUT_OTHER = 120 * 60000;

// Error states messages for allocations service
export const ALLOCATION_ERROR_STATES = Object.freeze({
  tableLocked: "ERROR: Lock exist. Please try again later.",
});

// all countries with country code
export const COUNTRIES = Object.freeze([
  { name: "Albania", code: "AL" },
  { name: "Åland Islands", code: "AX" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia (Plurinational State of)", code: "BO" },
  { name: "Bonaire, Sint Eustatius and Saba", code: "BQ" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cabo Verde", code: "CV" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo (the Democratic Republic of the)", code: "CD" },
  { name: "Congo", code: "CG" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Curaçao", code: "CW" },
  { name: "Cyprus", code: "CY" },
  { name: "Czechia", code: "CZ" },
  { name: "Côte d'Ivoire", code: "CI" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Eswatini", code: "SZ" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands [Malvinas]", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and McDonald Islands", code: "HM" },
  { name: "Holy See", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran (Islamic Republic of)", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea (the Democratic People's Republic of)", code: "KP" },
  { name: "Korea (the Republic of)", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People's Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia (Federated States of)", code: "FM" },
  { name: "Moldova (the Republic of)", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestine, State of", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Republic of North Macedonia", code: "MK" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "Réunion", code: "RE" },
  { name: "Saint Barthélemy", code: "BL" },
  { name: "Saint Helena, Ascension and Tristan da Cunha", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Martin (French part)", code: "MF" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Sint Maarten (Dutch part)", code: "SX" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "South Sudan", code: "SS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan (Province of China)", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom of Great Britain and Northern Ireland", code: "GB" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "United States of America", code: "US" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela (Bolivarian Republic of)", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands (British)", code: "VG" },
  { name: "Virgin Islands (U.S.)", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
]);

// all provinces with code
export const PROVINCES = Object.freeze([
  {
    name: "Alberta",
    code: "AB",
  },
  {
    name: "British Columbia",
    code: "BC",
  },
  {
    name: "Manitoba",
    code: "MB",
  },
  {
    name: "New Brunswick",
    code: "NB",
  },
  {
    name: "Newfoundland And Labrador",
    code: "NL",
  },
  {
    name: "Nova Scotia",
    code: "NS",
  },
  {
    name: "Nunavut",
    code: "NU",
  },
  {
    name: "Northwest Territories",
    code: "NT",
  },
  {
    name: "Ontario",
    code: "ON",
  },
  {
    name: "Prince Edward Island",
    code: "PE",
  },
  {
    name: "Quebec",
    code: "QC",
  },
  {
    name: "Saskatchewan",
    code: "SK",
  },
  {
    name: "Yukon",
    code: "YT",
  },
]);

// all states with code
export const STATES = Object.freeze([
  {
    name: "Alabama",
    code: "AL",
  },
  {
    name: "Alaska",
    code: "AK",
  },
  {
    name: "American Samoa",
    code: "AS",
  },
  {
    name: "Arizona",
    code: "AZ",
  },
  {
    name: "Arkansas",
    code: "AR",
  },
  {
    name: "California",
    code: "CA",
  },
  {
    name: "Colorado",
    code: "CO",
  },
  {
    name: "Connecticut",
    code: "CT",
  },
  {
    name: "Delaware",
    code: "DE",
  },
  {
    name: "District Of Columbia",
    code: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    code: "FM",
  },
  {
    name: "Florida",
    code: "FL",
  },
  {
    name: "Georgia",
    code: "GA",
  },
  {
    name: "Guam",
    code: "GU",
  },
  {
    name: "Hawaii",
    code: "HI",
  },
  {
    name: "Idaho",
    code: "ID",
  },
  {
    name: "Illinois",
    code: "IL",
  },
  {
    name: "Indiana",
    code: "IN",
  },
  {
    name: "Iowa",
    code: "IA",
  },
  {
    name: "Kansas",
    code: "KS",
  },
  {
    name: "Kentucky",
    code: "KY",
  },
  {
    name: "Louisiana",
    code: "LA",
  },
  {
    name: "Maine",
    code: "ME",
  },
  {
    name: "Marshall Islands",
    code: "MH",
  },
  {
    name: "Maryland",
    code: "MD",
  },
  {
    name: "Massachusetts",
    code: "MA",
  },
  {
    name: "Michigan",
    code: "MI",
  },
  {
    name: "Minnesota",
    code: "MN",
  },
  {
    name: "Mississippi",
    code: "MS",
  },
  {
    name: "Missouri",
    code: "MO",
  },
  {
    name: "Montana",
    code: "MT",
  },
  {
    name: "Nebraska",
    code: "NE",
  },
  {
    name: "Nevada",
    code: "NV",
  },
  {
    name: "New Hampshire",
    code: "NH",
  },
  {
    name: "New Jersey",
    code: "NJ",
  },
  {
    name: "New Mexico",
    code: "NM",
  },
  {
    name: "New York",
    code: "NY",
  },
  {
    name: "North Carolina",
    code: "NC",
  },
  {
    name: "North Dakota",
    code: "ND",
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
  },
  {
    name: "Ohio",
    code: "OH",
  },
  {
    name: "Oklahoma",
    code: "OK",
  },
  {
    name: "Oregon",
    code: "OR",
  },
  {
    name: "Palau",
    code: "PW",
  },
  {
    name: "Pennsylvania",
    code: "PA",
  },
  {
    name: "Puerto Rico",
    code: "PR",
  },
  {
    name: "Rhode Island",
    code: "RI",
  },
  {
    name: "South Carolina",
    code: "SC",
  },
  {
    name: "South Dakota",
    code: "SD",
  },
  {
    name: "Tennessee",
    code: "TN",
  },
  {
    name: "Texas",
    code: "TX",
  },
  {
    name: "Utah",
    code: "UT",
  },
  {
    name: "Vermont",
    code: "VT",
  },
  {
    name: "Virgin Islands",
    code: "VI",
  },
  {
    name: "Virginia",
    code: "VA",
  },
  {
    name: "Washington",
    code: "WA",
  },
  {
    name: "West Virginia",
    code: "WV",
  },
  {
    name: "Wisconsin",
    code: "WI",
  },
  {
    name: "Wyoming",
    code: "WY",
  },
]);

export const emailRegex =
  /^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i;

export const phoneRegex = /^\d{3}(-)?\d{3}(-)?\d{4}$/;

export const phoneIntlRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/;

export const canadaPostalCodeRegex =
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z] \d[ABCEGHJ-NPRSTV-Z]\d$/i;

export const usPostalCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
