import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

/**
 *
 * @param id
 * @param disabled
 * @param label
 * @param required
 * @param error
 * @param helperText
 * @param {string|number|boolean} value
 * @param options
 * @param onChangeValue
 * @param fullWidth
 * @param size
 * @param sx
 * @param onBlur
 * @returns {JSX.Element}
 * @constructor
 */
export const SelectInput = ({
  id = "",
  disabled = false,
  label = "",
  required = false,
  error = false,
  helperText = "",
  value = "",
  options = [],
  onChangeValue = () => {},
  fullWidth = true,
  size,
  sx = {},
  onBlur = () => {},
  autoFocus = false,
}) => {
  return (
    <TextField
      {...(autoFocus && { autoFocus })}
      id={id}
      key={id}
      select
      label={label}
      value={value}
      required={required}
      helperText={helperText}
      fullWidth={fullWidth}
      disabled={disabled}
      onChange={(evt) => onChangeValue(evt.target.value)}
      size={size}
      sx={sx}
      error={error}
      onBlur={() => onBlur()}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={option.disabled} // if not provided (which is the case in most of this app), will be undefined, and therefore not disabled!
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
