/**
 * Accepts a single ShipTo Address, and parses it to be more easily used throughout the application.
 * @param {object} shipToAddress - a single address, direct from the SAP response
 * @return {object} - a single address, parsed
 */
export function parseSingleShipToAddress(shipToAddress) {
  const contactDetails = getContactDetails(
    shipToAddress["ShiptoEmailNav"],
    shipToAddress["ShiptoPhoneNav"]
  );
  return {
    // rawData: shipToAddress, // the full set of fields, currently not needed
    dsName: shipToAddress["Ship_To_Name"],
    dsName1: shipToAddress["DS_Name1"],
    dsName2: shipToAddress["DS_Name2"],
    street: shipToAddress["Street"],
    streetNum: shipToAddress["House_No"],
    streetWithNum: `${shipToAddress["House_No"]} ${shipToAddress["Street"]}`,
    address2: shipToAddress["Str_Suppl1"],
    city: shipToAddress["City"],
    country: shipToAddress["Country"],
    postalCode: shipToAddress["Postl_Code"],
    shippingNote: shipToAddress["Shipping_Note"],
    province: shipToAddress["Province_Code"],
    primaryContactName: contactDetails.primary.name,
    primaryContactPhone: contactDetails.primary.phone,
    primaryContactExtension: contactDetails.primary.extension,
    primaryContactEmail: contactDetails.primary.email,
    secondaryContactName: contactDetails.secondary.name,
    secondaryContactPhone: contactDetails.secondary.phone,
    secondaryContactEmail: contactDetails.secondary.email,
    siteContactPhone: contactDetails.site.phone,
    siteContactEmail: contactDetails.site.email,
    contacts: contactDetails.contacts,
    usePfizer: shipToAddress["Kvgr1_Flag"] === "X",
    longitude: shipToAddress["Longitude"],
    latitude: shipToAddress["Latitude"],
    preferredLanguage: shipToAddress["Pref_Language"],
    delFlag: shipToAddress["DEL_Flag"] === "X",
    del: shipToAddress["DEL"],
    active:
      shipToAddress["Xdele_Flag"] === "" &&
      shipToAddress["Xblck_Flag"] === "" &&
      shipToAddress["Aufsd_Flag"] !== "01",
    division: Number(shipToAddress["Division"]),
    shipToNumber: Number(shipToAddress["Ship_To_Number"]),
  };
}

function getContactDetails(emailData, phoneData) {
  const contactInfo = {
    primary: {},
    secondary: {},
    site: {},
    contacts: [],
  };

  if (emailData && phoneData && emailData.results && phoneData.results) {
    const emailArr = emailData.results;
    const phoneArr = phoneData.results;
    const arrLength =
      phoneArr.length >= emailArr.length ? phoneArr.length : emailArr.length;
    for (let i = 0; i < arrLength; i++) {
      const phone = phoneArr[i];
      const email = emailArr[i];

      const contactDetails = {};
      contactDetails.name = phone?.Phone_Contact
        ? phone?.Phone_Contact
        : email?.Email_Contact;
      contactDetails.phone = phone?.Phone_Number ? phone?.Phone_Number : "";
      contactDetails.email = email?.EMail_Addr ? email?.EMail_Addr : "";
      contactDetails.extension = phone?.Phone_Extension
        ? phone?.Phone_Extension
        : "";

      contactInfo.contacts.push(contactDetails);

      if (phone) {
        if (phone.Phone_Default === "X") {
          contactInfo.primary.phone = phone.Phone_Number;
          contactInfo.primary.name = phone.Phone_Contact;
          contactInfo.primary.extension = phone.Phone_Extension;
        } else if (phone.Phone_Contact !== "") {
          contactInfo.secondary.phone = phone.Phone_Number;
          contactInfo.secondary.name = phone.Email_Contact;
          contactInfo.secondary.extension = phone.Phone_Extension;
        } else if (phone.Phone_Contact === "") {
          contactInfo.site.phone = phone.Phone_Number;
          contactInfo.site.extension = phone.Phone_Extension;
        }
      }

      if (email) {
        if (email.Email_Default === "X") {
          contactInfo.primary.email = email.EMail_Addr;
          if (!contactInfo.primary.name) {
            contactInfo.primary.name = email.Email_Contact;
          }
        } else if (email.Email_Contact !== "") {
          contactInfo.secondary.email = email.EMail_Addr;
          if (!contactInfo.secondary.name) {
            contactInfo.secondary.name = email.Email_Contact;
          }
        } else if (email.Email_Contact === "") {
          contactInfo.site.email = email.EMail_Addr;
        }
      }
    }
  }

  return contactInfo;
}
