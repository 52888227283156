import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  XLSX_UPLOAD_SHEET_NAME,
  XLSX_UPLOAD_SHEET_NAME_FR,
} from "../../../constants";

const WasteInventoryUploadSection = ({
  uploadedFile,
  workSheet,
  handleFile,
  isValidateFileName,
  uploadParseError,
  uploadErrorMsg,
  doneParsing,
  flow,
}) => {
  const { t } = useTranslation("wiManagement");

  return (
    <Stack spacing={1}>
      <Typography variant={"title"}>
        {t(
          `uploadFileSubHeading${
            flow[0].toUpperCase() + flow.slice(1).toLowerCase()
          }`
        )}
      </Typography>
      {uploadParseError && (
        <Typography variant={"title"} sx={{ color: "red" }}>
          {t("uploadGenericError", { uploadErrorMsg: uploadErrorMsg })}
        </Typography>
      )}
      {!uploadParseError &&
        uploadedFile &&
        !isValidateFileName(uploadedFile.name) && (
          <Typography variant={"title"} sx={{ color: "red" }}>
            {t("uploadFileExtensionError")}
          </Typography>
        )}
      {!uploadParseError &&
        uploadedFile &&
        isValidateFileName(uploadedFile.name) &&
        doneParsing &&
        !workSheet && (
          <Typography variant={"title"} sx={{ color: "red" }}>
            {t("uploadFileInvalidSheet", {
              sheetNameEN: XLSX_UPLOAD_SHEET_NAME,
              sheetNameFR: XLSX_UPLOAD_SHEET_NAME_FR,
            })}
          </Typography>
        )}
      <input
        type="file"
        accept="xlsx, xls"
        multiple={false}
        onChange={(e) => handleFile(e)}
      />
    </Stack>
  );
};
export default WasteInventoryUploadSection;
