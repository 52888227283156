import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

export const TextInput = ({
  id = "",
  disabled = false,
  error = false,
  value = "",
  label = "",
  onChange = () => {},
  onBlur = () => {},
  onEnter = () => {},
  helperText = "",
  multiline = false,
  required = false,
  rows = 4,
  fullWidth = true,
  type = undefined,
  maxLength = 524288, // html default
  sx = undefined,
  autoCompleteValue = undefined,
  endAdornmentErrorOnError = false,
}) => {
  const rowsAttribute = multiline ? { rows } : {};
  const autoCompleteAttribute = autoCompleteValue
    ? { autoComplete: autoCompleteValue }
    : {};

  const endAdornmentAttribute =
    endAdornmentErrorOnError && error
      ? {
          endAdornment: (
            <InputAdornment position="end">
              <ErrorOutlineIcon color={"error"} />
            </InputAdornment>
          ),
        }
      : {};
  return (
    <TextField
      id={id}
      key={id}
      label={label}
      helperText={helperText}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={(e) => onBlur(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          if (onEnter) {
            e.preventDefault();
            onEnter();
          }
        }
      }}
      disabled={disabled}
      multiline={multiline}
      {...rowsAttribute} // rows is not allowed in input element if multiline is false
      error={error}
      required={required}
      fullWidth={fullWidth}
      type={type}
      inputProps={{ maxLength, ...autoCompleteAttribute }}
      InputProps={{ ...endAdornmentAttribute }}
      sx={sx}
    />
  );
};
