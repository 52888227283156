export const orderManagement = {
  tabHistory: "Historique des commandes",
  titleHistory: "Historique des commandes",
  subTitleHistory: "Informations historiques des commandes",
  tabCurrent: "Gestion des commandes",
  titleCurrent: "Gestion des commandes",
  subTitleCurrent: "Choisissez le type de produit pour commencer",
  viewExisting: "Afficher la commande existante",
  viewExistingSubtitle:
    "Vous consultez présentement la commande {{orderNumber}}",
  csvExportPrefix: "exportation de l'historique des commandes ",
  csvExportOM: "Gestion_Commandes_Export",
  csvExportOH: "Historique_Commandes_Export",

  chooseRequestedDeliveryWeek: "Choisissez la semaine de livraison demandée",
  chooseOrderNo: "Choisissez le numéro de commande",
  chooseProductTypes: "Choisir le(s) type(s) de produit",

  productType: "Type de produit",
  requestedDeliveryWeek: "Semaine de livraison demandée",
  allocationRemaining: "Attribution restante",
  doses: "Doses",
  packs: "Boîtes ",
  localTime: "Heure locale",
  orderNo: "Numéro de commande",
  lineItemNo: "Article de la commande",
  productDistributionSite: "Site de livraison", //"Site de distribution de produits",
  totalDosesPacks: "Doses/boîtes totales",
  requestedDeliveryDate: "Date de livraison demandée",
  rushOrderFlag: "Commande urgente",
  bulkRecreateOrders: "Recréer des commandes en bloc",

  trackingNumber: "Numéro de suivi",
  lotNumber: "Lot #",
  expiryDate: "Date d’expiration",
  actualDeliveryDate: "Date de livraison réelle",

  productSKU: "UGS de l'article",
  snomed: "SNOMED",
  din: "DIN",
  quantityUnitOfMeasure: "Quantité Unités par charge utile",
  unitsOfMeasure: "Unités par charge utile",
  fptReferenceNumber: "Numéro de référence FPT",
};
