import { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useGetPermissionToken } from "../hooks/useGetPermissionToken";

export const Context = createContext({});
export const UpdateContext = createContext({});
export const useUserAccessFilterContext = () => useContext(Context);
export const useUserAccessFilterUpdateContext = () => useContext(UpdateContext);

export const UserAccessFilterContext = ({ loggedIn, children }) => {
  const [selectedFpt, setSelectedFpt] = useState(0);
  const [selectedFptText, setSelectedFptText] = useState("");
  const [selectedDivision, setSelectedDivision] = useState(0);
  const [fptList, setFptList] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const { permissionToken, permissions, permissionDataLoading } =
    useGetPermissionToken(loggedIn);
  const [cookies, setCookie] = useCookies(["lastFpt", "lastDivision"]);

  // initialization, runs when the permission object changes (rarely)
  useEffect(() => {
    if (!permissions?.length) return;

    const fptList = permissions
      .map((permission) => ({
        value: permission.Fpt.fptId,
        label: permission.Fpt.fptName,
        isActive: permission.activeInactiveFlag,
      }))
      // include only active FPTs
      .filter((fpt) => fpt.isActive)
      .filter(
        (fpt, index, self) =>
          // don't include FPT if it exists in the array already
          index === self.findIndex((x) => fpt.value === x.value)
      )
      .sort((a, b) => a.label.localeCompare(b.label));

    setFptList(fptList);
  }, [permissions]);

  // handles fpt selection
  useEffect(() => {
    if (!selectedFpt) {
      const defaultFpt = getDefaultValue({
        lastValue: cookies.lastFpt,
        valueList: fptList,
      });
      setSelectedFpt(defaultFpt);
    }

    // only save the cookie if the new value is not 0
    if (selectedFpt) setCookie("lastFpt", selectedFpt);

    setDivisionList(
      permissions
        .filter((permission) => permission.Fpt.fptId === selectedFpt)
        .map((permission) => ({
          value: permission.Division.divisionId,
          label: permission.Division.divisionDescription,
          isActive: permission.activeInactiveFlag,
          isAdmin: permission.Role.roleId === 2, // 1 - general user, 2 - site administrator
        }))
    );
    setSelectedFptText(
      fptList.find((fpt) => fpt.value === selectedFpt)?.label || ""
    );
  }, [permissions, fptList, selectedFpt, cookies.lastFpt, setCookie]);

  // handles division selection
  useEffect(() => {
    if (!selectedDivision) {
      const defaultDivision = getDefaultValue({
        lastValue: cookies.lastDivision,
        valueList: divisionList,
      });
      setSelectedDivision(defaultDivision);
    }

    // only save the cookie if the new value is not 0
    if (selectedDivision) setCookie("lastDivision", selectedDivision);

    setIsAdmin(
      permissions
        .filter((permission) => permission.Fpt.fptId === selectedFpt)
        .filter(
          (permission) => permission.Division.divisionId === selectedDivision
        )[0]?.accessRoleId === 2 // 1 - general user, 2 - site administrator
    );
  }, [
    permissions,
    divisionList,
    selectedFpt,
    selectedDivision,
    cookies.lastDivision,
    setCookie,
  ]);

  // if the current value is blank/zero, default to 1) a valid value from cookies, 2) the first active one, or 3) 0 (nothing)
  function getDefaultValue({ lastValue, valueList }) {
    const lastValueNumber = Number(lastValue);
    const lastValueIsValid =
      !!lastValueNumber &&
      !!valueList.find((item) => item.value === lastValueNumber);
    return lastValueIsValid
      ? lastValueNumber
      : valueList.filter((div) => div.isActive)[0]?.value || 0;
  }

  const onAccessFilterChange = (fieldProp, target) => {
    if (fieldProp === "selectedFpt") {
      // from user's active permissions if new division isn't active switch to another active division.
      const activePermissions = permissions.filter(
        (permission) =>
          permission.Fpt.fptId === target.value && permission.activeInactiveFlag
      );
      if (
        !activePermissions.find(
          (item) => item.Division.divisionId === selectedDivision
        )
      ) {
        setSelectedDivision(activePermissions[0].Division.divisionId);
      }
      setSelectedFpt(target.value);
    } else if (fieldProp === "selectedDivision")
      setSelectedDivision(target.value);
    else console.error(`unknown fieldProp: `, fieldProp);
  };

  return (
    <Context.Provider
      value={{
        permissionToken,
        selectedFpt,
        selectedFptText,
        selectedDivision,
        isAdmin,
        fpts: fptList,
        divisions: divisionList,
        permissionDataLoading,
      }}
    >
      <UpdateContext.Provider value={onAccessFilterChange}>
        {children}
      </UpdateContext.Provider>
    </Context.Provider>
  );
};
