import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useUserAccessFilterContext } from "../../../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { postCall } from "../../../utils/requests/apiCalls";
import apiUrls from "../../../utils/requests/apiUrls";

export const useRequestNewAllocations = () => {
  const { selectedFptText, selectedDivision } = useUserAccessFilterContext();
  const currentLocale = useTranslation().i18n.language;

  const convertDate = (allocation) =>
    allocation.Allocations !== ""
      ? dayjs(allocation.DeliveryDate).format("YYYYMMDD")
      : "";

  const requestNewAllocationsBody = (
    product,
    requesterEmail,
    allocations,
    justification
  ) => `
  {
    "d" : {
      "SoldTo"      : "${selectedFptText}",
      "Division"    : "0${selectedDivision}",
      "LanguageKey" : "${currentLocale === "fr-CA" ? "F" : "E"}",
      "SKUNumber"   : "${product.SKUNumber}",
      "Email"       : "${requesterEmail}",
      "AllocQty1"   : "${allocations[0].Allocations}",
      "DeliveryDate1" : "${convertDate(allocations[0])}",
      "UofM1" : "${allocations[0].Allocations ? product.BaseUoM : ""}",
      "AllocQty2"   : "${allocations[1].Allocations}",
      "DeliveryDate2" : "${convertDate(allocations[1])}",
      "UofM2" : "${allocations[1].Allocations ? product.BaseUoM : ""}",
      "AllocQty3"   : "${allocations[2].Allocations}",
      "DeliveryDate3" : "${convertDate(allocations[2])}",
      "UofM3" : "${allocations[2].Allocations ? product.BaseUoM : ""}",
      "AllocQty4"   : "${allocations[3].Allocations}",
      "DeliveryDate4" : "${convertDate(allocations[3])}",
      "UofM4" : "${allocations[3].Allocations ? product.BaseUoM : ""}",
      "Message"     : ${JSON.stringify(justification)}
    }
  }`;

  const requestNewAllocations = async (
    product,
    requesterEmail,
    allocations,
    justification
  ) => {
    try {
      const result = await postCall(
        `${apiUrls.allocationSet}/newAllocations`,
        requestNewAllocationsBody(
          product,
          requesterEmail,
          allocations,
          justification
        )
      );
      if (!result) {
        console.error("Failed to post new allocations");
        return null;
      }
      const resultJson = await result.json();
      console.log(`[New Allocations] SAP Response`, resultJson);
      return resultJson;
    } catch (e) {
      console.error("Failed to post new allocations: ", e);
      return null;
    }
  };

  return { requestNewAllocations };
};
