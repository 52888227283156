import { Link, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../components/TextInput/TextInput";
import { LOGIN_STATES, PHAC_EMAIL } from "../../../constants";
export const ResetPassword = ({
  userName,
  setLoginState,
  setUserName,
  forgotPassword,
  resetPasswordPageError,
  isSubmitting,
}) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const { t } = useTranslation(["login"]);
  const emailLabel = (
    <div>
      {t("email", { ns: "common" })}
      {currentLocale === "fr-CA" ? " " : ""}:{" "}
      <Link href={`mailto:${PHAC_EMAIL}`}>{PHAC_EMAIL}</Link>
    </div>
  );
  return (
    <Stack spacing={2}>
      <Typography>{t("sendCodeHelp")}</Typography>
      <Typography>{t("sendCodeContact")}</Typography>
      <Typography>{emailLabel}</Typography>
      <TextInput
        id="username"
        label={`${t("username")} (${t("required")})`}
        required
        value={userName}
        onChange={(value) => setUserName(value)}
        onEnter={forgotPassword}
        autoCompleteValue="username"
      />
      <Button
        children={<>{t("sendCode")}</>}
        variant={"contained"}
        id="login-button"
        onClick={forgotPassword}
        disabled={userName === "" || isSubmitting}
        size={"large"}
      />
      <Typography>
        {t("back")}{" "}
        <Link
          id="back-button"
          tabIndex="0"
          href="#"
          onClick={() => {
            setLoginState(LOGIN_STATES.LANDING);
          }}
        >
          {t("button")}
        </Link>
      </Typography>
    </Stack>
  );
};

ResetPassword.propTypes = {
  setLoginState: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  setUserName: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  setResetPasswordPageError: PropTypes.func.isRequired,
  resetPasswordPageError: PropTypes.bool.isRequired,
};
