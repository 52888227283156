import { accessProvisioning } from "./fr/accessProvisioning";
import { allocationManagement } from "./fr/allocationManagement";
import { common } from "./fr/common";
import { dsManagement } from "./fr/dsManagement";
import { home } from "./fr/home";
import { login } from "./fr/login";
import { orderManagement } from "./fr/orderManagement";
import { productRequest } from "./fr/productRequest";
import { wiManagement } from "./fr/wiManagement";

export const fr = {
  // each of these is a "namespace" and should be added to the 'ns' config in initI18n.js
  accessProvisioning,
  allocationManagement,
  common,
  dsManagement,
  home,
  orderManagement,
  productRequest,
  wiManagement,
  login,
};
