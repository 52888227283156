import { FormControlLabel, Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import { SelectInput } from "../../../components/Select/SelectInput";

export const ProductSelection = ({
  productOptions = [],
  selectedProduct,
  onSelectProduct,
  onDiluentSelection,
  diluentBoxVisibleIfApplicable = true,
  isDiluentSelected = false,
  productSelectionDisabled = false,
  isOrderConfirmed = false,
}) => {
  const { t } = useTranslation(["productRequest"]);

  // filter the available product options based on several requirements
  const productsFiltered = productOptions
    // hide products with no delivery weeks
    .filter((product) => product.deliveryWeeks.length > 0)
    // hide products with no delivery weeks that are available to submit orders for
    .filter((product) =>
      product.deliveryWeeks.some((week) => !week.pastSubmissionDeadline)
    )
    // hide products that are past the product end date
    .filter((product) => !product.isPastEndDate);

  // when viewing existing orders, sometimes the filters above will remove the product from the dropdown.
  // this will add the selected product in those cases, so the dropdown is not blank.
  if (selectedProduct && !productsFiltered.includes(selectedProduct))
    productsFiltered.push(selectedProduct);

  return (
    <Stack direction={"row"} spacing={3}>
      <SelectInput
        id={"chooseProductType"}
        disabled={isOrderConfirmed || productSelectionDisabled}
        label={`${t("chooseProductType")} (${t("required")})`}
        options={productsFiltered}
        value={selectedProduct?.value}
        onChangeValue={onSelectProduct}
        required={true}
        fullWidth={false}
        sx={{ minWidth: "400px" }}
      />
      {selectedProduct?.diluent && diluentBoxVisibleIfApplicable && (
        <FormControlLabel
          label={`${t("addDiluent")}: ${
            selectedProduct.diluent.productMatchingBom.label
          }`}
          disabled={isOrderConfirmed || productSelectionDisabled}
          control={
            <Checkbox
              checked={isDiluentSelected}
              onChange={(e) => onDiluentSelection(e.target.checked)}
            />
          }
        />
      )}
    </Stack>
  );
};
