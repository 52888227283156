import axios from "axios";
import { useEffect, useState } from "react";
import { getBearerToken } from "../../../utils/getBearerToken";
import apiUrls from "../../../utils/requests/apiUrls";

export const useGetPermissionToken = (loggedIn) => {
  const [permissionToken, setPermissionToken] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [permissionDataLoading, setPermissionDataLoading] = useState(true);

  useEffect(() => {
    const effects = async () => {
      if (loggedIn) {
        const bearer = await getBearerToken();
        const url = `${apiUrls.userManagement}/permission/token`;
        const permissionsURL = `${apiUrls.userManagement}/permission`;
        try {
          const token = await axios
            .get(url, {
              headers: {
                Authorization: `Bearer ${bearer}`,
              },
            })
            .then((res) => {
              return res.data.token;
            });

          const access = await axios
            .get(permissionsURL, {
              headers: {
                Authorization: `Bearer ${bearer}`,
                Permissions: `Bearer ${token}`,
              },
            })
            .then((res) => {
              if (res.data.permissions) return res.data.permissions;
              else console.error("failed to query from api", res);
            });

          if (access !== undefined) setPermissions(access);
          else console.error("failed to query access from api");

          if (token !== undefined) setPermissionToken(token);
          else console.error("failed to query token from api");
        } catch (e) {
          console.error("Unable to request for permission token.", e);
        }
        setPermissionDataLoading(false);
      }
    };
    effects();
  }, [loggedIn]);

  return { permissions, permissionToken, permissionDataLoading };
};
