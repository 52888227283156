import { Container, Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Context } from "../../../context/Context";
import { LoaderPage } from "../../components/LoaderPage/LoaderPage";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { useFetchDsLocations } from "../../hooks/useFetchDsLocations";
import { useSkipContentId } from "../../hooks/useSkipContentId";
import DsDataTable from "./components/DsDataTable";
import DsManagementForm from "./components/DsManagementForm";

/**
 * renders a VDS management screen
 * @returns {JSX.Element}
 * @constructor
 */
const VDSManagement = () => {
  const context = useContext(Context);
  const skipContentId = useSkipContentId();
  const { menuClicked, setMenuClicked } = context;
  const { t } = useTranslation("dsManagement");
  const [selectedDsIdString, setSelectedDsIdString] = useState(null);
  const [selectedDsDetails, setSelectedDsDetails] = useState(null);

  if (menuClicked && setSelectedDsIdString) {
    setTimeout(() => {
      setSelectedDsIdString(null);
      setSelectedDsDetails(null);
      setMenuClicked(false);
    }, 1);
  } else {
    setTimeout(() => {
      setMenuClicked(false);
    }, 1);
  }

  const { allDsLocations, dsDataLoading, setDsDataLoading } =
    useFetchDsLocations();

  // This handles when the user selects/creates a DS. It will store the DS object in the state.
  // It also handles when the Division/FPT selection changes, because when that happens foundDs will be undefined, so we set the state to null.
  useEffect(() => {
    const foundDs = allDsLocations.find(
      (ds) => ds.dsIdString === selectedDsIdString
    );
    if (foundDs) {
      setSelectedDsDetails(foundDs);
    } else if (selectedDsIdString !== "*" && !dsDataLoading) {
      setSelectedDsDetails(null);
      setSelectedDsIdString(null);
    }
  }, [allDsLocations, dsDataLoading, selectedDsIdString]);

  const backToDataTable = () => {
    const viewBtnId = `view-${selectedDsIdString}`;
    setSelectedDsIdString(null);
    setSelectedDsDetails(null);
    setTimeout(() => {
      const viewBtn = document.getElementById(viewBtnId);
      if (viewBtn) {
        viewBtn.focus();
      } else {
        window.scrollTo(0, 0);
      }
    }, 100);
  };

  return (
    <Container maxWidth={"lg"} id={skipContentId}>
      <PageHeader
        title={
          !selectedDsIdString
            ? t("title")
            : selectedDsDetails
            ? `${selectedDsDetails.dsName} (${selectedDsIdString})`
            : t("createVDS")
        }
        description={
          !selectedDsIdString ? (
            t("subTitle")
          ) : selectedDsDetails ? (
            <>
              {t("formSubTitle")}
              <br />
              <br />
              {t("formEditHint")}
            </>
          ) : (
            t("enterVDSInfo")
          )
        }
      />
      <Stack mb={6}>
        {dsDataLoading ? (
          <LoaderPage />
        ) : (
          <>
            {!selectedDsIdString ? (
              <DsDataTable
                setSelectedDsIdString={setSelectedDsIdString}
                allDsLocations={allDsLocations}
              />
            ) : (
              <DsManagementForm
                dsIdString={selectedDsIdString}
                allDsLocations={allDsLocations}
                backToDataTable={backToDataTable}
                setVds={setSelectedDsIdString}
                setDsDataLoading={setDsDataLoading}
              />
            )}
          </>
        )}
      </Stack>
    </Container>
  );
};

export default VDSManagement;
