export const wiManagement = {
  tab: "Inventory and Wastage Management",
  inventoryTitle: "Inventory",
  wastageTitle: "Wastage",
  inventoryButton: "Inventory",
  wastageButton: "Wastage",
  uploadButton: "Upload File",
  csvExportInv: "Inventory_Management_Export",
  csvExportWas: "Wastage_Management_Export",
  notReleasedText:
    "At this time, the Inventory and Wastage page is only available to the Vaccine/Diluent division. Should you want more information about these features or would like to have access to them, please contact",
  previewDialogUpsertReviewInventory:
    "Please review and confirm the information displayed below before submitting your inventory data",
  previewDialogUpsertReviewWastage:
    "Please review and confirm the information displayed below before submitting your wastage data",
  previewDialogUpsertTitle: "Preview File",
  previewDialogUpsertUpload: "Upload file name: {{fileName}}",
  previewDialogDeleteReviewWastage:
    "Please review and confirm the information displayed below is to be deleted from your Wastage Records.",
  previewDialogDeleteReviewInventory:
    "Please review and confirm the information displayed below is to be deleted from your Inventory Records.",
  previewDialogDeleteTitle: "Preview Records to be Deleted",
  previewDialogDeleteRecords:
    "Delete Records from Reporting Date: {{reportingDate}}",
  previewDialogError:
    "The spreadsheet contains {{errorCount}} error(s). Please correct and re-upload.",
  previewDownloadButton: "Download Preview",
  inventoryDescription: "Report inventory information",
  wastageDescription: "Submit and view wastage information",
  uploadFile: "Upload File",
  uploadFileSubHeadingInventory:
    "Please choose one Excel file to report inventory information",
  uploadFileSubHeadingWastage:
    "Please choose one Excel file to report wastage information",
  uploadFileExtensionError:
    "Wrong file format provided. Please re-upload a file with xlsx, xls, or xlsm file format",
  uploadFileInvalidSheet:
    "Could not find Sheet named {{sheetNameEN}} or {{sheetNameFR}}. Please re-upload the excel file with this sheet present",
  uploadGenericError:
    "Something went wrong when parsing the uploaded file. {{uploadErrorMsg}}",
  uploadNoDataError: "No data was found.",
  uploadMaxDataCount:
    "The uploaded file exceeds the maximum row count of {{maxRowCount, number}} rows.",
  uploadBadAvailableStockError: "Available Stock not valid.",
  uploadReportingDateError: "More than one Reporting Date.",
  uploadReportingHeadersMissingError:
    "The following column(s) were not found or are misspelled: {{headers}}",
  uploadBadReportingDateError: "Invalid Reporting Date.",
  uploadBadWastageDateError: "Invalid Wastage Date.",
  uploadBadWastageQuantityError: "Invalid Wastage Quantity.",
  uploadUpsertSuccessTitle: "File Uploaded",
  uploadUpsertSuccessBodyInventory:
    "Your inventory has been submitted. Thank you.",
  uploadUpsertSuccessBodyWastage: "Your wastage has been submitted. Thank you.",
  uploadDeleteConfirmTitle: "Please confirm",
  uploadDeleteConfirmBody:
    "Are you sure you want to delete {{recordNumber}} record(s)?",
  uploadDeleteSuccessTitle: "Records Deleted",
  uploadDeleteLocked:
    "The table is locked. Please try again later. If the problem persists, please contact the Strategic Enablement Unit (SEU) at vaccine-connect-support-connextion-vaccin@phac-aspc.gc.ca.",
  uploadDeleteSuccessBodyWastage:
    "Your wastage records have been deleted for the selected reporting date.",
  uploadDeleteSuccessBodyInventory:
    "Your inventory records have been deleted for the selected reporting date.",
  inventoryTableErrors: "Errors",
  inventoryTableFileRow: "File Row",
  inventoryTableReportingDate: "Reporting Date",
  inventoryTableProductName: "Product Name",
  inventoryTableProductGroup: "Product Group",
  inventoryTableDEL: "DEL",
  inventoryTableLotNumber: "Lot Number",
  inventoryTableExpiryDate: "Expiry Date",
  inventoryTableAvailableStock: "Available Stock",
  inventoryTableUofM: "Unit of Measure",
  inventoryTableMaterialNumber: "Material Number",
  inventoryTableFileName: "File Name",
  inventoryTableSubmittedBy: "Submitted By",
  inventoryTableSubmissionDate: "Submission Date",
  wastageTableErrors: "Errors",
  wastageTableFileRow: "File Row",
  wastageTableReportingDate: "Reporting Date",
  wastageTableWastageDate: "Wastage Date",
  wastageTableProductDescription: "Product Name",
  wastageTableProductGroup: "Product Group",
  wastageTableLotNumber: "Lot Number",
  wastageTableExpiryDate: "Expiry Date",
  wastageTableWastageCategory: "Wastage Category",
  wastageTableMaterialNumber: "Material Number",
  wastageTableExplanation: "Explanation",
  wastageTableQuantityAffected: "Quantity Affected",
  wastageTableUofM: "Unit of Measure",
  wastageTableFileName: "File Name",
  wastageTableSubmittedBy: "Submitted By",
  wastageTableSubmissionDate: "Submission Date",
  next: "Next",
};
