import Amplify from "aws-amplify";
import "core-js/stable";
import { StrictMode } from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./2.0/translations/initDayjs";
import "./2.0/translations/initI18n";
import { getBuildText } from "./2.0/utils/getBuildText";
import App from "./App";
import "./index.css";

const authDev2 = {
  Auth: {
    region: "ca-central-1",
    userPoolId: "ca-central-1_SSF5ZOuqs",
    userPoolWebClientId: "2nacq6drtv5r67286b4to0lidc",
  },
  storage: sessionStorage,
};

const authDev = {
  Auth: {
    region: "ca-central-1",
    userPoolId: "ca-central-1_SSF5ZOuqs",
    userPoolWebClientId: "2nacq6drtv5r67286b4to0lidc",
  },
  storage: sessionStorage,
};

const authStaging2 = {
  Auth: {
    region: "ca-central-1",
    userPoolId: "ca-central-1_R7Wves9tO",
    userPoolWebClientId: "3rukfqbs783094he99amtaj1ig",
  },
  storage: sessionStorage,
};

const authTraining2 = {
  Auth: {
    region: "ca-central-1",
    userPoolId: "ca-central-1_cAUG2JYDk",
    userPoolWebClientId: "6blu1claej6hie2d7kr3rvjt50",
  },
  storage: sessionStorage,
};

const authProd2 = {
  Auth: {
    region: "ca-central-1",
    userPoolId: "ca-central-1_zvQoaPD5U",
    userPoolWebClientId: "2tbcc5sse8vgcruskkkop0ducd",
  },
  storage: sessionStorage,
};

const { REACT_APP_ENVIRONMENT } = process.env;
let environment = "";
if (
  !REACT_APP_ENVIRONMENT ||
  REACT_APP_ENVIRONMENT === "dev" ||
  REACT_APP_ENVIRONMENT === "staging" ||
  REACT_APP_ENVIRONMENT === "dev0" ||
  REACT_APP_ENVIRONMENT === "trn"
) {
  environment = REACT_APP_ENVIRONMENT;
  console.log("Running environment --", REACT_APP_ENVIRONMENT);
}

const authEnv = () => {
  switch (REACT_APP_ENVIRONMENT) {
    case "dev2":
      return authDev2;
    case "qa2":
    case "staging2":
      return authStaging2;
    case "trn2":
      return authTraining2;
    case "dev":
      return authDev;
    case "main2":
    case "prod2":
      return authProd2;
    default:
      throw new Error(
        `failed to find auth config for env ${REACT_APP_ENVIRONMENT}`
      );
  }
};

console.log(`build info: `, getBuildText());

Amplify.configure(authEnv());

ReactDOM.render(
  <StrictMode>
    <App environment={environment} />
  </StrictMode>,

  document.getElementById("root")
);
