import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Alert,
  AlertTitle,
  Chip,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_DATE_FILTER_WEEKS } from "../../../constants";

const CUSTOM_PRESET = "custom";
const VIEW_ALL_PRESET = "viewAll";

export const initialDateFilterState = () => {
  return {
    fromDate: dayjs()
      .subtract(DEFAULT_DATE_FILTER_WEEKS, "week")
      .format("YYYY-MM-DD"),
    toDate: dayjs().format("YYYY-MM-DD"),
  };
};

const DateFilter = ({
  isLoading,
  setDateFilter,
  dateFilter,
  dateFilterFieldTitle = "dateRange",
  dateFilterOptions = [
    {
      label: "twelveMonths",
      dateValue: 12,
      dateUnit: "month",
    },
    {
      label: "sixMonths",
      dateValue: 6,
      dateUnit: "month",
    },
    {
      label: "fourMonths",
      dateValue: 4,
      dateUnit: "month",
    },
    {
      label: "fourWeeks",
      dateValue: 4,
      dateUnit: "week",
    },
  ],
}) => {
  const { t } = useTranslation("common");

  // add View all to beginning of date filter list
  dateFilterOptions.unshift({
    label: VIEW_ALL_PRESET,
    dateValue: null,
    dateUnit: null,
  });
  // add custom to the end of date filter list (when user selects a different pre defined value)
  dateFilterOptions.push({
    label: CUSTOM_PRESET,
    dateValue: null,
    dateUnit: null,
  });

  const [open, setOpen] = useState(false);

  // calculate date filter preset based on dateFilter.toDate / dateFilter.fromDate
  // options could be what the user has defined in dateFilterOptions and custom / View all
  const calculateDateFilterPreset = () => {
    const now = dayjs().format("YYYY-MM-DD");

    // if there is no date filter return view all
    if (!dateFilter || (!dateFilter.toDate && !dateFilter.fromDate))
      return VIEW_ALL_PRESET;

    // if one of toDate or fromDate is missing return custom
    if (!dateFilter.toDate || !dateFilter.fromDate) return CUSTOM_PRESET;

    // if filter matches options return that
    if (dateFilter.toDate === now) {
      const firstMatch = dateFilterOptions.filter((dateFilterOption) => {
        return (
          dayjs(dateFilter.toDate).diff(
            dateFilter.fromDate,
            dateFilterOption.dateUnit,
            true
          ) === dateFilterOption.dateValue
        );
      });
      if (firstMatch && firstMatch.length > 0 && firstMatch[0].label)
        return firstMatch[0]?.label;
    }
    // if nothing matches return custom
    return CUSTOM_PRESET;
  };
  // calculate filter value (to show in chips)
  const dateFilterPreset = calculateDateFilterPreset();

  const onChangeDateFilter = (dateValue, dateUnit) => {
    if (Number.isInteger(dateValue) && dateUnit) {
      const fromDate = dayjs()
        .subtract(dateValue, dateUnit)
        .format("YYYY-MM-DD");
      const toDate = dayjs().format("YYYY-MM-DD");
      setDateFilter({ fromDate, toDate });
    } else {
      setDateFilter({});
    }
  };
  const onChangeFromDatePicker = (dateValue) => {
    const fromDate = dayjs(dateValue).format("YYYY-MM-DD");
    setDateFilter({ ...dateFilter, fromDate });
  };

  const onChangeToDatePicker = (dateValue) => {
    const toDate = dayjs(dateValue).format("YYYY-MM-DD");
    setDateFilter({ ...dateFilter, toDate });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Alert
        icon={false}
        severity="success"
        sx={{
          alignSelf: "center",
          justifyContent: "left",
          paddingTop: 0,
          paddingBottom: 0,
          width: "100%",
        }}
        variant="outlined"
      >
        <AlertTitle
          onClick={() => setOpen(!open)}
          sx={{
            padding: 0,
            margin: 0,
          }}
        >
          <Typography sx={{ display: "inline" }}>
            {t(dateFilterFieldTitle)} (
            {dateFilterPreset === CUSTOM_PRESET
              ? `${dayjs(dateFilter?.fromDate).format("ll")} - ${dayjs(
                  dateFilter?.toDate
                ).format("ll")}`
              : t(dateFilterPreset)}
            )
          </Typography>
          <IconButton
            onClick={() => setOpen(!open)}
            aria-label="expand"
            size="small"
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </AlertTitle>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          sx={{ justifyContent: "center" }}
        >
          <Stack direction="row" spacing={1} alignItems="center" mt={1} mb={3}>
            {dateFilterOptions.map((filterOption) => (
              <Chip
                key={filterOption.label}
                label={t(filterOption.label)}
                variant={
                  dateFilterPreset === filterOption.label
                    ? "default"
                    : "outlined"
                }
                disabled={
                  isLoading ||
                  (dateFilterPreset !== filterOption.label &&
                    filterOption.label === CUSTOM_PRESET)
                }
                color={
                  dateFilterPreset === filterOption.label
                    ? "primary"
                    : "default"
                }
                onClick={() =>
                  filterOption.label !== CUSTOM_PRESET &&
                  onChangeDateFilter(
                    filterOption.dateValue,
                    filterOption.dateUnit
                  )
                }
              />
            ))}
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            alignItems="center"
            pb={2}
          >
            <DatePicker
              label={t("from")}
              value={dateFilter?.fromDate ? dayjs(dateFilter?.fromDate) : null}
              onChange={(event) => onChangeFromDatePicker(event)}
              disabled={isLoading}
            />
            <DatePicker
              label={t("to")}
              value={dateFilter?.toDate ? dayjs(dateFilter?.toDate) : null}
              onChange={(event) => onChangeToDatePicker(event)}
              disabled={isLoading}
            />
          </Stack>
        </Collapse>
      </Alert>
    </LocalizationProvider>
  );
};
export default DateFilter;
