export const allocationManagement = {
  tab: "Gestion des attributions",
  title: "Présenter une demande de changement d’attribution",
  subTitle:
    "Vous pouvez demander des ajustements à votre attribution de produit en changeant la semaine de livraison ou en ajoutant à votre réserve.",
  notAllowedPage:
    "En raison de votre sélection FPT, cette page n'est pas disponible.",
  guidelinesIntro: "Veuillez suivre les directives ci-dessous :",
  guideline1: "Les attributions ne peuvent pas être augmentées globalement",
  guideline2:
    "Il est interdit de transférer des attributions d'un type de produit à une semaine antérieure. Vous pouvez seulement demander de transférer des attributions à des semaines ultérieures ou à votre réserve",
  guideline3:
    "Les échanges d’attributions entre les juridictions fédérales, provinciales et territoriales ne sont pas permis dans le portail; veuillez communiquer avec l'USS si vous concluez une entente avec une autre juridiction fédérale, provinciale ou territoriale",
  guideline4:
    "Pour faire une demande de modification pour un type de produit qui n’apparaît pas dans la liste déroulante ci-dessous, veuillez contacter l'USS",
  guideline5:
    "Noter que les utilisateurs FPT « AMC  », « SCC » et « FAC » ne sont PAS admissibles à présenter des demandes de changement d’allocation",
  toUseIntro: "Pour utiliser ce formulaire :",
  toUse1: "Sélectionnez un type de produit dans la liste déroulante ci-dessous",
  toUse2:
    "Vous pouvez réduire vos attributions pour une semaine de livraison en entrant une valeur négative dans le champ « Nouveaux changements demandés ». Cette quantité sera automatiquement ajoutée à votre réserve",
  toUse3:
    "Pour redistribuer les attributions de votre réserve à une semaine de livraison ultérieure, entrez une valeur positive dans le champ « Nouveaux changements demandés »",
  toUse4:
    "Le champ « Nouveaux changements demandés à la réserve » affichera la variation totale de la quantité par rapport à votre réserve actuelle.",
  toUse5:
    "Cliquez sur « Soumettre la demande de changement » pour soumettre la demande d’approbation. Notez qu’il n’y a aucune garantie que votre demande sera approuvée.",
  toUse6:
    "Une fois que la demande de changement est approuvée par l’ASPC, les champs « Changements approuvés », « Attributions actuellement disponibles » et « Réserve actuellement disponible » seront mis à jour",
  titleChangeRequest: "Demande de modification d'attribution",
  originalAllocations: "Attributions initiales",
  carriedForward: "Reportées",
  adjustmentsAndApprovedChanges: "Ajustements et changements approuvés",
  adjustmentsByPhac: "Ajustements par l’ASPC",
  yourApprovedChanges: "Vos changements approuvés",
  placedOrders: "Commandes passées",
  approvedAllocations: "Attributions actuellement disponibles",
  newRequestedChanges: "Nouveaux changements demandés",
  pendingChanges: "Changements précédents en attente d'approbation",
  allocationAfterApproval: "Attributions disponibles après approbation",
  titleReserveAllocation: "Réserve et attributions courantes",
  noDeliveryWeekText:
    "Aucune semaine de livraison ouverte pour le type de produit sélectionné : {{selectedProduct}}",
  approvedChangeToReserve: "Réserve actuellement disponible",
  approvedChangeToReserveTip:
    "Ce champ sera seulement mis à jour après l’approbation de la demande de changement",
  requestedChangeToReserve: "Nouveaux changements demandés à la réserve",
  newReserveAfterApproval: "Réserve disponible après approbation",
  undoChanges: "Annuler les modifications",
  overLimitNotice:
    "Vous avez entré un changement qui dépasse les allocations disponibles pour une semaine, il a donc été corrigé automatiquement à la valeur la plus élevée possible, compte tenu de votre réserve et des changements demandés au cours des semaines précédentes. Veuillez noter que le « chargement anticipé » en prenant d'une semaine future dans une semaine précédente n'est pas autorisé.",
  underLimitNotice:
    "Vous avez tenté de retirer trop d'allocations d'une semaine, elle a donc été corrigée automatiquement à la valeur la plus basse possible.",
  reserveError:
    "Vous ne pouvez pas soumettre une demande de modification pour un nombre supérieur au nombre de doses qui vous ont été attribuées. Veuillez vous assurer que vos réductions sont égales ou supérieures à vos ajouts à d’autres semaines afin de soumettre votre demande de changement.",
  lockedError:
    "Échec de la demande de redistribution des attributions de vaccins. Cela est dû au fait que les tableaux des attributions de vaccins sont verrouillés par un autre utilisateur. Veuillez réessayer plus tard ou communiquer avec le CNO pour obtenir de l’aide immédiate.",
  submitRequest: "Soumettre la demande de changement",
};
