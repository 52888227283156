import { useEffect, useState } from "react";
import { useUserAccessFilterContext } from "../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { parseSingleShipToAddress } from "../utils/parseShipToAddress";
import { getCall } from "../utils/requests/apiCalls";
import apiUrls from "../utils/requests/apiUrls";

/**
 * @param {string} provCode
 * @param {number} division
 */
const fetchDsList = async ({ provCode, division }) => {
  console.log("[fetchDsList] fetching");
  const result = await getCall(
    `${apiUrls.shipToAddress}?provCode=${provCode}&division=${division}`
  );
  if (!result || result.status !== 200) {
    console.error(`[fetchDsList] failed to fetch: `, result);
    return [];
  }

  let resultJson = null;
  try {
    resultJson = await result.json();
  } catch (e) {
    console.error("[fetchDsList] failed to parse result: ", e);
    return [];
  }

  if (
    !resultJson.shipToAddressSetData.d ||
    !resultJson.shipToAddressSetData.d.results ||
    !resultJson.shipToAddressSetData.d.results.length
  ) {
    console.error("[fetchDsList] result contains no data: ", resultJson);
    return [];
  }

  const resultProcessed = resultJson.shipToAddressSetData.d.results
    .map((item) => {
      const parsedAddress = parseSingleShipToAddress(item);
      return {
        ...parsedAddress, // the full set of parsed fields
        Ship_To_Number: parsedAddress.shipToNumber,
        dsIdString: String(parsedAddress.shipToNumber), // used as object key
        label: `${parsedAddress.dsName} (${parsedAddress.shipToNumber})`, // the label used in select dropdowns
        value: parsedAddress.shipToNumber, // the primary key used in select dropdowns
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label))
    // remove duplicates
    .filter(
      (itemA, index, array) =>
        array.findIndex((itemB) => itemA.value === itemB.value) === index
    );

  console.log(
    `[fetchDsList] fetched ${resultProcessed.length} items (example): `,
    resultProcessed[0]
  );

  return resultProcessed;
};

export const useFetchDsLocations = () => {
  const [allDsLocations, setAllDsLocations] = useState([]);
  const [dsDataLoading, setDsDataLoading] = useState(true);
  const { selectedFptText, selectedDivision, permissionToken } =
    useUserAccessFilterContext();
  const [fetchedFptDivision, setFetchedFptDivision] = useState({
    fpt: undefined,
    division: undefined,
  });

  // forces reload when fpt/division selection changes
  useEffect(() => {
    if (
      fetchedFptDivision.fpt !== selectedFptText ||
      fetchedFptDivision.division !== selectedDivision
    )
      setDsDataLoading(true);
  }, [fetchedFptDivision, selectedDivision, selectedFptText]);

  useEffect(() => {
    if (dsDataLoading) {
      if (!selectedFptText || !selectedDivision || !permissionToken)
        return console.log(
          "[fetchDsList] unable to refresh, missing UM selections"
        );
      fetchDsList({
        provCode: selectedFptText,
        division: selectedDivision,
      }).then((result) => {
        setAllDsLocations(result);
        setFetchedFptDivision({
          fpt: selectedFptText,
          division: selectedDivision,
        });
        setDsDataLoading(false);
      });
    } else {
      console.log("[fetchDsList] skipping load, dataLoading is false");
    }
  }, [dsDataLoading, permissionToken, selectedDivision, selectedFptText]);

  return {
    allDsLocations,
    dsDataLoading,
    setDsDataLoading,
  };
};
