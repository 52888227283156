import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { NAV_STATES } from "../../../2.0/constants";
import strings from "../../../utils/strings";

const RouterShell = ({ nav }) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const lang = currentLocale === "fr-CA" ? "fr" : "en";
  const history = useHistory();
  const [pageTitle, setPageTitle] = useState("Vaccine Connect");

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  useEffect(() => {
    if (history) {
      switch (nav) {
        case NAV_STATES.CURRENT:
          setPageTitle(
            `${strings.title[lang]} - ${strings.home.tabs.pending.tab[lang]}`
          );
          history.push(strings.home.tabs.pending.path[lang]);
          break;

        case NAV_STATES.PREVIOUS:
          setPageTitle(
            `${strings.title[lang]} - ${strings.home.tabs.history.tab[lang]}`
          );
          history.push(strings.home.tabs.history.path[lang]);
          break;

        case NAV_STATES.ORDER:
          setPageTitle(
            `${strings.title[lang]} - ${strings.home.tabs.order.tab[lang]}`
          );
          history.push(strings.home.tabs.order.path[lang]);
          break;

        case NAV_STATES.HOME:
          setPageTitle(
            `${strings.title[lang]} - ${strings.home.tabs.home.tab[lang]}`
          );
          history.push(strings.home.tabs.home.path[lang]);
          break;

        case NAV_STATES.ALLOCATION:
          setPageTitle(
            `${strings.title[lang]} - ${strings.home.tabs.allocations.tab[lang]}`
          );
          history.push(strings.home.tabs.allocations.path[lang]);
          break;

        case NAV_STATES.VDS:
          setPageTitle(
            `${strings.title[lang]} - ${strings.home.tabs.vds.tab[lang]}`
          );
          history.push(strings.home.tabs.vds.path[lang]);
          break;

        case NAV_STATES.ACCESS_PROVISION:
          setPageTitle(
            `${strings.title[lang]} - ${strings.home.tabs.accessProvision.tab[lang]}`
          );
          history.push(strings.home.tabs.accessProvision.path[lang]);
          break;

        case NAV_STATES.WASTAGE_INVENTORY:
          setPageTitle(
            `${strings.title[lang]} - ${strings.home.tabs.wastageInventory.tab[lang]}`
          );
          history.push(strings.home.tabs.wastageInventory.path[lang]);
          break;

        default:
          history.push("/");
      }
    }
  }, [nav, history, lang, setPageTitle]);

  return null;
};

export default RouterShell;
