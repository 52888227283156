import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Alert, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { visuallyHidden } from "@mui/utils";
import { useTranslation } from "react-i18next";
import { useTranslateUnits } from "../../../hooks/useTranslateUnits";
import { translateTimeZone } from "../../../utils/translateTimeZone";
import { DeliveryWeekLocationAllocator } from "./DeliveryWeekLocationAllocator";

export const DeliveryWeek = ({
  visibleDeliveryWeekIndexes = [],
  orderValidationResults = [],
  deliveryWeek = {},
  deliveryWeekIndex = 0,
  updateDeliveryWeekOrder = () => {},
  locations = [],
  selectedProduct,
  onExpansionClick = () => {},
  removeLocationFromDeliveryWeekOrder = () => {},
  isOrderConfirmed = false,
  isDiluentSelected,
  copyOrdersFromPreviousWeek,
}) => {
  const { t, i18n } = useTranslation(["productRequest", "common"]);
  const { getTranslatedUnits } = useTranslateUnits();
  const currentLocale = i18n.language;

  const thisWeekHasValidationErrors = orderValidationResults
    .filter(
      (validationResult) =>
        validationResult.order.deliveryWeekStartDate.isSame(
          deliveryWeek.firstDay
        ) && validationResult.valid === false
    )
    .map((value) => value.valid)
    .includes(false);

  const thisWeekHasOrderData =
    deliveryWeek.orders && Object.keys(deliveryWeek.orders).length > 0;

  return (
    <Accordion
      hidden={
        // if order is confirmed,
        isOrderConfirmed
          ? // hide if week has no order data
            !thisWeekHasOrderData
          : // otherwise, hide if week not visible due to pagination
            !visibleDeliveryWeekIndexes.includes(deliveryWeekIndex)
      }
      expanded={deliveryWeek.isExpanded}
    >
      <AccordionSummary
        expandIcon={
          <IconButton
            aria-label="expand"
            onClick={onExpansionClick(deliveryWeek.weekIdentifier)}
            children={<ExpandMoreIcon />}
          ></IconButton>
        }
        tabIndex="-1"
      >
        <Typography style={visuallyHidden} tabIndex={0}>
          {`
            ${t("deliveryWeek")}: ${deliveryWeek.dateRange}
            ${t("sapPeriodNumber")}: ${deliveryWeek.periodAndModifier}
            ${t("amendmentDateDeadline")}: ${translateTimeZone(
            deliveryWeek.amendmentDeadline.format("lll LT z"),
            currentLocale
          )}
            ${t("allocationRemaining")}: ${t("intlNumber", {
            val: deliveryWeek.allocationRemainingCurrent,
          })}.
            ${isDiluentSelected && ` (${t("vaccine")}),`}.
            ${t("carryForwardTooltip", {
              count: t("intlNumber", {
                val: deliveryWeek.allocationData?.carryForward,
              }),
              units: getTranslatedUnits(
                deliveryWeek.allocationData?.carryForward,
                selectedProduct?.BaseUoM
              ),
            })}.
            ${t("intlNumber", {
              val: deliveryWeek.allocationRemainingDiluentCurrent,
            })} (${t("diluent")}).
            ${t("carryForwardTooltip", {
              count: t("intlNumber", {
                val: deliveryWeek.allocationDataDiluent?.carryForward || 0,
              }),
              units: getTranslatedUnits(
                deliveryWeek.allocationDataDiluent?.carryForward || 0,
                selectedProduct?.BaseUoM
              ),
            })}
           `}
        </Typography>
        <Tooltip
          title={
            <center>
              {t("sapPeriodNumber")}: {deliveryWeek.periodAndModifier}.
              <br />
              {t("amendmentDateDeadline")}:{" "}
              {translateTimeZone(
                deliveryWeek.amendmentDeadline.format("lll LT z"),
                currentLocale
              )}
            </center>
          }
          placement={"top"}
          arrow
        >
          <Typography
            component={"div"}
            sx={{ width: "45%", pointerEvents: "auto" }}
            tabIndex="0"
          >
            {t("deliveryWeek")}: {deliveryWeek.dateRange}
            <Typography style={visuallyHidden}>
              {`${t("sapPeriodNumber")}: ${deliveryWeek.periodAndModifier}`}
              {t("amendmentDateDeadline")}:{" "}
              {translateTimeZone(
                deliveryWeek.amendmentDeadline.format("lll LT z"),
                currentLocale
              )}
            </Typography>
          </Typography>
        </Tooltip>
        <Typography sx={{ color: "text.secondary" }} tabIndex={0}>
          {t("allocationRemaining")}:
        </Typography>
        <Tooltip
          title={`${t("carryForwardTooltip", {
            count: t("intlNumber", {
              val: deliveryWeek.allocationData?.carryForward,
            }),
            units: getTranslatedUnits(
              deliveryWeek.allocationData?.carryForward,
              selectedProduct?.BaseUoM
            ),
          })}`}
          placement={"top"}
          arrow
        >
          <Typography
            component={"div"}
            display={"inline"}
            fontWeight={600}
            paddingLeft={0.5}
            color={
              deliveryWeek.allocationRemainingCurrent >= 0
                ? "success.main"
                : "error.main"
            }
            sx={{ pointerEvents: "auto" }}
            tabIndex="0"
          >
            {t("intlNumber", {
              val: deliveryWeek.allocationRemainingCurrent,
            })}
            {isDiluentSelected && ` (${t("vaccine")}),`}
            <Typography style={visuallyHidden}>{`${t("carryForwardTooltip", {
              count: t("intlNumber", {
                val: deliveryWeek.allocationData?.carryForward,
              }),
              units: getTranslatedUnits(
                deliveryWeek.allocationData?.carryForward,
                selectedProduct?.BaseUoM
              ),
            })}`}</Typography>
          </Typography>
        </Tooltip>

        {isDiluentSelected && (
          <Tooltip
            title={`${t("carryForwardTooltip", {
              count: t("intlNumber", {
                val: deliveryWeek.allocationDataDiluent?.carryForward || 0,
              }),
              units: getTranslatedUnits(
                deliveryWeek.allocationDataDiluent?.carryForward || 0,
                selectedProduct?.BaseUoM
              ),
            })}`}
            placement={"top"}
            arrow
          >
            <Typography
              component={"div"}
              display={"inline"}
              fontWeight={600}
              paddingLeft={0.5}
              color={
                deliveryWeek.allocationRemainingDiluentCurrent >= 0
                  ? "success.main"
                  : "error.main"
              }
              sx={{ pointerEvents: "auto" }}
              tabIndex="0"
            >
              {`${t("intlNumber", {
                val: deliveryWeek.allocationRemainingDiluentCurrent,
              })} (${t("diluent")})`}
              <Typography style={visuallyHidden}>{`${t("carryForwardTooltip", {
                count: t("intlNumber", {
                  val: deliveryWeek.allocationDataDiluent?.carryForward || 0,
                }),
                units: getTranslatedUnits(
                  deliveryWeek.allocationDataDiluent?.carryForward || 0,
                  selectedProduct?.BaseUoM
                ),
              })}`}</Typography>
            </Typography>
          </Tooltip>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={3}>
          {deliveryWeek.allocationErrorProduct && (
            <Alert severity="error">
              {t("quantitiesExceed", {
                productType: isDiluentSelected
                  ? t("vaccine").toLowerCase()
                  : "",
                ordersTotal: deliveryWeek.ordersTotalProduct,
              })}
            </Alert>
          )}
          {deliveryWeek.allocationErrorDiluent && (
            <Alert severity="error">
              {t("quantitiesExceed", {
                productType: isDiluentSelected
                  ? t("diluent").toLowerCase()
                  : "",
                ordersTotal: deliveryWeek.ordersTotalDiluent,
              })}
            </Alert>
          )}
          {Object.keys(deliveryWeek.orders).length > 50 && (
            <Alert severity="error">{t("overDsLimitError")}</Alert>
          )}
          {thisWeekHasValidationErrors && (
            <Alert severity="error">{t("issueWithOrder")}</Alert>
          )}
          <DeliveryWeekLocationAllocator
            deliveryWeek={deliveryWeek}
            updateDeliveryWeekOrder={updateDeliveryWeekOrder}
            locations={locations}
            selectedProduct={selectedProduct}
            removeLocationFromDeliveryWeekOrder={
              removeLocationFromDeliveryWeekOrder
            }
            isOrderConfirmed={isOrderConfirmed}
            isDiluentSelected={isDiluentSelected}
            copyOrdersFromPreviousWeek={copyOrdersFromPreviousWeek}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
