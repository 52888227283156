export function getJobAidUrl(currentLocale) {
  // The Job Aid PDF is not hosted within the portal itself. If you are running locally, the portal will serve the Job Aid from the PROD env.

  // DEV2 (seemingly broken)
  // const PDF_URL_EN = `https://nvmip-webportal-dev2-user-published-content.s3.ca-central-1.amazonaws.com/portal/VaccineConnect_Web+Portal+Job+Aid.pdf`;
  // const PDF_URL_FR = `https://nvmip-webportal-dev2-user-published-content.s3.ca-central-1.amazonaws.com/portal/Guide+de+lUtilisateur_Portail_Web.pdf`;

  // TRN
  // const PDF_URL_EN = `https://portal-trn2.portfolio1.n.g.phac.cloud-nuage.canada.ca/portal/VaccineConnect_Web%20Portal%20Job%20Aid.pdf`;
  // const PDF_URL_FR = `https://portal-trn2.portfolio1.n.g.phac.cloud-nuage.canada.ca/portal/Guide%20de%20lUtilisateur_Portail_Web.pdf`;

  // PROD
  // const PDF_URL_EN = `https://portal-prod2.portfolio1.p.g.phac.cloud-nuage.canada.ca/portal/VaccineConnect_Web%20Portal%20Job%20Aid.pdf`;
  // const PDF_URL_FR = `https://portal-prod2.portfolio1.p.g.phac.cloud-nuage.canada.ca/portal/Guide%20de%20lUtilisateur_Portail_Web.pdf`;

  // After investigating further, it appears the PDF is only hosted on PROD/TRN, not DEV/QA. So, below we will default to PROD when we are not on PROD/TRN.

  const hostnamesWithJobAid = [
    "portal-prod2.portfolio1.p.g.phac.cloud-nuage.canada.ca",
    "portal-trn2.portfolio1.n.g.phac.cloud-nuage.canada.ca",
  ];
  const currentHostHasJobAid = hostnamesWithJobAid.includes(
    window.location.hostname
  );
  const baseUrlDynamic = `${window.location.protocol}//${window.location.host}`;
  const baseUrlProd = `https://portal-prod2.portfolio1.p.g.phac.cloud-nuage.canada.ca`;
  const baseUrl = currentHostHasJobAid ? baseUrlDynamic : baseUrlProd;

  const pdfFilenameEn = `VaccineConnect_Web%20Portal%20Job%20Aid.pdf`;
  const pdfFilenameFr = `Guide%20de%20lUtilisateur_Portail_Web.pdf`;
  const pdfFilename = currentLocale === "fr-CA" ? pdfFilenameFr : pdfFilenameEn;

  return `${baseUrl}/portal/${pdfFilename}`;
}
