import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { en } from "./en";
import { fr } from "./fr";

i18n

  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)

  // pass the i18n instance to react-i18next.
  // learn more: https://github.com/i18next/react-i18next
  .use(initReactI18next)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: { "en-CA": en, "fr-CA": fr },

    supportedLngs: ["en-CA", "fr-CA"],

    load: "currentOnly",

    // namespace config
    ns: [
      "accessProvisioning",
      "allocationManagement",
      "common",
      "dsManagement",
      "home",
      "orderManagement",
      "productRequest",
      "wiManagement",
      "login",
    ],
    defaultNS: "common",
    fallbackNS: "common",

    fallbackLng: "en-CA",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    debug: false,

    // detection plugin config: https://github.com/i18next/i18next-browser-languageDetector
    detection: {
      lookupQuerystring: "lang",
    },
  });
