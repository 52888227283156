/**
 * parses the data according to the column definitions
 * @param {object[]} data
 * @param {object[]} columns
 * @returns {*[][]}
 */
export const parseToCsv = (data, columns) => {
  const headerArray = [];
  columns.forEach((column) => {
    if (column.inCSV) {
      headerArray.push(column.text);
    }
  });

  const parsedCsvArray = [headerArray];

  /**
   * remove csv invalid characters
   * @param data
   * @returns {string}
   */
  const sanitizeStringData = (data) => {
    if (typeof data === "string") {
      const regex = /["',\n]+/g;
      return data.replace(regex, "");
    }

    return data;
  };

  data.forEach((item) => {
    const rowData = columns.map((column) => {
      if (column.inCSV) {
        return column.customCSVRenderer
          ? column.customCSVRenderer({
              value: sanitizeStringData(item[column.name]),
            })
          : sanitizeStringData(item[column.name]);
      }

      return null;
    });

    parsedCsvArray.push(rowData);
  });

  return parsedCsvArray;
};
