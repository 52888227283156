export const login = {
  username: "Username",
  title: "Sign in to your account",
  password: "Password",
  button: "Sign in",
  verification: "Verification code",
  confirmMfa: "Confirm SMS code",
  back: "Back to ",
  newPass: "New password",
  enterNewpass: "Enter your new password",
  reEnter: "Re-enter your password",
  fullName: "Full name",
  enterFullName: "Enter your full name",
  changePass: "Change password",
  confirmPass: "Confirm password",
  enterUser: "Enter your username",
  enterPass: "Enter your password",
  enterCode: "Enter code",
  incorrectUserPass: "Incorrect username or password",
  noUser: "Incorrect username or password",
  forgotPass: "Forgot your password?",
  resetPass: "Reset password",
  verifyMfa: "For security reasons, we need to verify your registration: ",
  mfaCta:
    "We've sent a text message with a verification code to the mobile number associated with your account.",
  mfaExpiring:
    "This code will expire in {{timeLeftMinutes}} minute(s) and {{timeLeftExtraSeconds}} seconds.",
  mfaExpired:
    "The verification code has expired, please go back and sign in again.",
  confirmSms: "Confirm SMS code",
  invalidCode: "Invalid authentication code",
  invalidVerificationCode: "Invalid verification code",
  invalidPasswordComplexity:
    "The password does not meet the password complexity requirements. Your password must be minimum 8 characters, contain at least one digit, one special character, one lowercase letter and one uppercase letter.",
  invalidPasswordNotSame:
    "The passwords entered do not match. Please make sure you enter the same password in both fields",
  passwordAttemptsExceeded:
    "You have exceeded the number of verification attempts allowed, please try again later.",
  invalidUserName: "Username does not exist",
  reset: "Reset your password",
  reEnterPass: "Re-enter your password",
  sendCode: "Send code",
  sendCodeHelp:
    "Please enter your username below. If the username is valid, a text message with a verification code will be sent to the mobile number associated with your account.",
  sendCodeContact:
    "If you do not receive a text message with a verification code within the next 10 minutes, please contact the Vaccine Connect Support.",
  heroSubTitle:
    "Helping the people of Canada protect themselves and their communities from the COVID-19 pandemic",
  heroLogoAlt: "Vaccine logo",
  authenticationTitle: "Sign in",
  authenticationUsername: "Username",
  authenticationPassword: "Password",
  authenticationButton: "Sign in",
};
