import { Container, Stack } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Context } from "../../../context/Context";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { useFetchDsLocations } from "../../hooks/useFetchDsLocations";
import { useFetchOrderList } from "../../hooks/useFetchOrderList";
import { useFetchProductList } from "../../hooks/useFetchProductList";
import { useSkipContentId } from "../../hooks/useSkipContentId";
import { ProductRequestPage } from "../product-request/ProductRequestPage";
import AmendmentDeadlines from "./components/AmendmentDeadlines";
import HeadFilters from "./components/HeadFilters";
import OrderHistoryTable from "./components/OrderManagementTable";

/**
 * @param {'CURR'|'HIST'} mode - the mode for the page, current or historical orders
 * @returns {JSX.Element}
 * @constructor
 */
const OrderManagementPage = ({ mode }) => {
  const context = useContext(Context);
  const skipContentId = useSkipContentId();
  const { menuClicked, setMenuClicked } = context;
  const { t } = useTranslation("orderManagement");
  const [selectedOrderNumber, setSelectedOrderNumber] = useState(null);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);

  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [selectedDeliveryWeek, setSelectedDeliveryWeek] = useState(null);
  const [selectedSalesDocument, setSelectedSalesDocument] = useState(null);
  const [filteredOrderList, setFilteredOrderList] = useState([]);
  const [currentMode, setCurrentMode] = useState(mode);
  const [filteredTranslatedOrderList, setFilteredTranslatedOrderList] =
    useState([]);

  if (currentMode !== mode) setCurrentMode(mode);
  if (menuClicked && selectedOrderNumber) {
    setTimeout(() => {
      setSelectedOrderNumber(null);
      setSelectedOrderDetails(null);
      setMenuClicked(false);
    }, 1);
  } else {
    setTimeout(() => {
      setMenuClicked(false);
    }, 1);
  }

  const isDateFilterEnabled = currentMode === "HIST";
  const {
    setDateFilter,
    dateFilter,
    orderList,
    orderDataLoading,
    setOrderDataLoading,
  } = useFetchOrderList({
    action: currentMode,
    isDateFilterEnabled,
  });

  const { productListOptions, productDataLoading, setProductDataLoading } =
    useFetchProductList();

  const { allDsLocations, dsDataLoading } = useFetchDsLocations();

  const somethingIsLoading =
    orderDataLoading || productDataLoading || dsDataLoading;

  // This resets the filters when user toggles between order management and order history pages.
  useEffect(() => {
    setSelectedProductTypes([]);
    setSelectedDeliveryWeek(null);
    setSelectedSalesDocument(null);
  }, [currentMode, dateFilter]);

  // This handles when the user updates the selected filters.
  useEffect(() => {
    let filteredOrders = [];
    if (
      selectedProductTypes.length === 0 &&
      !selectedDeliveryWeek &&
      !selectedSalesDocument
    ) {
      filteredOrders = orderList;
    } else {
      for (let order of orderList) {
        const lines = [];
        for (let line of order.OrderGetsToItemNav) {
          const foundBySkus =
            selectedProductTypes.length === 0 ||
            selectedProductTypes.find(
              (selectedProductType) => selectedProductType === line.SKUNumber
            );
          const foundByWeek =
            !selectedDeliveryWeek ||
            line.Period_StartDate === selectedDeliveryWeek;
          const foundByOrder =
            !selectedSalesDocument ||
            line.Sales_Document === selectedSalesDocument;
          if (foundBySkus && foundByWeek && foundByOrder) lines.push(line);
        }
        if (lines.length) {
          const anOrder = JSON.parse(JSON.stringify(order));
          anOrder.OrderGetsToItemNav = lines;
          filteredOrders.push(anOrder);
        }
      }
    }
    setFilteredOrderList(filteredOrders);
  }, [
    orderList,
    selectedProductTypes,
    selectedDeliveryWeek,
    selectedSalesDocument,
  ]);

  // This iterates through the list of data, and makes changes to some parts of it.
  useEffect(() => {
    for (let order of filteredOrderList) {
      for (let item of order.OrderGetsToItemNav) {
        const product = productListOptions.find(
          (product) => product.SKUNumber === item.SKUNumber
        );
        if (product) {
          item.SKUDescription = product.SKUDescription;
          item.din = product.din;
          item.snomed = product.snomed;
        }

        const deliverySite = allDsLocations.find(
          (ds) => item.Ship_To_Number === ds.Ship_To_Number
        );
        item.dsName = deliverySite?.dsName ?? `(${t("missing").toLowerCase()})`;
      }
    }

    setFilteredTranslatedOrderList(filteredOrderList);
  }, [allDsLocations, filteredOrderList, productListOptions, t]);

  useEffect(() => {
    const selectedOrderObject = orderList.find(
      (order) => order.Sales_Document === selectedOrderNumber
    );
    setSelectedOrderDetails(selectedOrderObject);
  }, [orderList, selectedOrderNumber]);

  const backToDataTable = ({ doRefresh = false }) => {
    const viewBtnId = `view-${selectedOrderNumber}`;
    setSelectedOrderNumber(null);
    setSelectedOrderDetails(null);
    if (doRefresh) {
      setOrderDataLoading(true);
      setProductDataLoading(true);
    }
    setTimeout(() => {
      const viewBtn = document.getElementById(viewBtnId);
      if (viewBtn) {
        viewBtn.focus();
      } else {
        window.scrollTo(0, 0);
      }
    }, 100);
  };

  return selectedOrderNumber && selectedOrderDetails ? (
    <ProductRequestPage
      orderToPopulate={selectedOrderDetails}
      backToDataTable={backToDataTable}
      pageMode={currentMode === "CURR" ? "MODIFY" : "VIEW"}
    />
  ) : (
    <Container maxWidth={false} id={skipContentId}>
      <PageHeader
        title={currentMode === "HIST" ? t("titleHistory") : t("titleCurrent")}
        description={
          currentMode === "HIST" ? t("subTitleHistory") : t("subTitleCurrent")
        }
      />

      <Stack mb={6} spacing={2}>
        {somethingIsLoading ? (
          <Skeleton height={30} />
        ) : (
          <HeadFilters
            orderList={orderList}
            productListOptions={productListOptions}
            selectedProductTypes={selectedProductTypes}
            setSelectedProductTypes={setSelectedProductTypes}
            selectedDeliveryWeek={selectedDeliveryWeek}
            setSelectedDeliveryWeek={setSelectedDeliveryWeek}
            selectedSalesDocument={selectedSalesDocument}
            setSelectedSalesDocument={setSelectedSalesDocument}
          />
        )}
        {!somethingIsLoading && currentMode === "CURR" && (
          <AmendmentDeadlines
            orderList={filteredTranslatedOrderList}
            productListOptions={productListOptions}
            selectedProductTypes={selectedProductTypes}
            selectedDeliveryWeek={selectedDeliveryWeek}
          />
        )}
        <OrderHistoryTable
          isDateFilterEnabled={isDateFilterEnabled}
          setSelectedOrder={setSelectedOrderNumber}
          orderList={filteredTranslatedOrderList}
          setDateFilter={setDateFilter}
          dateFilter={dateFilter}
          mode={currentMode}
          isDataLoading={somethingIsLoading}
        />
      </Stack>
    </Container>
  );
};

export default OrderManagementPage;
