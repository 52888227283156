import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../../../components/DataTable/DataTable";
import { translateTimeZone } from "../../../utils/translateTimeZone";

const AmendmentDeadlines = ({
  orderList,
  productListOptions,
  selectedProductTypes,
  selectedDeliveryWeek,
}) => {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation(["orderManagement"]);
  const currentLocale = i18n.language;

  if (!productListOptions || !orderList) {
    return <div>no data yet</div>;
  }

  const rows = productListOptions
    .filter(
      (product) =>
        selectedProductTypes.length === 0 ||
        selectedProductTypes.find(
          (selectedProductType) => product.SKUNumber === selectedProductType
        )
    )
    .map((product) =>
      product.deliveryWeeks
        .filter((week) => {
          const notPastDeadline = !week.pastAmendmentDeadline;
          const matchesWeekFilter =
            week.firstDay.format("YYYY-MM-DD") === selectedDeliveryWeek ||
            !selectedDeliveryWeek;
          return notPastDeadline && matchesWeekFilter;
        })
        .map((week) => {
          const startDate = week.firstDay.format("ll");
          const endDate = week.lastDay.format("ll");
          return {
            productType: product.label,
            periodAndModifier: week.periodAndModifier,
            weekIdentifier: week.weekIdentifier,
            dateRange: `${startDate} - ${endDate}`,
            allocationRemaining: week.allocationRemainingOriginal,
            amendmentDeadline: week.amendmentDeadline,
          };
        })
    )
    .flat()
    .sort(
      (a, b) =>
        a.amendmentDeadline - b.amendmentDeadline ||
        a.productType.localeCompare(b.productType)
    );

  const columns = [
    {
      name: "productType",
      text: t("productType"),
      inView: true,
      hasFilter: false,
    },
    {
      name: "dateRange",
      text: t("requestedDeliveryWeek"),
      inView: true,
      hasFilter: false,
      customViewRenderer: ({ data }) => (
        <Tooltip
          title={`${t("sapPeriodNumber")}: ${data.periodAndModifier}`}
          placement={"top"}
          arrow
        >
          <Box>{data.dateRange}</Box>
        </Tooltip>
      ),
    },
    {
      name: "allocationRemaining",
      text: t("allocationRemaining"),
      inView: true,
      hasFilter: false,
      customViewRenderer: ({ data }) =>
        t("intlNumber", { val: data.allocationRemaining }),
    },
    {
      name: "amendmentDeadline",
      text: t("amendmentDateDeadline"),
      inView: true,
      hasFilter: false,
      customViewRenderer: ({ data }) =>
        translateTimeZone(
          data.amendmentDeadline.format("lll LT z"),
          currentLocale
        ),
    },
  ];

  return (
    <Alert
      severity="success"
      sx={{
        alignSelf: "center",
        justifyContent: "left",
        paddingBottom: 0,
        width: "100%",
        // minWidth: "60%",
      }}
    >
      <AlertTitle onClick={() => setOpen(!open)}>
        {t("amendmentDateDeadline")}
        <IconButton
          onClick={() => setOpen(!open)}
          aria-label="expand"
          size="small"
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </AlertTitle>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{ justifyContent: "center" }}
      >
        <DataTable columns={columns} data={rows} defaultRowsPerPage={10} />
      </Collapse>
    </Alert>
  );
};

export default AmendmentDeadlines;
