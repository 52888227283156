import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Container } from "@mui/material";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../../../components/DataTable/DataTable";
import { useFetchWastageList } from "../hooks/useFetchWastageList";
import WasteInventoryUploadDialog from "./WasteInventoryUploadPreviewDialog";
const WastageDataTable = ({ canUseWastagePage }) => {
  const {
    setDateFilter,
    dateFilter,
    wastageData,
    wastageDataLoading,
    setWastageDataLoading,
  } = useFetchWastageList({ isDateFilterEnabled: true });
  const { t } = useTranslation("wiManagement");
  const [uploadDialogVisible, setUploadDialogVisible] = useState(false);
  const columns = [
    {
      name: "WasReptDate",
      text: t("wastageTableReportingDate"),
      inView: true,
      inCSV: true,
      customViewRenderer: ({ value }) => dayjs(value).format("ll"),
    },
    {
      name: "WastageDate",
      text: t("wastageTableWastageDate"),
      inView: true,
      inCSV: true,
      customViewRenderer: ({ value }) => dayjs(value).format("ll"),
    },
    {
      name: "MaterialName",
      text: t("wastageTableProductDescription"),
      inView: true,
      inCSV: true,
    },
    {
      name: "MaterialGroup",
      text: t("wastageTableProductGroup"),
      inView: true,
      inCSV: true,
    },
    {
      name: "Batch",
      text: t("wastageTableLotNumber"),
      inView: true,
      inCSV: true,
    },
    {
      name: "ExpiryDate",
      text: t("inventoryTableExpiryDate"),
      inView: true,
      inCSV: true,
      customViewRenderer: ({ value }) => dayjs(value).format("ll"),
    },
    {
      name: "Category",
      text: t("wastageTableWastageCategory"),
      inView: true,
      inCSV: true,
    },
    {
      name: "Explanation",
      text: t("wastageTableExplanation"),
      inView: true,
      inCSV: true,
    },
    {
      name: "WasQty",
      text: t("wastageTableQuantityAffected"),
      inView: true,
      inCSV: true,
    },
    {
      name: "Uom",
      text: t("wastageTableUofM"),
      inView: true,
      inCSV: true,
    },
    {
      name: "Filename",
      text: t("wastageTableFileName"),
      inView: true,
      inCSV: true,
    },
    // {
    //   name: "SubmitUser",
    //   text: t("wastageTableSubmittedBy"),
    //   inView: true,
    //   inCSV: true,
    // },
    {
      name: "SubmitDate",
      text: t("wastageTableSubmissionDate"),
      inView: true,
      inCSV: true,
      customViewRenderer: ({ value }) => dayjs(value).format("ll"),
    },
  ];

  const parseToCsv = (filteredItems, columns) => {
    const results = filteredItems.map((item) => {
      const row = {};
      columns
        .filter((column) => column.inCSV)
        .forEach((column) => {
          // TODO: here's a good place to do a data transformation based on custom rules defined in a column definition.
          const value = item[column.name];
          row[column.text] = value;
        });
      return row;
    });
    return results;
  };

  return (
    <Container maxWidth="false">
      <WasteInventoryUploadDialog
        uploadDialogVisible={uploadDialogVisible}
        setUploadDialogVisible={setUploadDialogVisible}
        flow="WASTAGE"
        setDataLoading={setWastageDataLoading}
      />
      <DataTable
        isDataLoading={wastageDataLoading}
        isDateFilterEnabled={true}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        dateFilterFieldTitle={"reportingDateRange"}
        columns={columns}
        data={wastageData}
        csvFilenamePrefix={t("csvExportWas")}
        parseToCsv={parseToCsv}
        UploadButtonsComponent={
          <Button
            variant={"outlined"}
            startIcon={<FileUploadIcon />}
            onClick={() => setUploadDialogVisible(true)}
            disabled={!canUseWastagePage}
            sx={{ width: "max-content" }}
            size="large"
          >
            {t("uploadButton")}
          </Button>
        }
      />
    </Container>
  );
};
export default WastageDataTable;
