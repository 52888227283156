import { Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { NavigationButton } from "../../../2.0/components/Button/NavigationButton";
import { useUserAccessFilterContext } from "../../../2.0/components/UserAccessFilter/contexts/UserAccessFilterContext";
import { NAV_PATHS, NAV_STATES } from "../../../2.0/constants";
import { getCanUseAllocationPage } from "../../../2.0/pages/allocation-management/utils/getCanUseAllocationPage";
import MenuIcon from "../../../assets/images/menu_down.svg";
import { Context } from "../../../context/Context";
import sizeVariables from "../../../styles/base/breakpoints.scss";
import "../../../styles/base/color.scss";
import strings from "../../../utils/strings.js";
import "./navigationBar.scss";

const NavigationBar = ({ nav, setNav, ariaHidden }) => {
  const { t } = useTranslation([
    "accessProvisioning",
    "allocationManagement",
    "common",
    "dsManagement",
    "home",
    "orderManagement",
    "productRequest",
    "wastageInventory",
  ]);
  const [menuVisible, setMenuVisible] = useState(false);
  const { isAdmin, selectedFptText } = useUserAccessFilterContext();
  const { setMenuClicked } = useContext(Context);
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const lang = currentLocale === "fr-CA" ? "fr" : "en";
  const pathname = useLocation().pathname;
  const isMobileViewport = useMediaQuery(
    `(max-width:${sizeVariables.mediaTMax})`
  );

  const canUseAllocationPage = getCanUseAllocationPage(selectedFptText);
  const node = useRef();

  const onClickHome = () => setNav(NAV_STATES.HOME);
  const onClickOrder = () => setNav(NAV_STATES.ORDER);
  const onClickAllocation = () => setNav(NAV_STATES.ALLOCATION);
  const onClickPending = () => {
    setMenuClicked(true);
    setNav(NAV_STATES.CURRENT);
  };
  const onClickPrevious = () => {
    setMenuClicked(true);
    setNav(NAV_STATES.PREVIOUS);
  };
  const onClickVDS = () => {
    setMenuClicked(true);
    setNav(NAV_STATES.VDS);
  };
  const onClickWI = () => {
    setMenuClicked(true);
    setNav(NAV_STATES.WASTAGE_INVENTORY);
  };
  const toggleMenu = () => setMenuVisible(!menuVisible);

  const onOutsideClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setMenuVisible(false);
  };

  useEffect(() => {
    if (menuVisible) {
      document.addEventListener("mousedown", onOutsideClick);
    } else {
      document.removeEventListener("mousedown", onOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", onOutsideClick);
    };
  }, [menuVisible]);

  useEffect(() => {
    setMenuVisible(false);
  }, [nav]);

  const {
    home: { menu },
  } = strings;

  return (
    <nav
      className="navigation"
      aria-hidden={ariaHidden}
      ref={node}
      role="menubar"
    >
      <div className="navigation__mobileMenu">
        <NavigationButton
          role="menuitem"
          component={"a"}
          disableRipple={true}
          onClick={toggleMenu}
          id={"menuNav"}
          isMobile={isMobileViewport}
          children={
            <>
              {menu[lang]}
              <img
                className="navigation__mobileMenu__menuIndicator"
                src={MenuIcon}
                alt=""
                aria-hidden
              />
            </>
          }
        ></NavigationButton>
      </div>
      <ul className={`navigation__container ${menuVisible ? "" : "invisible"}`}>
        <li className="navigation__list-item">
          <NavigationButton
            role="menuitem"
            component={"a"}
            disableRipple={true}
            id={"homeNav"}
            isMobile={isMobileViewport}
            onClick={onClickHome}
            selected={pathname === `/${NAV_PATHS.HOME[lang]}`}
            children={
              <Typography variant="h5" component="div">
                {t("tab", { ns: "home" })}
              </Typography>
            }
          ></NavigationButton>
        </li>
        <li className="navigation__list-item">
          <NavigationButton
            role="menuitem"
            component={"a"}
            disableRipple={true}
            id={"orderVaccineNav"}
            isMobile={isMobileViewport}
            onClick={onClickOrder}
            selected={pathname === `/${NAV_PATHS.ORDER[lang]}`}
            children={
              <Typography variant={"h5"} component="div">
                {t("tab", { ns: "productRequest" })}
              </Typography>
            }
          ></NavigationButton>
        </li>
        {canUseAllocationPage && (
          <li className="navigation__list-item">
            <NavigationButton
              role="menuitem"
              component={"a"}
              disableRipple={true}
              id={"allocationNav"}
              isMobile={isMobileViewport}
              onClick={onClickAllocation}
              selected={pathname === `/${NAV_PATHS.ALLOCATION[lang]}`}
              children={
                <Typography variant={"h5"} component="div">
                  {t("tab", { ns: "allocationManagement" })}
                </Typography>
              }
            ></NavigationButton>
          </li>
        )}
        <li className="navigation__list-item">
          <NavigationButton
            role="menuitem"
            component={"a"}
            disableRipple={true}
            id={"currentOrdersNav"}
            isMobile={isMobileViewport}
            onClick={onClickPending}
            selected={pathname === `/${NAV_PATHS.CURRENT[lang]}`}
            children={
              <Typography variant={"h5"} component="div">
                {t("tabCurrent", { ns: "orderManagement" })}
              </Typography>
            }
          ></NavigationButton>
        </li>
        <li className="navigation__list-item">
          <NavigationButton
            role="menuitem"
            component={"a"}
            disableRipple={true}
            id={"previousOrdersNav"}
            isMobile={isMobileViewport}
            onClick={onClickPrevious}
            selected={pathname === `/${NAV_PATHS.PREVIOUS[lang]}`}
            children={
              <Typography variant={"h5"} component="div">
                {t("tabHistory", { ns: "orderManagement" })}
              </Typography>
            }
          ></NavigationButton>
        </li>
        <li className="navigation__list-item">
          <NavigationButton
            role="menuitem"
            component={"a"}
            disableRipple={true}
            id={"vdsNav"}
            isMobile={isMobileViewport}
            onClick={onClickVDS}
            selected={pathname === `/${NAV_PATHS.VDS[lang]}`}
            children={
              <Typography variant={"h5"} component="div">
                {t("tab", { ns: "dsManagement" })}
              </Typography>
            }
          ></NavigationButton>
        </li>
        <li className="navigation__list-item">
          <NavigationButton
            role="menuitem"
            component={"a"}
            disableRipple={true}
            id={"wastageInventory"}
            isMobile={isMobileViewport}
            onClick={onClickWI}
            selected={pathname === `/${NAV_PATHS.WASTAGE_INVENTORY[lang]}`}
            children={
              <Typography variant={"h5"} component="div">
                {t("tab", { ns: "wiManagement" })}
              </Typography>
            }
          ></NavigationButton>
        </li>
        {isAdmin && (
          <li className="navigation__list-item">
            <NavigationButton
              role="menuitem"
              component={"a"}
              disableRipple={true}
              id={"accessProvision"}
              isMobile={isMobileViewport}
              onClick={() => setNav(NAV_STATES.ACCESS_PROVISION)}
              selected={pathname === `/${NAV_PATHS.ACCESS_PROVISION[lang]}`}
              children={
                <Typography variant={"h5"} component="div">
                  {t("tab", { ns: "accessProvisioning" })}
                </Typography>
              }
            ></NavigationButton>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default NavigationBar;

NavigationBar.propTypes = {
  /* The type of form this is, maps to NAV_STATES */
  nav: PropTypes.number.isRequired,
  /* Callback function to set the nav*/
  setNav: PropTypes.func.isRequired,
  ariaHidden: PropTypes.bool,
};
