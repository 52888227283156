export const login = {
  username: "Nom d’utilisateur",
  title: "Se connecter",
  password: "Mot de passe",
  button: "Se connecter",
  verification: "Code de vérification",
  confirmMfa: "Confirmer le code SMS",
  back: "Retourner à ",
  newPass: "Nouveau mot de passe",
  enterNewpass: "Saisissez votre nouveau mot de passe.",
  reEnter: "Entrez de nouveau votre mot de passe.",
  fullName: "Nom complet",
  enterFullName: "Entrez votre nom complet",
  changePass: "Modifiez votre mot de passe",
  confirmPass: "Confirmer votre mot de passe",
  enterUser: "Entrez votre nom d’utilisateur",
  enterPass: "Entrez votre mot de passe",
  enterCode: "Entrez votre code",
  incorrectUserPass: "Nom d’utilisateur ou mot de passe erroné",
  noUser: "Nom d’utilisateur ou mot de passe erroné",
  forgotPass: "Vous avez oublié votre mot de passe?",
  resetPass: "Réinitialiser le mot de passe",
  verifyMfa:
    "Pour des raisons de sécurité, nous devons vérifier votre inscription :",
  mfaCta:
    "Nous avons envoyé un message texte comprenant un code de vérification au numéro de téléphone cellulaire indiqué dans votre compte : ",
  mfaExpiring:
    "Ce code expirera dans {{timeLeftMinutes}} minute(s) et {{timeLeftExtraSeconds}} secondes.",
  mfaExpired:
    "Le code de vérification a expiré, veuillez revenir en arrière et se connecter à nouveau.",
  confirmSms: "Confirmer le code SMS",
  invalidCode: "Code d’authentification non valide",
  invalidVerificationCode: "Code de vérification non valide",
  invalidPasswordComplexity:
    "Votre mot de passe ne satisfait pas aux exigences d’un mot de passe complexe. Il doit contenir un minimum de huit caractères, dont au moins un chiffre, un caractère spécial, une lettre minuscule et une lettre majuscule.",
  invalidPasswordNotSame:
    "Les mots de passe saisis ne correspondent pas. Assurez-vous de saisir le même mot de passe dans les deux champs.",
  passwordAttemptsExceeded:
    "Vous avez dépassé le nombre d’essais de réinitialisation permis, veuillez réessayer plus tard.",
  invalidUserName: "Le nom d’utilisateur n’existe pas",
  reset: "Réinitialisez votre mot de passe",
  reEnterPass: "Entrez de nouveau votre mot de passe.",
  sendCode: "Envoyer un code",
  sendCodeHelp:
    "Veuillez entrer votre nom d'utilisateur ci-dessous. Si le nom d'utilisateur est valide, un message texte avec un code de vérification sera envoyé au numéro de téléphone cellulaire associé à votre compte.",
  sendCodeContact:
    "Si vous ne recevez pas de message texte avec un code de vérification dans les 10 minutes qui suivent, veuillez contacter le Support Connexion Vaccin.",
  heroSubTitle:
    "Aider la population du Canada à se protéger et à protéger ses collectivités contre la pandémie de COVID-19",
  heroLogoAlt: "Logo du vaccin",
  authenticationTitle: "Se connecter",
  authenticationUsername: "Nom d'utilisateur",
  authenticationPassword: "Mot de passe",
  authenticationButton: "Se connecter",
};
