export function translateTimeZone(text, locale) {
  if (locale !== "fr-CA") return text;
  return text
    .replace("PST", "HNP")
    .replace("MST", "HNR")
    .replace("CST", "HNC")
    .replace("EST", "HNE")
    .replace("AST", "HNA")
    .replace("NST", "HNT")
    .replace("PDT", "HAP")
    .replace("MDT", "HAR")
    .replace("CDT", "HAC")
    .replace("EDT", "HAE")
    .replace("ADT", "HAA")
    .replace("NDT", "HAT");
}
