import { Alert, AlertTitle, Box, Stack, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";

import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { translateTimeZone } from "../../../utils/translateTimeZone";
import { DeliveryWeek } from "./DeliveryWeek";
import { ProductSelection } from "./ProductSelection";

const PER_PAGE = 10;

export const ProductRequest = ({
  orderValidationResults = [],
  deliveryWeeks = [],
  updateDeliveryWeekOrder = () => {},
  productOptions = [],
  locations = [],
  selectedProduct,
  diluentBoxVisibleIfApplicable = true,
  isDiluentSelected = false,
  productSelectionDisabled = false,
  onDiluentSelection,
  onSelectProduct = () => {},
  onDeliveryWeekExpansionClick = () => {},
  removeLocationFromDeliveryWeekOrder = () => {},
  isOrderConfirmed = false,
  copyOrdersFromPreviousWeek,
}) => {
  const { t, i18n } = useTranslation(["productRequest"]);
  const currentLocale = i18n.language;

  const [page, setPage] = useState(1);
  const [visibleDeliveryWeekIndexes, setVisibleDeliveryWeekIndexes] = useState(
    getVisiblePages(page)
  );

  const countItems = deliveryWeeks.length;
  const countPages = Math.ceil(countItems / PER_PAGE);

  function getVisiblePages(page) {
    const min = (page - 1) * PER_PAGE;
    const max = min + PER_PAGE;
    const visible = [];
    for (let i = min; i < max; i++) {
      visible.push(i);
    }
    return visible;
  }

  const handlePageChange = (event, page) => {
    setPage(page);
    const visible = getVisiblePages(page);
    setVisibleDeliveryWeekIndexes(visible);
  };

  // build a list of weeks with no allocation remaining,
  // so we can inform the user, since we filter out these weeks from the ui
  const deliveryWeeksWithNoAllocation = selectedProduct?.deliveryWeeks.filter(
    (week) =>
      !week.pastSubmissionDeadline && week.allocationRemainingOriginal === 0
  );

  // get current datetime - keep it here and not global so it'll update on refresh
  const dayjsNow = dayjs();

  const currentDeliveryWeek = selectedProduct?.deliveryWeeks.find(
    (week) => week.firstDay < dayjsNow && week.lastDay > dayjsNow
  );

  return (
    <Stack spacing={2}>
      <Typography variant={"h4"} component={"h3"} gutterBottom>
        {t("productRequest")}
      </Typography>
      <ProductSelection
        isOrderConfirmed={isOrderConfirmed}
        diluentBoxVisibleIfApplicable={diluentBoxVisibleIfApplicable}
        isDiluentSelected={isDiluentSelected}
        productSelectionDisabled={productSelectionDisabled}
        onDiluentSelection={onDiluentSelection}
        productOptions={productOptions}
        selectedProduct={selectedProduct}
        onSelectProduct={onSelectProduct}
      />
      {selectedProduct?.value !== undefined &&
        (currentDeliveryWeek || deliveryWeeksWithNoAllocation.length > 0) && (
          <Alert severity="warning">
            <AlertTitle>{t("importantNotice")}</AlertTitle>
            {currentDeliveryWeek && (
              <p>
                {t("currentWeekClosed", {
                  currentWeek: currentDeliveryWeek.dateRangeLong,
                  closedOn: currentDeliveryWeek.submissionDeadline.format("LL"),
                  closedAt: translateTimeZone(
                    currentDeliveryWeek.submissionDeadline.format("LT z"),
                    currentLocale
                  ),
                })}
              </p>
            )}
            {deliveryWeeksWithNoAllocation.length > 0 && (
              <>
                <p>{t("followingWeeksNoAllocation")}</p>
                <ul>
                  {deliveryWeeksWithNoAllocation.map((week) => (
                    <li key={week.PeriodNumber}>{week.dateRangeLong}</li>
                  ))}
                </ul>
              </>
            )}
          </Alert>
        )}
      <Box>
        <>
          {deliveryWeeks.map((deliveryWeek, index) => {
            return (
              <DeliveryWeek
                visibleDeliveryWeekIndexes={visibleDeliveryWeekIndexes}
                isOrderConfirmed={isOrderConfirmed}
                orderValidationResults={orderValidationResults}
                key={`delivery-week-${index}`}
                updateDeliveryWeekOrder={updateDeliveryWeekOrder}
                deliveryWeek={deliveryWeek}
                deliveryWeekIndex={index}
                selectedProduct={selectedProduct}
                locations={locations}
                onExpansionClick={onDeliveryWeekExpansionClick}
                removeLocationFromDeliveryWeekOrder={
                  removeLocationFromDeliveryWeekOrder
                }
                isDiluentSelected={isDiluentSelected}
                copyOrdersFromPreviousWeek={copyOrdersFromPreviousWeek}
              />
            );
          })}
        </>
      </Box>
      {countPages > 1 && (
        <Box sx={{ justifyContent: "center", display: "flex" }}>
          <Pagination
            hidden={isOrderConfirmed || selectedProduct?.value === undefined}
            page={page}
            count={countPages}
            onChange={handlePageChange}
          />
        </Box>
      )}
    </Stack>
  );
};
