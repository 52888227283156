// regular, provinces, restrict province selection (not fully populated)
// const regularFpts = ["ON", "BC"];

// allowed to specify province, but country is locked
const provinceFpts = ["CSC", "CBSA", "DON", "PHAC", "DND", "ISC"];

// allowed to specify country or province
const countryProvinceFpts = ["GAC", "CAF"];

/**
 * There are three types of FPTs, see above comments for some info.
 * @type {{REGULAR: string, PROVINCE: string, COUNTRY_PROVINCE: string}}
 */
export const FPT_MODES = {
  REGULAR: "REGULAR",
  PROVINCE: "PROVINCE",
  COUNTRY_PROVINCE: "COUNTRY_PROVINCE",
};

/**
 * Given the FPT string, returns an enum/string that can be used to determine which type of FPT is provided.
 * @param fpt
 * @return {string}
 */
export function getFptMode(fpt) {
  if (countryProvinceFpts.includes(fpt)) return FPT_MODES.COUNTRY_PROVINCE;
  else if (provinceFpts.includes(fpt)) return FPT_MODES.PROVINCE;
  else return FPT_MODES.REGULAR;
}

/**
 * Given the FPT string, returns if this FPT should be able to specify country/province for various actions in the portal.
 * @param {string} fpt
 * @return {{canEditCountry: boolean, canEditProvince: boolean}}
 */
export function getFptRestrictions(fpt) {
  const fptMode = getFptMode(fpt);
  if (fptMode === FPT_MODES.COUNTRY_PROVINCE)
    return { canEditProvince: true, canEditCountry: true };
  else if (fptMode === FPT_MODES.PROVINCE)
    return { canEditProvince: true, canEditCountry: false };
  else return { canEditProvince: false, canEditCountry: false };
}
