import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import joinArrayValues from "./utils/joinArrayValues";
export const DropdownFilter = ({
  inputValue = "",
  items,
  columnName,
  columnText = "",
  onFilterChange,
}) => {
  const { t } = useTranslation();
  switch (true) {
    case inputValue === "true" && columnName === "usePfizer":
      inputValue = t("yes");
      break;
    case inputValue === "false" && columnName === "usePfizer":
      inputValue = t("no");
      break;
    case inputValue === "true" && columnName === "active":
      inputValue = t("active");
      break;
    case inputValue === "false" && columnName === "active":
      inputValue = t("inactive");
      break;
    default:
      break;
  }

  /**
   * @param {React.SyntheticEvent} event
   * @param {string|null} newValue
   */
  const onChange = (event, newValue) => {
    // when array of arrays are passed into to <Autocomplete> options field they are joined by ",". We need to do the same in order to get filter working
    newValue = joinArrayValues(newValue);

    // the following lines seem to be put in place for filtering Use For Pfizer flag under DS Management.
    if (["usePfizer", "active"].includes(columnName) && newValue) {
      if (newValue.toLowerCase() === "yes") newValue = "true";
      else if (newValue.toLowerCase() === "no") newValue = "false";
    }

    onFilterChange({ column: columnName, filter: newValue });
  };

  return (
    <Autocomplete
      size={"small"}
      id={`tableFilterAutocomplete-dropdown-${columnText}`}
      freeSolo
      disablePortal
      options={items}
      value={inputValue ? inputValue : null}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("filterBy", { filterName: columnText })}
          placeholder={t("all")}
        />
      )}
      getOptionLabel={(option) => {
        if (/^\d\d\d\d-\d\d-\d\d$/.test(String(option)))
          return dayjs(option).format("ll");
        return String(option);
      }} // resolves error when passing numbers as options
    />
  );
};
