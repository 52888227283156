export const accessProvisioning = {
  tab: "Gestion des accès",
  title: "Gestion des accès",
  subTitle: "Gérer l’accès à l’application et des autorisations",
  noAccess:
    "Vous n'avez pas accès à cette page avec la combinaison FPT et Division sélectionnée.",
  portalGeneralUser: "utilisateur général du portail",
  portalSiteAdministrator: "administrateur du portail",
  user: "Utilisateur",
  firstName: "Prénom",
  lastName: "Nom de famille",
  role: "Rôle",
  successMessage: `Vos modifications ont été soumises.`,
  errorMessage:
    "Des erreurs se sont produites lors de la soumission de votre demande.",
  successfulCreations: "Créations réussies",
  successfulChanges: "Changements réussis",
  creationErrors: "Erreurs de création",
  changeErrors: "Erreurs de changement",
  userStats:
    "Cet utilisateur a {{activeCount}} rôle(s) actif(s) et {{inactiveCount}} rôle(s) inactif(s).",
  editHelpText: "Cliquez sur un statut pour basculer son état.",
  creationHelpText:
    "Si une division est grisée dans la liste déroulante, veuillez plutôt mettre à jour l'autorisation existante.",
};
