import dayjs from "dayjs";
import preval from "preval.macro";
const { REACT_APP_ENVIRONMENT } = process.env;

export function getBuildText() {
  // preval is used to run on build-time, rather than run-time
  const buildDate = preval`module.exports = new Date()`;
  console.log("build raw date:", buildDate);
  const buildDateFormatted = dayjs(buildDate).format("YYYY-MM-DD h:mma Z");
  return `${REACT_APP_ENVIRONMENT} on ${buildDateFormatted}`;
}
