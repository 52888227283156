import { Auth } from "aws-amplify";

export async function getBearerToken() {
  let auth;
  try {
    auth = await Auth.currentSession();
  } catch (e) {}
  if (auth && auth.accessToken && auth.accessToken.jwtToken) {
    return auth.accessToken.jwtToken;
  } else {
    return null;
  }
}
