export const dsManagement = {
  tab: "DS Management",
  title: "Delivery site management",
  subTitle: "Find or add a new delivery site",
  vds: "Delivery site",
  city: "City",
  usePfizer: "Use for Pfizer",
  statusMessage:
    'The status can only be changed to "Inactive" once the delivery site has been created.',
  division: "Division",
  divisionMessage:
    "The division has been pre-set based on your user account and can not be changed.",
  createVDS: "Add new delivery site",
  enterVDSInfo: "Enter delivery site information",
  extension: "Extension",
  formSubTitle: "View/Edit delivery site information",
  formEditHint: "To edit, use the button at the bottom of the page.",
  heading: "Delivery site details",
  dsName: "Delivery site name",
  vdsNumber: "Delivery site number",
  primaryContactName: "Primary contact name",
  primaryContactPhone: "Primary contact phone",
  phoneErrorShort: "The entered number is too short.",
  phoneErrorLong: "The entered number is too long.",
  primaryContactEmail: "Primary contact email",
  secondaryContactName: "Secondary contact name",
  secondaryContactPhone: "Secondary contact phone",
  secondaryContactEmail: "Secondary contact email",
  tertiaryContactName: "Tertiary contact name",
  tertiaryContactPhone: "Tertiary contact phone",
  tertiaryContactEmail: "Tertiary contact email",

  // primaryContactPhoneExt: "Primary contact phone extension",
  // secondaryContactPhoneExt: "Secondary contact phone extension",
  // tertiaryContactPhoneExt: "Tertiary contact phone extension",
  primaryContactPhoneExt: "Extension",
  secondaryContactPhoneExt: "Extension",
  tertiaryContactPhoneExt: "Extension",

  country: "Country",
  province: "Province",
  address: "Address",
  address2: "Address 2 (floor, unit #, apt #, etc.)",
  address2Short: "Address 2",
  postalCode: "Postal code",
  deliveryNotes: "Delivery site notes",
  vdsUpdateFailure:
    "System failed to update delivery site. Please try again later or contact NOC for immediate assistance.",
  vdsUpdateLockFailure:
    "Your delivery site update failed due to table being locked by another user. Please try again later or contact NOC for immediate assistance.",
  preferredLanguage: "Communication Language",
  delFlag: "Drug Establishment Licenced Facility (DEL)",
  del: "DEL Number",
  possibleDuplicateDetected: "Possible duplicates detected",
  possibleDuplicateDetectedMessage:
    'The delivery site you are trying to create matches one or more existing site based on the combination of fields listed in red below. If you are sure you would like to create this delivery site, you may click "Confirm" below.',
  exactDuplicateDetected: "Duplicate detected",
  exactDuplicateDetectedMessage:
    "The delivery site you are trying to create matches one or more existing site based on the combination of fields listed in red below. As these fields must be unique, please update your entry and try again.",
  viewExistingVDS: "View existing delivery site",
  vdsExportPrefix: "delivery_site_export",
  sectionDs: "Delivery site details",
  sectionContact: "Contact information",
  sectionLocation: "Location details",
  sectionNotes: "Notes",
};
