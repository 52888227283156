export const home = {
  tab: "Accueil",
  headerTitle: "Acheminer les vaccins à toute la population du Canada",
  headerSubtitle: "Faisons les premiers pas vers un avenir meilleur",
  welcomeHeader: "Bienvenue à ConnexionVaccin",
  welcomeBody1:
    "Ce portail vous permet de demander des vaccins pour votre collectivité, afin que la population du Canada puisse être vaccinée rapidement et en toute sécurité.",
  welcomeBody2:
    "Pour utiliser le portail, consultez le Guide de l'utilisateur. Il vous indique le processus à suivre pour faire une demande de vaccins et vérifier l’état de vos commandes à l’aide de directives étape par étape faciles à suivre. Veuillez le télécharger et le lire attentivement avant d’utiliser le site.",
  jobAidButton: "Télécharger Guide de l'utilisateur",
  overviewHeader: "Aperçu du site",
  overviewBody:
    "Le portail comporte les sections suivantes, toutes facilement accessibles à l’aide des onglets dans le menu du haut.",
  purposeHeader: "Notre objectif",
  purposeBody1:
    "ConnexionVaccin est un environnement sécurisé qui transmet les renseignements directement au Centre national des opérations de l’Agence de la santé publique du Canada laquelle est responsable de la logistique de la distribution des vaccins contre la COVID-19 au Canada. Le portail vise à : ",
  purposeBody2: "Faciliter les demandes de vaccins contre la COVID-19",
  purposeBody3:
    "Conçu pour simplifier le partage d’information entre les compétences fédérales, provinciales et territoriales et l’ASPC, le portail est une collaboration conjointe entre l’ASPC, Santé Canada et Services partagés Canada.",
  purposeBody4:
    "Si vous avez des questions ou des préoccupations concernant ce portail, n’hésitez pas à communiquer avec le Support Connexion Vaccin.",
  lineItemSummary: "Sommaire des commandes",
  lineItemSummaryBody:
    "Le tableau ci-dessous résume le nombre de lignes de demande. Veuillez noter que les lignes de demande qui sont annulées ou rejetées ne sont pas incluses.",
  lineItemTableWaitingForApproval: "EN ATTENTE D’APPROBATION",
  lineItemTableApprovedOrSentToLSP: "APPROUVÉ LIVRAISON/ ENVOYÉ AU FSL",
  lineItemTableInTransit: "EN TRANSIT",
  lineItemTableDelivered: "LIVRÉ",
  availableReserve: "Réserve disponible et attributions actuelles",
  availableReserveSummaryBody:
    "Le tableau ci-dessous résume le nombre d’attributions disponibles dans la réserve pour la semaine de livraison en cours par produit.",
  availableReserveTableProductType: "Type de produit",
  availableReserveTableCurrentlyAvailable: "Réserve actuelle",
  availableReserveTableTotalCurrentAllocation:
    "Attribution totale actuellement disponible",
  availableReserveTableEmpty: "Aucune réserve disponible.",
};
