import * as _ from "lodash";
import {
  activateDeactivateVDS,
  editVDS,
} from "../../../utils/requests/apiPostCalls";
import {
  ModalGeneric,
  ModalSaveConfirmContent,
  ModalSaveDuplicateContent,
} from "../components/DsModals";

export const stateFunctions = {
  setVds: () => null,
  setVdsDetails: () => null,
  setFormSubmitting: () => null,
  setFormEditable: () => null,
  setModalIsOpen: () => null,
  setModalContent: () => null,
  setDirty: () => null,
};

/**
 * sets state update functions for library
 * @param {function} setVds
 * @param {function} setVdsDetails
 * @param {function} setFormSubmitting
 * @param {function} setFormEditable
 * @param {function} setModalIsOpen
 * @param {function} setModalContent
 * @param {function} setDirty
 * @param {function} backToDataTable
 */
export function setHandlerFunctions({
  setVds,
  setVdsDetails,
  setFormSubmitting,
  setFormEditable,
  setModalIsOpen,
  setModalContent,
  setDirty,
  backToDataTable,
}) {
  Object.keys(arguments[0]).forEach((stateKey) => {
    stateFunctions[stateKey] = arguments[0][stateKey];
  });
}

/**
 * close the modal
 */
export const closeModalHandler = () => {
  stateFunctions.setModalContent(null);
  stateFunctions.setModalIsOpen(false);
};

/**
 * activates/deactivates vds via api
 * @param {string} dsIdString - the ds ShipToNumber in string format
 * @param {object} vdsDetails - the vds details object
 * @param {string} fpt
 * @param {function} t - the useTranslation function
 * @returns {Promise<boolean>}
 */
export const activateDeactivateVdsHandler = async ({
  dsIdString,
  vdsDetails,
  fpt,
  t,
}) => {
  try {
    const resp = await activateDeactivateVDS({
      dsIdString,
      fpt,
      division: vdsDetails.division,
      active: vdsDetails.active,
    });
    console.log(`[dsStateUpdate] sap response: `, resp);
    switch (resp["sap-status"]) {
      case "OK":
        break;
      case "LOCK":
        console.error("[dsStateUpdate] failed updating ds: ", resp);
        stateFunctions.setModalContent(
          ModalGeneric({ bodyText: t("vdsUpdateLockFailure"), t })
        );
        stateFunctions.setModalIsOpen(true);
        return false;
      default:
        console.error("[dsStateUpdate] failed updating ds: ", resp);
        stateFunctions.setModalContent(
          ModalGeneric({ bodyText: t("vdsUpdateFailure"), t })
        );
        stateFunctions.setModalIsOpen(true);
        return false;
    }
    return true;
  } catch (e) {
    console.error(
      "[dsStateUpdate] failed updating ds: ",
      dsIdString,
      vdsDetails
    );
    stateFunctions.setModalContent(
      ModalGeneric({ bodyText: t("vdsUpdateFailure"), t })
    );
    stateFunctions.setModalIsOpen(true);

    return false;
  }
};

/**
 * save vds handler
 * @param {string} dsIdString - the ds ShipToNumber in string format
 * @param {object} vdsDetails - the vds details object
 * @param {object} userAttributes - the user attributes object
 * @param {string} fpt
 * @param {function} t - the useTranslation function
 * @returns {Promise<boolean>}
 */
export const saveVdsHandler = async ({
  dsIdString,
  vdsDetails,
  userAttributes,
  fpt,
  t,
}) => {
  try {
    const resp = await editVDS({
      formData: vdsDetails,
      dsIdString,
      userAttributes,
      fpt,
    });
    switch (resp["sap-status"]) {
      case "OK":
        return resp;

      case "LOCK":
        console.error("[dsEdit] failed updating ds: ", resp);
        stateFunctions.setModalContent(
          ModalGeneric({ bodyText: t("vdsUpdateLockFailure"), t })
        );
        stateFunctions.setModalIsOpen(true);
        return false;

      default:
        console.error("[dsEdit] failed updating ds: ", resp);
        stateFunctions.setModalContent(
          ModalGeneric({ bodyText: t("vdsUpdateFailure"), t })
        );
        stateFunctions.setModalIsOpen(true);
        return false;
    }
  } catch (e) {
    console.error("[dsEdit] failed updating ds: ", dsIdString, vdsDetails);
    stateFunctions.setModalContent(
      ModalGeneric({ bodyText: t("vdsUpdateFailure"), t })
    );
    stateFunctions.setModalIsOpen(true);

    return false;
  }
};

/**
 * submits the form data
 * @param {string} dsIdString - the ds ShipToNumber in string format
 * @param {object} vdsDetails - the vds details object
 * @param {object} userAttributes - the user attributes object
 * @param {string} fpt
 * @param {object} previousDetails - the vds details object, before any changes
 * @param {function} setDsDataLoading - setting this to true triggers a ds list refresh
 * @param {function} t - the useTranslation function
 * @returns {Promise<boolean>}
 */
export const submitFormHandler = async ({
  dsIdString,
  vdsDetails,
  userAttributes,
  fpt,
  previousDetails,
  setDsDataLoading,
  t,
}) => {
  const resp = await saveVdsHandler({
    dsIdString,
    vdsDetails,
    userAttributes,
    fpt,
    t,
  });

  if (!resp) return false;
  let shouldSendStateUpdate = false;

  // parse SAP response message and remove period characters
  const respParsed = resp["sap-message"].replace(/\./g, "").split(" ");

  // for some reason the results are in different locations depending on create or update
  const responseResultCreate = respParsed[1];
  const responseResultUpdate = respParsed[3];

  if (responseResultCreate === "Created") {
    // the api returns the dsId as a string with leading zeroes on edit, this removes them
    dsIdString = String(Number(respParsed[2]));
    if (!vdsDetails.active) shouldSendStateUpdate = true;
  } else if (responseResultUpdate === "Updated") {
    if (previousDetails.active !== vdsDetails.active)
      shouldSendStateUpdate = true;
  } else {
    console.error(`[dsEdit] unexpected result within response`, respParsed);
  }

  // the active/inactive state must be sent as a separate request due to api spec
  if (shouldSendStateUpdate) {
    const stateUpdateResult = await activateDeactivateVdsHandler({
      dsIdString,
      vdsDetails,
      fpt,
      t,
    });
    if (!stateUpdateResult) return false;
  }

  // reload ship to addresses
  setDsDataLoading(true);

  stateFunctions.setVds(dsIdString);

  return true;
};

/**
 * edit button click handler
 */
export const editClickHandler = () => {
  stateFunctions.setFormEditable(true);
};

/**
 * Takes a set of rules, and checks the allDsLocations array to see if the vdsDetails match based on those rules.
 * @param {{criteria: {[x: string]: any}, blockCreation: boolean, skipWhenException: boolean}[]} rules - the key is the field to check, the value is the value to check, if every key's value matches, it's a duplicate
 * @param {object} vdsDetails - the details of the ds that is being created
 * @param {object[]} allDsLocations
 * @param {string} fpt
 * @returns {{rule: object, ds: object}[]}
 */
const findDuplicates = (rules, vdsDetails, allDsLocations, fpt) => {
  const duplicates = [];

  const dsIsException =
    fpt === "GAC" &&
    vdsDetails.postalCode === "L5P 1B2" &&
    vdsDetails.streetNum === "3111" &&
    vdsDetails.street === "Convair Dr";

  rules
    .filter(
      (rule) =>
        // skip duplicate check when the created DS is an exception, and when the rule is set to skip when the DS is an exception
        !(dsIsException && rule.skipWhenException)
    )
    .forEach((rule) => {
      for (let ds of Object.values(allDsLocations)) {
        const isMatch = Object.entries(rule.criteria).reduce(
          (isMatch, [key, value]) => {
            const toCheck = String(ds[key] || "").toLowerCase();
            const currentValue = String(value || "").toLowerCase();
            const valueMatches = toCheck === currentValue;
            return isMatch && valueMatches;
          },
          true
        );
        if (isMatch) duplicates.push({ rule, ds });
      }
    });
  return duplicates;
};

/**
 * Searches the allDsLocations array for possible/exact duplicates.
 * @param {object} vdsDetails - the details of the ds that is being created
 * @param {object[]} allDsLocations
 * @param {string} fpt
 * @returns {object[]}
 */
const checkDsDuplication = (vdsDetails, allDsLocations, fpt) => {
  const rules = [
    {
      criteria: {
        streetNum: vdsDetails.streetNum,
        street: vdsDetails.street,
        postalCode: vdsDetails.postalCode,
      },
      blockCreation: false,
      skipWhenException: true,
    },
    {
      criteria: {
        dsName1: vdsDetails.dsName1,
        dsName2: vdsDetails.dsName2,
      },
      blockCreation: true,
      skipWhenException: true,
    },
    {
      criteria: {
        streetNum: vdsDetails.streetNum,
        street: vdsDetails.street,
        postalCode: vdsDetails.postalCode,
        address2: vdsDetails.address2,
      },
      blockCreation: false,
      skipWhenException: true,
    },
  ];
  return findDuplicates(rules, vdsDetails, allDsLocations, fpt);
};

/**
 * @param {string} dsIdString - the ds ShipToNumber in string format
 * @param {Object} vdsDetails
 * @param {Object} userAttributes
 * @param {string} fpt
 * @param {Object} previousDetails
 * @param {function} setDsDataLoading - setting this to true triggers a ds list refresh
 * @param {Function} t
 */
const makeSaveFlow = (
  dsIdString,
  vdsDetails,
  userAttributes,
  fpt,
  previousDetails,
  setDsDataLoading,
  t
) => {
  return () => {
    stateFunctions.setModalContent(() =>
      ModalSaveConfirmContent({
        dsIdString,
        vdsDetails,
        userAttributes,
        fpt,
        previousDetails,
        setDsDataLoading,
        t,
      })
    );
    stateFunctions.setModalIsOpen(true);
  };
};

/**
 * submit button click handler
 * @param {string} dsIdString - the ds ShipToNumber in string format
 * @param {object} vdsDetails - the vds details object
 * @param {object} userAttributes - the user attributes object
 * @param {string} fpt
 * @param {object[]} allDsLocations - array of existing ship to addresses
 * @param {function} setDsDataLoading - setting this to true triggers a ds list refresh
 * @param {function} t - the useTranslation function
 * @returns {Promise<void>}
 */
export const submitClickHandler = async ({
  dsIdString,
  vdsDetails,
  userAttributes,
  fpt,
  allDsLocations,
  setDsDataLoading,
  t,
}) => {
  const previousDetails = allDsLocations.find(
    (ds) => ds.dsIdString === dsIdString
  );
  const saveFlow = makeSaveFlow(
    dsIdString,
    vdsDetails,
    userAttributes,
    fpt,
    previousDetails,
    setDsDataLoading,
    t
  );

  console.log(`[dsEdit] processing form data: `, vdsDetails);

  const duplicateCheckResults = checkDsDuplication(
    vdsDetails,
    previousDetails
      ? allDsLocations.filter((ds) => ds.dsIdString !== dsIdString)
      : allDsLocations, // valid previousDetails mean editing, remove current entry to check duplicates
    fpt
  );

  if (duplicateCheckResults.length) {
    const duplicatesToBlock = duplicateCheckResults.filter(
      (result) => result.rule.blockCreation
    );
    const duplicatesToAllow = duplicateCheckResults.filter(
      (result) => !result.rule.blockCreation
    );
    const blockCreation = duplicatesToBlock.length > 0;
    const duplicatesToDisplay = (
      blockCreation ? duplicatesToBlock : duplicatesToAllow
    )
      // this will merge results where the same DS triggered multiple rules
      .reduce((duplicates, current) => {
        const found = duplicates.find(
          (existing) => existing.ds.shipToNumber === current.ds.shipToNumber
        );
        if (found)
          found.rule.criteria = {
            ...found.rule.criteria,
            ...current.rule.criteria,
          };
        else duplicates.push(current);
        return duplicates;
      }, []);

    stateFunctions.setModalContent(() =>
      ModalSaveDuplicateContent({
        duplicateResults: duplicatesToDisplay,
        saveFlow,
        blockCreation,
        t,
      })
    );
    stateFunctions.setModalIsOpen(true);
    return;
  }

  saveFlow();
};

/**
 * cancel button click handler
 * @param {object} cleanVds - the unaltered vds state
 */
export const cancelClickHandler = ({ cleanVds }) => {
  const shipToCurrent = _.cloneDeep(cleanVds);
  stateFunctions.setVdsDetails(shipToCurrent);
  stateFunctions.setFormEditable(false);
};

/**
 * generic field change handler
 * @param {string} fieldName - the filed to be set
 * @param {any} value - the value to set
 * @returns {Promise<void>}
 */
export const genericOnChangeHandler = async ({ fieldName, value }) => {
  stateFunctions.setVdsDetails((prevState) => {
    const newState = _.cloneDeep(prevState);
    newState[fieldName] = value;
    return newState;
  });
};

/**
 * phone number field change handler
 * @param {number} index - the contact index
 * @param {string} fieldName - the filed to be set
 * @param {boolean} intl - the value is an international phone number
 * @param {any} value - the value to set
 * @returns {Promise<void>}
 */
export const phoneNumberOnChangeHandler = async ({
  index,
  fieldName,
  intl,
  value,
}) => {
  value = value.replace(/([A-Za-z`~!@#$%^&*()_+\-=[\]{}\\|;:'",<.>/?])/g, "");

  stateFunctions.setVdsDetails((prevState) => {
    const newState = _.cloneDeep(prevState);
    if (intl) {
      newState.contacts[index][`${fieldName}`] = value;
    } else {
      // newState.contacts[index][`${fieldName}`] = phoneFormatter({ value, previousValue: prevState.contacts[index][`${fieldName}`] });
      newState.contacts[index][`${fieldName}`] = value;
    }
    return newState;
  });
};

/**
 * contact field change handler
 * @param {number} index - the contact index
 * @param {string} fieldName - the filed to be set
 * @param {any} value - the value to set
 * @returns {Promise<void>}
 */
export const contactOnChangeHandler = async ({ index, fieldName, value }) => {
  stateFunctions.setVdsDetails((prevState) => {
    const newState = _.cloneDeep(prevState);
    newState.contacts[index][`${fieldName}`] = value;
    return newState;
  });
};

/**
 * clear form button click handler
 * @param {object} cleanVds - the unaltered vds state
 */
export const clearFormClickHandler = ({ cleanVds }) => {
  const shipToCurrent = _.cloneDeep(cleanVds);
  stateFunctions.setVdsDetails(shipToCurrent);
};
