import dayjs from "dayjs";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Context } from "../../../../context/Context";
import { useUserAccessFilterContext } from "../../../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { postCall } from "../../../utils/requests/apiCalls";
import apiUrls from "../../../utils/requests/apiUrls";

export const useUpsertWastage = () => {
  const currentLocale = useTranslation().i18n.language;
  const { t } = useTranslation();
  const { userAttributes } = useContext(Context);
  const { selectedFptText, selectedDivision } = useUserAccessFilterContext();

  const upsertWastageRequestBody = (
    fileData,
    fileName,
    testSwitch = false,
    categoryData
  ) => `
  {"d":{
    "Division":"0${selectedDivision}",
    "Fpt":"${selectedFptText}",
    "WasReptDate":"${
      fileData && fileData.length > 0
        ? dayjs(fileData[0].WasReptDate).format("YYYYMMDD")
        : ""
    }",
    "Filename":"${fileName}",
    "SubmitUser":"${userAttributes.username}",
    "TestSwitch":"${testSwitch ? "X" : ""}",
    "Lang":"${currentLocale === "fr-CA" ? "F" : "E"}",
    "Message_FPT_WASSet":{"results":[]},
    "Detail_FPT_WASSet":{
      "results":[
        ${fileData.map(
          (i) =>
            `{
          "Batch": "${i.Batch || ""}",
          "WastageDate":"${
            i.WastageDate ? dayjs(i.WastageDate).format("YYYYMMDD") : ""
          }",
          "Category":"${
            categoryData.filter(
              (category) => category.Description.toUpperCase() === i.Category
            )[0]?.Category || ""
          }",
          "Explanation":"${i.Explanation || ""}",
          "WasQty":"${i.WasQty || ""}",
          "DataRow":"${i.DataRow || ""}",
          "Message_FPT_WASSet":{"results":[]}
          }`
        )}
    ]}}}`;

  const upsertWastage = async (
    fileData,
    fileName,
    testSwitch,
    categoryData
  ) => {
    const result = await postCall(
      `${apiUrls.wastage}`,
      upsertWastageRequestBody(fileData, fileName, testSwitch, categoryData)
    );
    const res = await result.json();
    console.log(
      `[Wastage] ${testSwitch ? "Soft " : ""} POST SAP Response`,
      res
    );
    const Items = res.Detail_FPT_WASSet.results.map((item) => {
      return {
        StatusFlag:
          item.StatusFlag === "OK"
            ? item.StatusFlag
            : item.Message_FPT_WASSet.results
                .map((result) => result.MsgText)
                .join(". "),
        ErrorFields:
          item.StatusFlag === "OK"
            ? []
            : item.Message_FPT_WASSet.results
                .map((result) => result.MsgFieldName)
                .join(". "),
        DataRow: item.DataRow,
        WasReptDate: item.WasReptDate
          ? dayjs(item.WasReptDate).format("ll")
          : currentLocale === "fr-CA"
          ? "ND"
          : "N/A",
        WastageDate: item.WastageDate
          ? dayjs(item.WastageDate).format("ll")
          : currentLocale === "fr-CA"
          ? "ND"
          : "N/A",
        MaterialNumber: item.Material,
        MaterialGroup: item.MaterialGroup,
        MaterialName: item.MaterialName,
        Category: item.CategoryDesc,
        ExpiryDate: item.ExpiryDate
          ? dayjs(item.ExpiryDate).format("ll")
          : currentLocale === "fr-CA"
          ? "ND"
          : "N/A",
        Explanation: item.ExplanationDesc,
        WasQty: t("intlNumber", { val: item.WasQty.replace(/[\,]+/g, ".") }),
        Batch: item.Batch,
        Uom: item.Uom,
        ExpCode: item.Explanation,
        CatCode: item.Category,
      };
    });

    return {
      StatusFlag: res?.StatusFlag ? res.StatusFlag : "",
      ErrorMessages: res?.Message_FPT_WASSet?.results
        ? res.Message_FPT_WASSet.results.map((item) => item.MsgText)
        : [],
      Items,
    };
  };

  return { upsertWastage };
};
