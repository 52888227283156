import dayjs from "dayjs";

export const VACCINE = "VACCINE";
export const THERAPEUTIC = "THERAPEUTIC";
export const PROPHYLACTIC = "PROPHYLACTIC";

export const PROVINCE_LIST = [
  {
    group: VACCINE,
    code: "AB",
    en: "Alberta",
    fr: "Alberta",
  },
  {
    group: VACCINE,
    code: "AB_FPT",
    en: "Alberta",
    fr: "Alberta",
  },
  {
    group: VACCINE,
    code: "BC",
    en: "British Columbia",
    fr: "Colombie-Britannique",
  },
  {
    group: VACCINE,
    code: "CAF",
    en: "Canadian Armed Forces",
    fr: "Forces Armées Canadiennes",
  },
  {
    group: VACCINE,
    code: "CSC",
    en: "Correctional Service Canada",
    fr: "Service Correctionnel Canada",
  },
  {
    group: VACCINE,
    code: "PHAC",
    en: "Public Health Agency of Canada",
    fr: "Agence de la santé publique du Canada",
  },
  {
    group: VACCINE,
    code: "GAC",
    en: "Global Affairs Canada",
    fr: "Affaires mondiales Canada",
  },
  {
    group: VACCINE,
    code: "BC_FPT",
    en: "British Columbia",
    fr: "Colombie-Britannique",
  },
  {
    group: VACCINE,
    code: "MB",
    en: "Manitoba",
    fr: "Manitoba",
  },
  {
    group: VACCINE,
    code: "NB",
    en: "New Brunswick",
    fr: "Nouveau-Brunswick",
  },
  {
    group: VACCINE,
    code: "NL",
    en: "Newfoundland and Labrador",
    fr: "Terre-Neuve-et-Labrador",
  },
  {
    group: VACCINE,
    code: "NT",
    en: "Northwest Territories",
    fr: "Territoires du Nord-Ouest",
  },
  {
    group: VACCINE,
    code: "NS",
    en: "Nova Scotia",
    fr: "Nouvelle-Écosse",
  },
  {
    group: VACCINE,
    code: "NU",
    en: "Nunavut",
    fr: "Nunavut",
  },
  {
    group: VACCINE,
    code: "ON",
    en: "Ontario",
    fr: "Ontario",
  },
  {
    group: VACCINE,
    code: "ON_FPT",
    en: "Ontario",
    fr: "Ontario",
  },
  {
    group: VACCINE,
    code: "PE",
    en: "Prince Edward Island",
    fr: "Île-du-Prince-Édouard",
  },
  {
    group: VACCINE,
    code: "QC",
    en: "Quebec",
    fr: "Québec",
  },
  {
    group: VACCINE,
    code: "QC_FPT",
    en: "Quebec",
    fr: "Québec",
  },
  {
    group: VACCINE,
    code: "SK",
    en: "Saskatchewan",
    fr: "Saskatchewan",
  },
  {
    group: VACCINE,
    code: "YT",
    en: "Yukon",
    fr: "Yukon",
  },
  {
    group: THERAPEUTIC,
    code: "TAB",
    en: "Therapeutics Alberta",
    fr: "Thérapeutiques Alberta",
  },
  {
    group: THERAPEUTIC,
    code: "TBC",
    en: "Therapeutics British Columbia",
    fr: "Thérapeutiques Colombie-Britannique",
  },
  {
    group: THERAPEUTIC,
    code: "TMB",
    en: "Therapeutics Manitoba",
    fr: "Thérapeutiques Manitoba",
  },
  {
    group: THERAPEUTIC,
    code: "TNB",
    en: "Therapeutics New Brunswick",
    fr: "Thérapeutiques Nouveau-Brunswick",
  },
  {
    group: THERAPEUTIC,
    code: "TNL",
    en: "Therapeutics Newfoundland",
    fr: "Thérapeutiques Terre-Neuve",
  },
  {
    group: THERAPEUTIC,
    code: "TNT",
    en: "Therapeutics Northwest Territories",
    fr: "Thérapeutiques Territoires du Nord-Ouest",
  },
  {
    group: THERAPEUTIC,
    code: "TNS",
    en: "Therapeutics Nova Scotia",
    fr: "Thérapeutiques Nouvelle-Écosse",
  },
  {
    group: THERAPEUTIC,
    code: "TNU",
    en: "Therapeutics Nunavut",
    fr: "Thérapeutiques Nunavut",
  },
  {
    group: THERAPEUTIC,
    code: "TON",
    en: "Therapeutics Ontario",
    fr: "Thérapeutiques Ontario",
  },
  {
    group: THERAPEUTIC,
    code: "TPE",
    en: "Therapeutics PEI",
    fr: "Thérapeutiques Î.-P.-É.",
  },
  {
    group: THERAPEUTIC,
    code: "TQC",
    en: "Therapeutics Quebec",
    fr: "Thérapeutiques Québec",
  },
  {
    group: THERAPEUTIC,
    code: "TSK",
    en: "Therapeutics Saskatchewan",
    fr: "Thérapeutiques Saskatchewan",
  },
  {
    group: THERAPEUTIC,
    code: "TYT",
    en: "Therapeutics Yukon",
    fr: "Thérapeutiques Yukon",
  },
  {
    group: THERAPEUTIC,
    code: "TPHAC",
    en: "Therapeutics PHAC",
    fr: "Thérapeutiques ASPC",
  },
  {
    group: THERAPEUTIC,
    code: "TISC",
    en: "Therapeutics - Indigenous Services Canada",
    fr: "Thérapeutiques - Services aux Autochtones Canada",
  },
  {
    group: THERAPEUTIC,
    code: "TDND",
    en: "Therapeutics - Department of National Defense",
    fr: "Thérapeutiques - Ministère de la Défense nationale",
  },
  {
    group: THERAPEUTIC,
    code: "TCSC",
    en: "Therapeutics - Correctional Services Canada",
    fr: "Thérapeutiques  - Service correctionnel Canada",
  },
  {
    group: PROPHYLACTIC,
    code: "PAB",
    en: "Prophylactics Alberta",
    fr: "Prophylactique Alberta",
  },
  {
    group: PROPHYLACTIC,
    code: "PBC",
    en: "Prophylactics British Columbia",
    fr: "Prophylactique Colombie-Britannique",
  },
  {
    group: PROPHYLACTIC,
    code: "PMB",
    en: "Prophylactics Manitoba",
    fr: "Prophylactique Manitoba",
  },
  {
    group: PROPHYLACTIC,
    code: "PNB",
    en: "Prophylactics New Brunswick",
    fr: "Prophylactique Nouveau-Brunswick",
  },
  {
    group: PROPHYLACTIC,
    code: "PNL",
    en: "Prophylactics Newfoundland",
    fr: "Prophylactique Terre-Neuve",
  },
  {
    group: PROPHYLACTIC,
    code: "PNT",
    en: "Prophylactics Northwest Territories",
    fr: "Prophylactique Territoires du Nord-Ouest",
  },
  {
    group: PROPHYLACTIC,
    code: "PNS",
    en: "Prophylactics Nova Scotia",
    fr: "Prophylactique Nouvelle-Écosse",
  },
  {
    group: PROPHYLACTIC,
    code: "PNU",
    en: "Prophylactics Nunavut",
    fr: "Prophylactique Nunavut",
  },
  {
    group: PROPHYLACTIC,
    code: "PON",
    en: "Prophylactics Ontario",
    fr: "Prophylactique Ontario",
  },
  {
    group: PROPHYLACTIC,
    code: "PPE",
    en: "Prophylactics PEI",
    fr: "Prophylactique Î.-P.-É.",
  },
  {
    group: PROPHYLACTIC,
    code: "PQC",
    en: "Prophylactics Quebec",
    fr: "Prophylactique Québec",
  },
  {
    group: PROPHYLACTIC,
    code: "PSK",
    en: "Prophylactics Saskatchewan",
    fr: "Prophylactique Saskatchewan",
  },
  {
    group: PROPHYLACTIC,
    code: "PYT",
    en: "Prophylactics Yukon",
    fr: "Prophylactique Yukon",
  },
  {
    group: PROPHYLACTIC,
    code: "PPHAC",
    en: "Prophylactics PHAC",
    fr: "Prophylactique ASPC",
  },
  {
    group: PROPHYLACTIC,
    code: "PDND",
    en: "Prophylactics – Department of National Defense",
    fr: "Prophylactique - Ministère de la Défense nationale",
  },
  {
    group: PROPHYLACTIC,
    code: "PCSC",
    en: "Prophylactics – Correctional Services Canada",
    fr: "Prophylactique - Service correctionnel Canada",
  },
];

const strings = {
  title: {
    en: "VaccineConnect",
    fr: "ConnexionVaccin",
  },
  langToggle: {
    en: {
      desktop: "Français",
      mobile: "FR",
    },
    fr: {
      desktop: "English",
      mobile: "EN",
    },
  },
  signout: {
    en: "Sign out",
    fr: "Se déconnecter",
  },
  phacLink: {
    en: "Government of Canada logo",
    fr: "Logo du gouvernement du Canada",
  },
  footer: {
    copyright: {
      en: `© ${dayjs().year()} VaccineConnect`,
      fr: `© ${dayjs().year()} ConnexionVaccin`,
    },
    about: {
      en: "About Canada.ca",
      fr: "À propos de Canada.ca",
    },
    terms: {
      en: "Terms and conditions",
      fr: "Avis",
    },
    privacy: {
      en: "Privacy",
      fr: "Confidentialité",
    },
    logoAlt: {
      en: "Symbol of the Government of Canada",
      fr: "Symbole du gouvernement du Canada",
    },
    links: {
      privacy: {
        en: "https://www.canada.ca/en/transparency/privacy.html",
        fr: "https://www.canada.ca/fr/transparence/confidentialite.html",
      },
      terms: {
        en: "https://www.canada.ca/en/transparency/terms.html",
        fr: "https://www.canada.ca/fr/transparence/avis.html",
      },
      about: {
        en: "https://www.canada.ca/en/government/about.html",
        fr: "https://www.canada.ca/fr/gouvernement/a-propos.html",
      },
    },
  },
  headerTitle: {
    en: "VaccineConnect",
    fr: "ConnexionVaccin",
  },
  home: {
    allocationPage: {
      title: {
        en: "Request redistribution of your allocation",
        fr: "Présenter une demande de changement d’attribution",
      },
      subtitle: {
        en: "You can request adjustments to your product allocations by shifting allocations between delivery weeks, or drawing from / adding to your reserve.",
        fr: "Vous pouvez demander des ajustements à votre attribution de produit en changeant la semaine de livraison ou en ajoutant à votre réserve.",
      },
      subtitleBullet1: {
        en: "Allocations cannot be increased overall.",
        fr: "Les attributions ne peuvent pas être augmentées globalement.",
      },
      subtitleBullet2: {
        en: "Front-loading allocations of a vaccine type to an earlier week is not permitted. You may only make requests to move doses to subsequent weeks or to your reserve.",
        fr: "Il est interdit de transférer des attributions de types de vaccins à une semaine antérieure. Vous pouvez seulement demander de transférer des doses à des semaines ultérieures ou à votre réserve.",
      },
      subtitleBullet3: {
        en: "Front-loading requests to earlier weeks for diluents is permitted.",
        fr: "Les demandes de transfert de diluant à des semaines antérieures sont permises.",
      },
      subtitleBullet4: {
        en: "Allocation swaps between FPTs are not permitted within the portal, please contact the NOC if you come to an agreement with another FPT.",
        fr: "Les échanges d’attributions entre les compétences fédérales, provinciales et territoriales ne sont pas permis dans le portail; veuillez communiquer avec le CNO si vous concluez une entente avec une autre compétence fédérale, provinciale ou territoriale.",
      },
      subtitleBullet5: {
        en: "Pfizer allocation redistribution requests will not be approved by the NOC.",
        fr: "Les demandes de redistribution des attributions de Pfizer ne seront pas approuvées par le CNO.",
      },
      undoChanges: {
        en: "Undo changes",
        fr: "Annuler les modifications",
      },
      toUse: {
        en: "To use this form:",
        fr: "Pour utiliser ce formulaire : ",
      },
      bullet1: {
        en: "First enter a negative number to reduce your allocation(s) to a particular week.  This reduction will be automatically allocated to your reserve and can be seen in the Change request field towards the bottom of the page.",
        fr: "Entrez d’abord un nombre négatif afin de réduire le nombre de vaccins attribués pour une semaine donnée. Ces vaccins seront automatiquement réattribués à votre réserve et vous les verrez dans le champ « Demande de changement » vers le bas de la page.",
      },
      bullet2: {
        en: "You may then redistribute those doses in your reserve to a different week or leave them in your rolling reserve for future use.",
        fr: "Vous pouvez ensuite redistribuer ces doses de votre réserve à une semaine différente ou les laisser dans votre réserve mobile pour une utilisation future. ",
      },
      bullet3: {
        en: "Then submit your request for approval: there is no guarantee the NOC can facilitate any request.",
        fr: "Soumettez ensuite votre demande d’approbation : il n’y a aucune garantie que le CNO pourra accommoder la demande.",
      },
      title2: {
        en: "Allocations change request",
        fr: "Demande de modification des attributions de vaccins",
      },
      subtitle2: {
        en: "The weeks seen here have not passed the amendment request deadline. All weeks seen have allocations assigned and approved by the NOC.",
        fr: "Les semaines indiquées ici n’ont pas dépassé la date limite de demande de modification.",
      },
      reserveTitle: {
        en: "Reserve allocation",
        fr: "Attribution des réserves",
      },
      reserveSubtitle: {
        en: "The Reserve change request field shows the number of doses you’ve removed from your current allocation schedule. You can shift those to other weeks by inserting positive numbers up to this figure’s value in the Change request field above or you can add this to your rolling reserve. All changes need to be approved after you submit them. The Approved net reserve field shows the total sum of doses approved by the NOC.",
        fr: "Le champ « Changement à la réserve » indique le nombre de doses que vous avez retirées de votre calendrier d’attribution actuel. Vous pouvez déplacer ces doses vers d’autres semaines en insérant des chiffres positifs jusqu’à la valeur de ce chiffre dans le champ « Demande de changement » ci-dessus ou vous pouvez ajouter cette valeur à votre réserve mobile. Toutes les modifications doivent être approuvées après leur soumission. Le champ « Réserve nette approuvée » indique la somme totale des doses approuvées par le CNO.",
      },
      reserveError: {
        en: "You cannot submit a change request for an amount greater than your allocation. Please ensure your reductions are equal to or more than your additions to other weeks in order to submit your change request.",
        fr: "Vous ne pouvez pas soumettre une demande de modification pour un nombre supérieur au nombre de doses qui vous ont été attribuées. Veuillez vous assurer que vos réductions sont égales ou supérieures à vos ajouts à d’autres semaines afin de soumettre votre demande de changement.",
      },
      changeRequest: {
        en: "Reserve change request",
        fr: "Changement à la réserve",
      },
      changeApprove: {
        en: "Approved change",
        fr: "Changement approuvé",
      },
      changeApproveReserved: {
        en: "Approved net reserve",
        fr: "Réserve nette approuvée",
      },
      submit: {
        en: "Submit change request",
        fr: "Soumettre la demande de changement",
      },
      net: {
        en: "Net allocation",
        fr: "Attribution nette",
      },
      changeRequest2: {
        en: "Change request",
        fr: "Demande de changement",
      },
      currentDoses: {
        en: "Current doses",
        fr: "Nombre de doses actuel",
      },
      allocations: {
        en: "Allocations",
        fr: "Attributions",
      },
      modal: {
        confirm: {
          en: "Confirm change request",
          fr: "Confirmer la demande de changement",
        },
        failureGeneric: {
          en: "Allocation redistribition request failed. This is likely due to a temporary technical issue with our system. Please try again later, hopefully the issue will be fixed shortly.",
          fr: "Échec de la demande de redistribution de l’attribution. Cela est probablement dû à un problème technique temporaire dans notre système. Veuillez réessayer plus tard, le problème devrait être réglé sous peu.",
        },
        success: {
          en: "Your allocation redistribution request(s) has been received by the National Operations Centre",
          fr: "Votre ou vos demandes de redistribution de l’attribution ont été reçues par le Centre national des opérations.",
        },
        allocationChangeError: {
          en: "Allocations for the selected week have changed since the page loaded. Please check your order details and submit again.",
          fr: "Les attributions pour la semaine sélectionnée ont été modifiées depuis le chargement de la page. Veuillez vérifier les renseignements sur votre commande et la soumettre de nouveau.",
        },
      },
    },
    tabs: {
      home: {
        tab: {
          en: "Home",
          fr: "Accueil",
        },
        path: {
          en: "home",
          fr: "accueil",
        },
        description: {
          en: "The Home page highlights general information about this portal.",
          fr: "La page d’accueil fournit des renseignements généraux au sujet du portail.",
        },
      },
      order: {
        tab: {
          en: "Product Request",
          fr: "Demande de produit",
        },
        path: {
          en: "product-request",
          fr: "demande-de-produit",
        },
        description: {
          en: "The Product Request section is where you can create your requests for vaccine, therapeutic and prophylactic products.",
          fr: "La section « Demande de produit » est l’endroit où vous pouvez créer vos demandes de vaccins, de produits thérapeutiques et prophylactiques.",
        },
      },
      allocations: {
        tab: {
          en: "Allocation Management",
          fr: "Gestion des attributions",
        },
        path: {
          en: "allocation-management",
          fr: "gestion-des-attributions",
        },
        description: {
          en: "Allocations Management section highlights your current allocation and allows you to request changes.",
          fr: "La section « Gestion des attributions » indique votre attribution actuelle et vous permet de demander des modifications.",
        },
      },
      pending: {
        tab: {
          en: "Order Management",
          fr: "Gestion des commandes",
        },
        path: {
          en: "order-management",
          fr: "gestion-des-commandes",
        },
        description: {
          en: "Order Management shows your active product requests and orders.",
          fr: "La section « Gestion des commandes » indique vos demandes et vos commandes.",
        },
      },
      history: {
        tab: {
          en: "Order History",
          fr: "Historique des commandes",
        },
        path: {
          en: "order-history",
          fr: "historique-des-commandes",
        },
        description: {
          en: "The Order History section allows you to review your past orders.",
          fr: "La section « Historique des commandes » vous permet de consulter vos commandes passées.",
        },
      },
      vds: {
        tab: {
          en: "DS Management",
          fr: "Gestion SL",
        },
        path: {
          en: "ds-management",
          fr: "gestion-sl",
        },
        description: {
          en: "DS Management allows you to view, edit, and add new Delivery Sites (DS).",
          fr: "La section « Gestion SL » vous permet de visualiser, de modifier, et d’ajouter de nouveaux sites de livraison (SL).",
        },
      },
      wastageInventory: {
        tab: {
          en: "Inventory and Wastage Management",
          fr: "Gestion des inventaires et du gaspillage",
        },
        path: {
          en: "wastage-inventory",
          fr: "gestion-des-inventaires-et-du-gaspillage",
        },
        description: {
          en: "Inventory and Wastage Management allows you to report, manage and view your inventory and wastage records.",
          fr: "Gestion des inventaires et du gaspillage vous permet de soumettre, gérer et visualiser les données d'inventaires et du gaspillage.",
        },
      },
      accessProvision: {
        tab: {
          en: "Access Provisioning",
          fr: "Gestion des accès",
        },
        path: {
          en: "access-provision",
          fr: "gestion-des-accès",
        },
        description: {
          en: "Manage application access and permissions.",
          fr: "Gérer l’accès à l’application et des autorisations.",
        },
      },
    },
    menu: {
      en: "Menu",
      fr: "Menu",
    },
    currentOrders: {
      LocalTime: {
        en: "Local time",
        fr: "Heure locale",
      },
      EasternTime: {
        en: "Eastern time",
        fr: "Heure de l’Est",
      },
      title: {
        en: "Order management",
        fr: "La gestion des commandes",
      },
      sort: {
        asending: {
          en: "Ascending",
          fr: "Croissant",
        },
        descending: {
          en: "Descending",
          fr: "Décroissant",
        },
      },
      week: {
        en: "Week",
        fr: "Semaine",
      },
      selectWeek: {
        en: "Select week",
        fr: "Choisissez la semaine",
      },
      listViewSubTitle: {
        en: "Here are your current vaccine orders and requests. Select one to view details, change, cancel or reorder if desired.",
        fr: "Voici vos demandes et commandes de vaccins en cours. Sélectionnez-en une pour en afficher les détails, la modifier, l’annuler ou la soumettre de nouveau si vous le souhaitez.",
      },
      subTitle: {
        en: "Select vaccine type to get started.",
        fr: "Choisissez un type de vaccin pour commencer.",
      },
      selectVaccine: {
        en: "Select vaccine type first",
        fr: "Sélectionnez d’abord le type de vaccin.",
      },
      noAllocations: {
        en: "There are no allocations for this vaccine type",
        fr: "Il n’y a aucune attribution pour ce type de vaccin.",
      },
      noOrders: {
        en: "No orders available",
        fr: "Aucune commande disponible",
      },
      orderViewSubTitle1: {
        en: "This is a current order. You can make modifications to it.",
        fr: "Cette commande est en cours de traitement. Vous pouvez y apporter des modifications.",
      },
      alertDosageAmount: {
        en: "",
        fr: "",
      },
    },
    history: {
      title: {
        en: "Historical order information",
        fr: "Historique des commandes",
      },
      listViewSubTitle: {
        en: "Here are your past vaccine order details. Select one to view details or reorder.",
        fr: "Voici les renseignements de vos commandes de vaccins précédentes. Sélectionnez-en une pour en afficher les détails ou la soumettre de nouveau.",
      },
      orderViewSubTitle1: {
        en: "You are viewing a past order. If desired, you can reorder it by clicking on 'Recreate order' below.",
        fr: "Vous consultez une commande antérieure. Si vous le souhaitez, vous pouvez la renouveler en cliquant sur le bouton 'Dupliquer la commande' ci-dessous.",
      },
      orderViewSubTitle2: {
        en: "However, if you need to change any amounts, destinations or even vaccine type, you must initiate a new order.",
        fr: "Toutefois, si vous devez modifier la quantité, la destination ou le type de vaccin, vous devez créer une nouvelle commande.",
      },
    },
    vds: {
      form: {
        postalCodeFormatMask: "(A1A 1A1)",
      },
    },
    orderTable: {
      recreateOrder: {
        en: "Recreate Order",
        fr: "Dupliquer la commande",
      },
      bulkRecreateOrders: {
        en: "Bulk recreate orders",
        fr: "Recréer des commandes en bloc",
      },
      order: {
        en: "Order #",
        fr: "Numéro de commande",
      },
      tracking: {
        en: "Tracking number",
        fr: "Numéro de suivi",
      },
      vaccine: {
        en: "Vaccine",
        fr: "Vaccin",
      },
      total: {
        en: "Total doses",
        fr: "Nombre total de doses",
      },
      date: {
        en: "Requested delivery date",
        fr: "Date de livraison demandée",
      },

      LotNo: {
        en: "Lot #",
        fr: "Lot #",
      },
      expiryDate: {
        en: "Expiry date",
        fr: "Date d’expiration",
      },
      actualDeliveryDate: {
        en: "Actual delivery date",
        fr: "Date de livraison",
      },

      status: {
        en: "Status",
        fr: "État",
      },
      deliveryStatus: {
        en: "Delivery status",
        fr: "État de livraison",
      },
      view: {
        en: "View",
        fr: "Visualiser",
      },
      downloadCsv: {
        en: "Export table to CSV",
        fr: "Exporter le tableau en format CSV",
      },
      sortDropdownLabel: {
        en: "Sort by",
        fr: "Trier par",
      },
      ascending: {
        en: "Ascending",
        fr: "Croissant",
      },
      descending: {
        en: "Descending",
        fr: "Décroissant",
      },
      csvFilePrefix: {
        en: "portal_orders_",
        fr: "Portail_commandes_",
      },
    },
    dataTable: {
      downloadCsv: {
        en: "Export table to CSV",
        fr: "Exporter le tableau en format CSV",
      },
      sortDropdownLabel: {
        en: "Sort by",
        fr: "Trier par",
      },
      ascending: {
        en: "Ascending",
        fr: "Croissant",
      },
      descending: {
        en: "Descending",
        fr: "Décroissant",
      },
    },
    hero: {
      welcome: {
        en: "Welcome back",
        fr: "Bon retour",
      },
    },
    placeOrder: {
      errors: {
        allocationChanged: {
          en: "Remaining available allocations have changed since you started this order – please revise and re-submit if needed.",
          fr: "Les attributions disponibles restantes ont été modifiées depuis que vous avez commencé cette commande. Veuillez réviser et soumettre à nouveau si nécessaire.",
        },
        allocationTableLocked: {
          en: "Vaccine request failed. This is due to allocation tables being locked by another user. Please try again later or contact the NOC for immediate assistance.",
          fr: " La demande de vaccin a échoué. Cela est dû au verrouillage de la table des attributions par un autre utilisateur. Veuillez réessayer plus tard ou contacter le CNO pour obtenir une assistance immédiate.",
        },
      },
      heading: {
        en: "Place a request for Vaccines",
        fr: "Soumettre une demande de vaccins",
      },
      subheading: {
        en: "Please complete the fields below to create a new order for vaccines.",
        fr: "Veuillez remplir les champs ci-dessous pour créer une nouvelle commande de vaccins.",
      },
      orderForm: {
        autoDiluentRounding: {
          en: "Please note that partial diluent will be rounded up to the nearest integer.",
          fr: "Veuillez noter que toute quantité partielle de diluant sera arrondie à l’entier supérieur le plus proche.",
        },
        noOptions: {
          en: "No options",
          fr: "Aucune option",
        },
        requiredLabel: {
          en: " (required)",
          fr: " (requis)",
        },
        vaccineFieldset: {
          heading: {
            en: "Product request",
            fr: "Demande de produit",
          },
          headingCap: {
            en: "Product Request",
            fr: "Demande de produit",
          },
          dateField: {
            title: {
              en: "Requested delivery week",
              fr: "Semaine de livraison demandée",
            },
            placeholder: {
              en: "Enter your requested date of delivery",
              fr: "Entrez la date à laquelle vous souhaitez recevoir les vaccins.",
            },
            error: {
              en: "Please select a valid date in the future for your delivery.",
              fr: "Veuillez sélectionner une date ultérieure valide pour la livraison.",
            },
          },
          typeField: {
            title: {
              en: "Choose vaccine type",
              fr: "Choisissez le type de vaccins",
            },
            placeholder: {
              en: "Vaccine name",
              fr: "Nom du vaccin",
            },
            searchHeading: {
              en: "Search for vaccine",
              fr: "Rechercher des vaccins",
            },
            error: {
              en: "Please select your vaccine type.",
              fr: "Veuillez sélectionner le type de vaccin.",
            },
          },
          referenceField: {
            title: {
              en: "FPT reference number",
              fr: "Notes de groupe",
            },
            placeholder: {
              en: "Enter your reference #",
              fr: "Entrez votre numéro de référence",
            },
          },
          notesField: {
            title: {
              en: "Vaccine group notes",
              fr: "Notes sur les groupes de vaccins",
            },
            placeholder: {
              en: "Information entered here is for your internal record keeping only. This area is not to be used to communicate with the NOC.",
              fr: "L’information entrée ici est destinée uniquement à la tenue de dossiers internes et ne sera pas visible par le CNO.",
            },
          },
        },
        destinationFieldset: {
          heading: {
            en: "Vaccine request details",
            fr: "Détails de la demande de vaccins",
          },
          diluentMsg: {
            en: "If product requires diluent, please include as part of order.",
            fr: "Si le produit nécessite un diluant, veuillez l’indiquer dans la commande.",
          },
          provinceField: {
            title: {
              en: "Federal/Province/Territory",
              fr: "Province ou territoire",
            },
            provinceList: PROVINCE_LIST,
            error: {
              en: "Please select your province.",
              fr: "Veuillez sélectionner la province.",
            },
          },
          deliveryField: {
            title: {
              en: "Delivery Site (DS)",
              fr: "Site de livraison (SL)",
            },
            placeholder: {
              en: "DS location",
              fr: "Lieu de SL",
            },
            searchHeading: {
              en: "Search for location",
              fr: "Rechercher un emplacement",
            },
            error: {
              en: "Please select a delivery destination for each allocation.",
              fr: "Veuillez sélectionner une destination de livraison pour chaque ensemble de vaccins attribués.",
            },
          },
          vdsTitle: {
            en: "DS details",
            fr: "Détails du SL",
          },
          quantityField: {
            title: {
              en: "Quantity of payloads",
              fr: "Quantité de charges",
            },
            placeholder: {
              en: "Number of payloads",
              fr: "Nombre de charges",
            },
            error: {
              en: "Please specify the number of payloads",
              fr: "Veuillez préciser le nombre de charges",
            },
            error2: {
              en: "for each allocation.",
              fr: "pour chaque allocation.",
            },
            therapeuticError: {
              en: "Please specify the number of boxes",
              fr: "Veuillez préciser le nombre de charges",
            },
            therapeuticsPlaceholder: {
              en: "Number of boxes",
              fr: "Nombre de boîtes",
            },
            title2: {
              en: "Doses per payload",
              fr: "Doses par boîte",
            },
            title2Vial: {
              en: "Vials per payload",
              fr: "Fioles par boîte",
            },
            title3: {
              en: "Total doses requested",
              fr: "Nombre total de doses demandées",
            },
            title3Vial: {
              en: "Total vials requested",
              fr: "Nombre total de fioles demandées",
            },
            titleTherapeutics: {
              en: "Packs (treatment courses) per box",
              fr: "Paquets (traitements) par boîte",
            },
            qtyTherapeutics: {
              en: "Box quantity",
              fr: "Quantité de boîtes",
            },
            totalTherapeutics: {
              en: "Total packs (treatment courses) requested",
              fr: "Nombre total de paquets (traitements) demandés",
            },
            totalHistoryTherapeutics: {
              en: "Total packs (treatment courses)",
              fr: "Nombre total de paquets (traitements)",
            },
          },
          quantityConversionField: {
            message: {
              en: "There are",
              fr: "Il y a",
            },
            message2: {
              en: " doses per payload",
              fr: " doses par boîte",
            },
          },
          conversionMessageField: {
            message: {
              en: "You are requesting",
              fr: "Vous demandez",
            },
            message2: {
              en: "doses",
              fr: "doses",
            },
            therapeutics: {
              en: "packs (treatment courses)",
              // fr: 'boîtes paquets (traitements)'
              fr: "paquets (traitements)",
            },
            therapeuticsCapitalized: {
              en: "Packs (treatment courses)",
              fr: "Boîtes paquets (traitements)",
            },
          },
          dateField: {
            title: {
              en: "Requested delivery date (MM/DD/YYYY)",
              fr: "Date de livraison demandée",
            },
            placeholder: {
              en: "Enter your requested date of delivery",
              fr: "Entrez la date à laquelle vous souhaitez recevoir les vaccins.",
            },
            error: {
              en: "Please select a valid date in the future for your delivery.",
              fr: "Veuillez sélectionner une date ultérieure valide pour la livraison.",
            },
            periodHeaderTooltip: {
              en: "If you change this date, all vaccine types will be cleared from the form based on ordering weeks. This is due to your allocation assignment based on vaccine type and delivery week.",
              fr: "Si vous modifiez cette date, tous les types de vaccins seront supprimés du formulaire en fonction des semaines de commande. Cela est dû à votre attribution en fonction du type de vaccin et de la semaine de livraison.",
            },
            tooltip: {
              en: "You can specify a delivery date for this allocation within the delivery week in the header details of this vaccine request.",
              fr: "Vous pouvez choisir une date de livraison pour cette attribution au cours de la semaine de livraison dans l’en-tête de la demande de vaccins.",
            },
          },
          allocationNote: {
            title: {
              en: "Vaccine request notes",
              fr: "Notes de demande de vaccins",
            },
            placeholder: {
              en: "Information entered here is for your internal record keeping only. This area is not to be used to communicate with the NOC.",
              fr: "Les renseignements entrés ici sont uniquement destinés à vos dossiers. Vous ne devez pas utiliser cette zone pour communiquer avec le Centre national des opérations.",
            },
          },
          removeAllocation: {
            en: "Remove request",
            fr: "Supprimer la demande",
          },
          addAllocation: {
            en: "Add another vaccine request",
            fr: "Ajouter une autre demande de vaccins",
          },
        },
      },
      submit: {
        en: "Place request",
        fr: "Soumettre une demande",
      },
      confirm: {
        en: "Confirm",
        fr: "Confirmer",
      },
      duplicate: {
        en: "Recreate order",
        fr: "Dupliquer la commande",
      },
      modifyOrder: {
        en: "Modification",
        fr: "la modification",
      },
      modifyOrderBtn: {
        en: "Modify",
        fr: "Modifier",
      },
      cancel: {
        en: "Clear form",
        fr: "Réinitialiser le formulaire",
      },
      cancelOrder: {
        en: "Cancel order",
        fr: "Annuler la commande ",
      },
      back: {
        en: "Back",
        fr: "Retourner",
      },
    },
    modal: {
      accessibility: {
        close: {
          en: "Close modal",
          fr: "Fermer modale",
        },
      },
      modifyOrderNotify: {
        en: "Please modify the associated diluent order if applicable.",
        fr: "Veuillez modifier la commande de diluant associée à cette commande de vaccins, le cas échéant.",
      },
      success: {
        heading: {
          en: "Your Vaccine request(s) has been received by the National Operations Centre",
          fr: "Votre ou vos demandes de vaccins ont été reçues par le Centre national des opérations.",
        },
        body: {
          en: "Request #",
          fr: "Demande no",
        },
        cta: {
          en: "Okay",
          fr: "OK",
        },
      },
      failure: {
        heading: {
          en: "There has been an error processing your request. This is likely due to a temporary technical issue with our system. Please try again later, hopefully the issue will be fixed shortly.",
          fr: "Une erreur est survenue durant le traitement de votre commande. Veuillez réessayer plus tard.",
        },
        body: {
          en: "",
          fr: "",
        },
        cta: {
          en: "Okay",
          fr: "OK",
        },
      },
    },
    form: {
      AutomaticAdditionOfDiluent: {
        en: "Automatic Addition of Diluent",
        fr: "Ajout automatique de diluant",
      },
    },
  },
  login: {
    component: {
      button: {
        en: "Sign in",
        fr: "Se connecter",
      },
    },
  },
  alerts: {
    allocationRemaining: {
      header: {
        en: "Vaccine allocation remaining for the selected week",
        // fr: "Répartition des vaccins restant(s) pour la semaine sélectionnée",
        fr: "Attribution des vaccins restant(s) pour la semaine sélectionnée",
      },
      explanation: {
        en: "This request exceeds the allotment for",
        fr: "Cette demande dépasse la limite de distribution actuelle pour",
      },
      explanation2: {
        en: " for this time. Please revise.",
        fr: ". Veuillez revoir la demande.",
      },
    },
    timeRemaining: {
      header: {
        en: "Amendment request deadline",
        fr: "Date limite pour demander une modification",
      },
      label: {
        en: "Modification of order:",
        fr: "Modification d’une commande",
      },
      makeChanges: {
        en: "before this order is locked",
        fr: "avant que cette commande ne soit verrouillée",
      },
      allotmentChanges: {
        en: "remaining to place orders against this allotment",
        fr: "restant(s) pour soumettre des demandes en lien avec cette distribution",
      },
      timeSuffix: {
        en: "remaining",
        fr: "restant(s)",
      },
    },
    deliveryStatus: {
      header: {
        en: "Order request deadline",
        fr: "Date limite pour commander",
      },
      label: {
        en: "Request submission:",
        fr: "Soumission d’une demande",
      },
      timeSuffix: {
        en: "remaining",
        fr: "restant(s)",
      },
      distributionDatePassed: {
        en: "Order request deadline has passed",
        fr: "La date limite pour commander est passée.",
      },
    },
    generalError: {
      label: {
        en: "Allocation data not found for this selection. Please contact the NOC for more information.",
        fr: "Aucune donnée d’attribution n’a été trouvée pour ce choix. Veuillez communiquer avec le Centre National des Opérations (CNO) pour en savoir plus.",
      },
    },
    nocWarning: {
      en: "Note: Changes during this period may delay delivery schedules and will require approval from the NOC.",
      fr: "Remarque : Les changements au cours de cette période peuvent retarder les livraisons et nécessiter l’approbation du CNO.",
    },
  },
  cognito: {
    en: {
      "Username *": "Username (Required)",
      "Password *": "Password (Required)",
      "Verification code": "Verification code (Required)",
    },
    fr: {
      "Sign in to your account": "Ouvrez une session de votre compte.",
      "Sign In": "Se connecter",
      "Sign in": "Se connecter",
      "Sign Up": "Se déconnecter",
      "Sign out": "Se déconnecter",
      "Username *": "Nom d’utilisateur (Requis)",
      Username: "Nom d’utilisateur (Requis)",
      "Password *": "Mot de passe (Requis)",
      Password: "Mot de passe (Requis)",
      "Enter your password": "Entrez le mot de passe.",
      "Enter your Username": "Entrez le nom d’utilisateur.",
      "Enter your username": "Entrez le nom d’utilisateur.",
      "No account?": "Vous n’avez pas de compte?",
      "Create account": "Créer un compte",
      "Create Account": "Créer un compte",
      "Have an account?": "Vous avez un compte?",
      "Have a code?": "Vous avez un code?",
      "Code *": "Code*",
      Confirm: "Confirmer",
      "Reset Password": "Réinitialiser le mot de passe",
      "Reset password": "Réinitialiser le mot de passe",
      "Reset your password": "Réinitialisez votre mot de passe.",
      "Back to Sign in": "Retourner à la page d’ouverture de session",
      "You will receive a verification code to reset your password":
        "Vous recevrez un code de vérification pour réinitialiser votre mot de passe.",
      "Verification code": "Code de vérification (Requis)",
      "Confirm SMS Code": "Confirmer le code SMS",
      Submit: "Soumettre",
      Verify: "Vérifier",
      "Verify Contact": "Vérifier les coordonnées",
      Skip: "Sauter",
      "Lost your code?'": "Vous avez perdu votre code?",
      Resend: "Envoyer de nouveau",
      "Forgot Password?": "Vous avez oublié votre mot de passe?",
      "Forgot your password?": "Vous avez oublié votre mot de passe?",
      "You will receive a verification code":
        "Vous recevrez un code de vérification.",
      Code: "Code",
      "Account recovery requires verified contact information":
        "Pour récupérer un compte, les coordonnées doivent avoir été vérifiées.",
      "User does not exist": "L’utilisateur n’existe pas.",
      "User already exists": "L’utilisateur existe déjà.",
      "Incorrect username or password":
        "Nom d’utilisateur ou mot de passe erroné",
      "Invalid password format": "Format de mot de passe invalide",
      "Invalid phone number format": "Format de numéro de téléphone invalide",
      "Username cannot be empty":
        "Le champ de nom d’utilisateur ne peut pas être vide.",
      "Custom auth lambda trigger is not configured for the user pool.":
        "Aucun déclencheur lambda personnalisé d’authentification n’est configuré pour le bassin d’utilisateurs.",
      "Incorrect username or password.":
        "Nom d’utilisateur ou mot de passe erroné",
      "Password attempts exceeded":
        "Nombre de tentatives de saisie du mot de passe excédé",
      "Forget your password? ": "Vous avez oublié votre mot de passe?",
      "No account? ": "Vous n’avez pas de compte?",
      "Have an account? ": "Vous avez un compte?",
      "Send Code": "Envoyer un code",
      "Back to Sign In": "Retourner à la page d’ouverture de session",
    },
  },
  datePicker: {
    calendarLabel: {
      en: "Calendar",
      fr: "Calendrier",
    },
    closeDatePicker: {
      en: "Close",
      fr: "Fermer",
    },
    jumpToPrevMonth: {
      en: "Move backward to switch to the previous month.",
      fr: "Revenir en arrière pour voir le mois précédent",
    },
    jumpToNextMonth: {
      en: "Move forward to switch to the next month.",
      fr: "Avancer pour voir le mois suivant",
    },
    moveFocusByOneDay: {
      en: "Move backward (left) and forward (right) by one day.",
      fr: "Revenir en arrière (gauche) et avancer (droite) d’une journée",
    },
    moveFocusByOneWeek: {
      en: "Move backward (up) and forward (down) by one week.",
      fr: "Revenir en arrière (haut) et avancer (bas) d’une semaine",
    },
    keyboardShortcuts: {
      en: "Keyboard Shortcuts",
      fr: "Revenir en arrière (haut) et avancer (bas) d’une semaine",
    },
    selectFocusedDate: {
      en: "Select the date in focus.",
      fr: "Sélectionner la date voulue",
    },
    moveFocusByOneMonth: {
      en: "Switch months.",
      fr: "Changer de mois",
    },
    showKeyboardShortcutsPanel: {
      en: "Open the keyboard shortcuts panel.",
      fr: "Ouvrir le panneau de raccourcis clavier ",
    },
    hideKeyboardShortcutsPanel: {
      en: "Close the shortcuts panel.",
      fr: "Fermer le panneau de raccourcis clavier",
    },
    enterKey: {
      en: "Enter key",
      fr: "Touche Entrée",
    },
    leftArrowRightArrow: {
      en: "Right and left arrow keys",
      fr: "Touches fléchées vers la droite et vers la gauche",
    },
    upArrowDownArrow: {
      en: "up and down arrow keys",
      fr: "Touches fléchées vers le haut et vers le bas",
    },
    pageUpPageDown: {
      en: "page up and page down keys",
      fr: "Touches de page vers le haut et de page vers le bas",
    },
    homeEnd: {
      en: "Home and end keys",
      fr: "Touches d’accueil et de fin",
    },
    escape: {
      en: "Escape key",
      fr: "Touche Échapper",
    },
    questionMark: {
      en: "Question mark",
      fr: "Point d’interrogation",
    },
    moveFocustoStartAndEndOfWeek: {
      en: "Go to the first or last day of a week.",
      fr: "Aller au premier ou au dernier jour d’une semaine",
    },
    keyboardForwardNavigationInstructions: {
      en: "Navigate forward to interact with the calendar and select a date. Press the question mark key to get the keyboard shortcuts for changing dates.",
      fr: "Avancer pour voir le calendrier et sélectionner une date. Cliquer sur le point d’interrogation pour connaître les raccourcis clavier permettant de changer les dates.",
    },
    keyboardBackwardNavigationInstructions: {
      en: "Navigate backward to interact with the calendar and select a date. Press the question mark key to get the keyboard shortcuts for changing dates.",
      fr: "Revenir en arrière pour voir le calendrier et sélectionner une date. Cliquer sur le point d’interrogation pour connaître les raccourcis clavier permettant de changer les dates.",
    },
  },
  statuses: {
    A: {
      en: "Approved",
      fr: "Approuvée",
    },
    B: {
      en: "Rejected",
      fr: "Refusée",
    },
    C: {
      en: "Cancelled",
      fr: "Annulée",
    },
    D: {
      en: "Pending approval",
      fr: "En attente d'approbation",
    },
    E: {
      en: "Processing",
      fr: "En traitement",
    },
    Processing: {
      en: "Processing",
      fr: "En traitement",
    },
    "In Transit": {
      en: "In transit",
      fr: "En transit",
    },
    Delivered: {
      en: "Delivered",
      fr: "Livré",
    },
    defaultRejectionReason: {
      en: "Your request has been declined: Please contact the National Operations Centre to learn more. ",
      fr: "Votre demande a été refusée : veuillez communiquer avec le centre national des opérations pour obtenir plus d’information. ",
    },
  },
  modals: {
    medicagoMessage: {
      en: "Only an integer quantity of payload can be selected for Medicago product. Please revise your order.",
      fr: "Seule une quantité entière de charge utile peut être sélectionnée pour le produit Medicago. Veuillez réviser votre commande.",
    },
  },
};

export default strings;
