/**
 * when array of arrays are passed into to <Autocomplete> options field they are joined by ",".
 * Wherever there is a need to join columns for DropdownFilter component we can invoke the joinArrayValues function
 */
export default function joinArrayValues(values) {
  if (Array.isArray(values)) {
    values = values.join(",");
  }
  return values;
}
