import { postCall } from "./apiCalls";
import apiUrls from "./apiUrls";

export const modifyAllocations = async (sku, tableData, province, user) => {
  const allocations = [];
  const trimmedUser = user.substring(0, 12);
  Object.keys(tableData).forEach((key) => {
    const requested = tableData[key].requested;
    const totalRequestedAlready =
      tableData[key].allocationData.approvedAndPending;
    const FPTQty = totalRequestedAlready + requested;
    if (FPTQty !== totalRequestedAlready)
      allocations.push({
        PeriodNumber: tableData[key].PeriodNumber,
        Modifier: String(tableData[key].Modifier),
        SKUNumber: String(sku.SKUNumber),
        SoldTo: province,
        FPTQty,
      });
  });

  const obj = {
    d: {
      SoldTo: province,
      UserId: trimmedUser,
      AllocationUpdateNav: {
        results: allocations,
      },
    },
  };

  console.log(`[modifyAllocations] posting to sap: `, obj);

  const response = await postCall(
    apiUrls.modifyAllocationSet,
    JSON.stringify(obj)
  );
  console.log(`[modifyAllocations] sap response: `, response);
  try {
    return await response.json();
  } catch (e) {
    console.error("[modifyAllocations] failed to parse result: ", e);
  }
};

/**
 * set a VDS to active/inactive
 * @param {string} fpt - the current fpt
 * @param {string} dsIdString - the ds ShipToNumber in string format
 * @param {boolean} active - active status, true to activate
 * @param {number} division - the number representing the division
 * @returns {Promise<any>} - the api call result
 */
export const activateDeactivateVDS = async ({
  fpt,
  dsIdString,
  active,
  division,
}) => {
  const payloadObject = {
    VDS_SoldTo: fpt,
    VDS_SalesOrg: "1000",
    VDS_DistrChan: "00",
    VDS_Division: `0${division}`,
    VDS_Number: dsIdString,
    VDS_Deactivate: active ? "" : "X",
  };
  const response = await postCall(
    apiUrls.activateDeactivateVDS,
    JSON.stringify(payloadObject)
  );
  return await response.json();
};

/**
 * updates/creates a vds based on action defined
 * @param {object} formData - the form data to update
 * @param {string} dsIdString - the ds ShipToNumber in string format
 * @param {object} userAttributes - the user attributes object
 * @param {string} fpt
 * @returns {Promise<any>}
 */
export const editVDS = async ({
  formData,
  dsIdString,
  userAttributes,
  fpt,
}) => {
  const action = dsIdString === "*" ? "CREATE" : "UPDATE";
  const vdsEditObj = {
    d: {
      Action: action,
      VDS_SoldTo: fpt,
      VDS_SalesOrg: "1000",
      VDS_DistrChan: "00",
      VDS_Division: `0${formData.division}`,
      VDS_Search_Term2: `0${formData.division}`,
      VDS_CreatedBy: userAttributes.username.substring(0, 12),
      VDS_Language: formData.preferredLanguage,
      VDS_Name1: formData.dsName1,
      VDS_Name2: formData.dsName2,
      VDS_Search_Term: fpt,
      VDS_Country: formData.country,
      VDS_Region: formData.province,
      VDS_City: formData.city,
      VDS_Postal: formData.postalCode,
      VDS_House: formData.streetNum,
      VDS_Street: formData.street,
      VDS_Str_Suppl1: formData.address2,
      VDS_Latitude: formData.latitude,
      VDS_Longitude: formData.longitude,
      VDS_Pfizer_Flag: formData.usePfizer ? "X" : "",
      VDS_DEL_Flag: formData.delFlag ? "X" : "",
      VDS_DEL: formData.delFlag ? formData.del : "",
      VDS_Shipping_Instr: formData.shippingNote,
    },
  };

  if (dsIdString !== "*") {
    vdsEditObj.d.VDS_ShipTo = dsIdString;
  }

  formData.contacts.forEach((contact, index) => {
    vdsEditObj.d[`VDS_Contact_Name${index + 1}`] = contact.name;
    vdsEditObj.d[`VDS_Contact_Phone${index + 1}`] = contact.phone;
    vdsEditObj.d[`VDS_Contact_Extension${index + 1}`] = contact.extension;
    vdsEditObj.d[`VDS_Contact_Email${index + 1}`] = contact.email;
  });

  console.log(`[dsEdit] posting to sap: `, vdsEditObj);
  const response = await postCall(apiUrls.editVDS, JSON.stringify(vdsEditObj));
  return await response.json();
};
