export const common = {
  actions: "Actions",
  active: "Actif",
  add: "Ajouter",
  all: "Tout",
  amendmentDateDeadline: "Date limite de modification ", // has a space at the end
  and: "et",
  auto: "Auto",
  back: "Retourner",
  boxQuantity: "Quantité de boîte",
  cancel: "Annuler",
  chooseProductType: "Choisir le type de produit",
  clearForm: "Réinitialiser le formulaire",
  close: "Fermer",
  confirm: "Confirmer",
  confirmRequest: "Confirmer la demande",
  create: "Créer",
  delete: "Supprimer",
  deleted: "Supprimé",
  deliveryWeek: "Semaine de livraison ",
  dialogConfirmMessage: "Êtes-vous sûr de vouloir soumettre cette demande?",
  dialogConfirmTitle: "Confirmez les changements",
  diluent: "Diluant",
  division: "Division",
  dosesPerPayload: "Doses par charge utile",
  doses_one: "Dose",
  doses_other: "Doses",
  edit: "Modifier",
  email: "Courriel",
  empty: "Vide",
  english: "Anglais",
  exportToCsv: "Exporter le tableau en format CSV",
  fpt: "FPT",
  french: "Français",
  idleTitle: "Vous êtes sur le point d'être déconnecté",
  idleBody:
    "Pour des raisons de sécurité, vous êtes sur le point d'être déconnecté car vous êtes resté inactif pendant un certain temps. Veuillez revenir sur cette page dans les deux prochaines minutes pour continuer votre séance.",
  inactive: "Inactif",
  intlNumber: "{{val, number}}",
  loading: "Chargement en cours...",
  location: "Site de livraison",
  locations: "Sites de livraison",
  missing: "Disparu",
  no: "Non",
  noAccess: "Pas d'accès",
  none: "Aucun",
  okay: "OK",
  or: "ou",
  options: "Options",
  packsCourses_one: "Paquet (traitement)",
  packsCourses_other: "Paquets (traitements)",
  packsPerBox: "Paquets par boîte",
  packs_one: "Paquet",
  packs_other: "Paquets",
  payloads: "Charges",
  payloads_one: "Charge",
  payloads_other: "Charges",
  phac: "ASPC",
  phone: "Téléphone",
  placeRequest: "Placer la commande",
  product: "Produit",
  prophylactics: "Prophylactiques",
  quantity: "Quantité",
  remove: "Supprimer",
  requestError: "Erreur de soumission de la demande",
  requestErrorDebug:
    "Détails d’erreur du système (disponible en anglais seulement) ", // has a space at the end
  requestErrorText:
    "Il y a eu une erreur inattendue lors de la soumission de votre demande. Pour obtenir de l'aide, veuillez contacter l'Unité de soutien stratégique (USS) à l'adresse vaccine-connect-support-connexion-vaccin@phac-aspc.gc.ca. Si possible, inclure une capture d'écran de ce message d'erreur.",
  requestSubmitted: "Demande soumise",
  requestSubmittedText: "Vos mises à jour ont été reçues et seront traitées.",
  required: "requis",
  results: "Résultats",
  rowsPerPage: "Lignes par page",
  sapDsNumber: "Numéro SL SAP ", // has a space at the end
  sapPeriodNumber: "Numéro de période SAP ", // has a space at the end
  sapSkuNumber: "Numéro SKU SAP ", // has a space at the end
  save: "Sauvegarder",
  search: "Rechercher",
  select: "Sélectionner",
  status: "État",
  submit: "Soumettre",
  submitRequest: "Soumettre la demande",
  therapeutics: "Thérapeutiques",
  typeThreeForSearch:
    "Tapez au moins trois caractères pour lancer la recherche",
  unitsPerBox: "Unités par boîte",
  unitsPerPayload: "Unités par charge utile",
  units_one: "Unité",
  units_other: "Unités",
  vaccine: "Vaccins",
  validateRequest: "Valider la demande",
  vialsPerPayload: "Fioles par charge utile",
  vials_one: "Fiole",
  vials_other: "Fioles",
  view: "Visualiser",
  yes: "Oui",
  skipLinkButton: "Passer au contenu principal",
  headerTitle: "ConnexionVaccin",
  twelveMonths: "12 mois",
  sixMonths: "6 mois",
  fourMonths: "4 mois",
  fourWeeks: "4 semaines",
  dateRange: "Plage de dates",
  custom: "Personnalisé",
  viewAll: "Voir tout",
  from: "De",
  to: "Au",
  reportingDateRange: "Plage de dates du rapport",
  requestedDeliveryDateRange: "Période de livraison demandée",
  filterBy: "Filtrer par {{filterName}}",
};
