import { Box, Grid, Typography } from "@mui/material";
import { DialogModal } from "../../../components/Dialog/Dialog";
import { stateFunctions, submitFormHandler } from "../utils/DsHandlers";

/**
 * renders a generic message component
 * @param {string} bodyText - the text to display
 * @param {function} t - the useTranslation function
 * @returns {JSX.Element} - the component
 * @constructor
 */
export const ModalGeneric = ({ bodyText, t }) => {
  const onClickClose = () => {
    stateFunctions.setModalIsOpen(false);
    stateFunctions.setModalContent(null);
  };
  return (
    <DialogModal
      open={true}
      title={bodyText}
      primaryButtonText={t("okay")}
      handlePrimaryClick={onClickClose}
      handleClose={onClickClose}
    />
  );
};
/**
 * renders a confirm modal dialog content component
 * @param {string} dsIdString - the ds ShipToNumber in string format
 * @param {object} vdsDetails - the vds details object
 * @param {object} userAttributes - the user attributes object
 * @param {string} fpt
 * @param {object} previousDetails - the vds details object, before any changes
 * @param {function} setDsDataLoading - setting this to true triggers a ds list refresh
 * @param {function} t - the useTranslation function
 * @returns {JSX.Element} - the component
 * @constructor
 */
export const ModalSaveConfirmContent = ({
  dsIdString,
  vdsDetails,
  userAttributes,
  fpt,
  previousDetails,
  setDsDataLoading,
  t,
}) => {
  const onClickSubmit = async () => {
    stateFunctions.setModalIsOpen(false);
    stateFunctions.setModalContent(null);
    stateFunctions.setFormSubmitting(true);

    const status = await submitFormHandler({
      dsIdString,
      vdsDetails,
      userAttributes,
      fpt,
      previousDetails,
      setDsDataLoading,
      t,
    });
    if (status) {
      stateFunctions.setFormSubmitting(false);
      stateFunctions.setFormEditable(false);
    } else {
      stateFunctions.setFormSubmitting(false);
      stateFunctions.setFormEditable(true);
    }
  };
  const onClickClose = () => {
    stateFunctions.setModalIsOpen(false);
    stateFunctions.setModalContent(null);
  };
  return (
    <DialogModal
      open={true}
      title={t("dialogConfirmTitle")}
      bodyText={t("dialogConfirmMessage")}
      primaryButtonText={t("confirm")}
      handlePrimaryClick={onClickSubmit}
      secondaryButtonText={t("back")}
      handleSecondaryClick={onClickClose}
      handleClose={onClickClose}
    />
  );
};

/**
 * renders a duplicate detected dialog content component
 * @param {object} params
 * @param {{rule: object, ds: object}[]} params.duplicateResults - the matching ds entries
 * @param {function} params.saveFlow - the save function
 * @param {boolean} params.blockCreation - if it is an exact duplicate or not (affects actions+text)
 * @param {function} params.t - the useTranslation function
 * @returns {JSX.Element} - the component
 * @constructor
 */
export const ModalSaveDuplicateContent = ({
  duplicateResults,
  saveFlow,
  blockCreation,
  t,
}) => {
  const onClickSubmit = async () => {
    saveFlow();
  };
  const onClickClose = () => {
    stateFunctions.setModalIsOpen(false);
    stateFunctions.setModalContent(null);
  };

  return (
    <DialogModal
      open={true}
      title={
        blockCreation
          ? t("exactDuplicateDetected")
          : t("possibleDuplicateDetected")
      }
      primaryButtonText={blockCreation ? t("back") : t("confirm")}
      handlePrimaryClick={blockCreation ? onClickClose : onClickSubmit}
      secondaryButtonText={blockCreation ? undefined : t("back")}
      handleSecondaryClick={blockCreation ? undefined : onClickClose}
      handleClose={onClickClose}
      bodyComponent={
        <div>
          <Typography pb={2}>
            {blockCreation
              ? t("exactDuplicateDetectedMessage")
              : t("possibleDuplicateDetectedMessage")}
          </Typography>

          {duplicateResults.map((duplicate) => {
            const vdsDetails = duplicate.ds;
            const duplicateKeys = Object.keys(duplicate.rule.criteria);

            function renderRow({ label, value, duplicateKey }) {
              const keyIsDuplicate = duplicateKeys.includes(duplicateKey);
              const color = keyIsDuplicate ? "red" : undefined;
              const fontWeight = keyIsDuplicate ? "bold" : "undefined";
              return (
                <Grid container direction="row" spacing={2}>
                  <Grid item md={5}>
                    <Typography color={color} sx={{ fontWeight: "bold" }}>
                      {label}
                    </Typography>
                  </Grid>
                  <Grid item md={7}>
                    <Typography color={color} sx={{ fontWeight }}>
                      {value || `(${t("empty").toLowerCase()})`}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }

            const definitions = [
              {
                label: "ID",
                value: vdsDetails.shipToNumber,
                duplicateKey: "shipToNumber",
              },
              {
                label: `${t("dsName")} 1`,
                value: vdsDetails.dsName1,
                duplicateKey: "dsName1",
              },
              {
                label: `${t("dsName")} 2`,
                value: vdsDetails.dsName2,
                duplicateKey: "dsName2",
              },
              {
                label: t("city"),
                value: vdsDetails.city,
                duplicateKey: "city",
              },
              {
                label: t("postalCode"),
                value: vdsDetails.postalCode,
                duplicateKey: "postalCode",
              },
              {
                label: t("address"),
                value: vdsDetails.streetWithNum,
                duplicateKey: "street",
              },
              {
                label: t("address2Short"),
                value: vdsDetails.address2,
                duplicateKey: "address2",
              },
              {
                label: t("primaryContactName"),
                value: vdsDetails.contacts[0]
                  ? `${vdsDetails.contacts[0].name} (${vdsDetails.contacts[0].email})`
                  : `(${t("empty").toLowerCase()})`,
                duplicateKey: "primaryContactName",
              },
              {
                label: t("usePfizer"),
                value: vdsDetails.usePfizer ? t("yes") : t("no"),
                duplicateKey: "usePfizer",
              },
              {
                label: t("status"),
                value: vdsDetails.active ? t("active") : t("inactive"),
                duplicateKey: "status",
              },
            ];

            return (
              <Box mb={4}>
                {definitions.map((definition) => renderRow(definition))}
              </Box>
            );
          })}
        </div>
      }
    />
  );
};
