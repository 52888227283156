import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { initialDateFilterState } from "../components/DataTable/DateFilter/DateFilter";
import { useUserAccessFilterContext } from "../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { getCall } from "../utils/requests/apiCalls";
import apiUrls from "../utils/requests/apiUrls";
import { useTranslateUnits } from "./useTranslateUnits";

/**
 * @param {'HIST'|'CURR'} action
 * @param fields - unused, not tested, possibly working
 * @param {string} provCode
 * @param {number} division
 * @param {string} permissionToken
 * @param {string} currentLocale - 'fr-CA' or 'en-CA'
 * @param {function} getTranslatedUnitsWithValue
 * @param {string} deliveryDateFrom - filters the response by delivery date, only returning orders on and AFTER the specified date. format: YYYY-MM-DD
 * @param {string} deliveryDateTo - filters the response by delivery date, only returning orders on and BEFORE the specified date. format: YYYY-MM-DD
 * @param t
 */
const fetchOrderList = async ({
  action = "HIST",
  // fields = null, // unused, not tested, possibly working
  provCode = "ON",
  division = 1,
  permissionToken,
  currentLocale,
  getTranslatedUnitsWithValue,
  deliveryDateFrom,
  deliveryDateTo,
  t,
} = {}) => {
  console.log("[fetchOrderList] fetching");
  const limit = 5000;
  let lastOrder = 200000000;
  const allOrders = [];
  while (true) {
    const params = [];
    let nextMinOrder = lastOrder;
    // if (fields) params.push(`fields=${fields}`); // unused, not tested, possibly working
    if (provCode) params.push(`soldTo=${provCode}`);
    if (division) params.push(`div=${division}`);
    if (currentLocale && currentLocale === "fr-CA")
      params.push(`lang=${currentLocale === "fr-CA" ? "F" : "E"}`);
    if (deliveryDateFrom) {
      params.push(`requestDeliveryFromDate=${deliveryDateFrom}`);
    }
    if (deliveryDateTo) {
      params.push(`requestDeliveryToDate=${deliveryDateTo}`);
    }
    const paramsString = params.join("&");
    // TODO: consider optimizing so noText=Yes for large requests. We only need noText=No when viewing a specific order. Need to create "get single order" request in lambdas+portal.
    const url =
      apiUrls.previousOrder +
      `?action=${action}&lastOrder=${nextMinOrder}&numOrders=${limit}&noText=No&` +
      paramsString;

    const result = await getCall(url, permissionToken);

    if (!result || result.status !== 200) {
      console.error(`[fetchOrderList] failed to fetch: `, result);
      return [];
    }

    let resultJson = null;
    try {
      resultJson = await result.json();
    } catch (e) {
      console.error("[fetchOrderList] failed to parse result: ", e);
      break;
    }

    if (!resultJson.data || !resultJson.data.length) {
      console.error("[fetchOrderList] result contains no data: ", resultJson);
      break;
    }

    // get current datetime - keep it here and not global, so it'll update on refresh
    const dayjsNow = dayjs();

    const resultProcessed = resultJson.data.map((item) => {
      const DocType = item.DocType;
      const rush = DocType === "ZRO" ? t("yes") : t("no");

      return {
        ...item,
        Sales_Document: Number(item["Sales_Document"]),
        rush,
        OrderGetsToItemNav: item["OrderGetsToItemNav"].map((order) => {
          const amendmentDeadline = dayjs
            .tz(
              `${order["Amend_EndDate"]} ${order["Amend_EndTime"]}`,
              "YYYY-MM-DD H:mm",
              "Canada/Eastern"
            )
            .local();
          const pastAmendmentDeadline = amendmentDeadline < dayjsNow;
          const PeriodNumber = order["Period_Number"];
          const Modifier = Number(order["Period_Modifier"]);
          const periodAndModifier =
            PeriodNumber + (Modifier !== 1 ? ` (mod ${Modifier})` : "");

          const Item_Reject_Reason = order["Item_Reject_Reason"];

          const Target_Qty = Math.round(Number(order["Target_Qty"]));
          const Order_Qty = Math.round(Number(order["Order_Qty"]));

          const productAndSku = `${order["SKUDescription"]} (SKU ${Number(
            order["SKUNumber"]
          )})`;

          const orderObject = {
            // There are other fields not explicitly included in this object, but rather are implicitly included by the spread operator below.
            // Such fields include "Item_Reject_Note". To see all the fields, inspect the response in your browser's network tab, or in the console output.
            ...order,
            rush,
            label: productAndSku,
            PeriodNumber,
            Modifier,
            periodAndModifier,
            weekIdentifier: Number(`${PeriodNumber.substring(1)}${Modifier}`), // W2240 mod 1 will become 22401, useful for comparisons
            Ship_To_Number: Number(order["Ship_To_Number"]),
            Sales_Document: Number(order["Sales_Document"]),
            Item_Number: Number(order["Item_Number"]),
            SKUNumber: Number(order["SKUNumber"]),
            Item_Reject_Reason,
            // payloads, this is what the user enters
            Target_Qty,
            Target_Qty_UOM: getTranslatedUnitsWithValue(
              Target_Qty,
              order.Target_Qty_UOM
            ),

            // doses/vials/etc., this is the computed sum
            Order_Qty,
            Order_UOM: getTranslatedUnitsWithValue(Order_Qty, order.Order_UOM),

            amendmentDeadline,
            pastAmendmentDeadline,

            // expiryDate: order.Amend_EndDate,
            expiryDate: "",

            isUpdatable: order["Updatable"] === "X",
            isCancelled: Item_Reject_Reason !== "",

            trackingNumber: "",
            lotNumber: "",
            actualDeliveryDate: "",
            deliveryStatus: "",
            isRush: DocType === "ZRO",
          };

          const trackingNumber =
            order["DeliveryGetsToOrdersNav"] &&
            order["DeliveryGetsToOrdersNav"].results &&
            order["DeliveryGetsToOrdersNav"].results.map
              ? order["DeliveryGetsToOrdersNav"].results
                  .map((el) => el["DeliveryTrackingNo"])
                  .join(", ")
              : null;

          orderObject.expiryDate = order["DeliveryGetsToOrdersNav"].results
            .map((el) =>
              el["DeliveryBatchExpDate"]
                ? dayjs(el["DeliveryBatchExpDate"]).format("ll")
                : ""
            )
            .join(", ");

          if (trackingNumber) {
            orderObject.trackingNumber = trackingNumber;
            orderObject.lotNumber = order["DeliveryGetsToOrdersNav"].results
              .map((el) => el["DeliveryBatch"])
              .join(", ");
            orderObject.actualDeliveryDate = order[
              "DeliveryGetsToOrdersNav"
            ].results
              .map((el) => {
                const qty = getTranslatedUnitsWithValue(
                  Number(el["DeliveryQty"]).toFixed(),
                  order["Order_UOM"]
                );
                const date = el["DeliveryLspDateItem"]
                  ? dayjs(el["DeliveryLspDateItem"]).format("ll")
                  : "";
                return date ? `${date} (${qty})` : "";
              })
              .join(", ");
          }

          // status logic:
          // DeliveryLspStatusItem > DeliveryLspStatus (header) > DeliveryItemStatusText > CurrStatusText
          const DeliveryLspStatusItem = order["DeliveryGetsToOrdersNav"].results
            .map((el) => el["DeliveryLspStatusItem"])
            .join(", ");
          const DeliveryLspStatus = order[
            "DeliveryGetsToOrdersNav"
          ].results.map((el) => el["DeliveryLspStatus"])[0];
          orderObject.deliveryStatus =
            DeliveryLspStatusItem ||
            DeliveryLspStatus ||
            order["Item_Reject_Desc"] ||
            order["DeliveryItemStatusText"] ||
            item["CurrStatusText"];

          return orderObject;
        }),
      };
    });

    // push everything we've processed into the order array
    for (let order of resultProcessed) allOrders.push(order);

    // if we received fewer orders than we requested, we've received everything and can stop now
    if (resultProcessed.length !== limit) break;

    // grabs the greatest order number
    lastOrder = allOrders.reduce(
      (greatest, current) =>
        current.Sales_Document > greatest ? current.Sales_Document : greatest,
      lastOrder
    );

    // the next request will start one order after the greatest order so far
    nextMinOrder = lastOrder + 1;
  }

  console.log(
    `[fetchOrderList] fetched ${allOrders.length} items (example): `,
    allOrders[0]
  );

  return allOrders;
};

export const useFetchOrderList = ({ isDateFilterEnabled, action, fields }) => {
  const [orderList, setOrderList] = useState([]);
  const [orderDataLoading, setOrderDataLoading] = useState(true);
  const [previousAction, setPreviousAction] = useState(null);
  const { selectedFptText, selectedDivision, permissionToken } =
    useUserAccessFilterContext();
  const [fetchedFptDivision, setFetchedFptDivision] = useState({
    fpt: undefined,
    division: undefined,
  });

  // date filter in this API pertains to Request Delivery Dates
  const [dateFilter, setDateFilter] = useState(
    isDateFilterEnabled ? initialDateFilterState() : {}
  );

  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  const { getTranslatedUnitsWithValue } = useTranslateUnits();

  useEffect(() => {
    if (action !== previousAction) {
      setOrderDataLoading(true);
      setPreviousAction(action);
      if (isDateFilterEnabled) setDateFilter(initialDateFilterState());
    }
  }, [action, previousAction, isDateFilterEnabled]);

  // forces reload when fpt/division selection or date filter changes
  useEffect(() => {
    if (
      fetchedFptDivision.fpt !== selectedFptText ||
      fetchedFptDivision.division !== selectedDivision
    )
      setOrderDataLoading(true);
  }, [fetchedFptDivision, selectedDivision, selectedFptText]);

  useEffect(() => {
    setOrderDataLoading(true);
  }, [dateFilter, isDateFilterEnabled]);

  useEffect(() => {
    if (orderDataLoading) {
      if (!selectedFptText || !selectedDivision || !permissionToken)
        return console.log(
          "[fetchDsList] unable to refresh, missing UM selections"
        );
      fetchOrderList({
        currentLocale,
        action,
        provCode: selectedFptText,
        division: selectedDivision,
        permissionToken,
        getTranslatedUnitsWithValue,
        deliveryDateFrom: isDateFilterEnabled && dateFilter.fromDate,
        deliveryDateTo: isDateFilterEnabled && dateFilter.toDate,
        t,
      }).then((result) => {
        setOrderList(result);
        setFetchedFptDivision({
          fpt: selectedFptText,
          division: selectedDivision,
        });
        setOrderDataLoading(false);
      });
    } else {
      console.log("[fetchOrderList] skipping load, dataLoading is false");
    }
  }, [
    action,
    fields,
    orderDataLoading,
    permissionToken,
    selectedFptText,
    selectedDivision,
    currentLocale,
    t,
    isDateFilterEnabled,
  ]);

  return {
    orderList,
    orderDataLoading,
    setOrderDataLoading,
    setDateFilter,
    dateFilter,
  };
};
