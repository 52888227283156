import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { initialDateFilterState } from "../../../components/DataTable/DateFilter/DateFilter";
import { useUserAccessFilterContext } from "../../../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { parseDate, parseEpochDateTime } from "../../../utils/dateUtils";
import { getCall } from "../../../utils/requests/apiCalls";
import apiUrls from "../../../utils/requests/apiUrls";

/**
 * Fetches the Allocation data from SAP, and does some minor processing to the response.
 * @param {string} currentLocale
 * @param {string} provCode
 * @param {number} division
 */
const fetchWastageList = async ({
  reptToDate,
  reptFromDate,
  currentLocale,
  provCode,
  division,
  t,
}) => {
  console.log("[fetchWastageList] fetching");

  const params = [`provCode=${provCode}`, `division=${division}`];
  if (reptToDate) {
    params.push(`reptToDate=${reptToDate}`);
  }
  if (reptFromDate) {
    params.push(`reptFromDate=${reptFromDate}`);
  }
  const url = `${apiUrls.wastage}?${params.join("&")}`;

  const result = await getCall(url);
  if (!result || result.status !== 200) {
    console.error(`[fetchWastageList] failed to fetch: `, result);
    return [];
  }

  let resultJson = null;
  try {
    resultJson = await result.json();
  } catch (e) {
    console.error("[fetchWastageList] failed to parse result: ", e);
    return [];
  }

  if (!resultJson || !resultJson.results || !resultJson.results.length) {
    console.error("[fetchWastageList] result contains no data: ", resultJson);
    return [];
  }

  const resultProcessed = resultJson.results
    .sort((a, b) => {
      if (a.WasReptDate > b.WasReptDate) return -1;
      if (a.WasReptDate < b.WasReptDate) return 1;
      return 0;
    })
    .map((wastage) => {
      return {
        WasReptDate: parseDate(wastage["WasReptDate"], currentLocale),
        WastageDate: parseDate(wastage["WastageDate"], currentLocale),
        MaterialName: wastage["to_MaterialText"].results.find(
          (item) =>
            item["Language"] === (currentLocale === "fr-CA" ? "FR" : "EN")
        ).MaterialName,
        MaterialGroup: wastage["MaterialGroup"],
        Batch: wastage["Batch"],
        ExpiryDate: parseDate(wastage["ExpiryDate"], currentLocale),
        Category: wastage["to_WasCategory"].results.find(
          (item) => item["SPRAS"] === (currentLocale === "fr-CA" ? "FR" : "EN")
        ).CAT_DESCRIPTION,
        Explanation:
          wastage["to_WasExplanation"].results.find(
            (item) =>
              item["SPRAS"] === (currentLocale === "fr-CA" ? "FR" : "EN")
          )?.EXPL_DESCRIPTION || "",
        WasQty: t("intlNumber", { val: wastage["WasQty"] }),
        Uom: wastage["Uom"],
        Filename: wastage["Filename"],
        // SubmitUser: wastage["SubmitUser"],
        SubmitDate: parseEpochDateTime(
          wastage["SubmitDateTime"],
          currentLocale
        ),
      };
    });

  console.log(
    `[fetchWastageList] fetched ${resultProcessed.length} items (example): `,
    resultProcessed[0]
  );

  return resultProcessed;
};

export const useFetchWastageList = (isDateFilterEnabled) => {
  const currentLocale = useTranslation().i18n.language;
  const [wastageData, setWastageData] = useState([]);
  const [wastageDataLoading, setWastageDataLoading] = useState(true);
  const { selectedFptText, selectedDivision } = useUserAccessFilterContext();
  const [fetchedFptDivision, setFetchedFptDivision] = useState({
    fpt: undefined,
    division: undefined,
    lang: currentLocale,
  });

  // date filter in this API pertains to Reporting Date
  const [dateFilter, setDateFilter] = useState(
    isDateFilterEnabled ? initialDateFilterState() : {}
  );
  const { t } = useTranslation();
  // forces reload when fpt/division selection changes
  useEffect(() => {
    if (
      fetchedFptDivision.fpt !== selectedFptText ||
      fetchedFptDivision.division !== selectedDivision ||
      fetchedFptDivision.lang !== currentLocale
    )
      setWastageDataLoading(true);
  }, [fetchedFptDivision, selectedDivision, selectedFptText, currentLocale]);

  useEffect(() => {
    setWastageDataLoading(true);
  }, [dateFilter]);

  useEffect(() => {
    if (wastageDataLoading) {
      if (!selectedFptText || !selectedDivision)
        return console.log(
          "[fetchWastageList] unable to refresh, missing UM selections"
        );

      fetchWastageList({
        reptToDate: isDateFilterEnabled && dateFilter.toDate,
        reptFromDate: isDateFilterEnabled && dateFilter.fromDate,
        currentLocale: currentLocale,
        provCode: selectedFptText,
        division: selectedDivision,
        t,
      }).then((result) => {
        setWastageData(result);
        setFetchedFptDivision({
          lang: currentLocale,
          fpt: selectedFptText,
          division: selectedDivision,
        });
        setWastageDataLoading(false);
      });
    } else {
      console.log("[fetchWastageList] skipping load, dataLoading is false");
    }
  }, [wastageDataLoading, selectedDivision, selectedFptText, currentLocale, t]);

  return {
    wastageData,
    setWastageData,
    wastageDataLoading,
    setWastageDataLoading,
    setDateFilter,
    dateFilter,
  };
};
