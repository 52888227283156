import { DialogContentText } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

/**
 * @param {boolean} open - whether the dialog is open
 * @param {string} title - title of the dialog
 * @param {'sm'|'md'|'lg'} maxWidth - maxWidth for the dialog, see mui docs
 *
 * @param {string} bodyText - a string to display
 * @param {{key:string, value:string}[]} bodyTableContent - an array of key/value to display in table format. if exists, replaces bodyText
 * @param {JSX.Element} bodyComponent - a raw component/element to display within the dialog, if exists, replaces bodyText
 *
 * @param {function} handleClose - function to call when dialog is closed
 * @param {function} handlePrimaryClick - function to call when primary action is taken
 * @param {string} primaryButtonText - text to display for the primary action (ex. save)
 * @param {boolean} primaryButtonDisabled - whether the primary action is disabled
 * @param {function} handleSecondaryClick
 * @param {string} secondaryButtonText
 * @param {boolean} secondaryButtonDisabled
 * @param {'text'|'outlined'|'contained'} secondaryButtonVariant - the style to use for the secondary button
 * @param {function} handleTertiaryClick
 * @param {string} tertiaryButtonText
 * @param {boolean} tertiaryButtonDisabled
 * @param {'text'|'outlined'|'contained'} tertiaryButtonVariant - the style to use for the tertiary button
 *
 * @return {JSX.Element}
 */
export const DialogModal = ({
  open = false,
  title = "",
  maxWidth = "sm",

  bodyText,
  bodyTableContent,
  bodyComponent,

  handleClose = () => {},
  handlePrimaryClick = () => {},
  primaryButtonText = "",
  primaryButtonDisabled = false,
  handleSecondaryClick = () => {},
  secondaryButtonText = "",
  secondaryButtonDisabled = false,
  secondaryButtonVariant = "contained",
  handleTertiaryClick = () => {},
  tertiaryButtonText = "",
  tertiaryButtonDisabled = false,
  tertiaryButtonVariant = "contained",
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      aria-labelledby="dialog_title"
      aria-describedby="dialog_content"
    >
      <DialogTitle id="dialog_title">{title}</DialogTitle>
      <DialogContent id="dialog_content">
        {bodyTableContent ? (
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {bodyTableContent.map((row) => (
                  <TableRow
                    key={row.key}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.key}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : bodyComponent ? (
          bodyComponent
        ) : (
          bodyText &&
          bodyText.split("\n").map((text, index) => (
            <DialogContentText key={index}>
              {text}
              <br />
            </DialogContentText>
          ))
        )}
      </DialogContent>
      <DialogActions>
        {tertiaryButtonText && (
          <Button
            onClick={handleTertiaryClick}
            disabled={tertiaryButtonDisabled}
            variant={tertiaryButtonVariant}
          >
            {tertiaryButtonText}
          </Button>
        )}
        {secondaryButtonText && (
          <Button
            onClick={handleSecondaryClick}
            disabled={secondaryButtonDisabled}
            variant={secondaryButtonVariant}
          >
            {secondaryButtonText}
          </Button>
        )}
        {primaryButtonText && (
          <Button
            variant="contained"
            onClick={handlePrimaryClick}
            disabled={primaryButtonDisabled}
            autoFocus
          >
            {primaryButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
