import { Stack, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid } from "@mui/material/index";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Context } from "../../../../context/Context";
import { DropdownAsync } from "../../../components/DropdownAsync/DropdownAsync";
import { LoaderPage } from "../../../components/LoaderPage/LoaderPage";
import { SelectInput } from "../../../components/Select/SelectInput";
import { TextInput } from "../../../components/TextInput/TextInput";
import { useUserAccessFilterContext } from "../../../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { COUNTRIES, PROVINCES, STATES } from "../../../constants";
import { getFptRestrictions } from "../../../utils/fptUtils";
import { requestArcGISGeolocationAddress } from "../../../utils/requests/requestArcGISGeolocateAddress";
import {
  cancelClickHandler,
  clearFormClickHandler,
  contactOnChangeHandler,
  editClickHandler,
  genericOnChangeHandler,
  phoneNumberOnChangeHandler,
  setHandlerFunctions,
  stateFunctions,
  submitClickHandler,
} from "../utils/DsHandlers";
import { newVDSObject } from "../utils/DsUtils";
import {
  initialVDSValidationBuilder,
  showProvinceField,
  useVDSFormValidationEffect,
} from "../utils/DsValidators";
import {
  BackButton,
  CancelButton,
  ClearFormButton,
  EditButton,
  SubmitButton,
} from "./DsButtons";
import VDSStatusField from "./DsStatusField";

/**
 * renders a vds management form
 * @param {string} dsIdString - the ds ShipToNumber in string format
 * @param {function} setVds - the set selected vds function
 * @param {function} backToDataTable - the back to list view function
 * @param {function} setDsDataLoading - setting this to true triggers a ds list refresh
 * @param {object[]} allDsLocations - the array of ds locations
 * @returns {JSX.Element} - the component
 * @constructor
 */
const VDSManagementForm = ({
  dsIdString,
  setVds,
  backToDataTable,
  setDsDataLoading,
  allDsLocations,
}) => {
  const createMode = dsIdString === "*";
  const { t } = useTranslation("dsManagement");
  const requiredFieldRender = (fieldText, condition) => {
    if (!condition) return fieldText;
    return `${fieldText} (${t("required")})`;
  };
  const { userAttributes } = useContext(Context);
  const { selectedFptText, selectedDivision } = useUserAccessFilterContext();
  const [blankDsObject] = useState(
    newVDSObject({
      fptCode: selectedFptText,
      division: selectedDivision,
    })
  );
  const [dsInitialState] = useState(
    createMode
      ? blankDsObject
      : allDsLocations.find((ds) => ds.dsIdString === dsIdString)
  );
  const [vdsDetails, setVdsDetails] = useState(dsInitialState);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formEditable, setFormEditable] = useState(createMode);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [dirty, setDirty] = useState(false);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [addressSearchTerm, setAddressSearchTerm] = useState("");
  const [addressAutocompleteDisabled, setAddressAutocompleteDisabled] =
    useState(false);
  const [addressAutocompleteDirty, setAddressAutocompleteDirty] =
    useState(false);
  const fptRestrictions = getFptRestrictions(selectedFptText);

  setHandlerFunctions({
    setVds,
    setVdsDetails,
    setFormSubmitting,
    setFormEditable,
    setModalIsOpen,
    setModalContent,
    setDirty,
    backToDataTable,
  });

  /**
   * The validation results for the form. Checks the form as a whole, and checks individual fields.
   * @type {{formInvalid: boolean, formInvalidState: {}}}
   */
  const validationResults = useVDSFormValidationEffect({
    formEditable,
    createMode,
    formData: vdsDetails,
    cleanState: createMode
      ? blankDsObject
      : allDsLocations.find((ds) => ds.dsIdString === dsIdString),
    prevIsDirty: dirty,
  });

  /**
   * Manages disabling and clearing of Address field.
   * If country or city is not provided, clear and disable address field.
   */
  useEffect(() => {
    const clearAndDisableAddress = () => {
      if (addressAutocompleteDirty) {
        clearAddressAutocomplete();
        setAddressAutocompleteDisabled(true);
      } else {
        setAddressAutocompleteDisabled(true);
      }
    };

    vdsDetails?.city && vdsDetails?.country
      ? setAddressAutocompleteDisabled(false)
      : clearAndDisableAddress();
  }, [addressAutocompleteDirty, vdsDetails?.city, vdsDetails?.country]);

  // if vds not found, return an empty page
  if (!vdsDetails) {
    return <div />;
  }

  const onSearchAddress = async (street) => {
    street = street.replace(/[!@#$%^&*()_\-`~]/g, " ");
    setAddressSearchTerm(street);
    setAddressAutocompleteDirty(true);
    const { city, postalCode, province, country } = vdsDetails || {};

    if (street && city && street.length >= 3) {
      const resp = await requestArcGISGeolocationAddress({
        street,
        city,
        province: province.value,
        country: country.value,
        postalCode,
      });
      if (resp && resp.candidates) {
        setAddressSuggestions(resp.candidates);
      }
    }
  };

  const onSelectAddress = (address) => {
    if (!address) return;
    setSelectedAddress(address);
    const mutations = getMutatedAddress(address.attributes);
    const newVdsDetails = { ...vdsDetails, ...mutations };
    stateFunctions.setVdsDetails(newVdsDetails);
  };

  const clearAddressAutocomplete = () => {
    setAddressSearchTerm("");
    setAddressSuggestions([]);
    setSelectedAddress({ address: "" });
    setAddressAutocompleteDirty(false);
  };

  const clearAddress = () => {
    clearAddressAutocomplete();
    const mutations = getMutatedAddress(null);
    stateFunctions.setVdsDetails({ ...vdsDetails, ...mutations });
  };

  const provStateOptions = getProvStateOptions(vdsDetails?.country);

  const contactLabels = {
    name: [
      t("primaryContactName"),
      t("secondaryContactName"),
      t("tertiaryContactName"),
    ],
    phone: [
      t("primaryContactPhone"),
      t("secondaryContactPhone"),
      t("tertiaryContactPhone"),
    ],
    email: [
      t("primaryContactEmail"),
      t("secondaryContactEmail"),
      t("tertiaryContactEmail"),
    ],
  };

  const renderContactFields = (contact, index, required) => {
    return (
      <Grid container direction={"row"} spacing={2} key={index} mb={2}>
        <Grid item xs={12} md={4}>
          <TextInput
            id={`contactName-${index}`}
            label={requiredFieldRender(
              contactLabels.name[index],
              formEditable && index === 0 && required
            )}
            required={formEditable && index === 0 && required}
            value={contact.name}
            disabled={!formEditable}
            onChange={(value) =>
              contactOnChangeHandler({
                index,
                fieldName: `name`,
                value,
              })
            }
            error={
              dirty &&
              validationResults.formInvalidState.contacts?.[index]?.name
            }
          />
        </Grid>
        <Grid item xs={8} md={3}>
          <TextInput
            id={`contactPhone-${index}`}
            label={requiredFieldRender(
              contactLabels.phone[index],
              formEditable && index === 0 && required
            )}
            helperText={
              contact.phone?.length < 10 && contact.phone?.length > 0
                ? t("phoneErrorShort")
                : contact.phone?.length > 10 && contact.phone?.length > 0
                ? t("phoneErrorLong")
                : undefined
            }
            required={formEditable && index === 0 && required}
            value={contact.phone}
            disabled={!formEditable}
            onChange={(value) =>
              phoneNumberOnChangeHandler({
                index,
                fieldName: `phone`,
                value,
                intl: userAttributes.IntlFPT,
              })
            }
            error={
              dirty &&
              validationResults.formInvalidState.contacts?.[index]?.phone
            }
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextInput
            id={`extension-${index}`}
            label={t("extension")}
            value={contact.extension || ""}
            onChange={(value) =>
              phoneNumberOnChangeHandler({
                index,
                fieldName: "extension",
                value,
                intl: userAttributes.IntlFPT,
              })
            }
            disabled={!formEditable}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            id={`contactEmail-${index}`}
            label={requiredFieldRender(
              contactLabels.email[index],
              formEditable && index === 0 && required
            )}
            required={formEditable && index === 0 && required}
            value={contact.email}
            disabled={!formEditable}
            onChange={(value) =>
              contactOnChangeHandler({
                index,
                fieldName: `email`,
                value,
              })
            }
            error={
              dirty &&
              validationResults.formInvalidState.contacts?.[index]?.email
            }
          />
        </Grid>
      </Grid>
    );
  };

  // loop to build out proper number of contact field in edit mode
  while (formEditable && vdsDetails.contacts.length < 3) {
    vdsDetails.contacts.push({
      name: "",
      phone: "",
      email: "",
    });
  }

  const validationObject = initialVDSValidationBuilder({
    formData: vdsDetails,
  });

  return (
    <>
      {modalIsOpen && modalContent}
      <div>
        {/* Important: for Accessibility: noValidate (along with component="form" stops browser tooltips to appear */}
        <form id="VDSManagement__form" autoComplete="off" noValidate>
          <Typography variant="h5" component={"h3"} gutterBottom mb={2}>
            {t("sectionDs")}
          </Typography>
          <Grid container direction={"row"} spacing={2} mb={2}>
            <Grid item xs={12} md={6}>
              <TextInput
                id={`dsName1`}
                label={requiredFieldRender(
                  t("dsName") + " 1",
                  formEditable && validationObject.dsName1.required
                )}
                required={formEditable && validationObject.dsName1.required}
                value={vdsDetails.dsName1}
                disabled={!formEditable}
                onChange={(value) =>
                  genericOnChangeHandler({
                    fieldName: "dsName1",
                    value,
                  })
                }
                error={dirty && validationResults.formInvalidState.dsName1}
                maxLength={validationObject.dsName1.maxLength}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput
                id={`dsName2`}
                label={requiredFieldRender(
                  t("dsName") + " 2",
                  formEditable && validationObject.dsName2.required
                )}
                required={formEditable && validationObject.dsName2.required}
                value={vdsDetails.dsName2}
                disabled={!formEditable}
                onChange={(value) =>
                  genericOnChangeHandler({
                    fieldName: "dsName2",
                    value,
                  })
                }
                error={dirty && validationResults.formInvalidState.dsName2}
                maxLength={validationObject.dsName2.maxLength}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <SelectInput
                id={`language-dropdown`}
                label={requiredFieldRender(
                  t("preferredLanguage"),
                  formEditable && validationObject.preferredLanguage.required
                )}
                value={vdsDetails.preferredLanguage}
                onChangeValue={(value) =>
                  genericOnChangeHandler({
                    fieldName: "preferredLanguage",
                    value: value,
                  })
                }
                options={[
                  {
                    value: "EN",
                    label: t("english"),
                  },
                  {
                    value: "FR",
                    label: t("french"),
                  },
                ]}
                required={
                  formEditable && validationObject.preferredLanguage.required
                }
                disabled={!formEditable}
                error={
                  dirty && validationResults.formInvalidState.preferredLanguage
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SelectInput
                id={`division-dropdown`}
                label={requiredFieldRender(
                  t("division"),
                  formEditable && validationObject.active.required
                )}
                value={vdsDetails.division.toString()}
                onChangeValue={(value) =>
                  genericOnChangeHandler({
                    fieldName: "division",
                    value: value,
                  })
                }
                options={[
                  { label: t("vaccine"), value: "1" },
                  { label: t("therapeutics"), value: "2" },
                  { label: t("prophylactics"), value: "3" },
                ]}
                required={formEditable && validationObject.active.required}
                disabled={true}
                error={dirty && validationResults.formInvalidState.active}
                helperText={createMode ? t("divisionMessage") : undefined}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SelectInput
                id={`status-dropdown`}
                label={requiredFieldRender(
                  t("status"),
                  formEditable && validationObject.active.required
                )}
                value={
                  createMode
                    ? statusOptions[0].value
                    : vdsDetails.active.toString()
                }
                onChangeValue={(value) =>
                  genericOnChangeHandler({
                    fieldName: "active",
                    value: value === "true",
                  })
                }
                options={statusOptions}
                required={formEditable && validationObject.active.required}
                disabled={!formEditable || createMode}
                error={dirty && validationResults.formInvalidState.active}
                helperText={createMode ? t("statusMessage") : undefined}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SelectInput
                id={`pfizer-dropdown`}
                label={requiredFieldRender(
                  t("usePfizer"),
                  formEditable && validationObject.usePfizer.required
                )}
                value={vdsDetails.usePfizer.toString()}
                onChangeValue={(value) =>
                  genericOnChangeHandler({
                    fieldName: "usePfizer",
                    value: value === "true",
                  })
                }
                options={[
                  {
                    value: "true",
                    label: t("yes"),
                  },
                  {
                    value: "false",
                    label: t("no"),
                  },
                ]}
                required={formEditable && validationObject.usePfizer.required}
                disabled={!formEditable}
                error={dirty && validationResults.formInvalidState.usePfizer}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SelectInput
                id={`delFlag-dropdown`}
                label={requiredFieldRender(
                  t("delFlag"),
                  formEditable && validationObject.delFlag.required
                )}
                value={vdsDetails.delFlag.toString()}
                onChangeValue={(value) =>
                  genericOnChangeHandler({
                    fieldName: "delFlag",
                    value: value === "true",
                  })
                }
                options={[
                  {
                    value: "true",
                    label: t("yes"),
                  },
                  {
                    value: "false",
                    label: t("no"),
                  },
                ]}
                required={formEditable && validationObject.delFlag.required}
                disabled={!formEditable}
                error={dirty && validationResults.formInvalidState.delFlag}
              />
            </Grid>
            {vdsDetails.delFlag === true && (
              <Grid item xs={6} md={4}>
                <TextInput
                  id={`del`}
                  label={requiredFieldRender(
                    t("del"),
                    formEditable && validationObject.del.required
                  )}
                  required={formEditable && validationObject.del.required}
                  value={vdsDetails.del}
                  disabled={!formEditable}
                  onChange={(value) =>
                    genericOnChangeHandler({
                      fieldName: "del",
                      value,
                    })
                  }
                  error={
                    dirty &&
                    vdsDetails.delFlag &&
                    validationResults.formInvalidState.del
                  }
                />
              </Grid>
            )}
          </Grid>

          <Typography variant="h5" component={"h3"} gutterBottom mt={4} mb={2}>
            {t("sectionContact")}
          </Typography>
          {vdsDetails.contacts.map((contact, index) => {
            const required = index === 0 && vdsDetails.active;
            return renderContactFields(contact, index, required);
          })}

          <Typography variant="h5" component={"h3"} gutterBottom mt={4} mb={2}>
            {t("sectionLocation")}
          </Typography>
          <Grid container direction={"row"} spacing={2} mb={2}>
            <Grid item xs={12} md={3}>
              <Autocomplete
                id={`country-dropdown`}
                value={vdsDetails.country}
                onChange={(event, value) =>
                  genericOnChangeHandler({
                    fieldName: "country",
                    value: value,
                  })
                }
                options={COUNTRIES.map((country) => country.code)}
                getOptionLabel={(option) =>
                  COUNTRIES.find((country) => country.code === option).name
                }
                disabled={
                  !formEditable ||
                  !createMode ||
                  !fptRestrictions.canEditCountry
                }
                fullWidth
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={requiredFieldRender(
                      t("country"),
                      createMode &&
                        initialVDSValidationBuilder({
                          formData: vdsDetails,
                          createMode,
                        }).country.required
                    )}
                    error={dirty && validationResults.formInvalidState.country}
                    required={
                      createMode &&
                      initialVDSValidationBuilder({
                        formData: vdsDetails,
                        createMode,
                      }).country.required
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              {showProvinceField(vdsDetails.country) && (
                <Autocomplete
                  id={`province-dropdown`}
                  value={vdsDetails.province}
                  onChange={(event, value) =>
                    genericOnChangeHandler({
                      fieldName: "province",
                      value: value,
                    })
                  }
                  options={provStateOptions.map((item) => item.value)}
                  getOptionLabel={(option) =>
                    provStateOptions.find(
                      (provState) => provState.value === option
                    )?.label || ""
                  }
                  disabled={
                    !formEditable ||
                    !createMode ||
                    !fptRestrictions.canEditProvince
                  }
                  fullWidth
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={requiredFieldRender(
                        t("province"),
                        createMode &&
                          initialVDSValidationBuilder({
                            formData: vdsDetails,
                            createMode,
                          }).province.required
                      )}
                      error={
                        dirty && validationResults.formInvalidState.province
                      }
                      required={
                        createMode &&
                        initialVDSValidationBuilder({
                          formData: vdsDetails,
                          createMode,
                        }).province.required
                      }
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              <TextInput
                id={`city`}
                label={requiredFieldRender(
                  t("city"),
                  formEditable && createMode
                )}
                required={formEditable && createMode}
                value={vdsDetails.city}
                disabled={!formEditable || !createMode}
                onChange={(value) =>
                  genericOnChangeHandler({
                    fieldName: "city",
                    value,
                  })
                }
                error={dirty && validationResults.formInvalidState.city}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextInput
                id={`postalCode`}
                label={requiredFieldRender(
                  t("postalCode"),
                  formEditable && createMode
                )}
                required={formEditable && createMode}
                value={vdsDetails.postalCode}
                disabled={!formEditable || !createMode}
                onChange={(value) =>
                  genericOnChangeHandler({
                    fieldName: "postalCode",
                    value,
                  })
                }
                error={dirty && validationResults.formInvalidState.postalCode}
              />
            </Grid>
            {createMode ? (
              <Grid item xs={12} md={6}>
                <DropdownAsync
                  label={requiredFieldRender(t("address"), true)}
                  onSearch={(street) => onSearchAddress(street)}
                  onSelect={onSelectAddress}
                  onClear={clearAddress}
                  searchTerm={addressSearchTerm}
                  optionLabelKey="address"
                  listItems={addressSuggestions}
                  selected={selectedAddress}
                  disabled={!createMode || addressAutocompleteDisabled}
                  required={true}
                  disableClearable={true}
                  error={dirty && validationResults.formInvalidState.street}
                />
              </Grid>
            ) : (
              <Grid item xs={12} md={6}>
                <TextInput
                  id={`address`}
                  label={requiredFieldRender(
                    t("address"),
                    formEditable && createMode
                  )}
                  required={formEditable && createMode}
                  value={vdsDetails.streetWithNum || ""}
                  disabled={true}
                  onChange={(value) =>
                    genericOnChangeHandler({
                      fieldName: "street",
                      value,
                    })
                  }
                  error={dirty && validationResults.formInvalidState.street}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <TextInput
                id={"address2"}
                label={t("address2")}
                disabled={!formEditable || !createMode}
                value={vdsDetails.address2}
                onChange={(value) =>
                  genericOnChangeHandler({
                    fieldName: "address2",
                    value,
                  })
                }
              />
            </Grid>
          </Grid>

          <Typography variant="h5" component={"h3"} gutterBottom mt={4} mb={2}>
            {t("sectionNotes")}
          </Typography>
          <Grid container direction={"row"} spacing={2} mb={2}>
            <Grid item xs={12} md={12}>
              <TextInput
                id={`deliveryNotes`}
                label={t("deliveryNotes")}
                required={false}
                value={vdsDetails.shippingNote}
                disabled={!formEditable}
                multiline
                rows={6}
                maxLength={1000}
                onChange={(value) =>
                  genericOnChangeHandler({
                    fieldName: "shippingNote",
                    value,
                  })
                }
              />
            </Grid>
          </Grid>
        </form>

        {!formSubmitting && (
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            spacing={4}
            mt={2}
          >
            <BackButton onClick={backToDataTable} />
            {!createMode && (
              <CancelButton
                editable={formEditable}
                disabled={formSubmitting}
                onClick={() =>
                  cancelClickHandler({
                    cleanVds: dsInitialState,
                  })
                }
              />
            )}
            {createMode && (
              <ClearFormButton
                editable={formEditable}
                disabled={formSubmitting}
                onClick={() =>
                  clearFormClickHandler({
                    cleanVds: dsInitialState,
                  })
                }
              />
            )}
            <SubmitButton
              editable={formEditable}
              disabled={
                !dirty || validationResults.formInvalid || formSubmitting
              }
              onClick={() =>
                submitClickHandler({
                  dsIdString,
                  vdsDetails,
                  userAttributes,
                  fpt: selectedFptText,
                  t,
                  allDsLocations,
                  setDsDataLoading,
                })
              }
            />
            <EditButton editable={formEditable} onClick={editClickHandler} />
          </Stack>
        )}
        {formSubmitting && <LoaderPage />}
      </div>
    </>
  );
};

VDSManagementForm.propTypes = {
  dsIdString: PropTypes.string.isRequired,
  setVds: PropTypes.func,
  backToDataTable: PropTypes.func,
};

export default VDSManagementForm;

const statusOptions = [
  {
    value: "true",
    label: <VDSStatusField status={true} />,
  },
  {
    value: "false",
    label: <VDSStatusField status={false} />,
  },
];

export function getProvStateOptions(country) {
  return country === "US"
    ? STATES.map((state) => {
        return {
          value: state.code,
          label: state.name,
        };
      })
    : PROVINCES.map((province) => {
        return {
          value: province.code,
          label: province.name,
        };
      });
}

/**
 * Takes the address attributes from ArcGis, and applies mutations in order to update the form, and prep the data to send to the API.
 * Accepts a null parameter, which is used when clearing the address dropdown.
 * @param {{StAddr: string, StName: string, StPreType: string, StType: string, StPreDir: string, StDir: string, AddNum: string, City: string, Postal: string, PostalExt: string, X: number, Y: number}|null} attributes
 */
function getMutatedAddress(attributes) {
  const result = {
    street: undefined,
    streetNum: undefined,
    latitude: undefined,
    longitude: undefined,
  };
  // attributes will be null if we are clearing the address dropdown.
  // return an empty object to clear the internal state.
  // only populate the object above with fields we want to clear - leave city/postal alone.
  if (!attributes) {
    console.log("no address attributes received, clearing data");
    return result;
  }
  // joins "truthy" (i.e. strings with text) parts of the address with a space
  result.street = [
    attributes.StName,
    attributes.StPreType,
    attributes.StType,
    attributes.StPreDir,
    attributes.StDir,
  ]
    .filter(Boolean)
    .join(" ");
  result.streetNum = attributes.AddNum;
  result.latitude = attributes.Y ? attributes.Y.toString() : "";
  result.longitude = attributes.X ? attributes.X.toString() : "";
  // only overwrite city/postal if we've received values from ArcGis
  if (attributes.City) result.city = attributes.City;
  if (attributes.Postal)
    result.postalCode = `${attributes.Postal} ${attributes.PostalExt}`;
  return result;
}
