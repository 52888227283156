import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { NAV_PATHS, NAV_STATES } from "../../2.0/constants";
import { AccessProvision } from "../../2.0/pages/access-provision/AccessProvision";
import AllocationsPage from "../../2.0/pages/allocation-management/AllocationPage";
import VDSManagement from "../../2.0/pages/ds-management/DsManagementPage";
import OrderManagementPage from "../../2.0/pages/order-management/OrderManagementPage";
import { ProductRequestPage } from "../../2.0/pages/product-request/ProductRequestPage";
import WastageInventoryPage from "../../2.0/pages/wi-management/WastageInventoryPage";
import { getNavStateOnRefresh } from "../../2.0/utils/routerUtils";
import Hero from "./Hero/";
import "./home.scss";
import LandingPage from "./LandingPage";
import NavigationBar from "./NavigationBar/";
import RouterShell from "./RouterShell";

const Home = ({ ariaHidden, setAriaHidden }) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const lang = currentLocale === "fr-CA" ? "fr" : "en";

  // eslint-disable-next-line no-restricted-globals
  const navStateOnRefresh = getNavStateOnRefresh(location.pathname, lang);
  const [nav, setNav] = useState(
    navStateOnRefresh ? navStateOnRefresh : NAV_STATES.HOME
  );
  const setOrderNavWrapper = (nav) => {
    setNav(nav);
  };

  return (
    <>
      <Router>
        <RouterShell nav={nav} />
        <Hero ariaHidden={ariaHidden} />
        <NavigationBar
          nav={nav}
          setNav={setOrderNavWrapper}
          ariaHidden={ariaHidden}
        />
        <Switch>
          <Route path={`/${NAV_PATHS.PREVIOUS[lang]}`}>
            <OrderManagementPage mode={"HIST"} clear={true} />
          </Route>

          <Route path={`/${NAV_PATHS.CURRENT[lang]}`}>
            <OrderManagementPage mode={"CURR"} clear={true} />
          </Route>

          <Route path={`/${NAV_PATHS.ORDER[lang]}`}>
            <ProductRequestPage pageMode={"CREATE"} />
          </Route>

          <Route path={`/${NAV_PATHS.HOME[lang]}`}>
            <LandingPage
              ariaHidden={ariaHidden}
              setAriaHidden={setAriaHidden}
              nav={nav}
              setNav={setOrderNavWrapper}
            />
          </Route>

          <Route path={`/${NAV_PATHS.ALLOCATION[lang]}`}>
            <AllocationsPage />
          </Route>

          <Route path={`/${NAV_PATHS.VDS[lang]}`}>
            <VDSManagement />
          </Route>

          <Route path={`/${NAV_PATHS.WASTAGE_INVENTORY[lang]}`}>
            <WastageInventoryPage />
          </Route>

          <Route path={`/${NAV_PATHS.ACCESS_PROVISION[lang]}`}>
            <AccessProvision />
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default Home;
