import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { initialDateFilterState } from "../../../components/DataTable/DateFilter/DateFilter";
import { useUserAccessFilterContext } from "../../../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { parseDate, parseEpochDateTime } from "../../../utils/dateUtils";
import { getCall } from "../../../utils/requests/apiCalls";
import apiUrls from "../../../utils/requests/apiUrls";

/**
 * Fetches the Allocation data from SAP, and does some minor processing to the response.
 * @param {string} currentLocale
 * @param {string} provCode
 * @param {number} division
 * @param {string} reportingDateFrom - filters the response by reporting date, only returning items on and AFTER the specified date. format: YYYY-MM-DD
 * @param {string} reportingDateTo - filters the response by reporting date, only returning items on and BEFORE the specified date. format: YYYY-MM-DD
 * @param t
 */
const fetchInventoryList = async ({
  currentLocale,
  provCode,
  division,
  reportingDateFrom,
  reportingDateTo,
  t,
}) => {
  console.log("[fetchInventoryList] fetching");

  const params = [`provCode=${provCode}`, `division=${division}`];
  if (reportingDateFrom) {
    params.push(`reptFromDate=${reportingDateFrom}`);
  }
  if (reportingDateTo) {
    params.push(`reptToDate=${reportingDateTo}`);
  }

  const url = `${apiUrls.inventory}?${params.join("&")}`;

  const result = await getCall(url);

  if (!result || result.status !== 200) {
    console.error(`[fetchInventoryList] failed to fetch: `, result);
    return [];
  }

  let resultJson = null;
  try {
    resultJson = await result.json();
  } catch (e) {
    console.error("[fetchInventoryList] failed to parse result: ", e);
    return [];
  }

  if (!resultJson || !resultJson.results || !resultJson.results.length) {
    console.error("[fetchInventoryList] result contains no data: ", resultJson);
    return [];
  }

  const resultProcessed = resultJson.results
    .sort((a, b) => {
      if (a.InvReptDate > b.InvReptDate) return -1;
      if (a.InvReptDate < b.InvReptDate) return 1;
      return 0;
    })
    .map((inventory) => {
      return {
        InvReptDate: parseDate(inventory["InvReptDate"], currentLocale),
        MaterialName: inventory["to_MaterialText"].results.find(
          (result) =>
            result["Language"] === (currentLocale === "fr-CA" ? "FR" : "EN")
        ).MaterialName,
        MaterialGroup: inventory["MaterialGroup"],
        Del: inventory["Del"]
          ? currentLocale === "fr-CA"
            ? "Oui"
            : "Yes"
          : currentLocale === "fr-CA"
          ? "Non"
          : "No",
        Batch: inventory["Batch"],
        ExpiryDate: parseDate(inventory["ExpiryDate"], currentLocale),
        AvailableStock: t("intlNumber", { val: inventory["AvailableStock"] }),
        Uom: inventory["Uom"],
        Material: inventory["Material"],
        Filename: inventory["Filename"],
        // SubmitUser: inventory["SubmitUser"],
        SubmitDate: parseEpochDateTime(
          inventory["SubmitDateTime"],
          currentLocale
        ),
      };
    });
  console.log(
    `[fetchInventoryList] fetched ${resultProcessed.length} items (example): `,
    resultProcessed[0]
  );

  return resultProcessed;
};

export const useFetchInventoryList = (isDateFilterEnabled) => {
  const currentLocale = useTranslation().i18n.language;
  const [inventoryData, setInventoryData] = useState([]);
  const [inventoryDataLoading, setInventoryDataLoading] = useState(true);
  const { selectedFptText, selectedDivision } = useUserAccessFilterContext();
  const [fetchedFptDivision, setFetchedFptDivision] = useState({
    fpt: undefined,
    division: undefined,
    lang: currentLocale,
  });

  // date filter in this API pertains to Reporting Date
  const [dateFilter, setDateFilter] = useState(
    isDateFilterEnabled ? initialDateFilterState() : {}
  );
  const { t } = useTranslation();
  // forces reload when fpt/division selection changes
  useEffect(() => {
    if (
      fetchedFptDivision.fpt !== selectedFptText ||
      fetchedFptDivision.division !== selectedDivision ||
      fetchedFptDivision.lang !== currentLocale
    )
      setInventoryDataLoading(true);
  }, [fetchedFptDivision, selectedDivision, selectedFptText, currentLocale]);

  useEffect(() => {
    setInventoryDataLoading(true);
  }, [dateFilter]);

  useEffect(() => {
    if (inventoryDataLoading) {
      if (!selectedFptText || !selectedDivision)
        return console.log(
          "[fetchInventoryList] unable to refresh, missing UM selections"
        );

      fetchInventoryList({
        currentLocale: currentLocale,
        provCode: selectedFptText,
        division: selectedDivision,
        reportingDateFrom: isDateFilterEnabled && dateFilter.fromDate,
        reportingDateTo: isDateFilterEnabled && dateFilter.toDate,
        t,
      }).then((result) => {
        setInventoryData(result);
        setFetchedFptDivision({
          fpt: selectedFptText,
          division: selectedDivision,
          lang: currentLocale,
        });
        setInventoryDataLoading(false);
      });
    } else {
      console.log("[fetchInventoryList] skipping load, dataLoading is false");
    }
  }, [
    inventoryDataLoading,
    selectedDivision,
    selectedFptText,
    currentLocale,
    t,
  ]);

  return {
    inventoryData,
    setInventoryData,
    inventoryDataLoading,
    setInventoryDataLoading,
    setDateFilter,
    dateFilter,
  };
};
