import { useTranslation } from "react-i18next";
import { useUserAccessFilterContext } from "../../../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { parseDate, parseEpochDateTime } from "../../../utils/dateUtils";
import { getCall } from "../../../utils/requests/apiCalls";
import apiUrls from "../../../utils/requests/apiUrls";

export const useFetchWastageListByReportDate = () => {
  const currentLocale = useTranslation().i18n.language;
  const { t } = useTranslation();
  const { selectedFptText: provCode, selectedDivision: division } =
    useUserAccessFilterContext();

  const fetchWastageList = async ({ reportingDateFilter }) => {
    console.log(
      `[fetchWastageListByReportDate] fetching - reportingDateFilter: ${reportingDateFilter}`
    );
    const result = await getCall(
      `${apiUrls.wastage}?provCode=${provCode}&division=${division}&wasReptDate=${reportingDateFilter}`
    );
    if (!result || result.status !== 200) {
      console.error(`[fetchWastageListByReportDate] failed to fetch: `, result);
      return [];
    }

    let resultJson = null;
    try {
      resultJson = await result.json();
    } catch (e) {
      console.error(
        "[fetchWastageListByReportDate] failed to parse result: ",
        e
      );
      return [];
    }

    if (!resultJson || !resultJson.results || !resultJson.results.length) {
      console.error(
        "[fetchWastageListByReportDate] result contains no data: ",
        resultJson
      );
      return [];
    }

    const resultProcessed = resultJson.results.map((wastage) => {
      return {
        WasReptDate: parseDate(wastage["WasReptDate"], currentLocale),
        WastageDate: parseDate(wastage["WastageDate"], currentLocale),
        MaterialName: wastage["to_MaterialText"].results.find(
          (item) =>
            item["Language"] === (currentLocale === "fr-CA" ? "FR" : "EN")
        ).MaterialName,
        MaterialGroup: wastage["MaterialGroup"],
        MaterialNumber: wastage["Material"],
        Batch: wastage["Batch"],
        ExpiryDate: parseDate(wastage["ExpiryDate"], currentLocale),
        Category: wastage["to_WasCategory"].results.find(
          (item) => item["SPRAS"] === (currentLocale === "fr-CA" ? "FR" : "EN")
        ).CAT_DESCRIPTION,
        Explanation: wastage["to_WasExplanation"].results.find(
          (item) => item["SPRAS"] === (currentLocale === "fr-CA" ? "FR" : "EN")
        ).EXPL_DESCRIPTION,
        WasQty: t("intlNumber", { val: wastage["WasQty"] }),
        Uom: wastage["Uom"],
        Filename: wastage["Filename"],
        // SubmitUser: wastage["SubmitUser"],
        SubmitDate: parseEpochDateTime(
          wastage["SubmitDateTime"],
          currentLocale
        ),
      };
    });

    console.log(
      `[fetchWastageListByReportDate] fetched ${resultProcessed.length} items (example): `,
      resultProcessed[0]
    );
    return resultProcessed;
  };

  return { fetchWastageList };
};
