import { Box, Link, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PHAC_EMAIL, PHAC_PHONE } from "../../../2.0/constants";

/**
 * renders support info
 * @param {boolean} supportInfoOnly - only show the support / contact info only
 * @returns {JSX.Element} - Support info block
 */

export const SupportInfo = ({ supportInfoOnly = false }) => {
  const { t, i18n } = useTranslation(["home"]);
  const currentLocale = i18n.language;
  return (
    <Box mb={4}>
      {!supportInfoOnly && (
        <>
          <Typography mb={1}>{t("purposeBody1")}</Typography>
          <ul>
            <li>
              <Typography mb={2}>{t("purposeBody2")}</Typography>
            </li>
          </ul>

          <Typography mb={2}>{t("purposeBody3")}</Typography>
        </>
      )}
      <Typography mb={2}>{t("purposeBody4")}</Typography>
      <Typography>
        {t("email")}
        {currentLocale === "fr-CA" ? " : " : ": "}
        <Link href={`mailto:${PHAC_EMAIL}`}>{PHAC_EMAIL}</Link>
      </Typography>
      <Typography>
        {t("phone")}
        {currentLocale === "fr-CA" ? " : " : ": "}
        {PHAC_PHONE.join(` ${t("and")}/${t("or")} `)}
      </Typography>
    </Box>
  );
};

SupportInfo.prototypes = {
  supportInfoOnly: PropTypes.bool,
};
