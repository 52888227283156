export const allocationManagement = {
  tab: "Allocation Management",
  title: "Request redistribution of your allocation",
  subTitle:
    "You can request adjustments to your product allocations by shifting allocations between delivery weeks, or drawing from / adding to your reserve.",
  notAllowedPage: "Due to your FPT selection, this page is not available.",
  guidelinesIntro: "Please follow the guidelines below:",
  guideline1: "Allocations cannot be increased overall",
  guideline2:
    "Front loading allocations of a product type to an earlier week is not permitted. You can only make requests to move allocations to subsequent weeks, or to your reserve",
  guideline3:
    "Allocation swaps between FPTs are not permitted within the Portal. Please contact the SEU if you come to an agreement with another FPT",
  guideline4:
    "To make a change request for a product type not shown in the dropdown below, please contact the SEU",
  guideline5:
    'Note that "GAC", "CSC" and "CAF" FPT users are NOT eligible to make allocation change requests',
  toUseIntro: "To use this form:",
  toUse1: "Select a product type from the dropdown below",
  toUse2:
    'You can reduce your allocation(s) for a delivery week by entering a negative value in the "New requested changes" field. This quantity will automatically be added to your reserve',
  toUse3:
    'To redistribute allocations from your reserve to a subsequent delivery week, enter a positive value in the "New requested changes" field',
  toUse4:
    'The "New requested changes to reserve" field will show the total quantity change to your current reserve',
  toUse5:
    'Click "Submit change request" to submit the request for approval. Note that there is no guarantee your request will be accommodated',
  toUse6:
    'Once the change request is approved by PHAC, the "Approved changes", "Currently available allocations" and "Currently available reserve" fields will update',
  titleChangeRequest: "Allocation change request",
  originalAllocations: "Original allocations",
  carriedForward: "Carried forward",
  adjustmentsAndApprovedChanges: "Adjustments and approved changes",
  adjustmentsByPhac: "Adjustments by PHAC",
  yourApprovedChanges: "Your approved changes",
  placedOrders: "Placed orders",
  approvedAllocations: "Currently available allocations",
  newRequestedChanges: "New requested changes",
  pendingChanges: "Previous changes pending approval",
  allocationAfterApproval: "Available allocations after approval",
  titleReserveAllocation: "Reserve and Current Allocations",
  noDeliveryWeekText:
    "No open Delivery Weeks for the selected product type: {{selectedProduct}}",
  approvedChangeToReserve: "Currently available reserve",
  approvedChangeToReserveTip:
    "This field will only update after the change request has been approved",
  requestedChangeToReserve: "New requested changes to reserve",
  newReserveAfterApproval: "Available reserve after approval",
  undoChanges: "Undo changes",
  overLimitNotice:
    'You have entered a change that exceeds the available allocations for a week, so it has been autocorrected to the highest possible value, considering your reserve and the changes requested in previous weeks. Please note that "front loading" by taking from a future week into a previous week is not permitted.',
  underLimitNotice:
    "You have attempted to withdraw too many allocations from a week, so it has been autocorrected to the lowest possible value.",
  reserveError:
    "You cannot submit a change request for an amount greater than your allocation. Please ensure your reductions are equal to or more than your additions to other weeks in order to submit your change request.",
  lockedError:
    "Allocation redistribution request failed. This is due to allocation tables being locked by another user. Please try again later or contact the NOC for immediate assistance.",
  submitRequest: "Submit change request",
};
