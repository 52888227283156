export const home = {
  tab: "Home",
  headerTitle: "Getting vaccines to all people of Canada",
  headerSubtitle: "Let’s take the first steps towards a better tomorrow",
  welcomeHeader: "Welcome to VaccineConnect",
  welcomeBody1:
    "This portal allows you to request vaccines for your community, enabling the people of Canada to get vaccinated safely and efficiently.",
  welcomeBody2:
    "Your key to using the portal is the Job Aid. It takes you through the process of requesting vaccines and checking your orders’ progress with easy-to-follow, step-by-step instructions. Please download it and read it thoroughly prior to using the site.",
  jobAidButton: "Download Job Aid",
  overviewHeader: "Site Overview",
  overviewBody:
    "The portal features the following sections, all easily accessible through the tabs across the top menu.",
  purposeHeader: "Our purpose",
  purposeBody1:
    "VaccineConnect is a secured environment that transmits information directly to the Vaccine Logistics National Operations Centre (NOC) of the Public Health Agency of Canada (PHAC), responsible for the logistics of COVID-19 vaccine distribution in Canada. The portal is designed to:",
  purposeBody2: "Facilitate COVID-19 vaccine requests",
  purposeBody3:
    "Developed to streamline information sharing between federal, provincial and territorial (FPT) jurisdictions and PHAC in a user-friendly way, the portal is a joint collaboration between PHAC, Health Canada and Shared Services Canada.",
  purposeBody4:
    "If you have any questions or concerns about this portal, please feel free to contact us at Vaccine Connect Support.",
  lineItemSummary: "Order Summary",
  lineItemSummaryBody:
    "The table below summarizes the number of line items included in your orders by status. Please note that any line items that are cancelled, or rejected are not included.",
  lineItemTableWaitingForApproval: "WAITING FOR APPROVAL",
  lineItemTableApprovedOrSentToLSP: "APPROVED/ SENT TO LSP",
  lineItemTableInTransit: "IN TRANSIT",
  lineItemTableDelivered: "DELIVERED",
  availableReserve: "Available Reserve and Current Allocations",
  availableReserveSummaryBody:
    "The table below summarizes the number of allocations available in the reserve and for the current delivery week by product.",
  availableReserveTableProductType: "Product Type",
  availableReserveTableCurrentlyAvailable: "Current Reserve",
  availableReserveTableTotalCurrentAllocation:
    "Total Allocation Currently Available",
  availableReserveTableEmpty: "No available reserves.",
};
