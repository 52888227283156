import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";

export default function CustomizedMenuButton({ menuItems = [] }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };
  const { t } = useTranslation("common");
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="customized-menu-button"
        aria-controls={open ? "customized-menu-button" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {t("options")}
      </Button>
      <Menu
        id="customized-menu-button"
        MenuListProps={{
          "aria-labelledby": "customized-menu-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.value}
            disabled={item.disabled}
            disableRipple
            onClick={() => {
              handleClose();
              item.onClick();
            }}
          >
            {item.icon && item.icon()}
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
