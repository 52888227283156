import { accessProvisioning } from "./en/accessProvisioning";
import { allocationManagement } from "./en/allocationManagement";
import { common } from "./en/common";
import { dsManagement } from "./en/dsManagement";
import { home } from "./en/home";
import { login } from "./en/login";
import { orderManagement } from "./en/orderManagement";
import { productRequest } from "./en/productRequest";
import { wiManagement } from "./en/wiManagement";

export const en = {
  // each of these is a "namespace" and should be added to the 'ns' config in initI18n.js
  accessProvisioning,
  allocationManagement,
  common,
  dsManagement,
  home,
  orderManagement,
  productRequest,
  wiManagement,
  login,
};
