import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserAccessFilterContext } from "../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { getCall } from "../utils/requests/apiCalls";
import apiUrls from "../utils/requests/apiUrls";

/**
 * Builds allocation data for a given period. A period can be a delivery week or a reserve.
 * @param {object} rawAllocationData - the raw data for the period
 * @param {boolean} reserveMode - if true, will not try to consider placed orders
 * @returns {object}
 */
function buildAllocationData(rawAllocationData, { reserveMode = false }) {
  const result = {
    rawAllocationData,
    netOriginal: 0,
    placed: 0,
    carryForward: 0,
    adjustments: 0,
    approved: 0,
    approvedAndPending: 0,
    approvedAndAdjustments: 0,
    pending: 0,
    netWithApproved: 0,
    netWithPlacedApproved: 0,
    netWithPlacedApprovedPending: 0,
  };

  if (!rawAllocationData) return result;

  result.netOriginal = rawAllocationData["AllocQty"] || 0; // the original amount before any modifications
  result.placed = reserveMode
    ? 0 // for reserve, we will completely ignore any placed order values
    : -rawAllocationData["OrdersQty"] || 0; // sum of orders that have been placed, will be negative
  result.carryForward = reserveMode
    ? 0 // for reserve, carry forward is disabled
    : rawAllocationData["CarryForwardQty"] || 0; // the amount that is being carried forward into this week from prev weeks
  result.adjustments = rawAllocationData["AdjustQty"] || 0; // any adjustments made by NOC
  result.approved = rawAllocationData["DO2Qty"] || 0; // approved changes to allocations
  result.approvedAndAdjustments = result.approved + result.adjustments; // approved changes plus NOC adjustments
  result.approvedAndPending = rawAllocationData["FPTQty"] || 0; // total sum of changes users have requested, including approved changes
  result.pending = result.approvedAndPending - result.approved; // sum of changes users have requested, not including approved changes
  result.netWithApproved = rawAllocationData["TotalAllocQty"] || 0; // original amount + approved changes
  result.netWithPlacedApproved = reserveMode
    ? rawAllocationData["TotalAllocQty"] || 0 // for reserve, we are not to use AvailableQty, so we use this instead
    : rawAllocationData["AvailableQty"] || 0; // original amount + approved changes - placed orders
  result.netWithPlacedApprovedPending =
    result.netWithPlacedApproved + result.pending; // original amount + approved changes - placed orders + pending changes

  return result;
}

/**
 * Builds allocation data for a given delivery week.
 * @param {object} week
 * @returns {object}
 */
function buildAllocationDataForWeek(week) {
  const rawAllocationData = week["allocationData"];

  return buildAllocationData(rawAllocationData, { reserveMode: false });
}

function buildDeliveryWeeksForProduct(deliveryWeeks, sku) {
  return deliveryWeeks.map((week) => {
    // const firstDay = dayjs
    //   .tz(week["StartWeek"], "YYYY-MM-DD", "Canada/Eastern")
    //   .local();
    // const lastDay = dayjs
    //   .tz(week["DeliveryWeek"], "YYYY-MM-DD", "Canada/Eastern")
    //   .local();

    const submissionDeadline = dayjs
      .tz(
        `${week["SubmissionEndDate"]} ${week["SubmissionEndTime"] || "12:00"}`,
        "YYYY-MM-DD H:mm",
        "Canada/Eastern"
      )
      .local();
    const amendmentDeadline = dayjs
      .tz(
        `${week["AmdmentEndDate"]} ${week["AmdmentEndTime"] || "12:00"}`,
        "YYYY-MM-DD H:mm",
        "Canada/Eastern"
      )
      .local();

    const firstDay = dayjs(week["StartWeek"]);
    const lastDay = dayjs(week["DeliveryWeek"]);

    const pastSubmissionDeadline = submissionDeadline < dayjs();
    const pastAmendmentDeadline = amendmentDeadline < dayjs();
    const PeriodNumber = week["PeriodNumber"];
    const Modifier = Number(week["Modifier"]);
    const periodAndModifier =
      PeriodNumber + (Modifier !== 1 ? ` (mod ${Modifier})` : "");
    const allocationData = buildAllocationDataForWeek(week);
    return {
      rawData: week,
      PeriodNumber,
      Modifier,
      periodAndModifier,
      SKUNumber: Number(week["SKUNumber"]),
      weekIdentifier: Number(`${PeriodNumber.substring(1)}${Modifier}`), // W2240 mod 1 will become 22401, useful for comparisons
      firstDay,
      lastDay,
      submissionDeadline,
      pastSubmissionDeadline,
      amendmentDeadline,
      pastAmendmentDeadline,
      dateRange: `${firstDay.format("ll")} - ${lastDay.format("ll")}`,
      dateRangeLong: `${firstDay.format("LL")} - ${lastDay.format("LL")}`,
      allocationRemainingOriginal: allocationData.netWithPlacedApproved,
      allocationData,
    };
  });
}

/**
 *
 * @param {'E'|'F'} sapLang - language code sent to SAP
 * @param {string} provCode
 * @param {number} division
 */
const fetchSkuSet = async ({ sapLang, provCode, division, currentLocale }) => {
  console.log("[fetchProductList] fetching");
  const result = await getCall(
    `${apiUrls.skuSet}/products/listing/${provCode}?sapLang=${sapLang}&division=${division}&incOrders=Y`
  );
  if (!result || result.status !== 200) {
    console.error(`[fetchProductList] failed to fetch: `, result);
    return undefined;
  }

  let resultJson = null;
  try {
    resultJson = await result.json();
  } catch (e) {
    console.error("[fetchProductList] failed to parse result: ", e);
    return [];
  }

  if (!resultJson.data || !resultJson.data["productListing"]) {
    console.error("[fetchProductList] result contains no data: ", resultJson);
    return [];
  }

  const products = resultJson.data["productListing"];
  const productsExtras = resultJson.data["skuSet"]["extra"]["d"]["results"];
  const resultProcessed = Object.keys(products)
    .map((key) => {
      const product = products[key];
      const productExtras = productsExtras.find(
        (extra) => product["SKUNumber"] === extra["Product"]
      );
      const snomed = productExtras ? productExtras["to_zzMara"]["SNOMED"] : "";
      const din = productExtras
        ? Number(productExtras["to_zzMara"]["DIN"]) !== 0
          ? Number(productExtras["to_zzMara"]["DIN"])
          : currentLocale === "fr-CA"
          ? "ND"
          : "N/A"
        : "";
      const reserve = product["reserve"];
      const isPfizer = productExtras
        ? productExtras["ManufacturerNumber"] === "1"
        : false;
      const deliveryWeeks = buildDeliveryWeeksForProduct(
        product["deliveryWeeks"],
        product.SKUNumber
      );
      const productSkuDinSnomed = `${product["SKUDescription"]} (SKU ${Number(
        product["SKUNumber"]
      )}) (DIN ${din}) (SNOMED ${
        snomed ? snomed : currentLocale === "fr-CA" ? "ND" : "N/A"
      })`;
      const endDateString = product["zzProductValidEndDate"];
      const endDate =
        endDateString !== "00000000"
          ? dayjs.tz(endDateString, "YYYY-MM-DD", "Canada/Eastern").local()
          : undefined;
      const isPastEndDate = endDateString !== "00000000" && endDate < dayjs();
      return {
        rawData: { product, productExtras },
        SKUNumber: Number(product["SKUNumber"]),
        SKUDescription: product["SKUDescription"],
        BaseUoM: product["BaseUoM"],
        deliveryWeeks,
        reserve: buildAllocationData(reserve, { reserveMode: true }),
        label: productSkuDinSnomed,
        value: productSkuDinSnomed,
        unitsPerPayload: product["UoMNumerator"] / product["UoMDenominator"],
        isPfizer,
        endDate,
        isPastEndDate,
        snomed,
        din,
      };
    })
    .sort(
      (a, b) =>
        a.SKUDescription.localeCompare(b.SKUDescription) ||
        a.SKUNumber - b.SKUNumber
    );

  console.log(
    `[fetchProductList] fetched ${resultProcessed.length} items (example): `,
    resultProcessed[0]
  );

  return resultProcessed;
};

const fetchBom = async () => {
  console.log("[fetchBom] fetching");

  // TODO: fetch using language code?
  const result = await getCall(apiUrls.billOfMaterials);
  if (!result || result.status !== 200) {
    console.error(`[fetchBom] failed to fetch: `, result);
    return undefined;
  }

  let resultJson = null;
  try {
    resultJson = await result.json();
  } catch (e) {
    console.error("[fetchBom] failed to parse result: ", e);
    return [];
  }

  if (!resultJson.results || !resultJson.results.length) {
    console.error("[fetchBom] result contains no data: ", resultJson);
    return [];
  }

  // if we find a BOM, process it by pulling out the relevant data
  const resultProcessed = resultJson.results.map((item) => ({
    rawData: item,
    Material: Number(item["Material"]), // the SKUNumber for the main product
    MaterialGroup: item["MaterialGroup"],
    BillOfMaterialItemUnit: item["BillOfMaterialItemUnit"], // aka BaseUoM
    BaseUoM: item["BillOfMaterialItemUnit"],
    BillOfMaterialItemQuantity: Number(item["BillOfMaterialItemQuantity"]), // used for calculating ratio with the other products in the BOM
    ComponentDescription: item["ComponentDescription"], // aka SKUDescription
    SKUDescription: item["ComponentDescription"],
    BillOfMaterialComponent: Number(item["BillOfMaterialComponent"]), // aka SKUNumber
    SKUNumber: Number(item["BillOfMaterialComponent"]),
    label: `${item["ComponentDescription"]} (SKU ${Number(
      item["BillOfMaterialComponent"]
    )})`,
  }));

  console.log(
    `[fetchBom] fetched ${resultProcessed.length} items (example): `,
    resultProcessed[0]
  );

  return resultProcessed;
};

/**
 * Builds the diluent object for a given product. This is what will have the ratio data.
 * @param {object} selectedProductBom - the selected product's BOM data
 * @param {object[]} productListOptions
 * @return {object}
 */
function getDiluentFromBom(selectedProductBom, productListOptions) {
  // no BOM = no diluent, abort
  if (selectedProductBom.length === 0) return;

  // the BOM will contain multiple entries, we just want the diluent, it will be the main result we return
  const bomDiluent = getSingleBomFromFullBom(selectedProductBom, "DILUENT");

  // next, we just want the product, so that we can calculate the desired ratio
  const bomProduct = getSingleBomFromFullBom(selectedProductBom, "VACCINE");

  if (!bomDiluent || !bomProduct) {
    console.log(
      "[fetchProductList] failed to find diluent+product match: ",
      bomDiluent,
      bomProduct
    );
    return;
  }

  // save ratio to result
  bomDiluent.ratio =
    bomDiluent.BillOfMaterialItemQuantity /
    bomProduct.BillOfMaterialItemQuantity;

  // next we need to find the matching product, because this will contain the delivery week info
  const productMatchingBom = getProductMatchingBom(
    bomDiluent,
    productListOptions
  );

  if (!productMatchingBom) {
    console.log(
      "[fetchProductList] failed to find productMatchingBom: ",
      bomDiluent,
      productListOptions
    );
    return;
  }

  // we've found the data we need, set it and return it
  bomDiluent.productMatchingBom = productMatchingBom;
  bomDiluent.deliveryWeeks = productMatchingBom.deliveryWeeks;

  return bomDiluent;
}

/**
 * Takes the full BOM for a product, and applies a filter with the goal of returning a single item.
 * Anything more or less is unexpected and an error.
 * @param {object} selectedProductBom - the selected product's BOM data
 * @param {string} filter - the type of product to look for within the BOM list
 * @return {object}
 */
function getSingleBomFromFullBom(selectedProductBom, filter) {
  const resultsProduct = selectedProductBom.filter(
    (item) => item.MaterialGroup === filter
  );
  if (resultsProduct.length !== 1)
    console.error(
      `[fetchProductList] failed to find a single ${filter} matching the BOM: `,
      selectedProductBom
    );
  return resultsProduct[0];
}

/**
 * Takes the Product Listing from the API, and looks for a single product matching the Diluent BOM.
 * Anything more or less is unexpected and an error.
 * @param {object} bomDiluent - the BOM representing the diluent, to find the matching product for
 * @param {object[]} productListOptions - all product data
 * @return {object} - the product data object matching the diluent specified in the BOM
 */
function getProductMatchingBom(bomDiluent, productListOptions) {
  const productsFiltered = productListOptions.filter(
    (product) => product.SKUNumber === bomDiluent.BillOfMaterialComponent
  );

  if (productsFiltered.length !== 1)
    console.error(
      `[fetchProductList] failed to find a single product for diluent: `,
      productListOptions,
      bomDiluent
    );
  return productsFiltered[0];
}

/**
 * Takes the productData and bomData, and merges them together. Also builds and merges in the diluent object.
 * @param {object[]} productData
 * @param {object[]} bomData
 * @return {object[]}
 */
function mergeBomIntoProduct(productData, bomData) {
  if (!productData || !bomData) {
    console.error(
      "[fetchProductList] failed to merge BOM into product, no data found",
      productData,
      bomData
    );
    return [];
  }
  return productData.map((product) => {
    const matchingBomData = bomData.filter(
      (bom) => bom.Material === product.SKUNumber
    );
    return {
      ...product,
      bom: matchingBomData,
      diluent: getDiluentFromBom(matchingBomData, productData),
    };
  });
}

export const useFetchProductList = () => {
  const [productListOptions, setProductListOptions] = useState([]);
  const [productDataLoading, setProductDataLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const currentLocale = useTranslation().i18n.language;
  const { selectedFptText, selectedDivision, permissionToken } =
    useUserAccessFilterContext();
  const [fetchedFptDivision, setFetchedFptDivision] = useState({
    fpt: undefined,
    division: undefined,
  });

  const resetProductSelection = () => {
    setSelectedProduct(undefined);
  };

  // forces reload when fpt/division selection changes
  useEffect(() => {
    if (
      fetchedFptDivision.fpt !== selectedFptText ||
      fetchedFptDivision.division !== selectedDivision
    )
      setProductDataLoading(true);
  }, [fetchedFptDivision, selectedDivision, selectedFptText]);

  useEffect(() => {
    async function fetchData() {
      const productData = fetchSkuSet({
        sapLang: currentLocale === "fr-CA" ? "F" : "E",
        provCode: selectedFptText,
        division: selectedDivision,
        currentLocale,
      });
      const bomData = fetchBom();
      return { productData: await productData, bomData: await bomData };
    }

    if (productDataLoading) {
      if (!selectedFptText || !selectedDivision || !permissionToken)
        return console.log(
          "[fetchProductList] unable to refresh, missing UM selections"
        );
      fetchData().then(({ productData, bomData }) => {
        const mergedData = mergeBomIntoProduct(productData, bomData);
        setProductListOptions(mergedData);
        console.log(
          `[fetchProductList] finished processing ${mergedData.length} items (example): `,
          mergedData.find((product) => product.bom.length > 0) || mergedData[0]
        );
        setFetchedFptDivision({
          fpt: selectedFptText,
          division: selectedDivision,
        });
        setProductDataLoading(false);
      });
    } else {
      console.log("[fetchProductList] skipping load, dataLoading is false");
    }
  }, [
    currentLocale,
    permissionToken,
    productDataLoading,
    selectedDivision,
    selectedFptText,
  ]);

  return {
    productListOptions,
    selectedProduct,
    setSelectedProduct,
    resetProductSelection,
    productDataLoading,
    setProductDataLoading,
  };
};
