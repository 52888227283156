import { NAV_PATHS, NAV_STATES } from "../constants";

const getNavStateOnRefresh = (originalPath, lang) => {
  if (originalPath !== "/") {
    if (originalPath === `/${NAV_PATHS.ORDER[lang]}`) {
      return NAV_STATES.ORDER;
    } else if (originalPath === `/${NAV_PATHS.CURRENT[lang]}`) {
      return NAV_STATES.CURRENT;
    } else if (originalPath === `/${NAV_PATHS.PREVIOUS[lang]}`) {
      return NAV_STATES.PREVIOUS;
    } else if (originalPath === `/${NAV_PATHS.ALLOCATION[lang]}`) {
      return NAV_STATES.ALLOCATION;
    } else if (originalPath === `/${NAV_PATHS.HOME[lang]}`) {
      return NAV_STATES.HOME;
    } else if (originalPath === `/${NAV_PATHS.VDS[lang]}`) {
      return NAV_STATES.VDS;
    } else if (originalPath === `/${NAV_PATHS.WASTAGE_INVENTORY[lang]}`) {
      return NAV_STATES.WASTAGE_INVENTORY;
    } else if (originalPath === `/${NAV_PATHS.ACCESS_PROVISION[lang]}`) {
      return NAV_STATES.ACCESS_PROVISION;
      // the é special characters mess up the string comparison, the below is a dirty fix
    } else if (lang === "fr" && originalPath === "/gestion-des-acc%C3%A8s") {
      return NAV_STATES.ACCESS_PROVISION;
    } else {
      return undefined;
    }
  }
};

export { getNavStateOnRefresh };
