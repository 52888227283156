import { useTranslation } from "react-i18next";
import { useUserAccessFilterContext } from "../../../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { parseDate, parseEpochDateTime } from "../../../utils/dateUtils";
import { getCall } from "../../../utils/requests/apiCalls";
import apiUrls from "../../../utils/requests/apiUrls";

export const useFetchInventoryListByReportDate = () => {
  const currentLocale = useTranslation().i18n.language;
  const { t } = useTranslation();
  const { selectedFptText: provCode, selectedDivision: division } =
    useUserAccessFilterContext();

  const fetchInventoryList = async ({ reportingDateFilter }) => {
    console.log(
      `[fetchInventoryListByReportDate] fetching - reportingDateFilter: ${reportingDateFilter}`
    );
    const result = await getCall(
      `${apiUrls.inventory}?provCode=${provCode}&division=${division}&invReptDate=${reportingDateFilter}`
    );
    if (!result || result.status !== 200) {
      console.error(
        `[fetchInventoryListByReportDate] failed to fetch: `,
        result
      );
      return [];
    }

    let resultJson = null;
    try {
      resultJson = await result.json();
    } catch (e) {
      console.error(
        "[fetchInventoryListByReportDate] failed to parse result: ",
        e
      );
      return [];
    }

    if (!resultJson || !resultJson.results || !resultJson.results.length) {
      console.error(
        "[fetchInventoryListByReportDate] result contains no data: ",
        resultJson
      );
      return [];
    }

    const resultProcessed = resultJson.results.map((inventory) => {
      return {
        InvReptDate: parseDate(inventory["InvReptDate"], currentLocale),
        MaterialName: inventory["to_MaterialText"].results.find(
          (result) =>
            result["Language"] === (currentLocale === "fr-CA" ? "FR" : "EN")
        ).MaterialName,
        MaterialGroup: inventory["MaterialGroup"],
        Del: inventory["Del"]
          ? currentLocale === "fr-CA"
            ? "Oui"
            : "Yes"
          : currentLocale === "fr-CA"
          ? "Non"
          : "No",
        Batch: inventory["Batch"],
        ExpiryDate: parseDate(inventory["ExpiryDate"], currentLocale),
        AvailableStock: t("intlNumber", { val: inventory["AvailableStock"] }),
        Uom: inventory["Uom"],
        Material: inventory["Material"],
        Filename: inventory["Filename"],
        // SubmitUser: inventory["SubmitUser"],
        SubmitDate: parseEpochDateTime(
          inventory["SubmitDateTime"],
          currentLocale
        ),
      };
    });

    console.log(
      `[fetchInventoryListByReportDate] fetched ${resultProcessed.length} items (example): `,
      resultProcessed[0]
    );
    return resultProcessed;
  };

  return { fetchInventoryList };
};
