import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DataTable from "../../../components/DataTable/DataTable";
import { parseToCsv } from "../../../components/DataTable/utils/parseToCsv";
import VDSStatusField from "./DsStatusField";

/**
 * transforms the shipTo data object for data table
 * @param dataIn
 * @returns {*[]} - the transformed array
 */
export const transformShipTo = (dataIn) => {
  const dataOut = [];

  Object.keys(dataIn).forEach((shipToId) => {
    const shipToTransformed = {};
    shipToTransformed.id = shipToId;

    Object.keys(dataIn[shipToId]).forEach((field) => {
      if (field !== "contacts") {
        shipToTransformed[field] = dataIn[shipToId][field];
      } else {
        dataIn[shipToId][field].forEach((contact, index) => {
          Object.keys(contact).forEach((contactField) => {
            shipToTransformed[`contact-${contactField}-${index}`] =
              contact[contactField];
          });
        });
      }
    });

    dataOut.push(shipToTransformed);
  });

  return dataOut.sort((a, b) => a.dsName.localeCompare(b.dsName));
};

/**
 * renders a VDS table
 * @param {function} setSelectedDsIdString - set selected DS Ship To Number in string format
 * @param {object[]} allDsLocations - the array of ds locations
 * @returns {JSX.Element} - the component
 * @constructor
 */
const VDSDataTable = ({ setSelectedDsIdString, allDsLocations }) => {
  const { t } = useTranslation("dsManagement");
  const shipToAddressArray = transformShipTo(allDsLocations);
  console.info(">> shipToAddressArray:", shipToAddressArray);

  const columns = [
    {
      name: "dsName",
      text: t("vds"),
      inView: true,
      inCSV: true,
    },
    {
      name: "Ship_To_Number",
      text: t("vdsNumber"),
      inView: true,
      inCSV: true,
    },
    {
      name: "city",
      text: t("city"),
      inView: true,
      inCSV: true,
    },
    {
      name: "province",
      text: t("province"),
      inView: false,
      inCSV: true,
    },
    {
      name: "country",
      text: t("country"),
      inView: false,
      inCSV: true,
    },
    {
      name: "street",
      text: t("address"),
      inView: false,
      inCSV: true,
    },
    {
      name: "address2",
      text: t("address2"),
      inView: false,
      inCSV: true,
    },
    {
      name: "postalCode",
      text: t("postalCode"),
      inView: false,
      inCSV: true,
    },
    {
      name: "preferredLanguage",
      text: t("preferredLanguage"),
      inView: false,
      inCSV: true,
      customCSVRenderer: ({ value }) =>
        value === "EN" ? t("english") : t("french"),
    },
    {
      name: "usePfizer",
      text: t("usePfizer"),
      inView: true,
      customViewRenderer: ({ value }) => (value ? t("yes") : t("no")),
      inCSV: true,
      customCSVRenderer: ({ value }) => (value ? t("yes") : t("no")),
    },
    {
      name: "active",
      text: t("status"),
      inView: true,
      customViewRenderer: ({ value }) => <VDSStatusField status={value} />,
      inCSV: true,
      customCSVRenderer: ({ value }) => (value ? t("active") : t("inactive")),
    },
    {
      name: "division",
      text: t("division"),
      inView: false,
      inCSV: true,
      customCSVRenderer: ({ value }) =>
        value === 2
          ? t("therapeutics")
          : value === 3
          ? t("prophylactics")
          : t("vaccine"),
    },
    {
      name: "delFlag",
      text: t("delFlag"),
      inView: false,
      customViewRenderer: ({ value }) => (value ? t("yes") : t("no")),
      inCSV: true,
      customCSVRenderer: ({ value }) => (value ? t("yes") : t("no")),
    },
    {
      name: "del",
      text: t("del"),
      inView: false,
      inCSV: true,
    },
    {
      name: "contact-name-0",
      text: t("primaryContactName"),
      inView: false,
      inCSV: true,
    },
    {
      name: "contact-phone-0",
      text: t("primaryContactPhone"),
      inView: false,
      inCSV: true,
    },
    {
      name: "contact-extension-0",
      text: t("primaryContactPhoneExt"),
      inView: false,
      inCSV: true,
    },
    {
      name: "contact-email-0",
      text: t("primaryContactEmail"),
      inView: false,
      inCSV: true,
    },
    {
      name: "contact-name-1",
      text: t("secondaryContactName"),
      inView: false,
      inCSV: true,
    },
    {
      name: "contact-phone-1",
      text: t("secondaryContactPhone"),
      inView: false,
      inCSV: true,
    },
    {
      name: "contact-extension-1",
      text: t("secondaryContactPhoneExt"),
      inView: false,
      inCSV: true,
    },
    {
      name: "contact-email-1",
      text: t("secondaryContactEmail"),
      inView: false,
      inCSV: true,
    },
    {
      name: "contact-name-2",
      text: t("tertiaryContactName"),
      inView: false,
      inCSV: true,
    },
    {
      name: "contact-phone-2",
      text: t("tertiaryContactPhone"),
      inView: false,
      inCSV: true,
    },
    {
      name: "contact-extension-2",
      text: t("tertiaryContactPhoneExt"),
      inView: false,
      inCSV: true,
    },
    {
      name: "contact-email-2",
      text: t("tertiaryContactEmail"),
      inView: false,
      inCSV: true,
    },
    {
      name: "shippingNote",
      text: t("deliveryNotes"),
      inView: false,
      inCSV: true,
    },
    {
      name: "actions",
      text: t("actions"),
      inView: true,
      inCSV: false,
      hasFilter: false,
      isSortable: false,
      customViewRenderer: ({ onClick, data }) => (
        <Button onClick={onClick} variant="outlined" id={`view-${data.id}`}>
          {t("view")}
        </Button>
      ),
    },
  ];

  /**
   * renders a create vds button
   * @returns {JSX.Element}
   * @constructor
   */
  const CreateVDSButton = () => {
    const onClick = () => {
      setSelectedDsIdString("*");
    };

    return (
      <Button id="createButton" variant={"contained"} onClick={onClick}>
        {t("createVDS")}
      </Button>
    );
  };

  /**
   * renders the Action Buttons component
   * @returns {JSX.Element}
   * @constructor
   */
  const ActionButtons = () => {
    return (
      <>
        <CreateVDSButton />
      </>
    );
  };

  return (
    <DataTable
      setSelectedItem={(data) => setSelectedDsIdString(data.dsIdString)}
      columns={columns}
      data={shipToAddressArray}
      csvFilenamePrefix={`${t("vdsExportPrefix")}`}
      parseToCsv={parseToCsv}
      ActionButtonsComponent={<ActionButtons />}
      buttonsJustifyContent={"space-between"}
    />
  );
};

export default VDSDataTable;

VDSDataTable.propTypes = {
  setSelectedDsIdString: PropTypes.func.isRequired,
};
