import { Container, Link, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoaderPage } from "../../components/LoaderPage/LoaderPage";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { useUserAccessFilterContext } from "../../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { PHAC_EMAIL } from "../../constants";
import { useSkipContentId } from "../../hooks/useSkipContentId";
import InventoryDataTable from "./components/InventoryDataTable";
import WastageDataTable from "./components/WastageDataTable";
import { getCanUseInventoryWastagePage } from "./utils/getCanUseWastageInventoryPage";

const WastageInventoryPage = () => {
  const wastageLoading = false;
  const inventoryLoading = false;
  const somethingIsLoading = inventoryLoading || wastageLoading;
  const skipContentId = useSkipContentId();

  const { t } = useTranslation("wiManagement");
  const [tabSelected, setTabSelected] = useState("Inventory");
  const { selectedDivision, selectedFptText } = useUserAccessFilterContext();
  const { canUseInventoryPage, canUseWastagePage } =
    getCanUseInventoryWastagePage(selectedFptText, selectedDivision);

  useEffect(() => {
    setTabSelected("Inventory");
  }, [selectedFptText, selectedDivision]);

  // check if fpt and div has inventory or wastage permission
  return canUseInventoryPage || canUseWastagePage ? (
    somethingIsLoading ? (
      <LoaderPage />
    ) : (
      <Container maxWidth={false} id={skipContentId}>
        <br />
        <Stack
          mt={3}
          direction={"row"}
          justifyContent="flex-start"
          alignItems={"center"}
          mb={0}
          spacing={1}
        >
          <Button
            variant={tabSelected === "Inventory" ? "contained" : "outlined"}
            onClick={() => setTabSelected("Inventory")}
          >
            {t("inventoryButton")}
          </Button>
          {canUseWastagePage ? (
            <Button
              variant={tabSelected === "Wastage" ? "contained" : "outlined"}
              onClick={() => setTabSelected("Wastage")}
            >
              {t("wastageButton")}
            </Button>
          ) : (
            ""
          )}
        </Stack>
        <PageHeader
          title={t(`${tabSelected.toLowerCase()}Title`)}
          marginTop={2}
          description={
            tabSelected === "Inventory"
              ? t("inventoryDescription")
              : t("wastageDescription")
          }
        />
        {tabSelected === "Inventory" ? (
          <InventoryDataTable canUseInventoryPage={canUseInventoryPage} />
        ) : (
          <WastageDataTable canUseWastagePage={canUseWastagePage} />
        )}
      </Container>
    )
  ) : (
    <Container>
      <PageHeader title={t("tab")} />
      <Stack mb={5}>
        <Typography>
          {`${t("notReleasedText")} `}
          <Link href={`mailto:${PHAC_EMAIL}`}>{PHAC_EMAIL}</Link>
        </Typography>
      </Stack>
    </Container>
  );
};

export default WastageInventoryPage;
