import { Box, TextField, Tooltip } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { limitNonNumericCharInput } from "../../../utils/limitNonNumericCharInput";

/**
 *
 * @param {object} allocationTableData
 * @param {function} handleAllocationRequestTableChange
 * @returns {JSX.Element}
 * @constructor
 */
export const AllocationsTable = ({
  allocationTableData,
  handleAllocationRequestTableChange,
}) => {
  const { t } = useTranslation("allocationManagement");

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("deliveryWeek")}</TableCell>
            <TableCell>{t("originalAllocations")}</TableCell>
            <TableCell>{t("adjustmentsAndApprovedChanges")}</TableCell>
            <TableCell>{t("placedOrders")}</TableCell>
            <TableCell>{t("carriedForward")}</TableCell>
            <TableCell>{t("approvedAllocations")}</TableCell>
            <TableCell>{t("pendingChanges")}</TableCell>
            <TableCell>{t("newRequestedChanges")}</TableCell>
            <TableCell>{t("allocationAfterApproval")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(allocationTableData)
            .filter(
              (weekIdentifier) =>
                // do not display the reserve in the table
                weekIdentifier !== "99991"
            )
            .map((weekIdentifier) => {
              const allocation = allocationTableData[weekIdentifier];
              const rowHasBeenChanged = allocation.requested !== 0;
              return (
                <TableRow
                  key={`row-${weekIdentifier}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Tooltip
                      title={`${t("sapPeriodNumber")}: ${
                        allocation.periodAndModifier
                      }`}
                      placement={"top"}
                      arrow
                    >
                      <Box>{allocation.dateRange}</Box>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {t("intlNumber", {
                      val: allocation.allocationData.netOriginal,
                    })}
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        <center>
                          {t("adjustmentsByPhac")}:{" "}
                          {t("intlNumber", {
                            val: allocation.allocationData.adjustments,
                          })}
                          <br />+<br />
                          {t("yourApprovedChanges")}:{" "}
                          {t("intlNumber", {
                            val: allocation.allocationData.approved,
                          })}
                          <br />=<br />
                          {t("adjustmentsAndApprovedChanges")}:{" "}
                          {t("intlNumber", {
                            val: allocation.allocationData
                              .approvedAndAdjustments,
                          })}
                        </center>
                      }
                      placement={"top"}
                      arrow
                    >
                      <Box>
                        {t("intlNumber", {
                          val: allocation.allocationData.approvedAndAdjustments,
                        })}
                      </Box>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {t("intlNumber", { val: allocation.allocationData.placed })}
                  </TableCell>
                  <TableCell>
                    {t("intlNumber", {
                      val: allocation.allocationData.carryForward,
                    })}
                  </TableCell>{" "}
                  <TableCell>
                    {t("intlNumber", {
                      val: allocation.allocationData.netWithPlacedApproved,
                    })}
                  </TableCell>
                  <TableCell>
                    {t("intlNumber", {
                      val: allocation.allocationData.pending,
                    })}
                  </TableCell>
                  <TableCell>
                    <TextField
                      key={`changeBox-${weekIdentifier}`}
                      sx={{ width: 100 }}
                      type="number"
                      size={"small"}
                      color={rowHasBeenChanged ? "secondary" : "primary"}
                      focused={rowHasBeenChanged}
                      value={allocation.requested}
                      onChange={(e) => {
                        handleAllocationRequestTableChange(
                          e.target.value,
                          allocation.weekIdentifier
                        );
                      }}
                      onKeyDown={(e) => limitNonNumericCharInput(e)}
                    />
                  </TableCell>
                  <TableCell>
                    {t("intlNumber", {
                      val:
                        allocation.allocationData.netWithPlacedApprovedPending +
                        allocation.requested,
                    })}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
