import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { Auth } from "aws-amplify";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserAccessFilter } from "../../2.0/components/UserAccessFilter/UserAccessFilter";
import Logo from "../../assets/images/goc_logo.svg";
import { Context } from "../../context/Context.jsx";
import strings from "../../utils/strings.js";
import "./header.scss";

const Header = ({ ariaHidden, loggedIn }) => {
  const { checkAuth } = useContext(Context);
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const lang = currentLocale === "fr-CA" ? "fr" : "en";

  const handleLangChange = () => {
    const currentLocale = i18n.language;
    const newLocale = currentLocale === "fr-CA" ? "en-CA" : "fr-CA";
    i18n.changeLanguage(newLocale);
  };

  const signout = () => {
    Auth.signOut();
    sessionStorage.clear();
    checkAuth();
    window.location.href = "/";
  };

  return (
    <header className="header" aria-hidden={ariaHidden}>
      <div className="header__container">
        {/* Important: for Accessibility: noValidate (along with component="form" stops browser tooltips to appear if required */}
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
          component="form"
          noValidate
        >
          <Grid item xs>
            <img
              src={Logo}
              alt={`${strings.phacLink["en"]} / ${strings.phacLink["fr"]}`}
              className="header__container__logo"
            />
          </Grid>
          {loggedIn && <UserAccessFilter />}
          <Grid item md={"auto"}>
            <Button
              onClick={handleLangChange}
              variant="outlined"
              component={"a"}
            >
              {strings.langToggle[lang].desktop}
            </Button>
          </Grid>
          <Grid item md={"auto"}>
            <Button onClick={signout} variant="outlined" component={"a"}>
              {loggedIn
                ? strings.signout[lang]
                : strings.login.component.button[lang]}
            </Button>
          </Grid>
        </Grid>
      </div>
    </header>
  );
};

export default Header;
