export const orderManagement = {
  tabHistory: "Order History",
  titleHistory: "Order History",
  subTitleHistory: "Historical order information", //"View past orders",
  tabCurrent: "Order Management",
  titleCurrent: "Order Management",
  subTitleCurrent: "Select product type to get started", //"Manage placed orders",
  viewExisting: "View existing order",
  viewExistingSubtitle: "You are currently viewing order {{orderNumber}}",
  csvExportPrefix: "order_history_export",
  csvExportOM: "Order_Management_Export",
  csvExportOH: "Order_History_Export",

  chooseRequestedDeliveryWeek: "Choose requested delivery week",
  chooseOrderNo: "Choose order number",
  chooseProductTypes: "Choose product type(s)",

  productType: "Product type",
  requestedDeliveryWeek: "Requested delivery week",
  allocationRemaining: "Allocation remaining",
  doses: "Doses",
  packs: "Packs",
  localTime: "Local time",
  orderNo: "Order #",
  lineItemNo: "Line item #",
  productDistributionSite: "Product delivery site",
  totalDosesPacks: "Total doses/packs",
  requestedDeliveryDate: "Requested delivery date",
  rushOrderFlag: "Rush order",
  bulkRecreateOrders: "Bulk recreate orders",

  trackingNumber: "Tracking number",
  lotNumber: "Lot #",
  expiryDate: "Expiry date",
  actualDeliveryDate: "Actual delivery date",

  productSKU: "Product SKU",
  snomed: "SNOMED",
  din: "DIN",
  quantityUnitOfMeasure: "Quantity unit of measure",
  unitsOfMeasure: "Unit of measure",
  fptReferenceNumber: "FPT reference number",
};
