import _ from "lodash";
import joinArrayValues from "./joinArrayValues";
/**
 * Applies filters to the data, and returns the filtered array.
 * @param {Array} data - the array of data to filter/sort
 * @param {Object.<string, string>} filters - the filters to apply to the data
 * @param t - the useTranslation function
 * @returns {Array}
 */
export function getFilteredData(data, filters, t) {
  let filteredData = _.cloneDeep(data);
  Object.keys(filters).forEach((filterKey) => {
    filteredData = filteredData.filter((row) => {
      if (
        row[filterKey]
          .toString()
          .toLowerCase()
          .includes(filters[filterKey].toString().toLowerCase())
      )
        return row;

      //  French translation seems to change the data comparisons from boolean to 'Oui' in the data.
      if (
        (filters[filterKey] === "Oui" && row[filterKey] === true) ||
        (filters[filterKey] === "Non" && row[filterKey] === false)
      )
        return row;

      if (
        (filterKey === "active" &&
          row[filterKey] === true &&
          filters[filterKey].toLowerCase() === t("active").toLowerCase()) ||
        (row[filterKey] === false &&
          filters[filterKey].toLowerCase() === t("inactive").toLowerCase())
      )
        return row;

      // if we reach here, filter out the item
      return null;
    });
  });
  return filteredData;
}

/**
 * @param {Array} data - the array of data to filter/sort
 * @param {string} columnName - the column to process, if not set, work with all columns
 * @param {Object.<string, string>} filters - the filters to apply to the data
 * @param t - the useTranslation function
 */
export function getDropdownOptions(data, columnName, filters, t) {
  let filteredData = _.cloneDeep(data);

  // handle filters for basic columns active/usePfizer
  if (columnName === "active") return [t("active"), t("inactive")];
  if (columnName === "usePfizer") return [t("yes"), t("no")];

  filteredData = getFilteredData(filteredData, filters, t);

  filteredData = getUniqueItemsByColumn(filteredData, columnName);
  return filteredData.length ? filteredData : [];
}

/**
 * Pulls out only the column's property, sorts, and filters to unique items,
 * to prevent duplicates in the filter dropdown.
 * @param {Array} data - the array of data to make unique
 * @param {string} columnName - the name of the column to work with
 * @returns {Array}
 */
function getUniqueItemsByColumn(data, columnName) {
  let result = data
    .map((item) => item[columnName])
    .map((item) => joinArrayValues(item));
  // unique values only
  result = [...new Set(result)];
  // if it is a date, sort in descending order (newest dates first)
  if (/^\d\d\d\d-\d\d-\d\d$/.test(String(result[0])))
    result = result.sort((a, b) => {
      return a > b ? -1 : a < b ? 1 : 0;
    });
  // otherwise sort normally (normalizing strings)
  else
    result = result.sort((a, b) => {
      if (typeof a === "string" && typeof b === "string") {
        a = a.toLowerCase();
        b = b.toLowerCase();
      }
      return a > b ? 1 : a < b ? -1 : 0;
    });
  return result;
}
