import { Alert, Link, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../components/TextInput/TextInput";
import { LOGIN_STATES } from "../../../constants";

export const MfaPage = ({
  userName,
  confirmMfa,
  verificationCode,
  setVerificationCode,
  mfaError,
  setLoginState,
  isSubmitting,
}) => {
  const { t } = useTranslation(["login"]);

  const timeoutSeconds = 180; // 3 minutes is 180 seconds
  const [submitTime] = useState(dayjs());
  const [mfaExpiryTime] = useState(submitTime.add(timeoutSeconds, "seconds"));
  const [timeLeftSeconds, setTimeLeftSeconds] = useState(timeoutSeconds);
  const [timeLeftMinutes, setTimeLeftMinutes] = useState(2);
  const [timeLeftExtraSeconds, setTimeLeftExtraSeconds] = useState("59");

  useEffect(() => {
    function updateTimeLeft() {
      const timeLeftMs = mfaExpiryTime.diff(dayjs());
      const _timeLeftSeconds = Math.floor(
        dayjs.duration(timeLeftMs, "ms").asSeconds()
      );
      setTimeLeftSeconds(_timeLeftSeconds);
      const timeLeftMinutes = Math.floor(_timeLeftSeconds / 60);
      setTimeLeftMinutes(timeLeftMinutes);
      const extraSeconds = _timeLeftSeconds % 60;
      const secondsPadded = String(extraSeconds).padStart(2, "0");
      setTimeLeftExtraSeconds(secondsPadded);
    }
    updateTimeLeft();
    setInterval(() => updateTimeLeft(), 1000);
  }, [mfaExpiryTime]);

  useEffect(() => {
    if (document.getElementById("verification-code")) {
      document.getElementById("verification-code").focus();
    }
  }, []);

  return (
    <Stack spacing={2}>
      <Typography>
        {t("verifyMfa")} <strong>{userName}</strong>
      </Typography>
      <Typography>{t("mfaCta")}</Typography>
      <Typography>
        {timeLeftSeconds > 0
          ? t("mfaExpiring", { timeLeftMinutes, timeLeftExtraSeconds })
          : t("mfaExpired")}
      </Typography>
      <TextInput
        id="verification-code"
        label={`${t("verification")} (${t("required")})`}
        required
        value={verificationCode}
        onChange={(value) => setVerificationCode(value)}
        onEnter={confirmMfa}
        autoCompleteValue={"one-time-code"}
      />
      <Button
        children={<>{t("confirmMfa")}</>}
        variant={"contained"}
        id="login-button-mfa"
        onClick={confirmMfa}
        disabled={verificationCode === "" || isSubmitting}
        size={"large"}
      />
      {mfaError && (
        <Alert severity="error">{t("invalidVerificationCode")}</Alert>
      )}
      <Typography>
        {t("back")}{" "}
        <Link
          id="back-button"
          tabIndex="0"
          href="#"
          onClick={() => {
            setLoginState(LOGIN_STATES.LANDING);
          }}
        >
          {t("button")}
        </Link>
      </Typography>
    </Stack>
  );
};

MfaPage.propTypes = {
  userName: PropTypes.string.isRequired,
  confirmMfa: PropTypes.func.isRequired,
  verificationCode: PropTypes.string.isRequired,
  setVerificationCode: PropTypes.func.isRequired,
  mfaError: PropTypes.bool.isRequired,
  setLoginState: PropTypes.func.isRequired,
};
