/**
 * Build and returns the initial allocation table state depending on the selectedProduct.
 * @param {object} selectedProduct
 * @param {object[]} productListOptions
 * @return {{}}
 */
export const initialAllocationTableBuilder = (
  selectedProduct,
  productListOptions
) => {
  if (!selectedProduct) return {};
  let allocationTableData = {};
  productListOptions
    .filter((product) => product.SKUNumber === selectedProduct.SKUNumber)
    .forEach((product) => {
      // handle reserve, since it is not stored in the deliveryWeeks array
      allocationTableData["99991"] = {
        allocationData: product.reserve,
        requested: 0, // this gets updated by the table as the user makes changes
        PeriodNumber: "W9999",
        Modifier: 1,
        weekIdentifier: 99991,
      };

      // handle regular deliveryWeeks
      product.deliveryWeeks
        .filter(
          (week) =>
            // do not include weeks past submission deadline
            !week.pastSubmissionDeadline
        )
        .forEach((week) => {
          // skip week if no valid allocation data for this week
          if (!week.allocationData) return;

          allocationTableData[week.weekIdentifier] = {
            allocationData: week.allocationData,
            requested: 0, // this gets updated by the table as the user makes changes
            PeriodNumber: week.PeriodNumber,
            Modifier: week.Modifier,
            periodAndModifier: week.periodAndModifier,
            weekIdentifier: week.weekIdentifier,
            dateRange: week.dateRange,
          };
        });
    });
  return allocationTableData;
};

/**
 * filter the data to only show products with actionable delivery weeks and do not include Pfizer products
 * @param {*} products
 * @returns filtered products
 */
export const filterProductsByActionableDeliverWeeks = (products) => {
  return (
    products
      .filter((product) => {
        const hasDeliveryWeeks = product.deliveryWeeks.length > 0;
        // const anyWeekIsEditable = product.deliveryWeeks.some(
        //   (week) => !week.pastSubmissionDeadline
        // );
        const anyWeekHasAllocation = product.deliveryWeeks.some(
          (week) =>
            week.allocationRemainingOriginal > 0 ||
            week.allocationData.netOriginal > 0
        );
        const reserveHasAllocation =
          product.reserve.netOriginal > 0 ||
          product.reserve.netWithPlacedApproved > 0;
        return (
          hasDeliveryWeeks &&
          // anyWeekIsEditable &&
          (anyWeekHasAllocation || reserveHasAllocation)
        );
      })
      // exclude Pfizer products
      .filter((product) => !product.isPfizer)
  );
};

export const getTableDataDifference = (x, y) => {
  let difference = {};
  if (x && y) {
    const keysX = Object.keys(x);
    const keysY = Object.keys(y);

    if (keysX.length === keysY.length) {
      for (let i = 0; i < keysY.length; i++) {
        let isAllocationEqual = true;
        isAllocationEqual &&=
          x[keysY[i]].allocations === y[keysY[i]].allocations;
        isAllocationEqual &&= x[keysY[i]].approved === y[keysY[i]].approved;
        isAllocationEqual &&=
          x[keysY[i]].netAllocations === y[keysY[i]].netAllocations;
        isAllocationEqual &&=
          Number(x[keysY[i]].requested) === y[keysY[i]].requested;

        if (!isAllocationEqual) {
          difference[keysY[i]] = x[keysY[i]];
        }
      }
    }
  }
  return difference;
};
