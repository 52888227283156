import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import cn from "classnames";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    width: "130px",
  },
  dot: {
    fontSize: "20px",
  },
  active: {
    color: "#5ECD1A",
  },
  inactive: {
    color: "#A6A1A1",
  },
}));

/**
 * renders a VDS table
 * @param {boolean} status - set selected vds state
 * @returns {JSX.Element} - the component
 * @constructor
 */
const VDSStatusField = ({ status }) => {
  const { t } = useTranslation("dsManagement");
  const classes = useStyles();

  return (
    <Grid
      container
      direction={"row"}
      alignItems={"center"}
      className={classes.root}
    >
      <Grid item md={2}>
        <div
          className={cn(
            classes.dot,
            status ? classes.active : classes.inactive
          )}
        >
          ●
        </div>
      </Grid>
      <Grid item md={10}>
        {status ? t("active") : t("inactive")}
      </Grid>
    </Grid>
  );
};

export default VDSStatusField;

VDSStatusField.propTypes = {
  status: PropTypes.bool.isRequired,
};
