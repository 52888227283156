export const common = {
  actions: "Actions",
  active: "Active",
  add: "Add",
  all: "All",
  amendmentDateDeadline: "Amendment deadline",
  and: "and",
  auto: "Auto",
  back: "Back",
  boxQuantity: "Box quantity",
  cancel: "Cancel",
  chooseProductType: "Choose product type",
  clearForm: "Clear form",
  close: "Close",
  confirm: "Confirm",
  confirmRequest: "Confirm request",
  create: "Create",
  delete: "Delete",
  deleted: "Deleted",
  deliveryWeek: "Delivery week",
  dialogConfirmMessage: "Are you sure you would like to submit this request?",
  dialogConfirmTitle: "Confirm changes",
  diluent: "Diluent",
  division: "Division",
  dosesPerPayload: "Doses per payload",
  doses_one: "Dose",
  doses_other: "Doses",
  edit: "Edit",
  email: "Email",
  empty: "Empty",
  english: "English",
  exportToCsv: "Export table to CSV",
  fpt: "FPT",
  french: "French",
  idleTitle: "You are about to be logged out",
  idleBody:
    "For security reasons, you are about to be logged out as you have been idle for some time. Please return to this page within two minutes to continue your session.",
  inactive: "Inactive",
  intlNumber: "{{val, number}}",
  loading: "Loading...",
  location: "Delivery site",
  locations: "Delivery sites",
  missing: "Missing",
  no: "No",
  noAccess: "No access",
  none: "None",
  okay: "Okay",
  or: "or",
  options: "Options",
  packsCourses_one: "Pack (treatment course)",
  packsCourses_other: "Packs (treatment courses)",
  packsPerBox: "Packs per box",
  packs_one: "Pack",
  packs_other: "Packs",
  payloads: "Payloads",
  payloads_one: "Payload",
  payloads_other: "Payloads",
  phac: "PHAC",
  phone: "Phone",
  placeRequest: "Place request",
  product: "Product",
  prophylactics: "Prophylactics",
  quantity: "Quantity",
  remove: "Remove",
  requestError: "Error submitting request",
  requestErrorDebug: "Error details from system",
  requestErrorText:
    "There was an unexpected error while submitting your request. For assistance, please contact the Strategic Enablement Unit (SEU) at vaccine-connect-support-connexion-vaccin@phac-aspc.gc.ca, and (if possible) include a screenshot of this error message.",
  requestSubmitted: "Request submitted",
  requestSubmittedText:
    "Your updates have been received and will be processed.",
  required: "required",
  results: "Results",
  rowsPerPage: "Rows per page",
  sapDsNumber: "SAP DS number",
  sapPeriodNumber: "SAP period number",
  sapSkuNumber: "SAP SKU number",
  save: "Save",
  search: "Search",
  select: "Select",
  status: "Status",
  submit: "Submit",
  submitRequest: "Submit request",
  therapeutics: "Therapeutics",
  typeThreeForSearch: "Type at least three characters to start search",
  unitsPerBox: "Units per box",
  unitsPerPayload: "Units per payload",
  units_one: "Unit",
  units_other: "Units",
  vaccine: "Vaccine",
  validateRequest: "Validate request",
  vialsPerPayload: "Vials per payload",
  vials_one: "Vial",
  vials_other: "Vials",
  view: "View",
  yes: "Yes",
  skipLinkButton: "Skip to main content",
  headerTitle: "VaccineConnect",
  twelveMonths: "12 months",
  sixMonths: "6 months",
  fourMonths: "4 months",
  fourWeeks: "4 weeks",
  dateRange: "Date Range",
  custom: "Custom",
  viewAll: "View all",
  from: "From",
  to: "To",
  reportingDateRange: "Reporting date range",
  requestedDeliveryDateRange: "Requested delivery date range",
  filterBy: "Filter by {{filterName}}",
};
