import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Autocomplete,
  Checkbox,
  Chip,
  InputAdornment,
  Stack,
  tableCellClasses,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomizedMenuButton from "../../../components/CustomizedMenuButton/CustomizedMenuButton";
import { DialogModal } from "../../../components/Dialog/Dialog";
import { SelectInput } from "../../../components/Select/SelectInput";
import { useUserAccessFilterContext } from "../../../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { useTranslateUnits } from "../../../hooks/useTranslateUnits";
import { limitNonNumericCharInput } from "../../../utils/limitNonNumericCharInput";
import { sanitizeInteger } from "../utils/sanitizeInteger";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "bold",
  },
}));

export const DeliveryWeekLocationAllocator = ({
  deliveryWeek = {},
  updateDeliveryWeekOrder = () => {},
  selectedProduct,
  locations = [],
  removeLocationFromDeliveryWeekOrder = () => {},
  isOrderConfirmed,
  isDiluentSelected,
  copyOrdersFromPreviousWeek,
}) => {
  const { t } = useTranslation(["productRequest", "dsManagement", "common"]);
  const { selectedDivision } = useUserAccessFilterContext();
  const { getTranslatedUnitsWithValue } = useTranslateUnits();
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectLocationsIsOpen, setSelectLocationsIsOpen] = useState(false);

  const unitsPerPayloadProduct = selectedProduct?.unitsPerPayload;
  const unitsPerPayloadDiluent =
    selectedProduct?.diluent?.productMatchingBom?.unitsPerPayload;

  // clear selected locations when selected product changes
  useEffect(() => setSelectedLocations([]), [selectedProduct]);

  // filters location dropdown to only show active locations
  const filteredLocations = locations.filter((location) => location.active);

  // handles marking locations as selected when added to an order, also pre-selects locations when an existing order is being viewed
  useEffect(() => {
    const locationsToSelect = Object.keys(deliveryWeek.orders).map(
      (locationNumber) =>
        locations.find(
          (location) => location.Ship_To_Number === Number(locationNumber)
        )
    );
    setSelectedLocations(locationsToSelect);
  }, [deliveryWeek.orders, locations]);

  // these locations have existing data and should not be removed - must be read only
  const readOnlyLocations = Object.keys(deliveryWeek.orders).filter(
    (locationNumber) =>
      deliveryWeek.orders[locationNumber].existingOrderData !== undefined
  );

  /**
   * Updates the list of orders on location selection.
   * If a location is not present in the list of orders, add it, and use the date/quantity defaults below.
   * @param {number} weekIdentifier
   * @param {{label: string, value: string}[]} locations
   */
  const onLocationSelection = (weekIdentifier, locations) => {
    const clonedOrders = _.cloneDeep(deliveryWeek.orders);
    const newOrders = locations.reduce((newOrders, location) => {
      const existingOrder = clonedOrders[location.value];
      newOrders[location.value] = existingOrder ?? {
        quantityProduct: 0,
        quantityDiluentManual: "", // can't use undefined or else React gets angry
        quantityDiluentAuto: 0,
        allocationIgnoreProduct: 0,
        allocationIgnoreDiluent: 0,
        reqDate: deliveryWeek.reqDateOptions[0].value,
        readOnly: false,
        markForDeletion: false,
        location,
      };
      return newOrders;
    }, {});
    updateDeliveryWeekOrder(weekIdentifier, newOrders);
  };

  /**
   * Removes a location from the list of selected locations.
   * @param {string} locationToRemove - the value (ship to number) of the location to remove.
   */
  const removeLocationFromSelectedLocations = (locationToRemove) => {
    const newSelectedLocations = _.cloneDeep(selectedLocations).filter(
      (location) => location.value !== locationToRemove
    );
    setSelectedLocations(newSelectedLocations);
  };

  /**
   * Updates a location's order given a certain key/value.
   * @param {number} locationValue - the value (key) of the location
   * @param {string} key - the key to update (quantity/reqDate)
   * @param {string|number|boolean} value - the new value of the key
   * @param {Object} options - options for the function
   * @param {boolean} options.enforceInteger - if true, enforces the value to be a positive integer
   */
  const updateLocationOrderByKey = (
    locationValue,
    key,
    value,
    { enforceInteger = false } = {}
  ) => {
    if (!locationValue)
      return console.error(`unable to update order for missing delivery site`);
    const clonedOrders = _.cloneDeep(deliveryWeek.orders);
    if (enforceInteger) value = sanitizeInteger(value);
    clonedOrders[locationValue][key] = value;
    updateDeliveryWeekOrder(deliveryWeek.weekIdentifier, clonedOrders);
  };
  /**
   * Toggles whether an order is marked for deletion.
   * @param {object} order - the order to mark/unmark for deletion
   */
  const toggleMarkOrderForDeletion = (order) => {
    const clonedOrders = _.cloneDeep(deliveryWeek.orders);
    const clonedOrder = clonedOrders[order.location.value];
    const newDeletionState = !clonedOrder.markForDeletion;
    clonedOrder.markForDeletion = newDeletionState;
    clonedOrder.quantityProduct = newDeletionState
      ? 0
      : clonedOrder.existingOrderData.product.originalQuantity;
    clonedOrder.quantityDiluentManual = newDeletionState
      ? 0
      : clonedOrder.existingOrderData.diluent?.originalQuantity || 0;
    updateDeliveryWeekOrder(deliveryWeek.weekIdentifier, clonedOrders);
  };

  const [dsDetailDialogOpen, setDsDetailDialogOpen] = useState(false);
  const [dsDetailDialogContent, setDsDetailDialogContent] = useState([]);

  const weekHasOrders = Object.keys(deliveryWeek.orders).length > 0;
  const anyOrderPlacedAlready = Object.keys(deliveryWeek.orders).some(
    (locationKey) => deliveryWeek.orders[locationKey].existingOrderData
  );
  const allOrdersReadOnly = Object.keys(deliveryWeek.orders).every(
    (locationKey) => deliveryWeek.orders[locationKey].readOnly
  );
  const deliveryWeekReadOnly =
    isOrderConfirmed || (allOrdersReadOnly && weekHasOrders);

  return (
    <>
      <DialogModal
        open={dsDetailDialogOpen}
        handleClose={() => {
          setDsDetailDialogOpen(false);
        }}
        handlePrimaryClick={() => {
          setDsDetailDialogOpen(false);
        }}
        title={t("deliverySiteInformation")}
        bodyTableContent={dsDetailDialogContent}
        primaryButtonText={t("close")}
      />
      <Stack
        direction={{ sm: "row", xs: "column" }}
        spacing={1}
        alignItems="center"
      >
        <Autocomplete
          value={selectedLocations}
          multiple
          fullWidth
          id={`select-locations-${deliveryWeek.weekIdentifier}`}
          options={filteredLocations}
          onChange={(event, values) => {
            setSelectedLocations(values);
            if (!selectLocationsIsOpen)
              onLocationSelection(deliveryWeek.weekIdentifier, values);
          }}
          onClose={() => {
            setSelectLocationsIsOpen(false);
            onLocationSelection(deliveryWeek.weekIdentifier, selectedLocations);
          }}
          onOpen={() => {
            setSelectLocationsIsOpen(true);
          }}
          disableCloseOnSelect
          disableClearable={anyOrderPlacedAlready || undefined}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          // style={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("selectedLocationsLabel")}
              placeholder={t("selectedLocationsPlaceholder")}
              InputLabelProps={{ shrink: true }} // causes label to show up top, so placeholders are visible
            />
          )}
          getOptionDisabled={(option) =>
            readOnlyLocations.includes(option.dsIdString) || !option
          }
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              // sometimes the DS will be undefined, such as if it has been deleted from SAP
              const italicIfDsMissing = !option ? { fontStyle: "italic" } : {};
              // noinspection JSValidateTypes
              return (
                <Chip
                  {...getTagProps({ index })}
                  label={option?.label ?? `(${t("missing").toLowerCase()})`}
                  disabled={
                    readOnlyLocations.includes(option?.dsIdString) || !option
                  }
                  sx={italicIfDsMissing}
                />
              );
            })
          }
          disabled={deliveryWeekReadOnly}
        />
        <CustomizedMenuButton
          key={`menu-week-${deliveryWeek.weekIdentifier}`}
          menuItems={[
            {
              value: "copy",
              label: t("copyFromPrevious"),
              disabled: deliveryWeekReadOnly,
              onClick: () => {
                copyOrdersFromPreviousWeek({
                  weekIdentifierCopyTo: deliveryWeek.weekIdentifier,
                });
              },
            },
          ]}
        />
      </Stack>
      {Object.keys(deliveryWeek.orders || []).length > 0 && (
        <TableContainer>
          <Table size={isDiluentSelected ? "small" : "medium"}>
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("location")}</StyledTableCell>
                {isDiluentSelected && (
                  <StyledTableCell>{t("product")}</StyledTableCell>
                )}
                <StyledTableCell>
                  {selectedDivision !== 2 ? t("payloads") : t("boxQuantity")}
                </StyledTableCell>
                <StyledTableCell align={"center"}>
                  {selectedDivision !== 2
                    ? t("unitsPerPayload")
                    : t("unitsPerBox")}
                </StyledTableCell>
                <StyledTableCell align={"center"}>
                  {t("totalUnitsRequested")}
                </StyledTableCell>
                <StyledTableCell>{t("requestedDate")}</StyledTableCell>
                {!isOrderConfirmed && (
                  <StyledTableCell align="center">
                    {t("actions")}
                  </StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(deliveryWeek.orders || []).map((key) => {
                const order = deliveryWeek.orders[key];
                const location = order.location;
                const orderReadOnly = order.readOnly;

                const missingQuantityProductError =
                  !order.quantityProduct || order.quantityProduct <= 0;
                const missingQuantityDiluentError =
                  (!order.quantityDiluentManual &&
                    !order.quantityDiluentAuto) ||
                  (order.quantityDiluentManual || order.quantityDiluentAuto) <=
                    0;
                const missingDateError = !order.reqDate;

                const productTotalUnits =
                  (order.quantityProduct ?? 0) * unitsPerPayloadProduct;
                const diluentTotalUnits =
                  (order.quantityDiluentManual !== ""
                    ? order.quantityDiluentManual
                    : order.quantityDiluentAuto) * unitsPerPayloadDiluent;

                const actionMenuItems = [
                  {
                    value: "remove",
                    label: order.existingOrderData
                      ? order.markForDeletion
                        ? t("unmarkOrderForDeletion")
                        : t("markOrderForDeletion")
                      : t("remove"),
                    disabled: deliveryWeekReadOnly || orderReadOnly,
                    onClick: () => {
                      if (order.existingOrderData) {
                        toggleMarkOrderForDeletion(order);
                      } else {
                        removeLocationFromSelectedLocations(location.value);
                        removeLocationFromDeliveryWeekOrder(
                          deliveryWeek.weekIdentifier,
                          location.value
                        );
                      }
                    },
                  },
                  {
                    value: "locationInformation",
                    label: t("deliverySiteInformation"),
                    disabled: false,
                    onClick: () => {
                      setDsDetailDialogOpen(true);
                      setDsDetailDialogContent([
                        {
                          key: `${t("dsName", { ns: "dsManagement" })} 1`,
                          value: location.dsName1,
                        },
                        {
                          key: `${t("dsName", { ns: "dsManagement" })} 2`,
                          value: location.dsName2,
                        },
                        {
                          key: t("vdsNumber", { ns: "dsManagement" }),
                          value: location.shipToNumber,
                        },
                        {
                          key: t("division"),
                          value:
                            location.division === 2
                              ? t("therapeutics")
                              : location.division === 3
                              ? t("prophylactics")
                              : t("vaccine"),
                        },
                        {
                          key: t("status"),
                          value: location.active ? t("active") : t("inactive"),
                        },
                        {
                          key: t("usePfizer", { ns: "dsManagement" }),
                          value: location.usePfizer ? t("yes") : t("no"),
                        },
                        {
                          key: t("preferredLanguage", { ns: "dsManagement" }),
                          value:
                            location.preferredLanguage === "FR"
                              ? t("french")
                              : t("english"),
                        },
                        {
                          key: t("delFlag", { ns: "dsManagement" }),
                          value: location.delFlag ? t("yes") : t("no"),
                        },
                        {
                          key: t("address", { ns: "dsManagement" }),
                          value: location.streetWithNum,
                        },
                        {
                          key: `${t("address", { ns: "dsManagement" })} 2`,
                          value: location.address2,
                        },
                        {
                          key: t("city", { ns: "dsManagement" }),
                          value: location.city,
                        },
                        {
                          key: t("province", { ns: "dsManagement" }),
                          value: location.province,
                        },
                        {
                          key: t("postalCode", { ns: "dsManagement" }),
                          value: location.postalCode,
                        },
                        {
                          key: t("primaryContactName", { ns: "dsManagement" }),
                          value: location.primaryContactName,
                        },
                        {
                          key: t("primaryContactPhone", { ns: "dsManagement" }),
                          value: location.primaryContactPhone,
                        },
                        {
                          key: t("extension", { ns: "dsManagement" }),
                          value: location.primaryContactExtension,
                        },
                        {
                          key: t("primaryContactEmail", { ns: "dsManagement" }),
                          value: location.primaryContactEmail,
                        },
                        {
                          key: t("deliveryNotes", { ns: "dsManagement" }),
                          value: location.shippingNote,
                        },
                      ]);
                    },
                  },
                ];

                const orderMarkedForDeletion = order.markForDeletion;
                const strikethroughIfMarkedDeletion = orderMarkedForDeletion
                  ? { textDecoration: "line-through" }
                  : {};
                const italicIfDsMissing = !location
                  ? { fontStyle: "italic" }
                  : {};

                const disabled =
                  deliveryWeekReadOnly ||
                  orderReadOnly ||
                  orderMarkedForDeletion;
                return (
                  <TableRow
                    key={key}
                    sx={{
                      // this removes the horizontal line from the last row of the table
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>
                      <Typography
                        sx={{
                          ...strikethroughIfMarkedDeletion,
                          ...italicIfDsMissing,
                        }}
                      >
                        {location?.label ?? `(${t("missing").toLowerCase()})`}
                      </Typography>
                    </TableCell>
                    {isDiluentSelected && (
                      <TableCell>
                        <Stack spacing={3}>
                          <Typography sx={strikethroughIfMarkedDeletion}>
                            {t("vaccine")}
                          </Typography>
                          <Typography sx={strikethroughIfMarkedDeletion}>
                            {t("diluent")}
                          </Typography>
                        </Stack>
                      </TableCell>
                    )}
                    <TableCell>
                      <Stack spacing={1}>
                        <TextField
                          id={`quantity-product-${key}`}
                          type="number"
                          size={isDiluentSelected ? "small" : "medium"}
                          value={order.quantityProduct.toString()} // toString magically removes leading zeros
                          onChange={(e) => {
                            if (e.target.value.toString().length < 16) {
                              updateLocationOrderByKey(
                                location?.value,
                                "quantityProduct",
                                parseInt(e.target.value),
                                { enforceInteger: true }
                              );
                            }
                          }}
                          onKeyDown={(e) => limitNonNumericCharInput(e)}
                          error={missingQuantityProductError}
                          disabled={disabled}
                          InputProps={
                            missingQuantityProductError && !disabled
                              ? {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <ErrorOutlineIcon color={"error"} />
                                    </InputAdornment>
                                  ),
                                }
                              : {}
                          }
                          sx={{ maxWidth: 100 }}
                        />
                        {isDiluentSelected && (
                          <TextField
                            id={`quantity-diluent-${key}`}
                            type="number"
                            size={isDiluentSelected ? "small" : "medium"}
                            value={order.quantityDiluentManual.toString()} // toString magically removes leading zeros
                            placeholder={`${order.quantityDiluentAuto}${
                              order.quantityDiluentAuto ? ` (${t("auto")})` : ""
                            }`}
                            onChange={(e) =>
                              updateLocationOrderByKey(
                                location?.value,
                                "quantityDiluentManual",
                                parseInt(e.target.value),
                                { enforceInteger: true }
                              )
                            }
                            onKeyDown={(e) => limitNonNumericCharInput(e)}
                            error={missingQuantityDiluentError}
                            disabled={disabled}
                            InputProps={
                              missingQuantityDiluentError && !disabled
                                ? {
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <ErrorOutlineIcon color={"error"} />
                                      </InputAdornment>
                                    ),
                                  }
                                : {}
                            }
                            sx={{ maxWidth: 100 }}
                          />
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack spacing={3}>
                        <Typography
                          align={"center"}
                          sx={strikethroughIfMarkedDeletion}
                        >
                          {getTranslatedUnitsWithValue(
                            unitsPerPayloadProduct,
                            selectedProduct?.BaseUoM
                          )}
                        </Typography>
                        {isDiluentSelected && (
                          <Typography
                            align={"center"}
                            sx={strikethroughIfMarkedDeletion}
                          >
                            {getTranslatedUnitsWithValue(
                              unitsPerPayloadDiluent,
                              selectedProduct?.diluent?.BaseUoM
                            )}
                          </Typography>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack spacing={3}>
                        <Typography
                          align={"center"}
                          sx={strikethroughIfMarkedDeletion}
                        >
                          {getTranslatedUnitsWithValue(
                            productTotalUnits,
                            selectedProduct?.BaseUoM
                          )}
                        </Typography>
                        {isDiluentSelected && (
                          <Typography
                            align={"center"}
                            sx={strikethroughIfMarkedDeletion}
                          >
                            {getTranslatedUnitsWithValue(
                              diluentTotalUnits,
                              selectedProduct?.diluent?.BaseUoM
                            )}
                          </Typography>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <SelectInput
                        disabled={disabled}
                        value={order.reqDate}
                        size={isDiluentSelected ? "small" : "medium"}
                        options={deliveryWeek.reqDateOptions}
                        required
                        error={missingDateError}
                        onChangeValue={(newDate) =>
                          updateLocationOrderByKey(
                            location?.value,
                            "reqDate",
                            newDate
                          )
                        }
                      />
                    </TableCell>
                    {!isOrderConfirmed && (
                      <TableCell align="center">
                        <CustomizedMenuButton
                          key={`menu-order-${key}`}
                          menuItems={actionMenuItems}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
