import { Box, Container, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogModal } from "../../components/Dialog/Dialog";
import { LoaderPage } from "../../components/LoaderPage/LoaderPage";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { useUserAccessFilterContext } from "../../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { useCreateOrder } from "../../hooks/useCreateOrder";
import { useFetchDsLocations } from "../../hooks/useFetchDsLocations";
import { useFetchProductList } from "../../hooks/useFetchProductList";
import { useGetDeliveryWeeks } from "../../hooks/useGetDeliveryWeeks";
import { useSkipContentId } from "../../hooks/useSkipContentId";
import { NewAllocationRequest } from "./components/NewAllocationRequest";
import { OrderMetadata } from "./components/OrderMetadata";
import { ProductRequest } from "./components/ProductRequest";

/**
 *
 * @param {'CREATE'|'VIEW'|'MODIFY'} pageMode
 * @param {object} orderToPopulate - when this is provided, the page will populate using data from a specific order
 * @param {function} backToDataTable - calling this goes back to the order history table
 * @return {JSX.Element}
 * @constructor
 */
export const ProductRequestPage = ({
  pageMode,
  orderToPopulate,
  backToDataTable,
}) => {
  // pageMode is determined only by which of OM/OH the user is coming from.
  // This will affect the entire page, including all line-items and the product info fields at the top.
  // Search the code for #OrderReadOnly for other locations to define related logic.
  const readOnly =
    pageMode === "VIEW" ||
    orderToPopulate?.isRush ||
    orderToPopulate?.OrderGetsToItemNav.every((order) => !order.isUpdatable);

  const sapAction = readOnly
    ? undefined
    : pageMode === "MODIFY"
    ? "CHANGE"
    : "CREATE";

  const { t } = useTranslation(["productRequest", "orderManagement"]);
  const skipContentId = useSkipContentId();
  const { selectedFptText } = useUserAccessFilterContext();
  const [diluentBoxVisibleIfApplicable, setDiluentBoxVisibleIfApplicable] =
    useState(true);
  const [isDiluentSelected, setIsDiluentSelected] = useState(false);
  const [orderIsSubmitting, setOrderIsSubmitting] = useState(false);
  const [productInformation, setProductInformation] = useState({
    fpt: selectedFptText,
    fptRef: "",
    groupNotes: "",
  });
  const [dialogContent, _setDialogContent] = useState({
    title: undefined,
    body: undefined,
    open: false,
    onClose: undefined,
  });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const {
    productListOptions,
    selectedProduct,
    setSelectedProduct,
    resetProductSelection,
    productDataLoading,
    setProductDataLoading,
  } = useFetchProductList();
  const { postOrder } = useCreateOrder({
    action: sapAction,
    existingSalesDocument: orderToPopulate?.Sales_Document,
  });
  const { allDsLocations, dsDataLoading } = useFetchDsLocations();
  const [requestDialogOpen, setRequestDialogOpen] = useState(false);

  const somethingIsLoading = dsDataLoading || productDataLoading;
  const productSelectionDisabled = orderToPopulate !== undefined;

  // handle product/diluent selection using order number
  useEffect(() => {
    if (!orderToPopulate) return;

    if (!productListOptions || productListOptions.length === 0) return;

    console.log(
      `populating data from order ${orderToPopulate.Sales_Document}: `,
      orderToPopulate
    );

    const { productToSelect, diluentToSelect } = getProductsToSelect(
      orderToPopulate,
      productListOptions
    );

    setSelectedProduct(productToSelect);
    setProductInformation({
      fpt: orderToPopulate["Sold_To"],
      fptRef: orderToPopulate["PurchNoC"],
      groupNotes: orderToPopulate["Notes"],
    });

    if (productToSelect && diluentToSelect) {
      setIsDiluentSelected(true);
      setDiluentBoxVisibleIfApplicable(true);
    } else {
      setIsDiluentSelected(false);
      setDiluentBoxVisibleIfApplicable(false);
    }
  }, [
    orderToPopulate,
    productListOptions,
    selectedProduct,
    setSelectedProduct,
  ]);

  const {
    deliveryWeeks,
    updateDeliveryWeekOrder,
    onDeliveryWeekExpansionClick,
    validateAllOrders,
    orderValidationResults,
    removeLocationFromDeliveryWeekOrder,
    isOrderConfirmed,
    setIsOrderConfirmed,
    resetDeliveryWeeks,
    setAllDeliveryWeekExpansionByWeek,
    resetDeliveryWeekDiluentOrders,
    copyOrdersFromPreviousWeek,
  } = useGetDeliveryWeeks(
    selectedProduct,
    isDiluentSelected,
    allDsLocations,
    orderToPopulate
  );

  const filteredDeliveryWeeks = filterDeliveryWeeks(
    deliveryWeeks,
    orderToPopulate,
    readOnly
  );

  // true if any week has order data (quantities entered)
  const orderHasQuantities = deliveryWeeks.some(
    (week) =>
      week.orders &&
      Object.keys(week.orders).length > 0 &&
      Object.keys(week.orders).some((orderKey) => {
        const order = week.orders[orderKey];
        return (
          order.quantityProduct ||
          order.quantityDiluentManual ||
          order.quantityDiluentAuto
        );
      })
  );

  // true if any week has allocation errors
  const orderHasAllocationErrors = deliveryWeeks.some(
    (week) => week.allocationErrorProduct || week.allocationErrorDiluent
  );

  // true if any week has validation errors (these are checked when clicking confirm order)
  const orderHasValidationErrors = orderValidationResults.some(
    (result) => result.valid === false
  );

  /**
   * A utility function for working with the dialog.
   * @param {string?} title
   * @param {string?} body
   * @param {boolean?} clear - if true, clears the dialog and closes it
   * @param {function} onCloseFunc - a function that can be called when closing the dialog
   */
  const setDialogContent = useCallback(
    ({ title, body, clear, onCloseFunc }) => {
      const onCloseFuncFull = () => {
        setDialogContent({ clear: true });
        if (onCloseFunc) onCloseFunc();
      };
      if (clear)
        _setDialogContent({
          title: undefined,
          body: undefined,
          open: false,
          onClose: undefined,
        });
      else
        _setDialogContent({
          title,
          body,
          open: true,
          onClose: onCloseFuncFull,
        });
    },
    []
  );

  /**
   * Clears the page. This is used when something triggers the page to reload (productDataLoading === true).
   */
  const resetPage = useCallback(() => {
    resetDeliveryWeeks();
    resetProductSelection();
    setDialogContent({ clear: true });
    setProductInformation({
      fpt: selectedFptText,
      fptRef: "",
      groupNotes: "",
    });
  }, [
    resetDeliveryWeeks,
    resetProductSelection,
    selectedFptText,
    setDialogContent,
  ]);

  /**
   * Clears the page, and forces a refresh of the data by setting productDataLoading to true.
   * If backToDataTable is set (which it will be if coming from OM/OH), that function is called, navigating the user away from this page.
   */
  const resetAndRefreshPage = useCallback(() => {
    resetPage();
    setProductDataLoading(true);
    if (backToDataTable) backToDataTable({ doRefresh: true });
  }, [backToDataTable, resetPage, setProductDataLoading]);

  /**
   * This handles clearing the page when Division/FPT changes (aka when the product list is reloaded).
   * It only triggers when a product is selected, otherwise it would loop indefinitely.
   */
  useEffect(() => {
    if (productDataLoading && selectedProduct) resetPage();
  }, [productDataLoading, resetPage, selectedProduct]);

  /**
   * After entering their data, users must first validate the order.
   * Then, any weeks with orders will be expanded, any other weeks will be hidden (the logic for the latter is elsewhere).
   */
  const onOrderValidate = () => {
    // we've commented out the filter and now will simply show all weeks with orders expanded
    function getWeeksWithErrors(validationResults) {
      return (
        validationResults
          // .filter((result) => !result.valid)
          .map((result) => result.order.deliveryWeekStartDate)
      );
    }

    validateAllOrders().then((validationResults) => {
      const weeksWithErrors = getWeeksWithErrors(validationResults);
      setAllDeliveryWeekExpansionByWeek(weeksWithErrors);
    });
  };

  /**
   * After confirming their order, users can submit/place it, or go back to edit.
   * This covers the latter. It will expand any weeks that the user has entered order data into, and collapse the rest.
   */
  const onOrderEdit = () => {
    function getWeeksWithOrderData(deliveryWeeks) {
      return deliveryWeeks
        .filter((week) => Object.keys(week.orders).length > 0)
        .map((week) => week.firstDay);
    }

    setIsOrderConfirmed(false);
    const weeksWithData = getWeeksWithOrderData(deliveryWeeks);
    setAllDeliveryWeekExpansionByWeek(weeksWithData);
  };

  /**
   * After confirming their order, users can submit/place it, or go back to edit.
   * This covers the former. It will pull out the validated data from the state, and pass it to the postOrder function.
   */
  const onOrderSubmit = () => {
    function getOrderData(deliveryWeeks) {
      return deliveryWeeks.map((week) => week.order);
    }

    if (orderIsSubmitting) return console.error("order is already submitting");
    setOrderIsSubmitting(true);

    const orderData = getOrderData(orderValidationResults);
    console.log(`submitting orderData to SAP: `, orderData);
    postOrder(orderData, productInformation)
      .then((result) => {
        const anyResultHasError = result.sales_document_id.some(
          (result) => result.error
        );
        if (result.error || anyResultHasError) {
          console.error("error while posting order! ", result);
          const errorDetail = result.sales_document_id
            .map(
              (item) =>
                `\nItem ${item.item_number} [${item.result["Action_Item"]} ${item.result["Target_Qty"]}x SKU ${item.result["SKUNumber"]} to DS ${item.result["Ship_To_Number"]} on ${item.result["ReqDateI"]}]:
                ${item.result["Post_Message"]}`
            )
            .join("\n");
          const errorMessage =
            errorDetail && `\n\n${t("requestErrorDebug")}:\n${errorDetail}`;
          setDialogContent({
            title: t("requestError"),
            body: t("requestErrorText") + errorMessage,
          });
        } else {
          console.log(`successfully posted order, result: `, result);
          setDialogContent({
            title: t("requestSubmitted"),
            body: t("requestSubmittedText"),
            onCloseFunc: resetAndRefreshPage,
          });
        }
      })
      .catch((error) => {
        console.error("unexpected error while posting order! ", error);
        setDialogContent({
          title: t("requestError"),
          body: t("requestErrorText"),
        });
      })
      .finally(() => setOrderIsSubmitting(false));
  };

  const onSelectProduct = async (value) => {
    const selectedProductObject = productListOptions.find(
      (product) => product.value === value
    );
    console.log(`selectedProduct: `, selectedProductObject);
    setSelectedProduct(selectedProductObject);
  };

  const onDiluentToggle = () => {
    const newState = !isDiluentSelected;
    if (!newState) resetDeliveryWeekDiluentOrders();
    setIsDiluentSelected(newState);
  };

  // handles auto-selecting diluent
  useEffect(() => {
    if (!diluentBoxVisibleIfApplicable) return;
    setIsDiluentSelected(selectedProduct?.diluent !== undefined);
  }, [selectedProduct, diluentBoxVisibleIfApplicable]);

  return (
    <Container maxWidth={"lg"} id={skipContentId}>
      <PageHeader
        title={
          !orderToPopulate
            ? t("placeOrderTitle")
            : t("viewExisting", { ns: "orderManagement" })
        }
        description={
          !orderToPopulate
            ? t("placeOrderSubtitle")
            : t("viewExistingSubtitle", {
                ns: "orderManagement",
                orderNumber: orderToPopulate.Sales_Document,
              })
        }
      />
      <DialogModal
        open={dialogContent.open}
        handleClose={dialogContent.onClose}
        handlePrimaryClick={dialogContent.onClose}
        title={dialogContent.title}
        bodyText={dialogContent.body}
        primaryButtonText={t("okay")}
      />
      <DialogModal
        open={confirmDialogOpen}
        handleClose={() => setConfirmDialogOpen(false)}
        title={t("dialogConfirmTitle")}
        bodyText={t("dialogConfirmMessage")}
        primaryButtonText={t("confirmRequest")}
        handlePrimaryClick={() => {
          setConfirmDialogOpen(false);
          onOrderSubmit();
        }}
        secondaryButtonText={t("back")}
        handleSecondaryClick={() => setConfirmDialogOpen(false)}
      />
      {somethingIsLoading ? (
        <LoaderPage />
      ) : (
        <Stack spacing={6}>
          <OrderMetadata
            productInformation={productInformation}
            setProductInformation={setProductInformation}
            readOnly={readOnly}
          />
          {pageMode === "CREATE" && (
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              mb={4}
              spacing={1}
            >
              <Button
                variant={"contained"}
                onClick={() => setRequestDialogOpen(true)}
              >
                {t("newAllocationButton")}
              </Button>
            </Stack>
          )}
          {requestDialogOpen && (
            <NewAllocationRequest
              requestDialogOpen={requestDialogOpen}
              setRequestDialogOpen={setRequestDialogOpen}
              selectedProduct={selectedProduct}
              productOptions={productListOptions}
              setProductDataLoading={setProductDataLoading}
            />
          )}
          <ProductRequest
            isOrderConfirmed={isOrderConfirmed}
            orderValidationResults={orderValidationResults}
            deliveryWeeks={filteredDeliveryWeeks}
            updateDeliveryWeekOrder={updateDeliveryWeekOrder}
            locations={allDsLocations}
            unitsPerPayload={selectedProduct && selectedProduct["UoMNumerator"]}
            diluentBoxVisibleIfApplicable={diluentBoxVisibleIfApplicable}
            isDiluentSelected={isDiluentSelected}
            productSelectionDisabled={productSelectionDisabled}
            onDiluentSelection={onDiluentToggle}
            onSelectProduct={onSelectProduct}
            selectedProduct={selectedProduct}
            productOptions={productListOptions}
            onDeliveryWeekExpansionClick={onDeliveryWeekExpansionClick}
            removeLocationFromDeliveryWeekOrder={
              removeLocationFromDeliveryWeekOrder
            }
            copyOrdersFromPreviousWeek={copyOrdersFromPreviousWeek}
          />
          <Stack direction={"row"} justifyContent={"flex-end"} spacing={4}>
            {orderToPopulate !== undefined && (
              <Button
                variant="contained"
                onClick={() => backToDataTable({ doRefresh: false })}
              >
                {t("back")}
              </Button>
            )}
            {!isOrderConfirmed && !readOnly && (
              <Button
                variant="contained"
                disabled={
                  (!orderHasQuantities && !orderToPopulate) ||
                  orderHasValidationErrors ||
                  orderHasAllocationErrors ||
                  !selectedProduct
                }
                onClick={() => onOrderValidate()}
              >
                {t("validateRequest")}
              </Button>
            )}
            {isOrderConfirmed && (
              <>
                <Button variant="outlined" onClick={() => onOrderEdit()}>
                  {t("editRequest")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setConfirmDialogOpen(true)}
                >
                  {t("submitRequest")}
                </Button>
              </>
            )}
          </Stack>
          <Box />
        </Stack>
      )}
    </Container>
  );
};

/**
 * Filters the whole list of delivery weeks to only what should be displayed.
 * When creating a new order, show weeks with allocation that are in the future.
 * When viewing an existing order, same as above but also including weeks that have order data.
 * @param {object[]} deliveryWeeks
 * @param {object} orderToPopulate
 * @param {boolean} readOnly - when true, only show delivery weeks with order data. when false, also shows weeks in the future with allocation.
 */
function filterDeliveryWeeks(deliveryWeeks, orderToPopulate, readOnly) {
  if (!orderToPopulate) {
    // creating a new order, only show weeks accepting orders - must have allocation and be in the future
    return deliveryWeeks.filter(
      (week) =>
        week.allocationRemainingOriginal > 0 && !week.pastSubmissionDeadline
    );
  } else {
    // editing an existing order - show weeks that have existing order data, plus weeks accepting orders
    const periodsWithOrders = orderToPopulate["OrderGetsToItemNav"].map(
      (order) => order["Period_Number"]
    );
    return deliveryWeeks.filter((week) => {
      const weekContainsDesiredDay = periodsWithOrders.some(
        (periodNumber) => week["PeriodNumber"] === periodNumber
      );
      return (
        weekContainsDesiredDay ||
        (!readOnly &&
          week.allocationRemainingOriginal > 0 &&
          !week.pastSubmissionDeadline)
      );
    });
  }
}

/**
 * @param {object} orderToPopulate
 * @param {object[]} productListOptions
 * @returns {object|undefined} result.productToSelect
 * @returns {object|undefined} result.diluentToSelect
 */
function getProductsToSelect(orderToPopulate, productListOptions) {
  const productSkusToSelect = Array.from(
    new Set(
      orderToPopulate["OrderGetsToItemNav"].map((lineItem) =>
        Number(lineItem.SKUNumber)
      )
    )
  );

  if (productSkusToSelect.length !== 1 && productSkusToSelect.length !== 2) {
    console.error("could not find 1-2 matching SKUs");
    return { productToSelect: undefined, diluentToSelect: undefined };
  }

  const productsToSelect = productSkusToSelect.map((sku) =>
    productListOptions.find((product) => product.SKUNumber === sku)
  );

  let productToSelect, diluentToSelect;
  if (productsToSelect.length === 1) productToSelect = productsToSelect[0];
  else if (productsToSelect.length === 2) {
    // figure out which is product and which is diluent
    if (productsToSelect[0].BaseUoM === "VI") {
      // if [0] is measured in vials, assume it is diluent
      diluentToSelect = productsToSelect[0];
      productToSelect = productsToSelect[1];
    } else {
      // otherwise assume [1] is diluent
      diluentToSelect = productsToSelect[1];
      productToSelect = productsToSelect[0];
    }
  }

  // validate selected diluent matches product's BOM
  if (productToSelect.diluent && diluentToSelect) {
    if (
      productToSelect.diluent.BillOfMaterialComponent !==
      diluentToSelect.SKUNumber
    ) {
      console.error("diluent sku does not match bom sku");
      diluentToSelect = undefined;
    }
  }

  return { productToSelect, diluentToSelect };
}
