import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation(["login"]);

  return (
    <Box padding={6} sx={{ backgroundColor: "#293749" }}>
      <Container maxWidth={"sm"}>
        <Typography variant={"h2"} color={"white"}>
          {t("headerTitle", { ns: "common" })}
        </Typography>
        <Typography variant={"subtitle1"} color={"white"}>
          {t("heroSubTitle")}
        </Typography>
      </Container>
    </Box>
  );
};

export default Hero;
