import { Auth } from "aws-amplify";
import axios from "axios";
import apiUrls from "../../../utils/requests/apiUrls";

async function getBearerToken() {
  let auth;
  try {
    auth = await Auth.currentSession();
  } catch (e) {}
  if (auth && auth.accessToken && auth.accessToken.jwtToken) {
    return auth.accessToken.jwtToken;
  } else {
    return null;
  }
}

/**
 * @param {string[]} emails
 * @param {string} permissionsToken
 * @returns Promise<object[]>
 */
export const getPermsForUser = async (emails = [], permissionsToken) => {
  try {
    const bearer = await getBearerToken();
    const userEmails = emails.map((email) => encodeURIComponent(email));

    if (!emails.length) {
      const url = `${apiUrls.userManagement}/permission/access`;
      const promise = axios.get(url, {
        headers: {
          Authorization: `Bearer ${bearer}`,
          Permissions: `Bearer ${permissionsToken}`,
        },
      });
      return promise
        .then((res) => res.data)
        .then((responses) => responses.flat());
    }

    const promises = userEmails.map((email) => {
      const url = `${apiUrls.userManagement}/permission/access${
        email ? `/${email}` : ""
      }`;
      return axios.get(url, {
        headers: {
          Authorization: `Bearer ${bearer}`,
          Permissions: `Bearer ${permissionsToken}`,
        },
      });
    });

    return Promise.all(promises)
      .then((responses) => responses.map((res) => res.data))
      .then((responses) => responses.flat());
  } catch (e) {
    console.error("Unable to request for access list.", e);
  }
};
