import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Checkbox, Stack, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const HeadFilters = ({
  orderList,
  productListOptions,
  selectedProductTypes,
  setSelectedProductTypes,
  selectedDeliveryWeek,
  setSelectedDeliveryWeek,
  selectedSalesDocument,
  setSelectedSalesDocument,
}) => {
  const { t } = useTranslation("orderManagement");

  const productTypeOptions = [];
  const deliveryWeekOptions = [];
  const salesDocumentOptions = [];

  for (let order of orderList) {
    for (let line of order.OrderGetsToItemNav) {
      const productLine = productListOptions.find(
        (el) => el.SKUNumber === line.SKUNumber
      );

      // take care of the "ProductType" options:
      if (productLine && productLine.SKUDescription) {
        const foundProductTypeOption = productTypeOptions.find(
          (el) => el.sku === line.SKUNumber
        );
        if (!foundProductTypeOption) {
          productTypeOptions.push({
            sku: line.SKUNumber,
            name: productLine.label,
            startDates: [line.Period_StartDate],
          });
        } else {
          foundProductTypeOption.startDates.push(line.Period_StartDate);
        }
      }

      // take care of "DeliveryWeek" options:
      if (
        selectedProductTypes.length === 0 ||
        selectedProductTypes.find(
          (selectedProductType) => selectedProductType === line.SKUNumber
        )
      ) {
        const foundDeliveryWeekOption = deliveryWeekOptions.find(
          (el) => el.startDate === line.Period_StartDate
        );
        if (!foundDeliveryWeekOption) {
          const start = dayjs(line.Period_StartDate).format("ll");
          const end = dayjs(line.Period_EndDate).format("ll");
          deliveryWeekOptions.push({
            startDate: line.Period_StartDate,
            endDate: line.Period_EndDate,
            orderNumbers: [line.Sales_Document],
            name: `${start} - ${end}`,
          });
        } else {
          foundDeliveryWeekOption.orderNumbers.push(line.Period_EndDate);
        }
      }

      // take care of "OrderNumbers" options:
      if (
        (selectedProductTypes.length === 0 ||
          selectedProductTypes.find(
            (selectedProductType) => selectedProductType === line.SKUNumber
          )) &&
        (!selectedDeliveryWeek ||
          selectedDeliveryWeek === line.Period_StartDate)
      ) {
        const foundSalesDocumentOption = salesDocumentOptions.find(
          (el) => el.orderNumber === line.Sales_Document
        );
        if (!foundSalesDocumentOption) {
          salesDocumentOptions.push({
            orderNumber: line.Sales_Document,
            name: line.Sales_Document,
          });
        }
      }
    }
  }
  const sortedProductTypeOptions = productTypeOptions.sort((a, b) =>
    a.name > b.name ? 1 : a.name < b.name ? -1 : 0
  );

  const sortedDeliveryWeekOptions = deliveryWeekOptions.sort((a, b) =>
    a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0
  );

  const sortedSalesDocumentOptions = salesDocumentOptions.sort((a, b) =>
    a.name > b.name ? 1 : a.name < b.name ? -1 : 0
  );

  return (
    <Stack spacing={2} direction={"row"}>
      <Autocomplete
        size={"small"}
        id={`product-dropdown`}
        multiple
        value={selectedProductTypes}
        onChange={(event, value) => {
          setSelectedProductTypes(value);
          setSelectedDeliveryWeek(null);
          setSelectedSalesDocument(null);
        }}
        options={sortedProductTypeOptions.map((el) => el.sku)}
        getOptionLabel={(option) => {
          const found = sortedProductTypeOptions.find(
            (el) => el.sku === option
          );
          return found?.name || "";
        }}
        disableCloseOnSelect
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {props.key}
            </li>
          );
        }}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("chooseProductTypes")}
            placeholder={
              selectedProductTypes.length === 0
                ? `${t("chooseProductTypes")}...`
                : ""
            }
            InputLabelProps={{ shrink: true }} // causes label to show up top, so placeholders are visible
          />
        )}
      />
      <Autocomplete
        size={"small"}
        id={`chooseRequestedDeliveryWeek-dropdown`}
        value={selectedDeliveryWeek}
        onChange={(event, value) => {
          setSelectedDeliveryWeek(value);
          setSelectedSalesDocument(null);
        }}
        options={sortedDeliveryWeekOptions.map((el) => el.startDate)}
        getOptionLabel={(option) => {
          const found = sortedDeliveryWeekOptions.find(
            (el) => el.startDate === option
          );
          return found?.name || "";
        }}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("chooseRequestedDeliveryWeek")}
            placeholder={t("all")}
            InputLabelProps={{ shrink: true }} // causes label to show up top, so placeholders are visible
          />
        )}
      />
      <Autocomplete
        size={"small"}
        id={`chooseOrderNo-dropdown`}
        value={selectedSalesDocument}
        onChange={(event, value) => {
          setSelectedSalesDocument(value);
        }}
        options={sortedSalesDocumentOptions.map((el) => el.orderNumber)}
        getOptionLabel={(option) => {
          const found = sortedSalesDocumentOptions.find(
            (el) => el.orderNumber === option
          );
          return String(found?.name) || "";
        }}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("chooseOrderNo")}
            placeholder={t("all")}
            InputLabelProps={{ shrink: true }} // causes label to show up top, so placeholders are visible
          />
        )}
      />
    </Stack>
  );
};

export default HeadFilters;
