import axios from "axios";
import { useEffect, useState } from "react";
import { getBearerToken } from "../../../utils/getBearerToken";
import apiUrls from "../../../utils/requests/apiUrls";

export const useGetPermissions = () => {
  const [permissionToken, setPermissionToken] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [selectableFpts, setSelectableFpts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const bearer = await getBearerToken();
      const url = `${apiUrls.userManagement}/permission/token`;
      const permissionsURL = `${apiUrls.userManagement}/permission`;
      try {
        const token = await axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${bearer}`,
            },
          })
          .then((res) => res.data.token);

        const access = await axios
          .get(permissionsURL, {
            headers: {
              Authorization: `Bearer ${bearer}`,
              Permissions: `Bearer ${token}`,
            },
          })
          .then((res) => res.data.permissions);

        setPermissions(access);
        setPermissionToken(token);
      } catch (e) {
        console.error("Unable to request for permission token.", e);
      }
    };
    fetchData().then(() => {});
  }, []);

  useEffect(() => {
    let fpts = [];

    if (permissions.length) {
      fpts = permissions
        .filter((perm) => perm.activeInactiveFlag)
        .filter((perm) => perm.access_role_id === 2)
        .map((perm) => ({
          value: perm.Fpt.fptId,
          label: perm.Fpt.fptName,
        }))
        // must sort before filtering, because the filter will check the immediately previous item
        .sort((a, b) => a.label.localeCompare(b.label))
        .filter((perm, i, arr) =>
          i < arr.length - 1 ? perm.value !== arr[i + 1].value : true
        );
      setSelectableFpts(fpts);
    }
  }, [permissions]);

  const permissionsWithActiveAdmin = permissions.filter(
    (perm) => perm.Role.roleId === 2 && perm.activeInactiveFlag
  );

  return {
    permissions,
    permissionsWithActiveAdmin,
    permissionToken,
    selectableFpts,
  };
};
