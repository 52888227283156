import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Auth } from "aws-amplify";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { CookiesProvider } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { DialogModal } from "./2.0/components/Dialog/Dialog";
import { LoaderPage } from "./2.0/components/LoaderPage/LoaderPage";
import { UserAccessFilterContext } from "./2.0/components/UserAccessFilter/contexts/UserAccessFilterContext";
import { AUTH_TIMEOUT_OTHER, AUTH_TIMEOUT_PROD } from "./2.0/constants";
import { useSkipContentId } from "./2.0/hooks/useSkipContentId";
import LoginPage from "./2.0/pages/login/LoginPage";
import getTheme from "./2.0/ThemeProvider";
import { checkAuth } from "./2.0/utils/requests/checkAuth";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import SkipLink from "./components/Home/SkipLink/SkipLink";
import { initialState } from "./context/Context";
import ContextProvider from "./context/ContextProvider";
import "./styles/main.scss";
import strings from "./utils/strings";

function App(props) {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;
  const skipContentId = useSkipContentId();

  const [userAttributes, setUserAttributes] = useState(
    initialState.userAttributes
  );
  const [ariaHidden, setAriaHidden] = useState(initialState.ariaHidden);
  const [loggedIn, setLoggedIn] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);
  const [menuClicked, setMenuClicked] = useState(false);
  const [timeoutDialogVisible, setTimeoutDialogVisible] = useState(false);

  useEffect(() => {
    const fetchCheckAuth = async () => {
      await checkAuth(setLoggedIn, setAuthChecked, setUserAttributes);
    };
    fetchCheckAuth().catch((e) => {
      console.error("Failure to check auth ", e);
    });
  }, []);

  // handles language changes
  useEffect(() => {
    const currentLang = currentLocale === "fr-CA" ? "fr" : "en";
    console.log(`currentLocale: ${currentLocale}, currentLang: ${currentLang}`);
    document.title = strings.title[currentLang];
    document.documentElement.lang = currentLang;
    dayjs.locale(currentLocale.toLowerCase());
  }, [currentLocale]);

  // Notify the user 2 minutes before logout that they're about to be logged out.
  useIdleTimer({
    timeout:
      (props.environment === "prod2" ? AUTH_TIMEOUT_PROD : AUTH_TIMEOUT_OTHER) -
      2 * 60000,
    onIdle: () => setTimeoutDialogVisible(loggedIn),
    onActive: () => setTimeoutDialogVisible(false),
  });

  // Logs the user out after a period of inactivity.
  useIdleTimer({
    timeout:
      props.environment === "prod2" ? AUTH_TIMEOUT_PROD : AUTH_TIMEOUT_OTHER,
    onIdle: async () => {
      setTimeoutDialogVisible(false);
      await Auth.signOut();
      await checkAuth(setLoggedIn, setAuthChecked, setUserAttributes);
    },
  });

  return (
    <ThemeProvider theme={getTheme(currentLocale)}>
      <CookiesProvider>
        <CssBaseline />
        <ContextProvider
          value={{
            environment: props.environment,
            userAttributes,
            setUserAttributes,
            checkAuth,
            ariaHidden,
            setAriaHidden,
            menuClicked,
            setMenuClicked,
          }}
        >
          <div className="app">
            <DialogModal
              open={timeoutDialogVisible}
              title={t("idleTitle")}
              bodyText={t("idleBody")}
              primaryButtonText={t("okay")}
              handlePrimaryClick={() => setTimeoutDialogVisible(false)}
              handleClose={() => setTimeoutDialogVisible(false)}
            />
            <UserAccessFilterContext loggedIn={loggedIn}>
              <SkipLink
                className={"primary skip-link"}
                id={"skip-link"}
                skipTo={`#${skipContentId}`}
              >
                <button type="button">{t("skipLinkButton")}</button>
              </SkipLink>
              <Header ariaHidden={ariaHidden} loggedIn={loggedIn} />
              {authChecked ? (
                <main className="app__main">
                  {!loggedIn ? (
                    <LoginPage
                      {...props}
                      checkAuth={() =>
                        checkAuth(
                          setLoggedIn,
                          setAuthChecked,
                          setUserAttributes
                        )
                      }
                    />
                  ) : (
                    <Home
                      ariaHidden={ariaHidden}
                      setAriaHidden={setAriaHidden}
                    />
                  )}
                </main>
              ) : (
                <LoaderPage />
              )}
              <Footer ariaHidden={ariaHidden} />
            </UserAccessFilterContext>
          </div>
        </ContextProvider>
      </CookiesProvider>
    </ThemeProvider>
  );
}

export default App;
