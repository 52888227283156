export const productRequest = {
  tab: "Product Request",
  placeOrderTitle: "Place a request for product",
  placeOrderSubtitle:
    "Please complete the fields below to create a new request for product.",
  productInformation: "Product Information",
  selectedFPTRegion: "Federal/Province/Territory",
  FPTReferenceNumber: "FPT reference number",
  groupNotes: "Group notes",
  groupNotesHint:
    "You can type notes that are applicable to all product requests below.",
  productRequest: "Product Request",
  addDiluent: "Add diluent",
  allocationRemaining: "Allocation remaining",
  selectedLocationsLabel: "Selected delivery sites",
  selectedLocationsPlaceholder: "Select delivery sites...",
  totalUnitsRequested: "Total units requested",
  requestedDate: "Requested date",
  deliverySiteInformation: "Delivery site information",
  carryForwardTooltip:
    "{{count}} of these {{units}} are carried forward from a previous week.",
  issueWithOrder: "There is an issue with this request. Please resolve.",
  overDsLimitError:
    "You have selected more than 50 delivery sites. Please reduce the number of selected delivery sites before placing your request.",
  quantitiesExceed:
    "The total number of {{productType}} units requested ({{ordersTotal}}) exceed the weekly allocated amount. Please reduce your number of units.",
  editRequest: "Edit request",
  importantNotice: "Important notice",
  currentWeekClosed:
    "Requests for the current week ({{currentWeek}}) closed on {{closedOn}} at {{closedAt}}. Please contact the Strategic Enablement Unit (SEU) for more information. Email: vaccine-connect-support-connexion-vaccin@phac-aspc.gc.ca",
  followingWeeksNoAllocation:
    "The following weeks do not appear below as there is no allocation available for those weeks:",
  markOrderForDeletion: "Mark order for deletion",
  unmarkOrderForDeletion: "Unmark order for deletion",
  copyFromPrevious: "Copy request from previous week",
  newAllocationButton: "Request New Allocations",
  newAllocationTitle: "Submit a Request for New Allocations",
  newAllocationSubmitButton: "Validate Request",
  newAllocationDescription:
    "Please use the form below to request additional allocations for the selected product. Up to 4 requests can be included.",
  newAllocationReserveAndAllocationTitle:
    "Available Reserve and Current Allocations",
  newAllocationReserveText: "Current Reserve: {{reserve}}",
  newAllocationTotalCurrentAllocationText:
    "Total Allocation Currently Available: {{totalAllocation}}",
  newAllocationAllocationError:
    "There is an error with the field(s) highlighted in red. Please update and submit your request again.",
  newAllocationAllocationColumn: "Allocations",
  newAllocationDeliveryDateColumn: "Delivery Date",
  newAllocationAllocationRequest: "Enter Allocation Request",
  newAllocationRequestorEmail: "Requestor Email",
  newAllocationJustification: "Justification",
  newAllocationJustificationHelper:
    "Please provide a justification for new allocations using the space above. Limit {{current}}/254 characters.",
  newAllocationSubmittedTitle: "Request Submitted",
  newAllocationSubmittedDescription:
    "Your request for additional allocations has been submitted. After review, the NOC will confirm if your request has been approved or not.",
  newAllocationReviewDescription:
    "Please review the information entered in the form below before submitting your request for additional allocations.",
  newAllocationRequestTitleError: "Error submitting request",
  newAllocationRequestError:
    "There was an unexpected error while submitting your request. For assistance, please contact the Strategic Enablement Unit (SEU) at vaccine-connect-support-connexion-vaccin@phac-aspc.gc.ca, and (if possible) include a screenshot of this error message.",
};
