import { Stack, TablePagination, TableSortLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SORTING_DIRECTIONS } from "../../constants";
import { DropdownFilter } from "./DropdownFilter";
import { getDropdownOptions, getFilteredData } from "./utils/filterTableData";

/**
 * renders the data table rows
 * @param {object[]} columns - array of column objects
 * @param {object[]} items - array of data item objects
 * @param {string} actionText = the action button text
 * @param {function} onClick - the on click function handler
 * @returns {JSX.Element} - the table rows
 */
const renderTableRows = (columns, items, actionText, onClick) => {
  return (
    <>
      {items &&
        items.map((dataItem, index) => {
          return (
            <TableRow key={index}>
              {columns &&
                columns
                  .filter((column) => column.inView)
                  .map((column, index) => (
                    <TableCell key={index}>
                      {column.customViewRenderer
                        ? column.customViewRenderer({
                            value: dataItem[column.name],
                            data: dataItem,
                            onClick: () => onClick(dataItem),
                          })
                        : dataItem[column.name]}
                    </TableCell>
                  ))}
            </TableRow>
          );
        })}
    </>
  );
};

/**
 * renders a desktop data table
 * @param {object[]} columns - array og column objects
 * @param {object[]} items - array of data item objects
 * @param {object[]} filteredItems - filtered array of data item objects
 * @param setFilteredItems
 * @param {object} sortConfig - the sorting config object
 * @param {function} requestSortToggle - the request sort function
 * @param {function} setSelectedItem - the set selected item function
 * @param {number} defaultRowsPerPage
 * @param {string} setAnnounceContent - function passed on to announces content for assistive technology. It is important to keep this outside of data-table to avoid multiple renders
 * @returns {JSX.Element} - the component
 * @constructor
 */
const DataTableContent = ({
  columns,
  items,
  filteredItems,
  setFilteredItems,
  sortConfig,
  requestSortToggle,
  setSelectedItem,
  defaultRowsPerPage = 25,
  setAnnounceContent,
}) => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;
  const [pageItems, setPageItems] = useState([]);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  useEffect(() => {
    const filtered = getFilteredData(items, filters, t);
    setFilteredItems(filtered);
    const pages = _.chunk(filtered, rowsPerPage);
    setPageItems(pages[page]);
    if (pages.length < page) {
      setPage(0);
    }
  }, [items, page, filters, setFilteredItems, rowsPerPage, t]);

  const onFilterChange = ({ column, filter }) => {
    const newFilters = { ...filters, [column]: filter };
    if (!filter) delete newFilters[column];
    setFilters(newFilters);
  };

  /**
   * @param {React.MouseEvent<HTMLButtonElement>} event
   * @param {number} page - the new active page
   */
  const handlePageChange = (event, page) => setPage(page);

  /**
   * @param {React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>} event
   */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * @param {string} id - the id of the ds to display
   */
  const onClickView = (id) => setSelectedItem(id);

  const atLeastOneColumnHasFilter = columns.some(
    (column) => column.hasFilter !== false
  );

  const tableDescription =
    currentLocale && currentLocale === "fr-CA"
      ? "Ce tableau offre une fonction de tri via les boutons situés dans la ligne d'en-tête de la colonne, une seule instance étant visible à la fois."
      : "This table provides a sorting feature via the buttons across the column header row with only one instance visible at a time.";
  const columnDescriptionDesc =
    currentLocale && currentLocale === "fr-CA"
      ? "trié par ordre décroissant. Activer pour le tri ascendant"
      : "descending sorted. Activate for ascending sort";
  const columnDescriptionAsc =
    currentLocale && currentLocale === "fr-CA"
      ? "trié par ordre croissant. Activer pour le tri descendant"
      : "ascending sorted. Activate for descending sort";

  // Use announce content hook to dynamically announce content for assisstive technology whenever sortConfig object changes
  useEffect(() => {
    if (sortConfig.direction === "asc")
      setAnnounceContent(sortConfig.text + " " + columnDescriptionAsc);
    if (sortConfig.direction === "desc")
      setAnnounceContent(sortConfig.text + " " + columnDescriptionDesc);
  }, [
    columnDescriptionAsc,
    columnDescriptionDesc,
    setAnnounceContent,
    sortConfig,
  ]);

  return (
    <Stack alignItems={"center"}>
      <TableContainer>
        <Table size={"small"} aria-label={tableDescription}>
          <TableHead>
            <TableRow>
              {columns &&
                columns
                  .filter((column) => column.inView)
                  .map((column, index) => (
                    <TableCell key={index} sortDirection={false}>
                      {column.isSortable !== false ? (
                        <TableSortLabel
                          onClick={() =>
                            requestSortToggle(
                              column.text,
                              column.name,
                              column.text
                            )
                          }
                          active={sortConfig.field === column.name}
                          direction={
                            sortConfig.field === column.name
                              ? sortConfig.direction
                              : SORTING_DIRECTIONS.ASC
                          }
                        >
                          {column.text}
                          {sortConfig.field !== column.name ? (
                            <Box
                              component="span"
                              sx={visuallyHidden}
                              aria-label={
                                currentLocale && currentLocale === "fr-CA"
                                  ? "Activer pour le tri ascendant"
                                  : "Activate for ascending sort"
                              }
                            ></Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        column.text
                      )}
                    </TableCell>
                  ))}
            </TableRow>
            {atLeastOneColumnHasFilter && (
              <TableRow>
                {columns
                  .filter((column) => column.inView)
                  .map((column, index) => (
                    <TableCell
                      key={index}
                      sx={{ paddingLeft: 1, paddingRight: 1 }}
                    >
                      {column.hasFilter !== false && (
                        <DropdownFilter
                          inputValue={filters[column.name] || ""}
                          onFilterChange={onFilterChange}
                          columnName={column.name}
                          columnText={column.text}
                          items={getDropdownOptions(
                            items,
                            column.name,
                            filters,
                            t
                          )}
                        />
                      )}
                    </TableCell>
                  ))}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {renderTableRows(columns, pageItems, t("view"), onClickView)}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        sx={{ justifySelf: "center" }}
        rowsPerPageOptions={[5, 10, 25, 50]}
        count={filteredItems.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton={true}
        showLastButton={true}
        labelRowsPerPage={t("rowsPerPage")}
      />
    </Stack>
  );
};

export default DataTableContent;

DataTableContent.propTypes = {
  columns: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  sortConfig: PropTypes.object,
  requestSortToggle: PropTypes.func.isRequired,
  setSelectedItem: PropTypes.func,
};
