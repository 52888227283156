import { Auth } from "aws-amplify";
import axios from "axios";
import apiUrls from "../../../utils/requests/apiUrls";

async function getBearerToken() {
  let auth;
  try {
    auth = await Auth.currentSession();
  } catch (e) {}
  if (auth && auth.accessToken && auth.accessToken.jwtToken) {
    return auth.accessToken.jwtToken;
  } else {
    return null;
  }
}

export const postPermCreations = async (records, permissionsToken) => {
  try {
    const bearer = await getBearerToken();
    const promises = records.map((record) => {
      const url = `${apiUrls.userManagement}/permission/access/create`;
      const body = {
        email: record.User.userEmail,
        fptId: record.access_fpt_id,
        divId: record.access_division_id,
        roleId: record.access_role_id,
        isActive: record.activeInactiveFlag,
      };
      return axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${bearer}`,
          Permissions: `Bearer ${permissionsToken}`,
        },
      });
    });

    return Promise.all(promises)
      .then((responses) => responses.map((res) => res.data))
      .then((responses) => responses.flat())
      .catch((err) => console.error(err));
  } catch (e) {
    console.error("Unable to create new access records.", e);
  }
};
