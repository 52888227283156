import { getProvStateOptions } from "../components/DsManagementForm";

export const newVDSObject = ({ fptCode, division }) => {
  const provStateOptions = getProvStateOptions("CA");

  // we look through the array of province options rather than simply using the fptCode, because not all FPTs are provinces.
  const defaultProvince =
    provStateOptions.find((provState) => provState.value === fptCode)?.value ||
    "";

  return {
    dsName1: "",
    dsName2: "",
    street: "",
    city: "",
    country: "CA",
    postalCode: "",
    shippingNote: "",
    province: defaultProvince,
    contacts: [
      { name: "", phone: "", email: "" },
      { name: "", phone: "", email: "" },
      { name: "", phone: "", email: "" },
    ],
    usePfizer: false,
    longitude: "",
    latitude: "",
    preferredLanguage: "EN",
    delFlag: false,
    del: "",
    active: true,
    division: division,
  };
};
