import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { read, utils, writeFileXLSX } from "xlsx";
import { DialogModal } from "../../../components/Dialog/Dialog";
import {
  XLSX_INVENTORY_HEADERS,
  XLSX_UPLOAD_MAX_ROWS,
  XLSX_UPLOAD_SHEET_NAME,
  XLSX_UPLOAD_SHEET_NAME_FR,
  XLSX_WASTAGE_HEADERS,
} from "../../../constants";
import { useDeleteInventory } from "../hooks/useDeleteInventory";
import { useDeleteWastage } from "../hooks/useDeleteWastage";
import { useFetchCategoryList } from "../hooks/useFetchCategoryList";
import { useFetchExplanationList } from "../hooks/useFetchExplanationList";
import { useUpsertInventory } from "../hooks/useUpsertInventory";
import { useUpsertWastage } from "../hooks/useUpsertWastage";
import WasteInventoryPreviewSection from "./WasteInventoryPreviewSection";
import WasteInventoryUploadSection from "./WasteInventoryUploadSection";
const _ = require("lodash");

const WasteInventoryUploadPreviewDialog = ({
  uploadDialogVisible,
  setUploadDialogVisible,
  flow,
  setDataLoading,
}) => {
  const { t, i18n } = useTranslation("wiManagement");
  const currentLocale = i18n.language;
  const [uploadedFile, setUploadedFile] = useState(null);
  const [workSheet, setWorkSheet] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [previewDataLoading, setPreviewDataLoading] = useState(true);
  const [previewErrorMessage, setPreviewErrorMessage] = useState([]);
  const [uploadParseError, setUploadParseError] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const [confirmation, setConfirmation] = useState(true);
  const [deleteRecordNumber, setDeleteRecordNumber] = useState(0);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [action, setAction] = useState(null); // file ingestion set for deletion or for update/creation? "DELETE" or "UPSERT"
  const [doneParsing, setDoneParsing] = useState(false);
  const [sheetLanguage, setSheetLanguage] = useState("");
  const { upsertInventory } = useUpsertInventory();
  const { upsertWastage } = useUpsertWastage();
  const { deleteInventory } = useDeleteInventory();
  const { deleteWastage } = useDeleteWastage();
  const { categoryData } = useFetchCategoryList();
  const { explanationData } = useFetchExplanationList();

  /**
   * parses excel sheet for inventory, performs transformations and returns the transformed data
   * @returns transformed data
   */
  const transformInventoryData = () => {
    let transformedData = [];
    // read data from excel file and add it to transformedData
    const data = utils.sheet_to_json(workSheet, { raw: false });

    // filter out empty rows in excel sheet
    transformedData = data.filter(
      (datum) => !Object.values(datum).every((value) => _.isEmpty(value))
    );
    // excel rows validation
    if (transformedData.length === 0) throw t("uploadNoDataError");
    if (transformedData.length > XLSX_UPLOAD_MAX_ROWS)
      throw t("uploadMaxDataCount", { maxRowCount: XLSX_UPLOAD_MAX_ROWS });

    transformedData = transformedData.map((datum) => ({
      DataRow: datum[XLSX_INVENTORY_HEADERS[sheetLanguage].dataRow],
      InvReptDate: datum[XLSX_INVENTORY_HEADERS[sheetLanguage].invReptDate],
      Del: datum[XLSX_INVENTORY_HEADERS[sheetLanguage].del],
      Batch: datum[XLSX_INVENTORY_HEADERS[sheetLanguage].batch],
      AvailableStock:
        datum[XLSX_INVENTORY_HEADERS[sheetLanguage].availableStock],
    }));

    validateHeaders(
      workSheet,
      Object.values(XLSX_INVENTORY_HEADERS[sheetLanguage])
    );

    validateDuplicateReportingDate(transformedData, "InvReptDate");
    setAction(isDeleteFlow(transformedData, flow) ? "DELETE" : "UPSERT");

    // check for valid reporting date after we check for missing headers
    validateDate(transformedData, "InvReptDate");

    return transformedData;
  };

  /**
   * parses excel sheet for wastage, performs transformations and returns the transformed data
   * @returns transformed data
   */
  const transformWastageData = () => {
    let transformedData = [];
    // read data from excel file and add it to transformedData
    const data = utils.sheet_to_json(workSheet, { raw: false });

    // filter out empty rows in excel sheet
    transformedData = data.filter(
      (datum) => !Object.values(datum).every((value) => _.isEmpty(value))
    );

    // excel rows validation
    if (transformedData.length === 0) throw t("uploadNoDataError");
    if (transformedData.length > XLSX_UPLOAD_MAX_ROWS)
      throw t("uploadMaxDataCount", { maxRowCount: XLSX_UPLOAD_MAX_ROWS });

    transformedData = transformedData.map((datum) => ({
      DataRow: datum[XLSX_WASTAGE_HEADERS[sheetLanguage].dataRow],
      WasReptDate: datum[XLSX_WASTAGE_HEADERS[sheetLanguage].wasReptDate],
      Batch: datum[XLSX_WASTAGE_HEADERS[sheetLanguage].batch],
      Category: datum[XLSX_WASTAGE_HEADERS[sheetLanguage].category]
        ? datum[XLSX_WASTAGE_HEADERS[sheetLanguage].category].toUpperCase()
        : undefined,
      Explanation: datum[XLSX_WASTAGE_HEADERS[sheetLanguage].explanation],
      WastageDate: datum[XLSX_WASTAGE_HEADERS[sheetLanguage].wastageDate],
      WasQty: datum[XLSX_WASTAGE_HEADERS[sheetLanguage].wasQty],
    }));

    validateHeaders(
      workSheet,
      Object.values(XLSX_WASTAGE_HEADERS[sheetLanguage])
    );

    validateDuplicateReportingDate(transformedData, "WasReptDate");
    setAction(isDeleteFlow(transformedData, flow) ? "DELETE" : "UPSERT");
    if (!isDeleteFlow(transformedData, flow)) {
      // wastage upsert

      validateDate(transformedData, "WastageDate");
    }
    // check for valid reporting date after we check for missing headers
    validateDate(transformedData, "WasReptDate");
    return transformedData;
  };

  const handleFile = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploadedFile(file);
    setWorkSheet(null);
    setFileErrorMessage("");
  };

  const handleResetAllStates = () => {
    setUploadedFile(null);
    setWorkSheet(null);
    setFileData(null);
    setTableData(null);
    setUploadParseError(false);
    setFormSubmitSuccess(false);
    setConfirmation(true);
    setDeleteRecordNumber(0);
    setFileErrorMessage("");
    setDisableSubmit(false);
    setUploadDialogVisible(false);
    setDataLoading(true);
    setPreviewDataLoading(true);
    setPreviewErrorMessage([]);
    setDoneParsing(false);
    setSheetLanguage("");
  };

  const submitData = () => {
    if (action === "UPSERT") {
      if (flow === "INVENTORY") {
        upsertInventory(fileData, uploadedFile.name, false).then((data) => {
          if (data.StatusFlag === "LOCK") {
            setConfirmation(true);
            setPreviewErrorMessage([t("uploadDeleteLocked")]);
            return;
          }
          if (
            data.items?.filter((item) => item.StatusFlag !== "OK").length > 0
          ) {
            setFormSubmitSuccess(false);
          }
          setFormSubmitSuccess(true);
        });
      }
      if (flow === "WASTAGE") {
        upsertWastage(fileData, uploadedFile.name, false, categoryData).then(
          (data) => {
            if (data.StatusFlag === "LOCK") {
              setConfirmation(true);
              setPreviewErrorMessage([t("uploadDeleteLocked")]);
              return;
            }
            if (
              data.items?.filter((item) => item.StatusFlag !== "OK").length > 0
            ) {
              setFormSubmitSuccess(false);
            }
            setFormSubmitSuccess(true);
          }
        );
      }
    }
    if (action === "DELETE") {
      if (flow === "INVENTORY") {
        deleteInventory(fileData[0].InvReptDate, uploadedFile.name, false).then(
          (data) => {
            if (data.StatusFlag === "LOCK") {
              setConfirmation(true);
              setPreviewErrorMessage([t("uploadDeleteLocked")]);
              return;
            }
            setFormSubmitSuccess(true);
            setConfirmation(true);
          }
        );
      }
      if (flow === "WASTAGE") {
        deleteWastage(fileData[0].WasReptDate, uploadedFile.name, false).then(
          (data) => {
            if (data.StatusFlag === "LOCK") {
              setConfirmation(true);
              setPreviewErrorMessage([t("uploadDeleteLocked")]);
              return;
            }
            setFormSubmitSuccess(true);
            setConfirmation(true);
          }
        );
      }
    }
  };
  /**
   * If the user submits a spreadsheet with at least one record, and that record only has Reporting Date and Row Ref data then we know the user wants to delete data for that reporting date
   * @param {Spreadsheet Formatted Data} data
   * @returns Boolean
   */
  const isDeleteFlow = (data) => {
    if (data.length === 0) return false;
    for (const [key, value] of Object.entries(data[0])) {
      if (["InvReptDate", "WasReptDate", "DataRow"].includes(key)) continue;
      if (!_.isNil(value) && value !== "") {
        return false;
      }
    }
    return true;
  };

  /**
   * uses the dataFileHeader reference to ensure there are no duplicate reporting dates in a data object
   * @param {*} data
   * @param {*} dateFileHeader
   */
  const validateDuplicateReportingDate = (data, dateFileHeader) => {
    let reportingDates = new Set();
    data.forEach((datum) => reportingDates.add(datum[dateFileHeader]));
    if (reportingDates.size !== 1) throw t("uploadReportingDateError");
  };

  /**
   * Ensures all the required headers are in place
   * @param {*} workSheet
   * @param {*} headers to verify
   */
  const validateHeaders = (workSheet, headersToVerify) => {
    // {header: 1} option returns result as array of arrays with first array containing all excel headers
    const sheetData = utils.sheet_to_json(workSheet, {
      header: 1,
      blankrows: false,
    });
    const headers = sheetData.shift();

    const missingHeaders = [];
    headersToVerify.forEach((header) => {
      if (!headers.includes(header)) missingHeaders.push(header);
    });
    if (missingHeaders.length > 0) {
      console.warn(
        "The following column(s) were not found in the uploaded excel file or are misspelled: ",
        missingHeaders
      );
      throw t("uploadReportingHeadersMissingError", {
        headers: missingHeaders.join(", "),
      });
    }
  };

  const validateDate = (data, dataFileHeader) => {
    if (
      data.filter((datum) => !dayjs(datum[dataFileHeader]).isValid()).length ===
      0
    )
      return;
    switch (dataFileHeader) {
      case "InvReptDate":
        throw t("uploadBadReportingDateError");
      // break;
      case "WasReptDate":
        throw t("uploadBadReportingDateError");
      // break;
      case "WastageDate":
        throw t("uploadBadWastageDateError");
      // break;
      default:
        break;
    }
  };

  /**
   * sets the fileData state variable after reading and transforming excel data
   * @returns
   */
  const handlePopulateFileData = () => {
    if (!workSheet) return;
    // raw transformation here so that it all parses correctly!

    try {
      let transformedData = [];
      if (flow === "INVENTORY") {
        transformedData = transformInventoryData();
      } else if (flow === "WASTAGE") {
        transformedData = transformWastageData();
      }
      setFileData(transformedData);
    } catch (e) {
      console.error("Something went wrong when parsing the uploaded file.", e);
      setFileErrorMessage(e);
      setUploadParseError(true);
    }
  };

  useEffect(() => {
    const parseWorkSheet = async (file) => {
      setWorkSheet(null);
      setUploadParseError(false);
      if (file?.name && isValidateFileName(file.name)) {
        try {
          const data = await file.arrayBuffer();
          const wb = read(data);
          const result = wb.SheetNames.filter(
            (name) =>
              name === XLSX_UPLOAD_SHEET_NAME ||
              name === XLSX_UPLOAD_SHEET_NAME_FR
          );
          if (result.length === 0)
            throw t("uploadFileInvalidSheet", {
              sheetNameEN: XLSX_UPLOAD_SHEET_NAME,
              sheetNameFR: XLSX_UPLOAD_SHEET_NAME_FR,
            });
          setSheetLanguage(
            result[0] === XLSX_UPLOAD_SHEET_NAME
              ? "EN"
              : result[0] === XLSX_UPLOAD_SHEET_NAME_FR
              ? "FR"
              : ""
          );
          setWorkSheet(wb.Sheets[result[0]]);
          setDoneParsing(true);
        } catch (e) {
          console.error(
            "Something went wrong when parsing the uploaded file.",
            e
          );
          setFileErrorMessage(e);
          setUploadParseError(true);
        }
      }
    };

    parseWorkSheet(uploadedFile).catch(console.error);
  }, [setUploadedFile, t, uploadedFile]);

  const isValidateFileName = (fileName) => {
    if (!fileName) return false;

    if (
      ["xlsx", "xls", "xlsm"].includes(fileName.split(".").pop().toLowerCase())
    )
      return true;

    return false;
  };

  const parseData = () => {
    const currentLang = currentLocale === "en-CA" ? "EN" : "FR";
    if (flow === "INVENTORY") {
      return tableData.map((data) => {
        return {
          [XLSX_INVENTORY_HEADERS[currentLang].dataRow]: data.DataRow
            ? data.DataRow
            : null,
          [XLSX_INVENTORY_HEADERS[currentLang].invReptDate]: fileData.find(
            (datum) => datum.DataRow === data.DataRow
          )?.InvReptDate
            ? dayjs(
                fileData.find((datum) => datum.DataRow === data.DataRow)
                  ?.InvReptDate
              ).format("YYYY-MM-DD")
            : null,
          [XLSX_INVENTORY_HEADERS[currentLang].del]: data.Del
            ? data.Del[0]
            : null,
          [XLSX_INVENTORY_HEADERS[currentLang].batch]: data.Batch
            ? data.Batch
            : null,
          [XLSX_INVENTORY_HEADERS[currentLang].availableStock]:
            data.AvailableStock
              ? fileData.find((datum) => datum.DataRow === data.DataRow)
                  ?.AvailableStock
              : null,
          [t("inventoryTableErrors")]: data.StatusFlag,
        };
      });
    } else if (flow === "WASTAGE") {
      return tableData.map((data) => {
        return {
          [XLSX_WASTAGE_HEADERS[currentLang].dataRow]: data.DataRow
            ? data.DataRow
            : null,
          [XLSX_WASTAGE_HEADERS[currentLang].wasReptDate]: fileData.find(
            (datum) => datum.DataRow === data.DataRow
          )?.WasReptDate
            ? dayjs(
                fileData.find((datum) => datum.DataRow === data.DataRow)
                  ?.WasReptDate
              ).format("YYYY-MM-DD")
            : null,
          [XLSX_WASTAGE_HEADERS[currentLang].batch]: data.Batch,
          [XLSX_WASTAGE_HEADERS[currentLang].category]: data.Category
            ? data.Category
            : null,
          [XLSX_WASTAGE_HEADERS[currentLang].explanation]: data.ExpCode
            ? data.ExpCode
            : null,
          [XLSX_WASTAGE_HEADERS[currentLang].wastageDate]: fileData.find(
            (datum) => datum.DataRow === data.DataRow
          )?.WastageDate
            ? dayjs(
                fileData.find((datum) => datum.DataRow === data.DataRow)
                  ?.WastageDate
              ).format("YYYY-MM-DD")
            : null,
          [XLSX_WASTAGE_HEADERS[currentLang].wasQty]: data.WasQty
            ? fileData.find((datum) => datum.DataRow === data.DataRow)?.WasQty
            : null,
          [t("wastageTableErrors")]: data.StatusFlag,
        };
      });
    }
  };
  const exportXLSXFile = () => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(parseData());
    utils.book_append_sheet(
      wb,
      ws,
      currentLocale === "fr-CA"
        ? XLSX_UPLOAD_SHEET_NAME_FR
        : XLSX_UPLOAD_SHEET_NAME
    );
    writeFileXLSX(
      wb,
      `${flow === "INVENTORY" ? t("inventoryTitle") : t("wastageTitle")}_${t(
        "previewDownloadButton"
      )
        .split(" ")
        .pop()}_${dayjs().format("L")}.xlsx`
    );
  };
  return (
    <>
      {/* upload section */}
      {!formSubmitSuccess && !fileData && (
        <DialogModal
          open={uploadDialogVisible}
          title={t("uploadFile")}
          primaryButtonText={t("next")}
          secondaryButtonText={t("cancel")}
          maxWidth={false}
          bodyComponent={
            <WasteInventoryUploadSection
              uploadedFile={uploadedFile}
              workSheet={workSheet}
              handleFile={handleFile}
              isValidateFileName={isValidateFileName}
              uploadParseError={uploadParseError}
              uploadErrorMsg={fileErrorMessage}
              doneParsing={doneParsing}
              flow={flow}
            />
          }
          handlePrimaryClick={() => handlePopulateFileData()}
          handleSecondaryClick={() => handleResetAllStates()}
          primaryButtonDisabled={
            !uploadedFile ||
            !isValidateFileName(uploadedFile.name) ||
            !workSheet ||
            !!fileErrorMessage
          }
          handleClose={() => handleResetAllStates()}
        />
      )}
      {/* preview section */}
      {confirmation && !formSubmitSuccess && fileData && (
        <DialogModal
          open={uploadDialogVisible}
          title={
            action === "UPSERT"
              ? t("previewDialogUpsertTitle")
              : t("previewDialogDeleteTitle")
          }
          primaryButtonText={t("confirm")}
          secondaryButtonText={t("cancel")}
          tertiaryButtonText={
            action === "UPSERT" && disableSubmit
              ? t("previewDownloadButton")
              : ""
          }
          maxWidth={false}
          bodyComponent={
            <WasteInventoryPreviewSection
              fileData={fileData}
              fileName={uploadedFile.name}
              tableData={tableData}
              setTableData={setTableData}
              action={action}
              flow={flow}
              disableSubmit={disableSubmit}
              setDisableSubmit={setDisableSubmit}
              setDeleteRecordNumber={setDeleteRecordNumber}
              dataLoading={previewDataLoading}
              setDataLoading={setPreviewDataLoading}
              previewErrorMessage={previewErrorMessage}
              setPreviewErrorMessage={setPreviewErrorMessage}
              categoryData={categoryData}
              explanationData={explanationData}
              sheetLanguage={sheetLanguage}
            />
          }
          handlePrimaryClick={() =>
            action === "UPSERT" ? submitData() : setConfirmation(false)
          }
          handleSecondaryClick={() => handleResetAllStates()}
          handleTertiaryClick={exportXLSXFile}
          primaryButtonDisabled={disableSubmit}
          handleClose={() => handleResetAllStates()}
        />
      )}
      {/* success dialog section for UPSERT */}
      {formSubmitSuccess && action === "UPSERT" && (
        <DialogModal
          open={uploadDialogVisible}
          title={t("uploadUpsertSuccessTitle")}
          primaryButtonText={t("close")}
          maxWidth={false}
          bodyText={t(
            `uploadUpsertSuccessBody${
              flow[0].toUpperCase() + flow.slice(1).toLowerCase()
            }`
          )}
          handlePrimaryClick={() => handleResetAllStates()}
          handleSecondaryClick={() => handleResetAllStates()}
          handleClose={() => handleResetAllStates()}
        />
      )}
      {/* confirm dialog section for DELETE */}
      {!confirmation && !formSubmitSuccess && action === "DELETE" && (
        <DialogModal
          open={uploadDialogVisible}
          title={t("uploadDeleteConfirmTitle")}
          primaryButtonText={t("confirm")}
          secondaryButtonText={t("cancel")}
          maxWidth={false}
          bodyText={t("uploadDeleteConfirmBody", {
            recordNumber: deleteRecordNumber,
          })}
          handlePrimaryClick={() => submitData()}
          handleSecondaryClick={() => handleResetAllStates()}
          handleClose={() => handleResetAllStates()}
        />
      )}
      {/* success dialog section for DELETE */}
      {confirmation && formSubmitSuccess && action === "DELETE" && (
        <DialogModal
          open={uploadDialogVisible}
          title={t("uploadDeleteSuccessTitle")}
          primaryButtonText={t("close")}
          maxWidth={false}
          bodyText={t(
            `uploadDeleteSuccessBody${
              flow[0].toUpperCase() + flow.slice(1).toLowerCase()
            }`
          )}
          handlePrimaryClick={() => handleResetAllStates()}
          handleClose={() => handleResetAllStates()}
        />
      )}
    </>
  );
};
export default WasteInventoryUploadPreviewDialog;
