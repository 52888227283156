import { Auth } from "aws-amplify";
import axios from "axios";
import { useEffect, useState } from "react";
import apiUrls from "../../../utils/requests/apiUrls";

async function getBearerToken() {
  let auth;
  try {
    auth = await Auth.currentSession();
  } catch (e) {}
  if (auth && auth.accessToken && auth.accessToken.jwtToken) {
    return auth.accessToken.jwtToken;
  } else {
    return null;
  }
}

export const useGetSelectOptions = (listName, permissionToken) => {
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (permissionToken) {
          const bearer = await getBearerToken();
          const url = `${apiUrls.userManagement}/permission/${listName}`;
          const data = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${bearer}`,
              Permissions: `Bearer ${permissionToken}`,
            },
          });
          setData({ ...data, [listName]: data.data });
        }
      } catch (e) {
        console.error("Unable to request for fpt list.", e);
      }
    };
    fetchData().then(() => {});
  }, [listName, permissionToken]);

  return data[listName];
};
