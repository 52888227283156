import DownloadIcon from "@mui/icons-material/Download";
import { Box, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import { visuallyHidden } from "@mui/utils";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { LoaderPage } from "../LoaderPage/LoaderPage";
import DataTableContent from "./DataTableContent";
import DateFilter from "./DateFilter/DateFilter";
import SortTableData from "./utils/sortTableData";

class PropDataUpdatedCSVLink extends CSVLink {
  componentWillReceiveProps(nextProps) {
    const { data, headers, separator, uFEFF } = nextProps;
    this.setState({ href: this.buildURI(data, uFEFF, headers, separator) });
  }
}

/**
 * renders a data table
 * @param {boolean} isDataLoading - if true dataTable will start loading
 * @param {function} setSelectedItem - set selected item function
 * @param {object[]} data - the array of data objects
 * @param {object[]} columns - the array of column objects
 * @param {number} defaultRowsPerPage
 *
 * @param {{toDate: string, fromDate: string}} dateFilter - state value that contains date filter to / from values
 * @param {object} setDateFilter - set state value that for date
 * @param {string} dateFilterFieldTitle - set filter field title for date Filter
 * @param {boolean} isDateFilterEnabled - pass in a bool value to identify if date filter is enabled
 * @param {object[]} dateFilterOptions - provide filter options for DateFilter component
 *
 * @param {function} parseToCsv - optional parse csv function. if provided, enables csv file downloading, and providing csvFilenamePrefix is recommended
 * @param {string} csvFilenamePrefix - optional download csv filename prefix
 *
 * @param ActionButtonsComponent - optional action buttons component, shows at top of table
 * @param UploadButtonsComponent - optional upload buttons component, shows at top of table
 *
 * @returns {JSX.Element}
 * @constructor
 */
const DataTable = ({
  isDataLoading,
  setSelectedItem,
  data,
  columns,
  defaultRowsPerPage = 25,
  dateFilter,
  setDateFilter,
  dateFilterFieldTitle,
  isDateFilterEnabled,
  dateFilterOptions,
  parseToCsv,
  csvFilenamePrefix = "csv_download_",
  ActionButtonsComponent,
  UploadButtonsComponent,
  buttonsJustifyContent,
}) => {
  const { t } = useTranslation("common");

  const [filteredItems, setFilteredItems] = useState([]);

  const csvDataDefaultState = columns.map((column) => column.text);
  const [csvData, setCsvData] = useState([csvDataDefaultState]);
  const [announceContent, setAnnounceContent] = useState(null);

  useEffect(() => {
    if (parseToCsv) setCsvData(parseToCsv(filteredItems, columns));
  }, [columns, filteredItems, parseToCsv]);

  const { items, requestSortToggle, sortConfig } = SortTableData(data, {
    text: null,
    field: null,
    direction: null,
  });

  return (
    <>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={
          buttonsJustifyContent ? buttonsJustifyContent : "flex-end"
        }
        alignItems={"flex-end"}
        mb={4}
        spacing={1}
      >
        <Box
          component="span"
          sx={visuallyHidden}
          aria-live="assertive"
          aria-atomic="true"
        >
          {announceContent}
        </Box>
        {isDateFilterEnabled && (
          <DateFilter
            isLoading={isDataLoading}
            dateFilterFieldTitle={dateFilterFieldTitle}
            dateFilterOptions={dateFilterOptions}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
          />
        )}
        {isDataLoading &&
        (parseToCsv || ActionButtonsComponent || UploadButtonsComponent) ? (
          <Skeleton height={46.7} width={300} />
        ) : (
          <>
            {(parseToCsv ||
              ActionButtonsComponent ||
              UploadButtonsComponent) && (
              <>
                {UploadButtonsComponent && <Box>{UploadButtonsComponent}</Box>}
                {ActionButtonsComponent && <Box>{ActionButtonsComponent}</Box>}
                <Box>
                  {parseToCsv && csvData && filteredItems.length > 0 && (
                    <PropDataUpdatedCSVLink
                      tabIndex="-1"
                      filename={`${csvFilenamePrefix}-${dayjs().format(
                        "YYYY-MM-DD"
                      )}.csv`}
                      data={csvData}
                    >
                      <Button
                        variant={"outlined"}
                        startIcon={<DownloadIcon />}
                        onClick={() => null}
                        size="large"
                        sx={{ width: "max-content" }}
                      >
                        {t("exportToCsv")}
                      </Button>
                    </PropDataUpdatedCSVLink>
                  )}
                </Box>
              </>
            )}
          </>
        )}
      </Stack>
      {isDataLoading ? (
        <LoaderPage />
      ) : (
        <DataTableContent
          columns={columns}
          items={items}
          filteredItems={filteredItems}
          setFilteredItems={setFilteredItems}
          sortConfig={sortConfig}
          requestSortToggle={requestSortToggle}
          setSelectedItem={setSelectedItem}
          defaultRowsPerPage={defaultRowsPerPage}
          setAnnounceContent={setAnnounceContent}
        />
      )}
    </>
  );
};

export default DataTable;
