import { Alert, Link, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { SupportInfo } from "../../../../components/Common/SupportInfo/SupportInfo";
import { TextInput } from "../../../components/TextInput/TextInput";
import { LOGIN_STATES } from "../../../constants";

export const LoginLanding = ({
  userName,
  setUserName,
  password,
  setPassword,
  signIn,
  signInError,
  signInErrorNoUsername,
  setLoginState,
  isSubmitting,
}) => {
  const { t } = useTranslation(["login"]);

  return (
    <Stack spacing={2}>
      <TextInput
        id="username"
        label={`${t("username")} (${t("required")})`}
        required
        value={userName}
        onChange={(value) => setUserName(value)}
        onEnter={signIn}
        fullWidth={false}
        autoCompleteValue="username"
      />
      <TextInput
        id="password"
        label={`${t("password")} (${t("required")})`}
        type="password"
        required
        value={password}
        onChange={(value) => setPassword(value)}
        onEnter={signIn}
        fullWidth={false}
        autoCompleteValue="current-password"
      />
      <Button
        children={<>{t("button")}</>}
        id="login-button"
        variant="contained"
        size={"large"}
        onClick={signIn}
        disabled={userName === "" || password === "" || isSubmitting}
      />
      {signInError && <Alert severity="error">{t("incorrectUserPass")}</Alert>}
      {signInErrorNoUsername && <Alert severity="error">{t("noUser")}</Alert>}
      <Typography>
        {t("forgotPass")}{" "}
        <Link
          id="go-to-reset-password"
          tabIndex="0"
          href="#"
          onClick={() => {
            setLoginState(LOGIN_STATES.RESET_PASSWORD);
          }}
        >
          {t("resetPass")}
        </Link>
      </Typography>
      <SupportInfo supportInfoOnly={true} />
    </Stack>
  );
};

LoginLanding.propTypes = {
  userName: PropTypes.string.isRequired,
  setUserName: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  signInError: PropTypes.bool.isRequired,
  signInErrorNoUsername: PropTypes.bool.isRequired,
  setLoginState: PropTypes.func.isRequired,
};
