import { Box, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import DataTable from "../../../components/DataTable/DataTable";

/**
 * @param {function} setSelectedOrder - function setting the selected order/line-item
 * @param {object[]} orderList - the array of orders
 * @returns {JSX.Element} - the component
 * @constructor
 */
const OrderDataTable = ({
  isDateFilterEnabled,
  isDataLoading,
  setSelectedOrder,
  orderList,
  mode,
  setDateFilter,
  dateFilter,
}) => {
  const { t } = useTranslation("orderManagement");
  const transformedOrders = transformOrders(orderList);

  // doses: "Doses",
  // packs: "Packs",
  // localTime: "Local Time",
  // orderNo: "Order #",
  // lineItemNo: "Line Item #",
  // productDistributionSite: "Product Distribution site",
  // totalDosesPacks: "Total doses/packs",
  // : "Requested delivery date",
  // : "Rush order flag",
  // bulkRecreateOrders: "Bulk recreate orders",

  const columns = [
    {
      name: "Sales_Document",
      text: t("orderNo"),
      inView: true,
      inCSV: true,
      hasFilter: false,
    },
    {
      name: "Item_Number",
      text: t("lineItemNo"),
      inView: true,
      inCSV: true,
      hasFilter: false,
    },
    {
      name: "dsName",
      text: t("productDistributionSite"),
      inView: true,
      inCSV: true,
      hasFilter: false,
      customViewRenderer: ({ data }) => (
        <Tooltip
          title={`${t("sapDsNumber")}: ${data.Ship_To_Number}`}
          placement={"top"}
          arrow
        >
          <Box>{data.dsName}</Box>
        </Tooltip>
      ),
    },
    {
      name: "Ship_To_Number",
      text: "Ship_To_Number",
      inView: false,
      inCSV: true,
      hasFilter: false,
    },
    {
      name: "SKUDescription",
      text: t("productType"),
      inView: true,
      inCSV: true,
      hasFilter: false,
      customViewRenderer: ({ data }) => (
        <Tooltip
          title={`${t("sapSkuNumber")}: ${data.SKUNumber}`}
          placement={"top"}
          arrow
        >
          <Box>{data.SKUDescription}</Box>
        </Tooltip>
      ),
    },
    {
      name: "SKUNumber",
      text: t("productSKU"),
      inView: false,
      inCSV: true,
      hasFilter: false,
    },
    {
      name: "snomed",
      text: t("snomed"),
      inView: true,
      inCSV: true,
      hasFilter: false,
    },
    {
      name: "din",
      text: t("din"),
      inView: true,
      inCSV: true,
      hasFilter: false,
    },
    {
      name: "Target_Qty",
      text: t("quantity"),
      inView: true,
      inCSV: true,
      hasFilter: false,
      customViewRenderer: ({ data }) => {
        // return `${data.Target_Qty} ${data.Target_Qty_UOM}`
        return `${data.Target_Qty_UOM}`;
      },
    },
    {
      name: "Target_Qty_UOM",
      text: t("quantityUnitOfMeasure"),
      inView: false,
      inCSV: false,
      hasFilter: false,
    },
    {
      name: "Order_Qty",
      text: t("totalDosesPacks"),
      inView: true,
      inCSV: true,
      hasFilter: false,
      customViewRenderer: ({ data }) => {
        // return `${data.Order_Qty} ${data.Order_UOM}`;
        return `${data.Order_UOM}`;
      },
    },
    {
      name: "Order_UOM",
      text: t("unitsOfMeasure"),
      inView: false,
      inCSV: true,
      hasFilter: false,
    },
    {
      name: "trackingNumber",
      text: t("trackingNumber"),
      inView: true,
      inCSV: true,
      hasFilter: false,
    },
    {
      name: "lotNumber",
      text: t("lotNumber"),
      inView: true,
      inCSV: true,
      hasFilter: false,
    },
    {
      name: "expiryDate",
      text: t("expiryDate"),
      inView: true,
      inCSV: true,
      hasFilter: false,
    },
    {
      name: "ReqDateI",
      text: t("requestedDeliveryDate"),
      inView: true,
      inCSV: true,
      hasFilter: false,
      customViewRenderer: ({ data }) =>
        data.ReqDateI ? dayjs(data.ReqDateI).format("ll") : "",
    },
    {
      name: "actualDeliveryDate",
      text: t("actualDeliveryDate"),
      inView: true,
      inCSV: true,
      hasFilter: false,
    },
    {
      // name: "CurrStatusText",
      name: "deliveryStatus",
      text: t("status"),
      inView: true,
      inCSV: true,
      hasFilter: false,
    },
    {
      name: "rush",
      text: t("rushOrderFlag"),
      inView: true,
      inCSV: true,
      hasFilter: false,
      // customViewRenderer: ({ data }) => (data.isRush ? t("yes") : t("no")),
    },
    {
      name: "PurchNoC",
      text: t("fptReferenceNumber"),
      inView: true,
      inCSV: true,
      hasFilter: false,
    },
    {
      name: "actions",
      text: t("actions"),
      inView: true,
      inCSV: false,
      hasFilter: false,
      isSortable: false,
      customViewRenderer: ({ onClick, data }) => {
        // These conditions affect only the displayed text, check elsewhere to define the actual page behavior.
        // Search the code for #OrderReadOnly for other locations to define related logic.
        const viewOnly =
          data.pastAmendmentDeadline ||
          data.isRush ||
          data.isCancelled ||
          !data.isUpdatable;
        return (
          <Button
            color={viewOnly ? "secondary" : "primary"}
            onClick={onClick}
            variant="outlined"
            id={`view-${data.Sales_Document}-${data["Item_Number"]}`}
          >
            {viewOnly ? t("view") : t("edit")}
          </Button>
        );
      },
    },
  ];

  const parseToCsv = (filteredItems, columns) => {
    const results = filteredItems.map((item) => {
      const row = {};
      columns
        .filter((column) => column.inCSV)
        .forEach((column) => {
          // TODO: here's a good place to do a data transformation based on custom rules defined in a column definition.
          const value = item[column.name];
          row[column.text] = value;
        });
      return row;
    });
    return results;
  };

  const fileNamePrefix = mode === "CURR" ? t("csvExportOM") : t("csvExportOH");
  return (
    <DataTable
      isDataLoading={isDataLoading}
      setSelectedItem={(data) => setSelectedOrder(data.Sales_Document)}
      setDateFilter={setDateFilter}
      dateFilter={dateFilter}
      dateFilterFieldTitle={"requestedDeliveryDateRange"}
      isDateFilterEnabled={isDateFilterEnabled}
      columns={columns}
      data={transformedOrders}
      csvFilenamePrefix={fileNamePrefix}
      parseToCsv={parseToCsv}
    />
  );
};

/**
 * transforms the orderList data object for data table
 * @param {object[]} orderList - the unprocessed order list
 * @returns {object[]} - the transformed array
 */
export const transformOrders = (orderList) => {
  const result = orderList
    .map((order) => {
      return order["OrderGetsToItemNav"].map((lineItem) => {
        return { ...lineItem, PurchNoC: order["PurchNoC"] };
      });
    })
    .flat()
    .sort(
      // sorts by Sales_Document high-low, then Item_Number low-high
      (a, b) =>
        b.Sales_Document - a.Sales_Document || a.Item_Number - b.Item_Number
    );
  console.log(`transformed orders (example)]: `, result[0]);
  return result;
};

export default OrderDataTable;
