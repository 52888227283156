import { tableCellClasses, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetchOrderList } from "../../../2.0/hooks/useFetchOrderList";

const OrderLineItemSummary = (isMobile) => {
  const [orderStatuses, setorderStatuses] = useState(null);
  const { t } = useTranslation(["home"]);
  const StyledTableHeaderCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: "bold",
      textAlign: "center",
      width: "1%",
    },
  }));
  const StyledTableOrderStatusCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      textAlign: "center",
    },
  }));
  const { orderList, orderDataLoading } = useFetchOrderList({
    action: "CURR",
  });

  const { orderList: histOrderList, orderDataLoading: histOrderDataLoading } =
    useFetchOrderList({
      action: "HISS",
    });

  /**
   * if a line item(s) of a Product Order has statuses that include IN TRANSIT pick that over DELIVERED. Otherwise just send the first status
   * @param {*} results
   * @returns
   */
  const findDeliveryLspStatusFromLineItems = (results) => {
    if (!results || results.length === 0) return null;

    // note: DeliveryLspStatus is delivery status from the header - keep using header to determine the order status
    let tempStatus;
    results.forEach((item) => {
      tempStatus = item.DeliveryLspStatus;
      if (["IN TRANSIT", "EN TRANSIT"].includes(tempStatus)) {
        return item.DeliveryLspStatus;
      }
    });
    // if we still cannot find an IN TRANSIT Status we'll send the first status
    return results[0].DeliveryLspStatus;
  };
  /**
   * This method will accumulate the various statuses for orders and return that back
   * Moreover according to other parts of the app: status logic is in this order: DeliveryLspStatus > DeliveryItemStatusText > CurrStatusText
   * @param {*} data OrderList or HistoricalOrderList
   * @param {*} initialValue of the OrderStatuses
   * @returns
   */
  const calculateOrderStatuses = (data, initialValue) => {
    return data.reduce((acc, currVal) => {
      currVal.OrderGetsToItemNav.forEach((item) => {
        const DeliveryLspStatus = findDeliveryLspStatusFromLineItems(
          item.DeliveryGetsToOrdersNav.results
        );
        let status =
          DeliveryLspStatus ||
          item.Item_Reject_Desc ||
          item.DeliveryItemStatusText ||
          item.CurrStatusText;

        if (!status) return;

        status = status.trim();

        if (
          [
            "REJECTED",
            "CANCELLED",
            "REJECTÉ",
            "ANNULÉ",
            "FPT cancellation",
            "FPT exceeded allocation",
            "Annulation FPT",
            "FPT a dépassé l’attribution",
            "Rejeté par l’ASPC",
            "Rejected by PHAC",
            "PHAC cancellation",
            "Undelivered as per PHAC",
            "Annulation ASPC",
            "Non livré selon l’ASPC",
          ].includes(status)
        )
          return;

        switch (status) {
          case "WAITING FOR APPROVAL":
          case "EN ATTENTE D’APPROBATION":
            acc.waitingForApproval++;
            break;
          case "APPROVED":
          case "APPROUVÉ LIVRAISON":
          case "SENT TO LSP":
          case "ENVOYÉ AU FSL":
            acc.approvedOrSentToLSP++;
            break;
          case "IN TRANSIT":
          case "EN TRANSIT":
            acc.inTransit++;
            break;
          case "DELIVERED":
          case "LIVRÉ":
            acc.delivered++;
            break;
          default:
            console.warn(
              `[Order Summary] - calculateOrderStatuses - Could not find mapping for the following status: ${status}.`
            );
            break;
        }
      });
      return acc;
    }, initialValue);
  };

  useEffect(() => {
    if (orderDataLoading || histOrderDataLoading) return;
    let results = {
      approvedOrSentToLSP: 0,
      waitingForApproval: 0,
      inTransit: 0,
      delivered: 0,
    };
    results = calculateOrderStatuses(orderList, results);
    results = calculateOrderStatuses(histOrderList, results);

    setorderStatuses(results);
  }, [orderList, histOrderList, orderDataLoading, histOrderDataLoading]);

  const somethingIsLoading = orderDataLoading || histOrderDataLoading;

  return (
    <>
      <Typography variant={"h4"} mt={4} mb={2}>
        {t("lineItemSummary")}
      </Typography>
      <Typography mb={2}>{t("lineItemSummaryBody")}</Typography>
      <TableContainer>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell>
                {t("lineItemTableWaitingForApproval")}
              </StyledTableHeaderCell>
              <StyledTableHeaderCell>
                {t("lineItemTableApprovedOrSentToLSP")}
              </StyledTableHeaderCell>
              <StyledTableHeaderCell>
                {t("lineItemTableInTransit")}
              </StyledTableHeaderCell>
              <StyledTableHeaderCell>
                {t("lineItemTableDelivered")}
              </StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={1}>
              <StyledTableOrderStatusCell>
                <Typography
                  variant={"h4"}
                  component={"div"}
                  sx={{ color: "#595959" }}
                >
                  {somethingIsLoading ? (
                    <Skeleton height={36.7} />
                  ) : orderStatuses ? (
                    orderStatuses.waitingForApproval
                  ) : (
                    "-"
                  )}
                </Typography>
              </StyledTableOrderStatusCell>
              <StyledTableOrderStatusCell>
                <Typography
                  variant={"h4"}
                  component={"div"}
                  sx={{ color: "#339934" }}
                >
                  {somethingIsLoading ? (
                    <Skeleton height={36.7} />
                  ) : orderStatuses ? (
                    orderStatuses.approvedOrSentToLSP
                  ) : (
                    "-"
                  )}
                </Typography>
              </StyledTableOrderStatusCell>
              <StyledTableOrderStatusCell>
                <Typography
                  variant={"h4"}
                  component={"div"}
                  sx={{ color: "#026fc0" }}
                >
                  {somethingIsLoading ? (
                    <Skeleton height={36.7} />
                  ) : orderStatuses ? (
                    orderStatuses.inTransit
                  ) : (
                    "-"
                  )}
                </Typography>
              </StyledTableOrderStatusCell>
              <StyledTableOrderStatusCell>
                <Typography
                  variant={"h4"}
                  component={"div"}
                  sx={{ color: "#ab8a13" }}
                >
                  {somethingIsLoading ? (
                    <Skeleton height={36.7} />
                  ) : orderStatuses ? (
                    orderStatuses.delivered
                  ) : (
                    "-"
                  )}
                </Typography>
              </StyledTableOrderStatusCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OrderLineItemSummary;
