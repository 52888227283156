import dayjs from "dayjs";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Context } from "../../../../context/Context";
import { useUserAccessFilterContext } from "../../../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { postCall } from "../../../utils/requests/apiCalls";
import apiUrls from "../../../utils/requests/apiUrls";

export const useUpsertInventory = () => {
  const currentLocale = useTranslation().i18n.language;
  const { t } = useTranslation();
  const { userAttributes } = useContext(Context);
  const { selectedFptText, selectedDivision } = useUserAccessFilterContext();
  const handleDelRequest = (del) => {
    if (del === "Y" || del === "O") return "Y";
    if (!del || del.trim() === "") return "";
    return del;
  };
  const handleDelResponse = (del) => {
    switch (del) {
      case "Y":
        return currentLocale === "fr-CA" ? "Oui" : "Yes";
        break;
      case "N":
        return currentLocale === "fr-CA" ? "Non" : "No";
        break;
      default:
        return del;
    }
  };
  const upsertInventoryRequestBody = (
    fileData,
    fileName,
    testSwitch = false
  ) => `
  {"d" : {
      "Division":"0${selectedDivision}",
      "Fpt":"${selectedFptText}",
      "Filename":"${fileName}",
      "InvReptDate":"${
        fileData && fileData.length > 0
          ? dayjs(fileData[0].InvReptDate).format("YYYYMMDD")
          : ""
      }",
      "SubmitUser":"${userAttributes.username}",
      "TestSwitch":"${testSwitch ? "X" : ""}",
      "Lang":"${currentLocale === "fr-CA" ? "F" : "E"}",
      "Message_FPT_INVSet":{"results":[]},
      "Detail_FPT_INVSet":{
        "results":[
          ${fileData.map(
            (i) =>
              `{
            "Batch":"${i.Batch || ""}",
            "Del":"${handleDelRequest(i.Del)}",
            "AvailableStock":"${i.AvailableStock || ""}",
            "DataRow":"${i.DataRow || ""}",
            "Message_FPT_INVSet":{"results":[]}
            }`
          )}]}}}`;

  const upsertInventory = async (fileData, fileName, testSwitch) => {
    const result = await postCall(
      `${apiUrls.inventory}`,
      upsertInventoryRequestBody(fileData, fileName, testSwitch)
    );
    const res = await result.json();
    console.log(
      `[Inventory] ${testSwitch ? "Soft " : ""} POST SAP Response`,
      res
    );
    const Items = res.Detail_FPT_INVSet.results.map((item) => {
      return {
        StatusFlag:
          item.StatusFlag === "OK"
            ? item.StatusFlag
            : item.Message_FPT_INVSet.results
                .map((result) => result.MsgText)
                .join(". "),
        ErrorFields:
          item.StatusFlag === "OK"
            ? []
            : item.Message_FPT_INVSet.results
                .map((result) => result.MsgFieldName)
                .join(". "),
        DataRow: item.DataRow,
        InvReptDate: item.InvReptDate
          ? dayjs(item.InvReptDate).format("ll")
          : currentLocale === "fr-CA"
          ? "ND"
          : "N/A",
        MaterialName: item.MaterialName,
        MaterialGroup: item.MaterialGroup,
        Del: handleDelResponse(item.Del),
        Batch: item.Batch,
        ExpiryDate: item.ExpiryDate
          ? dayjs(item.ExpiryDate).format("ll")
          : currentLocale === "fr-CA"
          ? "ND"
          : "N/A",
        AvailableStock: t("intlNumber", {
          val: item.AvailableStock.replace(/[\,]+/g, "."),
        }),
        Uom: item.Uom,
      };
    });

    return {
      StatusFlag: res?.StatusFlag ? res.StatusFlag : "",
      ErrorMessages: res?.Message_FPT_INVSet?.results
        ? res.Message_FPT_INVSet.results.map((item) => item.MsgText)
        : [],
      Items,
    };
  };

  return { upsertInventory };
};
