import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCall } from "../../../utils/requests/apiCalls";
import apiUrls from "../../../utils/requests/apiUrls";

/**
 * Fetches the wastage category data from SAP, and does some minor processing to the response.
 * @param {string} lang
 */
const fetchCategoryList = async () => {
  console.log("[fetchCategoryList] fetching");
  const result = await getCall(`${apiUrls.wastage}/category`);
  if (!result || result.status !== 200) {
    console.error(`[fetchCategoryList] failed to fetch: `, result);
    return [];
  }

  let resultJson = null;
  try {
    resultJson = await result.json();
  } catch (e) {
    console.error("[fetchCategoryList] failed to parse result: ", e);
    return [];
  }

  if (!resultJson || !resultJson.results || !resultJson.results.length) {
    console.error("[fetchCategoryList] result contains no data: ", resultJson);
    return [];
  }
  const resultProcessed = resultJson.results.map((category) => {
    return {
      Category: category.Category,
      Description: category.CategoryDesc,
      Language: category.Language,
    };
  });

  return resultProcessed;
};

export const useFetchCategoryList = () => {
  const currentLocale = useTranslation().i18n.language;
  const [categoryData, setCategoryData] = useState([]);
  const [categoryDataLoading, setCategoryDataLoading] = useState(true);

  useEffect(() => {
    fetchCategoryList(currentLocale === "fr-CA" ? "fr" : "en").then(
      (result) => {
        setCategoryData(result);
      }
    );
  }, [currentLocale]);

  return {
    categoryData,
    setCategoryData,
    categoryDataLoading,
    setCategoryDataLoading,
  };
};
