import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import * as yup from "yup";

const validOrderSchema = yup.array().of(
  yup.object().shape({
    sku: yup.string().required(),
    quantity: yup.number().min(1).required(),
    shipTo: yup.string().required(),
    reqDate: yup.string().required(),
    isDiluent: yup.boolean(),
    markedForDeletion: yup.boolean(),
    existingItemNumber: yup.number().min(1),
  })
);

/**
 * @param {object} selectedProduct
 * @param {boolean} isDiluentSelected
 * @param {object[]} allDsLocations
 * @param {object} orderToPopulate
 */
export const useGetDeliveryWeeks = (
  selectedProduct,
  isDiluentSelected,
  allDsLocations,
  orderToPopulate
) => {
  const [deliveryWeeks, setDeliveryWeeks] = useState([]);
  const [orderValidationResults, setOrderValidationResults] = useState([]);
  const [isOrderConfirmed, setIsOrderConfirmed] = useState(false);

  const resetDeliveryWeeks = () => {
    setDeliveryWeeks([]);
    setOrderValidationResults([]);
    setIsOrderConfirmed(false);
  };

  // when diluent is unchecked, this will clear diluent quantities and errors from the state.
  const resetDeliveryWeekDiluentOrders = () => {
    const clonedWeeks = _.cloneDeep(deliveryWeeks).map((week) => {
      week.allocationRemainingDiluentCurrent =
        week.allocationRemainingDiluentOriginal;
      week.ordersTotalDiluent = 0;
      week.allocationErrorDiluent = false;
      if (week.orders)
        Object.keys(week.orders).forEach(
          (location) => (week.orders[location].quantityDiluentManual = "")
        );
      return week;
    });
    setDeliveryWeeks(clonedWeeks);
  };

  const removeLocationFromDeliveryWeekOrder = (
    weekIdentifier,
    locationValue
  ) => {
    const clonedDeliveryWeeks = _.cloneDeep(deliveryWeeks);
    const selectedDeliveryWeek = clonedDeliveryWeeks.find(
      (week) => week.weekIdentifier === weekIdentifier
    );
    delete selectedDeliveryWeek.orders[locationValue];
    selectedDeliveryWeek.selection = selectedDeliveryWeek.selection.filter(
      (location) => location.value !== locationValue
    );
    updateDeliveryWeekOrder(weekIdentifier, selectedDeliveryWeek.orders).then();
  };

  /**
   * Given a week to copy to, this will look through the previous weeks, and copy orders from
   * the most recent week that a) has orders, and b) is older than the target week.
   * @param {number} weekIdentifierCopyTo - PeriodNumber combined with Modifier as a number.
   */
  const copyOrdersFromPreviousWeek = ({ weekIdentifierCopyTo }) => {
    const clonedDeliveryWeeks = _.cloneDeep(deliveryWeeks);
    const previousDeliveryWeek = clonedDeliveryWeeks
      .filter((week) => {
        const weekHasOrders = Object.keys(week.orders).length > 0;
        const weekIsOlder = weekIdentifierCopyTo > week.weekIdentifier;
        return weekHasOrders && weekIsOlder;
      })
      .at(-1); // at() returns last item
    if (!previousDeliveryWeek)
      return console.error("there are no previous weeks with valid orders!");
    const targetDeliveryWeek = clonedDeliveryWeeks.find(
      (week) => week.weekIdentifier === weekIdentifierCopyTo
    );
    Object.keys(previousDeliveryWeek.orders).forEach((locationKey) => {
      const copiedOrder = previousDeliveryWeek.orders[locationKey];
      if (!copiedOrder.markForDeletion) {
        const existingOrder = targetDeliveryWeek.orders[locationKey];
        if (existingOrder) {
          if (!existingOrder.markForDeletion)
            targetDeliveryWeek.orders[locationKey] = {
              ...existingOrder,
              quantityProduct: copiedOrder.quantityProduct,
              quantityDiluentManual: copiedOrder.quantityDiluentManual,
              quantityDiluentAuto: copiedOrder.quantityDiluentAuto,
            };
        } else {
          targetDeliveryWeek.orders[locationKey] = {
            ...copiedOrder,
            allocationIgnoreProduct: 0,
            allocationIgnoreDiluent: 0,
            existingOrderData: undefined,
            readOnly: false,
            reqDate: targetDeliveryWeek.reqDateOptions[0].value, // default to first date
          };
        }
      }
    });
    updateDeliveryWeekOrder(
      weekIdentifierCopyTo,
      targetDeliveryWeek.orders
    ).then();
  };

  const validateAllOrders = async () => {
    const clonedDeliveryWeeks = _.cloneDeep(deliveryWeeks);
    const processedOrders = clonedDeliveryWeeks
      .map((deliveryWeek) => ({
        orders: { ...deliveryWeek.orders },
        deliveryWeekStartDate: deliveryWeek.firstDay,
        deliveryWeekEndDate: deliveryWeek.lastDay,
      }))
      .reduce((acc, week) => {
        Object.keys(week.orders).forEach((location) => {
          // prep order for location
          // a single locationOrder will have the product and diluent quantities
          const locationOrder = week.orders[location];

          // do not do anything at all with read only orders!
          if (locationOrder.readOnly) return;

          const baseOrderData = {
            reqDate: locationOrder.reqDate,
            shipTo: locationOrder.location.value,
          };

          // process product order
          const markedForDeletion = locationOrder.markForDeletion;
          const productOrderData = {
            ...baseOrderData,
            sku: selectedProduct.SKUNumber,
            quantity: markedForDeletion
              ? locationOrder.existingOrderData?.product?.originalQuantity
              : locationOrder.quantityProduct,
            existingItemNumber:
              locationOrder.existingOrderData?.product?.Item_Number,
            markedForDeletion,
          };
          const orderData = [productOrderData];

          if (isDiluentSelected) {
            // process diluent order
            const quantityDiluent =
              locationOrder.quantityDiluentManual !== ""
                ? locationOrder.quantityDiluentManual
                : locationOrder.quantityDiluentAuto;
            if (quantityDiluent || markedForDeletion) {
              const diluentOrderData = {
                ...baseOrderData,
                sku: selectedProduct.diluent.BillOfMaterialComponent,
                quantity: markedForDeletion
                  ? locationOrder.existingOrderData?.diluent?.originalQuantity
                  : quantityDiluent,
                existingItemNumber:
                  locationOrder.existingOrderData?.diluent?.Item_Number,
                markedForDeletion,
                isDiluent: true,
              };
              orderData.push(diluentOrderData);
            }
          }

          // finalize order for location
          acc.push({
            orderData,
            location: locationOrder.location.value,
            deliveryWeekStartDate: week.deliveryWeekStartDate,
            deliveryWeekEndDate: week.deliveryWeekEndDate,
          });
        });
        return acc;
      }, []);

    const validationResults = processedOrders.map(async (order) => {
      try {
        await validOrderSchema.validate(order.orderData);
        return { order, valid: true };
      } catch (e) {
        return { order, valid: false, error: e };
      }
    });

    return await Promise.all(validationResults).then((result) => {
      const allValid = result.every((value) => value.valid);
      console.log(`orders validated: `, result);
      if (!allValid) console.error(`orders are invalid: `, result);
      setIsOrderConfirmed(allValid);
      setOrderValidationResults(result);
      return result;
    });
  };

  /**
   * Sets a delivery week's order data.
   * @param {number} weekIdentifier
   * @param {Object.<string, {
   * quantityProduct: number,
   * quantityDiluentAuto: number,
   * quantityDiluentManual: number|string,
   * allocationIgnoreProduct: number,
   * allocationIgnoreDiluent: number,
   * reqDate: string,
   * location: {label: string, value: string}}>} newOrders - an object with the location value as the key
   */
  const updateDeliveryWeekOrder = async (weekIdentifier, newOrders) => {
    const clonedDeliveryWeeks = _.cloneDeep(deliveryWeeks);
    const deliveryWeek = clonedDeliveryWeeks.find(
      (week) => week.weekIdentifier === weekIdentifier
    );

    // clear validation results - no longer valid as the order has changed
    if (orderValidationResults) setOrderValidationResults([]);

    // calculate and save quantity and allocationRemaining for product
    const totalOrdersQuantityProduct = Object.keys(newOrders)
      .map((key) => newOrders[key])
      .reduce((sum, order) => {
        const unitsInThisOrder =
          (order.quantityProduct ?? 0) * selectedProduct.unitsPerPayload;
        const unitsToIgnore =
          (order.allocationIgnoreProduct ?? 0) *
          selectedProduct.unitsPerPayload;
        return sum + unitsInThisOrder - unitsToIgnore;
      }, 0);
    deliveryWeek.allocationRemainingCurrent =
      deliveryWeek.allocationRemainingOriginal - totalOrdersQuantityProduct;
    deliveryWeek.ordersTotalProduct = totalOrdersQuantityProduct;

    // error will be true if product is over-allocated (but only if there is a positive total quantity - negative get a free pass)
    deliveryWeek.allocationErrorProduct =
      totalOrdersQuantityProduct > 0 &&
      deliveryWeek.ordersTotalProduct >
        deliveryWeek.allocationRemainingOriginal;

    if (selectedProduct.diluent) {
      // automatically populates diluent based on product quantity and bill of materials
      // we do this even if diluent is not selected, in case they select it later
      Object.keys(newOrders).forEach((key) => {
        const locationOrder = newOrders[key];
        const productTotalUnits =
          locationOrder.quantityProduct * selectedProduct.unitsPerPayload;
        const desiredDiluentTotalUnits =
          productTotalUnits * selectedProduct.diluent.ratio;
        const desiredDiluentPayloads =
          desiredDiluentTotalUnits /
          selectedProduct.diluent.productMatchingBom.unitsPerPayload;
        locationOrder.quantityDiluentAuto = Math.ceil(desiredDiluentPayloads);
      });

      // only validate diluent when it's selected
      if (isDiluentSelected) {
        // calculate and save quantity and allocationRemaining for diluent
        const totalOrdersQuantityDiluent = Object.keys(newOrders)
          .map((key) => newOrders[key])
          .reduce((sum, order) => {
            const diluentQuantity =
              order.quantityDiluentManual !== ""
                ? order.quantityDiluentManual
                : order.quantityDiluentAuto;
            const diluentUnitsInThisOrder =
              diluentQuantity *
              selectedProduct.diluent.productMatchingBom.unitsPerPayload;
            const diluentUnitsToIgnore =
              (order.allocationIgnoreDiluent ?? 0) *
              selectedProduct.diluent.productMatchingBom.unitsPerPayload;
            return sum + diluentUnitsInThisOrder - diluentUnitsToIgnore;
          }, 0);
        deliveryWeek.allocationRemainingDiluentCurrent =
          deliveryWeek.allocationRemainingDiluentOriginal -
          totalOrdersQuantityDiluent;
        deliveryWeek.ordersTotalDiluent = totalOrdersQuantityDiluent;

        // error will be true if diluent is over-allocated (but only if there is a positive total quantity - negative get a free pass)
        deliveryWeek.allocationErrorDiluent =
          totalOrdersQuantityDiluent > 0 &&
          deliveryWeek.ordersTotalDiluent >
            deliveryWeek.allocationRemainingDiluentOriginal;
      }
    }

    // save newOrders and selection to deliveryWeek
    deliveryWeek.orders = newOrders;
    deliveryWeek.selection = Object.keys(newOrders).map((key) => ({
      value: key,
      label: newOrders[key].location.label,
    }));

    console.log(`updated product request data: `, clonedDeliveryWeeks);

    // save all changes to deliveryWeeks state
    setDeliveryWeeks(clonedDeliveryWeeks);
  };

  const onDeliveryWeekExpansionClick = (weekIdentifier) => () => {
    const clonedDeliveryWeeks = _.cloneDeep(deliveryWeeks);
    const deliveryWeekIndex = clonedDeliveryWeeks.findIndex(
      (week) => week.weekIdentifier === weekIdentifier
    );
    clonedDeliveryWeeks[deliveryWeekIndex].isExpanded =
      !clonedDeliveryWeeks[deliveryWeekIndex].isExpanded;
    setDeliveryWeeks(clonedDeliveryWeeks);
  };

  /**
   * Sets all delivery weeks to be expanded or not.
   * @param {boolean} isExpanded
   */
  const setAllDeliveryWeekExpansion = (isExpanded) => {
    const clonedDeliveryWeeks = _.cloneDeep(deliveryWeeks);
    clonedDeliveryWeeks.forEach((week) => (week.isExpanded = isExpanded));
    setDeliveryWeeks(clonedDeliveryWeeks);
  };

  /**
   * Accepts a list of delivery weeks to expand, any others will be collapsed.
   * @param {dayjs.Dayjs[]} weeksToExpand
   */
  const setAllDeliveryWeekExpansionByWeek = (weeksToExpand) => {
    const clonedDeliveryWeeks = _.cloneDeep(deliveryWeeks);
    clonedDeliveryWeeks.forEach((deliveryWeek) => {
      const dateMatches =
        weeksToExpand.filter((weekToExpand) =>
          weekToExpand.isSame(deliveryWeek.firstDay)
        ).length > 0;
      return (deliveryWeek.isExpanded = dateMatches);
    });
    setDeliveryWeeks(clonedDeliveryWeeks);
  };

  /**
   * Builds the list of dates for the "requested date" dropdown.
   * @param {dayjs.Dayjs} firstDay
   * @param {dayjs.Dayjs} lastDay
   * @returns {{value:string, label:string}[]}
   */
  const getReqDateOptions = (firstDay, lastDay) => {
    const end = lastDay;
    let start = firstDay;
    const reqDateOptions = [];
    while (start.isBefore(end) || start.isSame(end, "day")) {
      reqDateOptions.push({
        value: start.format("YYYY-MM-DD"),
        label: start.format("lll"),
      });
      start = start.add(1, "day");
    }
    return reqDateOptions;
  };

  useEffect(() => {
    // inits and saves the state
    const initDeliveryWeeks = async () => {
      const productWeeks = _.cloneDeep(selectedProduct.deliveryWeeks).map(
        (week) => ({
          ...week,
          allocationRemainingCurrent: week.allocationRemainingOriginal,
          orders: {},
          selection: [],
          reqDateOptions: getReqDateOptions(week.firstDay, week.lastDay),
          isExpanded: false,
        })
      );

      // if there's an associated diluent, marge its delivery weeks with the product's delivery weeks
      if (selectedProduct.diluent) {
        productWeeks.forEach((productWeek, productWeekIndex) => {
          const diluentWeek = selectedProduct.diluent.deliveryWeeks.find(
            (diluentWeek) =>
              diluentWeek.periodAndModifier === productWeek.periodAndModifier
          );
          if (diluentWeek) {
            productWeek.allocationRemainingDiluentOriginal =
              diluentWeek.allocationRemainingOriginal;
            productWeek.allocationRemainingDiluentCurrent =
              diluentWeek.allocationRemainingOriginal;
            productWeek.allocationDataDiluent = diluentWeek.allocationData;
          } else if (productWeek.submissionDeadline > dayjs())
            console.error(
              `valid product week ${productWeek.periodAndModifier} does not have a matching diluent week!`,
              selectedProduct.diluent.deliveryWeeks
            );
          productWeeks[productWeekIndex] = productWeek;
        });
      }

      if (orderToPopulate) {
        orderToPopulate.OrderGetsToItemNav.forEach((order) => {
          const matchingWeekIndex = productWeeks.findIndex(
            (week) => week.periodAndModifier === order.periodAndModifier
          );
          if (matchingWeekIndex === undefined)
            return console.error(
              `failed to find matching week: `,
              order,
              productWeeks
            );

          const matchingWeek = productWeeks[matchingWeekIndex];

          // figure out if we are working with diluent or product, determine what fields to work with
          const orderIsDiluent =
            order.SKUNumber === selectedProduct.diluent?.SKUNumber;
          const quantityFieldToSet = orderIsDiluent
            ? "quantityDiluentManual"
            : "quantityProduct";
          const existingDataFieldToSet = orderIsDiluent ? "diluent" : "product";
          // we ignore the amount already placed when doing allocation calculations so we aren't "double spending"
          const allocationIgnoreFieldToSet = orderIsDiluent
            ? "allocationIgnoreDiluent"
            : "allocationIgnoreProduct";

          const existingOrder = matchingWeek.orders[order.Ship_To_Number];
          if (existingOrder !== undefined) {
            // if order exists, we've already set most of the data, just need to define the values for the quantity/diluent we haven't set yet
            existingOrder[quantityFieldToSet] = Number(
              order.isCancelled ? 0 : order.Target_Qty
            );
            existingOrder[allocationIgnoreFieldToSet] = order.isCancelled
              ? 0
              : Number(order.Target_Qty);
            const existingOrderData = existingOrder.existingOrderData;
            existingOrder.existingOrderData = {
              ...existingOrderData,
              [existingDataFieldToSet]: {
                Item_Number: order.Item_Number,
                originalQuantity: order.Target_Qty,
                isCancelled: order.isCancelled,
              },
            };
          } else {
            // order doesn't exist, need to init the whole thing
            const location = allDsLocations.find(
              (location) => location.Ship_To_Number === order.Ship_To_Number
            );
            matchingWeek.orders[order.Ship_To_Number] = {
              quantityProduct: 0,
              quantityDiluentManual: "",
              allocationIgnoreProduct: 0,
              allocationIgnoreDiluent: 0,
              [quantityFieldToSet]: Number(
                order.isCancelled ? 0 : order.Target_Qty
              ),
              [allocationIgnoreFieldToSet]: order.isCancelled
                ? 0
                : Number(order.Target_Qty),
              reqDate: order.ReqDateI,
              location,

              // These conditions affect the line-item, not the whole order.
              // Note that the conditions here do not affect the "view/edit" button on OM/OH, they only affect the read-only state in the PR tab.
              // Search the code for #OrderReadOnly for other locations to define related logic.
              readOnly:
                order.pastAmendmentDeadline ||
                order.isRush ||
                order.isCancelled ||
                !order.isUpdatable,

              markForDeletion: order.isCancelled,
              existingOrderData: {
                [existingDataFieldToSet]: {
                  Item_Number: order.Item_Number,
                  originalQuantity: order.Target_Qty,
                  isCancelled: order.isCancelled,
                },
              },
            };
          }
          matchingWeek.isExpanded = true;
        });
      }
      setDeliveryWeeks(productWeeks);
    };

    if (selectedProduct) {
      initDeliveryWeeks().then();
    }
  }, [allDsLocations, orderToPopulate, selectedProduct]);

  return {
    deliveryWeeks,
    updateDeliveryWeekOrder,
    onDeliveryWeekExpansionClick,
    validateAllOrders,
    orderValidationResults,
    removeLocationFromDeliveryWeekOrder,
    isOrderConfirmed,
    setIsOrderConfirmed,
    resetDeliveryWeeks,
    setAllDeliveryWeekExpansion,
    setAllDeliveryWeekExpansionByWeek,
    resetDeliveryWeekDiluentOrders,
    copyOrdersFromPreviousWeek,
  };
};
