import { useContext } from "react";
import { Context } from "../../context/Context";
import { useUserAccessFilterContext } from "../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { postCall } from "../utils/requests/apiCalls";
import apiUrls from "../utils/requests/apiUrls";

/**
 * Parses orderData and pulls the highest item number from it. If no existing numbers found, returns 0.
 * @param {object[]} orderData
 * @return {number}
 */
function getHighestExistingNumber(orderData) {
  // builds a list of item numbers from existing orders
  const existingNumbers = orderData
    .map((deliveryWeek) =>
      deliveryWeek.orderData.map(
        (locationOrderData) => locationOrderData.existingItemNumber
      )
    )
    .flat()
    .filter((number) => number !== undefined);
  // get the highest item number the list
  // if no numbers found, default to 1, which will later round up to 10
  return Math.max(...existingNumbers, 1);
}
/**
 * Takes the last item number, and increments accordingly.
 * @param {number} lastItemNumber
 * @param {boolean} isDiluent
 * @return {number}
 */
function getNewItemNumber(lastItemNumber, isDiluent) {
  let nextNumber = lastItemNumber;
  if (!isDiluent) {
    nextNumber = nextNumber + 1;
    // rounds up to the nearest 10
    nextNumber = Math.ceil(nextNumber / 10) * 10;
  } else {
    nextNumber += 1;
  }
  return nextNumber;
}

/**
 * @param {'CREATE'|'CHANGE'} action - the value of the 'Action' field to send to SAP API
 * @param {number?} existingSalesDocument - if action is CHANGE, an existing order number must be provided
 */
export const useCreateOrder = ({ action, existingSalesDocument }) => {
  const { userAttributes } = useContext(Context);
  const { selectedFptText, selectedDivision } = useUserAccessFilterContext();

  // completely block this hook if action is not defined
  if (!action) return { postOrder: undefined };

  if (action === "CHANGE" && !existingSalesDocument) {
    console.error("useCreateOrder missing required existingSalesDocument");
    return { postOrder: undefined };
  }

  function transformOrderDataToPostData(orderData, productInformation) {
    try {
      let lastItemNumber = getHighestExistingNumber(orderData);
      let currentItemNumber = lastItemNumber;
      const processedOrders = orderData
        .map((deliveryWeek) => {
          const processed = deliveryWeek.orderData.map((locationOrderData) => {
            if (locationOrderData.existingItemNumber) {
              currentItemNumber = locationOrderData.existingItemNumber;
            } else {
              currentItemNumber = getNewItemNumber(
                lastItemNumber,
                locationOrderData.isDiluent
              );
              lastItemNumber = currentItemNumber;
            }
            const actionItem =
              action === "CHANGE" &&
              locationOrderData.existingItemNumber !== undefined
                ? "UPDATE"
                : "ADD";
            return {
              // dynamic data from user input
              SKUNumber: String(locationOrderData.sku),
              Target_Qty: String(locationOrderData.quantity),
              Ship_To_Number: String(locationOrderData.shipTo),
              ReqDateI: locationOrderData.reqDate,

              // static data
              Action_Item: actionItem,
              Target_Qty_UOM: "PL",

              // calculated data
              Item_Number: String(currentItemNumber),
              Item_Reject_Reason: locationOrderData.markedForDeletion
                ? "Z0"
                : "",

              // not implemented data
              // AllocationNote: "string",
            };
          });
          console.log(`processed single week data: `, processed);
          return processed;
        })
        .flat();
      console.log(`processed all week data: `, processedOrders);
      const fullOrderData = {
        d: {
          DocType: "ZOR",
          Action: action,
          Sales_Document:
            action === "CHANGE" ? String(existingSalesDocument) : "",
          SalesOrg: "1000",
          DistrChan: "00",
          Division: `0${selectedDivision}`,
          CompCdeB: "1000",
          CreatedBy: userAttributes.username,
          Sold_To: selectedFptText,
          PurchNoC: productInformation.fptRef,
          Notes: productInformation.groupNotes,
          HeaderToItemNav: { results: processedOrders },
        },
      };
      console.log(`fullOrderData: `, fullOrderData);
      return fullOrderData;
    } catch (e) {
      console.error("error transforming order data", e);
    }
  }

  const postOrder = async (orderData, productInformation) => {
    console.log("posting create order", orderData);

    const postData = transformOrderDataToPostData(
      orderData,
      productInformation
    );
    console.log(`postData: `, postData);
    const result = await postCall(
      apiUrls.createOrder,
      JSON.stringify(postData)
    );
    if (result === null) {
      console.log("failed to post order");
      return null;
    }
    const resultJson = await result.json();
    console.log(`resultJson: `, resultJson);

    return resultJson;
  };

  return { postOrder };
};
