import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
/**
 * renders a back button
 * @param {function} onClick - on click handler
 * @returns {JSX.Element} - the component
 * @constructor
 */
export const BackButton = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Button
      id="tertiaryButton"
      variant="outlined"
      children={t("back")}
      onClick={onClick}
    />
  );
};

/**
 * renders an edit button
 * @param {boolean} editable - editable form state
 * @param {function} onClick - on click handler
 * @returns {JSX.Element} - the component
 * @constructor
 */
export const EditButton = ({ editable, onClick }) => {
  const { t } = useTranslation();

  if (editable) {
    return null;
  }

  return (
    <Button
      id="editButton"
      variant="contained"
      children={t("edit")}
      onClick={onClick}
    />
  );
};

/**
 * renders a submit button
 * @param {boolean} editable - editable form state
 * @param {boolean} disabled - disabled state
 * @param {function} onClick - on click handler
 * @returns {JSX.Element} - the component
 * @constructor
 */
export const SubmitButton = ({ editable, disabled, onClick }) => {
  const { t } = useTranslation();

  if (!editable) {
    return null;
  }

  return (
    <Button
      id="submitButton"
      children={t("submit")}
      variant="contained"
      onClick={onClick}
      disabled={disabled}
    />
  );
};

/**
 * renders an cancel button
 * @param {boolean} editable - editable form state
 * @param {boolean} disabled - disabled state
 * @param {function} onClick - on click handler
 * @returns {JSX.Element} - the component
 * @constructor
 */
export const CancelButton = ({ editable, disabled, onClick }) => {
  const { t } = useTranslation();

  if (!editable) {
    return null;
  }

  return (
    <Button
      id="cancelButton"
      variant="outlined"
      children={t("cancel")}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

/**
 * renders an clear form button
 * @param {boolean} editable - editable form state
 * @param {boolean} disabled - disabled state
 * @param {function} onClick - on click handler
 * @returns {JSX.Element} - the component
 * @constructor
 */
export const ClearFormButton = ({ editable, disabled, onClick }) => {
  const { t } = useTranslation();

  if (!editable) {
    return null;
  }

  return (
    <Button
      id="clearFormButton"
      variant="outlined"
      children={t("clearForm")}
      onClick={onClick}
      disabled={disabled}
    />
  );
};
