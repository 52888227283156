import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../components/TextInput/TextInput";
import { useUserAccessFilterContext } from "../../../components/UserAccessFilter/contexts/UserAccessFilterContext";

export const OrderMetadata = ({
  productInformation,
  setProductInformation,
  readOnly,
}) => {
  const { t } = useTranslation(["productRequest"]);
  const { selectedFptText } = useUserAccessFilterContext();

  const onFieldsChange = ({ fieldName, value }) => {
    const clonedFields = Object.assign({}, productInformation);
    clonedFields[fieldName] = value;
    setProductInformation(clonedFields);
  };

  // keep FPT field in sync with User Management dropdowns
  useEffect(() => {
    if (productInformation.fpt !== selectedFptText)
      setProductInformation({ ...productInformation, fpt: selectedFptText });
  }, [selectedFptText, productInformation, setProductInformation]);

  return (
    <div>
      <Typography variant={"h4"} component={"h3"} marginBottom={3}>
        {t("productInformation")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            id={"selectedFPTRegion"}
            value={productInformation.fpt}
            label={t("selectedFPTRegion")}
            disabled={true}
            error={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            id={"FPTReferenceNumber"}
            value={productInformation.fptRef}
            label={t("FPTReferenceNumber")}
            onChange={(value) => onFieldsChange({ fieldName: "fptRef", value })}
            maxLength={35}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <TextInput
            id={"groupNotes"}
            value={productInformation.groupNotes}
            label={t("groupNotes")}
            helperText={t("groupNotesHint")}
            onChange={(value) =>
              onFieldsChange({ fieldName: "groupNotes", value })
            }
            maxLength={500}
            multiline
            rows={5}
            disabled={readOnly}
          />
        </Grid>
      </Grid>
    </div>
  );
};
