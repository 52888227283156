export function getCanUseInventoryWastagePage(
  selectedFptText,
  selectedDivision
) {
  // the FPTs/divisions listed here are the only ones allowed to use the inventory/wastage pages, otherwise we will disable the page completely
  return {
    canUseInventoryPage: [1].includes(selectedDivision),
    canUseWastagePage:
      [1].includes(selectedDivision) && ["ON"].includes(selectedFptText),
  };
}
