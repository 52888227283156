import { Alert, Container, Stack, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Context } from "../../../context/Context";
import { DialogModal } from "../../components/Dialog/Dialog";
import { LoaderPage } from "../../components/LoaderPage/LoaderPage";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { useUserAccessFilterContext } from "../../components/UserAccessFilter/contexts/UserAccessFilterContext";
import { ALLOCATION_ERROR_STATES } from "../../constants";
import { useFetchProductList } from "../../hooks/useFetchProductList";
import { useSkipContentId } from "../../hooks/useSkipContentId";
import { useTranslateUnits } from "../../hooks/useTranslateUnits";
import * as postCalls from "../../utils/requests/apiPostCalls.js";
import { AllocationsTable } from "./components/AllocationTable";
import {
  filterProductsByActionableDeliverWeeks,
  getTableDataDifference,
  initialAllocationTableBuilder,
} from "./utils/allocationUtils";
import { getCanUseAllocationPage } from "./utils/getCanUseAllocationPage";

const AllocationsPage = () => {
  const { t } = useTranslation(["allocationManagement"]);
  const skipContentId = useSkipContentId();
  const { getTranslatedUnitsPerPayload } = useTranslateUnits();
  const { userAttributes } = useContext(Context);
  const [error, setError] = useState(false);
  const [displayNotice, setDisplayNotice] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [initialTableData, setInitialTableData] = useState({});
  const [allocationTableData, setAllocationTableData] = useState({});
  const [toBeUpdated, setToBeUpdated] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [requestSuccessfulModal, setRequestSuccessfulModal] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorText, setRequestErrorText] = useState(null);
  const {
    productListOptions,
    productDataLoading,
    setProductDataLoading,
    selectedProduct,
    setSelectedProduct,
  } = useFetchProductList();

  const productsFiltered =
    filterProductsByActionableDeliverWeeks(productListOptions);

  // internal loading is when the page itself is sending a request
  const [internalLoading, setInternalLoading] = useState(false);
  const somethingIsLoading = productDataLoading || internalLoading;

  const { selectedFptText } = useUserAccessFilterContext();

  const reserve = allocationTableData["99991"];
  const filterReserve = (weekIdentifier) =>
    // do not display the reserve in the table
    {
      return weekIdentifier !== "99991";
    };
  /**
   * Applies the allocation change to the table dataset. Includes "autocorrect" logic when users enter values too high or low.
   * @param {number|string} newValue - the change requested by the user
   * @param {string} weekIdentifier - the weekIdentifier that is being updated
   */
  const handleAllocationRequestTableChange = (newValue, weekIdentifier) => {
    const newTableData = _.cloneDeep(allocationTableData);
    const allocation = newTableData[weekIdentifier];

    // convert number string to a number, but only if it's a number. allow blank strings so user can backspace the text box.
    if (!isNaN(newValue) && newValue !== "") newValue = parseInt(newValue);

    allocation.requested = newValue;

    // limit negative numbers to whatever the currently approved allocations are for that week
    if (newValue < 0) {
      const overLimit =
        Math.abs(newValue) >
        allocation.allocationData.netWithPlacedApprovedPending;
      if (overLimit) {
        allocation.requested =
          allocation.allocationData.netWithPlacedApprovedPending * -1;
        setDisplayNotice("underLimitNotice");
      }
    }

    const reserve = newTableData["99991"];
    if (!reserve || !reserve.allocationData)
      return console.error(
        "unable to find reserve for product",
        selectedProduct
      );

    // sum together the reserve plus any changes from each week.
    // availableToAllocate will represent the max number of allocations for that week, considering the reserve and PREVIOUS weeks.
    // we are deliberately not considering FUTURE weeks, to prevent front-loading.
    let availableToAllocate =
      reserve.allocationData.netWithPlacedApprovedPending;
    Object.entries(newTableData).forEach(([key, week]) => {
      if (key === "99991") return; // do not include the reserve in the sum
      week.availableToAllocate = availableToAllocate;
      availableToAllocate -= week.requested;
    });

    // limit positive numbers to the available amount for that week (considering the reserve and PREVIOUS weeks)
    if (newValue > 0) {
      const overLimit = newValue > allocation.availableToAllocate;
      if (overLimit) {
        allocation.requested = allocation.availableToAllocate;
        setDisplayNotice("overLimitNotice");
      }
    }

    // sum together all weeks and use the number to adjust the reserve
    reserve.requested = Object.keys(newTableData).reduce(
      (sum, weekIdentifier) => {
        if (weekIdentifier === "99991") return sum; // do not include the reserve in the sum
        return (
          sum +
          initialTableData[weekIdentifier].requested -
          newTableData[weekIdentifier].requested
        );
      },
      0
    );
    console.log(`updated allocation data: `, newTableData);
    setAllocationTableData(newTableData);
  };

  const clearTable = () => {
    setAllocationTableData(initialTableData);
    setDisplayNotice("");
  };

  async function submitTable() {
    //Add the reserve to the table if it is different
    const table = toBeUpdated;
    table["99991"] = allocationTableData["99991"];
    setInternalLoading(true);
    try {
      const responseData = await postCalls.modifyAllocations(
        selectedProduct,
        table,
        selectedFptText,
        userAttributes.username
      );
      console.log(`[modifyAllocations] sap response json: `, responseData);
      if (responseData && responseData.modifyAllocationSet) {
        clearTable();
        setProductDataLoading(true);
        setInternalLoading(false);
        setConfirmModal(false);
        setRequestSuccessfulModal(true);
        setRequestError(false);
        setRequestErrorText(null);
      } else {
        if (responseData.error === ALLOCATION_ERROR_STATES.tableLocked) {
          setRequestErrorText(t("lockedError"));
        } else {
          setRequestErrorText(t("requestErrorText"));
        }
        setRequestSuccessfulModal(true);
        setRequestError(true);
        setInternalLoading(false);
      }
    } catch (e) {
      console.error(
        `[modifyAllocations] unexpected error submitting allocation change: `,
        e
      );
      setRequestSuccessfulModal(true);
      setRequestError(true);
      setRequestErrorText(t("requestErrorText"));
      setInternalLoading(false);
    }
  }

  // This handles clearing the selected product when Division/FPT changes.
  useEffect(() => {
    setSelectedProduct(null);
  }, [productListOptions, setSelectedProduct]);

  // This handles initializing the table once a product has been selected.
  useEffect(() => {
    console.log(`selectedProduct: `, selectedProduct);
    const initialData = initialAllocationTableBuilder(
      selectedProduct,
      productListOptions
    );
    setInitialTableData(initialData);
    setAllocationTableData(initialData);
    setDisplayNotice("");
  }, [selectedProduct, productListOptions]);

  // This handles whenever the user makes changes to the table, and calculating what changes have been made.
  useEffect(() => {
    setToBeUpdated(
      getTableDataDifference(allocationTableData, initialTableData)
    );
  }, [allocationTableData, initialTableData]);

  // This handles disabling buttons when no changes have been made.
  useEffect(() => {
    if (toBeUpdated && Object.keys(toBeUpdated).length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [toBeUpdated]);

  // This handles calculating the reserve error whenever the user makes changes to the table.
  useEffect(() => {
    if (!reserve) return;
    const reserveAfterApproval =
      reserve.allocationData.netWithPlacedApprovedPending + reserve.requested;

    setError(reserveAfterApproval < 0);
  }, [allocationTableData, reserve]);

  const canUseAllocationPage = getCanUseAllocationPage(selectedFptText);

  if (!canUseAllocationPage)
    return (
      <Container maxWidth={"lg"}>
        <PageHeader title={t("title")} description={t("notAllowedPage")} />
      </Container>
    );
  return (
    <Container maxWidth={"lg"} id={skipContentId}>
      <PageHeader title={t("title")} description={t("subTitle")} />
      <Stack spacing={2} mb={4}>
        <Typography>{t("guidelinesIntro")}</Typography>
        <ul>
          <li>{t("guideline1")}</li>
          <li>{t("guideline2")}</li>
          <li>{t("guideline3")}</li>
          <li>{t("guideline4")}</li>
          <li>{t("guideline5")}</li>
        </ul>
        <Typography>{t("toUseIntro")}</Typography>
        <ul>
          <li>{t("toUse1")}</li>
          <li>{t("toUse2")}</li>
          <li>{t("toUse3")}</li>
          <li>{t("toUse4")}</li>
          <li>{t("toUse5")}</li>
          <li>{t("toUse6")}</li>
        </ul>
      </Stack>
      <Stack spacing={4} mb={4}>
        {somethingIsLoading ? (
          <LoaderPage />
        ) : (
          <>
            <Typography variant={"h5"} component={"h3"}>
              {t("titleChangeRequest")}
            </Typography>
            <Stack direction={"row"} spacing={2}>
              <Autocomplete
                id={`vaccine-dropdown`}
                value={selectedProduct}
                onChange={(event, value) => {
                  setSelectedProduct(value);
                }}
                options={productsFiltered}
                disableClearable
                fullWidth
                getOptionLabel={(item) => item.label}
                renderInput={(params) => (
                  <TextField {...params} label={t("chooseProductType")} />
                )}
              />
              <TextField
                id={`doses-per-payload`}
                fullWidth
                label={getTranslatedUnitsPerPayload(selectedProduct?.BaseUoM)}
                value={selectedProduct ? selectedProduct.unitsPerPayload : ""}
                disabled
              />
            </Stack>
            {selectedProduct && reserve && (
              <>
                {Object.keys(allocationTableData).filter(filterReserve)
                  .length >= 1 && (
                  <AllocationsTable
                    allocationTableData={allocationTableData}
                    handleAllocationRequestTableChange={
                      handleAllocationRequestTableChange
                    }
                  />
                )}
                {Object.keys(allocationTableData).filter(filterReserve)
                  .length === 0 && (
                  <Typography>
                    <b>
                      {t("noDeliveryWeekText", {
                        selectedProduct: selectedProduct.label,
                      })}
                    </b>
                  </Typography>
                )}

                {error && <Alert severity={"error"}>{t("reserveError")}</Alert>}
                {displayNotice && (
                  <Alert severity={"warning"}>{t(displayNotice)}</Alert>
                )}
                <Typography variant={"h5"} component={"h3"}>
                  {t("titleReserveAllocation")}
                </Typography>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    id={`approved-allocations`}
                    label={t("approvedChangeToReserve")}
                    helperText={t("approvedChangeToReserveTip")}
                    value={reserve.allocationData.netWithPlacedApproved}
                    fullWidth
                    disabled={true}
                  />
                  <TextField
                    id={`requested-allocations-pending`}
                    label={t("pendingChanges")}
                    value={reserve.allocationData.pending}
                    fullWidth
                    disabled={true}
                  />
                  <TextField
                    id={`requested-allocations-requested`}
                    label={t("requestedChangeToReserve")}
                    value={reserve.requested}
                    fullWidth
                    disabled={true}
                  />
                  <TextField
                    id={`requested-allocations-new-reserve`}
                    label={t("newReserveAfterApproval")}
                    value={
                      reserve.allocationData.netWithPlacedApprovedPending +
                      reserve.requested
                    }
                    fullWidth
                    disabled={true}
                  />
                  <TextField
                    id={`requested-allocations-pending`}
                    label={t("approvedAllocations")}
                    value={
                      reserve?.allocationData.rawAllocationData
                        ?.CarryForwardQty || 0
                    }
                    fullWidth
                    disabled={true}
                  />
                </Stack>

                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    id="secondaryButton"
                    variant="outlined"
                    children={t("undoChanges")}
                    disabled={disabled}
                    onClick={() => clearTable()}
                  />
                  <Button
                    id="primaryButton"
                    variant={"contained"}
                    children={t("submitRequest")}
                    disabled={error || disabled}
                    onClick={() => {
                      setConfirmModal(true);
                    }}
                  />
                </Stack>
              </>
            )}
          </>
        )}
        {confirmModal && (
          <DialogModal
            open={confirmModal}
            title={t("dialogConfirmTitle")}
            bodyText={t("dialogConfirmMessage")}
            primaryButtonText={t("confirmRequest")}
            handlePrimaryClick={(e) => {
              e.preventDefault();
              setConfirmModal(false);
              submitTable().then();
            }}
            handleSecondaryClick={() => setConfirmModal(false)}
            secondaryButtonText={t("back")}
            handleClose={() => setConfirmModal(false)}
          />
        )}
        {requestSuccessfulModal && (
          <DialogModal
            open={requestSuccessfulModal}
            title={requestError ? t("requestError") : t("requestSubmitted")}
            bodyText={
              requestError ? requestErrorText : t("requestSubmittedText")
            }
            primaryButtonText={t("okay")}
            handlePrimaryClick={() => {
              setRequestSuccessfulModal(false);
            }}
            handleClose={() => setConfirmModal(false)}
          />
        )}
      </Stack>
    </Container>
  );
};

export default AllocationsPage;
