import { Auth } from "aws-amplify";

//Wrapper function for a get call
export async function getCall(url, permissionToken) {
  const bearer = await getBearerToken();
  if (bearer) {
    const headers = {
      Authorization: `Bearer ${bearer}`,
    };
    if (permissionToken) {
      headers.Permissions = `Bearer ${permissionToken}`;
    }
    try {
      const response = await fetch(url, {
        method: "get",
        headers: new Headers(headers),
      });
      if (response.status !== 200)
        console.error("error in getCall: ", url, response);
      return response;
    } catch (e) {
      console.error("error in getCall: ", url, e);
    }
  }
  return null;
}

/**
 * Wrapper function for a post call
 * @param {string} url - the url to send the post request to
 * @param {string} requestBody - the stringified request body
 */
export async function postCall(url, requestBody) {
  const bearer = await getBearerToken();
  if (bearer) {
    try {
      return await fetch(url, {
        method: "post",
        headers: new Headers({
          Authorization: `Bearer ${bearer}`,
          "Content-Type": "application/json",
        }),
        body: requestBody,
      });
    } catch (e) {
      console.error("error in postCall:", e);
    }
  }
  return null;
}

//Grabs the current sessions bearer token if exists
async function getBearerToken() {
  let auth;
  try {
    auth = await Auth.currentSession();
  } catch (e) {}
  if (auth && auth.accessToken && auth.accessToken.jwtToken) {
    return auth.accessToken.jwtToken;
  } else {
    return null;
  }
}
