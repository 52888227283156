import { ButtonBase } from "@mui/material";
import { styled } from "@mui/system";
import variables from "../../../styles/base/color.scss";

export const NavigationButton = styled(ButtonBase, {
  // we don't forward our internal prop isMobile
  shouldForwardProp: (prop) => {
    if (prop === "isMobile") return false;
    return true;
  },
})(({ theme, selected, isMobile }) => ({
  backgroundColor: "transparent",
  color: variables.colorWhite,
  textAlign: "center",
  fontFamily: "Lato",
  border: "none",
  borderLeft: `4px solid ${
    selected && isMobile ? variables.colorWhite : variables.colorBlue
  } !important`,
  borderBottom: `4px solid ${
    selected && !isMobile ? variables.colorWhite : variables.colorBlue
  } !important`,
  padding: "17px",
  fontSize: "20.5px",
  fontWeight: "600",
  lineHeight: "24px",
  borderRadius: "unset",
  height: "100%",
  "&:hover": {
    textDecoration: "underline",
  },
  "&:active": {
    color: variables.colorWhite,
    backgroundColor: "transparent",
  },
  "&.Mui-focusVisible": {
    outline: "auto 1px",
    outlineColor: variables.colorWhite,
    outlineOffset: 0,
    borderBottom: `4px solid ${variables.colorBlue} !important`,
  },
}));
