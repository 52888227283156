import { Box } from "@mui/material";
import Loader from "react-loader-spinner";

export function LoaderPage() {
  return (
    <Box display={"flex"} justifyContent={"center"} margin={6}>
      <Loader type="Puff" color="#3a73d8" height={100} width={100} />
    </Box>
  );
}
